import { useState, useMemo, useCallback, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import { FiCheck, FiX } from 'react-icons/fi';
import { ColorizedAvatars } from '../../../ui/elements/ColorizedAvatars'
import { Badge } from 'primereact/badge';
import { Dialog } from 'primereact/dialog';
import PermissionsSelectForm from "../PermissionsSelectForm";
import axios from "axios";
import { updateGroupsAndPermissions } from "../../../../api";


export const FooterContent = ({ onHide, save, userInfo }) => (
    <div className='col-12'>
        <div className="row border-top border-bottom mb-2">
            <div className='col-lg-6 col-12 d-flex align-items-center text-start small'>
                <div className='b-radius border-1 opacity-30 px-1 py-1 me-2'>
                    <FiX className='text-dark' />
                </div>
                Screen-readers already announce `img` tags as
            </div>
            <div className='col-lg-6 col-12 d-flex align-items-center text-start small '>
                <div className='bg-info opacity-80 b-radius border-1 px-1 py-1 me-2' style={{ borderColor: 'transparent' }}>
                    <FiCheck className='text-white' />
                </div>
                Screen-readers already announce `img` tags as
            </div>
            <div className='col-lg-6 col-12 d-flex align-items-center text-start small'>
                <div className='bg-danger opacity-80 b-radius border-1 px-1 py-1 me-2' style={{ borderColor: 'transparent' }}>
                    <FiX className='text-white' />
                </div>
                Screen-readers already announce `img` tags as
            </div>
            <div className='col-lg-6 col-12 d-flex align-items-center text-start small'>
                <div className='bg-primary opacity-80 b-radius border-1 px-1 py-1 me-2' style={{ borderColor: 'transparent' }}>
                    <FiCheck className='text-white' />
                </div>
                Screen-readers already announce `img` tags as
            </div>
        </div>
        <div className='col-12'>
            <div className='d-flex justify-content-center'>
                <Button label="Չեղարկել" icon="pi pi-times" className='mx-2' rounded outlined severity='secondary' onClick={onHide} />
                <Button label="Պահպանել" icon="pi pi-check" className='mx-2' rounded onClick={(e) => { console.log({ abcd: save }); save && save(); onHide() }} />
            </div>
        </div>
    </div>
);
export const groupCreateFooterContent = ({ setVisible, accept, userInfo }) => (
    <div className='col-12'>
        <div className='d-flex justify-content-center'>
            <Button label="Չեղարկել" icon="pi pi-times" className='mx-2' rounded outlined severity='secondary' onClick={() => setVisible(false)} />
            <Button label="Պահպանել" icon="pi pi-check" className='mx-2' rounded onClick={(e) => { accept(); setVisible(false) }} />
        </div>
    </div>
);


export const headerContent = (userInfo) => {
    return (
        <div className="d-flex">
            <span className="text-muted fw-normal"> You are going to add or change permissions for a user </span>
            <span className='ms-1 text-danger fw-bold'> {userInfo?.username} </span>
        </div>
    )
};

export const avatarBody = (userInfo) => {
    return (
        userInfo?.profile?.avatar ?
            <Avatar image={userInfo?.profile?.avatar} size="xlarge" shape="circle" />
            :
            <Avatar label={userInfo?.username?.charAt(0)} size="xlarge" shape="circle"
                style={{ background: `linear-gradient(135deg, ${ColorizedAvatars(userInfo?.username?.substring(0, 1))} 30%, ${ColorizedAvatars(userInfo?.username?.substring(0, 3))} 100%`, color: '#fff' }} />
    )
};

export const detailsBody = (userInfo) => {
    return (
        <div className='d-grid'>
            <div className='text-primary'>{userInfo?.profile?.partner?.full_name_am}</div>
            <div className='text'>{userInfo?.profile?.partner?.full_name_en}</div>
            <div className=''>{userInfo?.profile?.partner?.phone}</div>
            <div className=''>{userInfo?.profile?.partner?.email}</div>
        </div>
    )
};

export const statusBody = (userInfo) => (
    userInfo?.groups?.map((group, i) =>
        <Badge key={i} className='m-1' value={`${group.name}`}></Badge>
    )
);

export const UserPermissionDialog = ({ userInfo, visible, onHide, groups, refreshContent, toast, editGroup }) => {
    const [param, setParam] = useState({})
    const save = useCallback(async () => {
        try {
            await axios.post(updateGroupsAndPermissions, param);
            refreshContent()
            toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
        } catch (error) {
            toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        }

    }, [param])

    return <Dialog header={headerContent(userInfo)} visible={visible} className='col-lg-5 col-md-7 col-10' onHide={onHide} footer={FooterContent({ onHide, save })}>
        <PermissionsSelectForm groups={groups} userInfo={userInfo} setParam={setParam} />
    </Dialog>
}