import React from 'react'
import { Avatar } from 'primereact/avatar';
import data from '../../json_content/AZKDataProjects.json'
import { Link } from 'react-router-dom';

export default function TicketsPreview() {
  return (
    <div className="card w-100 b-radius b-shadow p-2">
      <div className="px-4 py-3 h6 border-bottom align-items-center text-uppercase"><i className='pi pi-inbox me-3'></i>My Tickets</div>
      <div className="col-12">

        <ul className="list-unstyled px-3">
          {data.slice(0, 5).map((p, i) => {
            return (
              <li className="list-group border-bottom mb-3 py-2">
                <a className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <Avatar label={p.ProjectNameEng.substring(0, 1)} className='p-overlay-badge' size="large" shape="circle" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h6 className="text-truncate mb-1 text-wrap fw-bold">{p.ProjectFullName.substring(0, 40)}{p.ProjectFullName.length > 40 ? '...' : null}</h6>
                    <p className="text-truncate mb-0 text-muted text-wrap">{p.ProjectNameEng.substring(0, 70)}{p.ProjectNameEng.length > 70 ? '...' : null}</p>
                  </div>
                </a>
              </li>
            )
          })}

          <li className="list-group mb-3 pt-2">
            <a className="d-flex align-items-center justify-content-center text-center">
              <div className="flex-grow-1 ms-3">
                <Link to={''} className='btn btn-label-primary rounded px-4 py-2 small'>
                  <h6 className="text-truncate m-0">More ...</h6>
                </Link>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}
