import { useEffect, useState } from 'react'
import logo_main from '../../assets/edm_main.png'
import Footer from '../ui/Footer'
import FakeNavbar from './components/ui/FakeNavbar';
import { Skeleton } from 'primereact/skeleton';
import UserFirstLogin from './user_phone_login/UserFirstLogin';
import UserPhoneLogin from './user_phone_login/UserPhoneLogin';
import { Link } from 'react-router-dom';
import { useAuth } from "../../context/AuthContext";
import { logout } from '../../utils/authUtils';

export default function UserFakeInterface(props) {
    const { mobileAuth, passwordChangeRequired } = useAuth();

    // useEffect(() => {
    //     if (passwordRequired === true && mobileVerificationRequired === true) {
    //         setPasswordResetForms(false)
    //     }
    //     if (passwordRequired === false && mobileVerificationRequired === true) {
    //         setPasswordResetForms(true)
    //     }
    //     if (passwordRequired === true && mobileVerificationRequired === false) {
    //         setPasswordResetForms(false)
    //     }
    // }, [passwordRequired, mobileVerificationRequired])
    console.log(mobileAuth);

    return (
        <div className="col-sm-12 p-0">
            <div className="d-flex">
                <aside id="sidebar" className='card b-radius b-shadow open'>
                    <div className="col-sm-12 text-center logo-wrapper">
                        <img className="logo" src={logo_main} alt="" />
                    </div>
                    <div className="w-100 border-0 px-3 pt-4" listStyle={{ maxHeight: '70vh', minHeight: '50vh' }}>
                        <Skeleton width="100%" className="py-3 mb-2"></Skeleton>
                        <Skeleton width="80%" className="py-3 mb-2"></Skeleton>
                        <Skeleton width="90%" className="py-3 mb-2"></Skeleton>
                        <Skeleton width="60%" className="py-3 mb-2"></Skeleton>
                        <Skeleton width="80%" className="py-3 mb-2"></Skeleton>
                        <Skeleton width="90%" className="py-3 mb-2"></Skeleton>
                        <Skeleton width="80%" className="py-3 mb-2"></Skeleton>
                        <Skeleton width="70%" className="py-3 mb-2"></Skeleton>

                        <div className="flex align-items-center my-2 px-4 py-1" onClick={logout} style={{ cursor: 'pointer' }}>
                            <i className={'pi pi-sign-out my-1 me-4'}></i> <span className='link'>Ելք</span>
                        </div>
                    </div>
                </aside>
                <div className="w-100 mx-auto position-sticky float-start px-3 d-flex flex-column p-0">
                    <FakeNavbar />
                    <div className="container-fluid p-0 mt-3">
                        <div className="col-12 p-0">
                            <div className="card b-radius b-shadow w-100 d-flex justify-content-center align-items-center p-5" style={{ minHeight: '84vh' }}>
                                <div className="col-6">
                                    {mobileAuth && <UserPhoneLogin />}
                                    {!mobileAuth && passwordChangeRequired && <UserFirstLogin />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}
