import { Avatar } from 'primereact/avatar';
import { ColorizedAvatars } from '../../../ui/elements/ColorizedAvatars'
import { Button } from 'primereact/button';
import { FiX, FiExternalLink, FiInfo, FiAlertTriangle } from 'react-icons/fi'
import { Link } from 'react-router-dom';

export const usersListAvatarBody = (rowData) => (
    <div className={`d-flex align-items-center p-0 opacity-${rowData?.is_active ? 100 : 60}`}>
        <div className="me-4" style={{ width: '60px', height: '60px' }}>
            {rowData?.profile?.avatar ?
                <Avatar
                    image={rowData?.profile?.avatar}
                    size="xlarge"
                    shape="circle" />
                :
                <Avatar
                    label={rowData?.username?.substring(0, 1)}
                    size="xlarge"
                    shape="circle"
                    className='text-uppercase text-white'
                    style={rowData?.is_active ? { backgroundColor: ColorizedAvatars(rowData?.username?.substring(0, 2)) } : null}
                />
            }
        </div>
        <div className='d-grid'>
            <div className=''>Username:
                <span className="text-dark fw-bold fs-5 ms-2">{rowData.username}</span>
            </div>
            <div className='my-1'>Mobile:
                <span className="text-dark ms-2">{rowData?.profile?.mobile ? rowData?.profile?.mobile :
                    <span className='text-danger small fs-italic'>user must have mobile number</span>}
                </span>
            </div>
            <div>Status:
                <span className='ms-2'>{rowData?.is_active ?
                    <span className='text-success small fw-bold'>Enabled User</span> :
                    <span className='text-danger small fw-bold'>Disabled user</span>}
                </span>
            </div>
        </div>
    </div>
);

export const usersListActiveUsers = (rowData) => (
    <div className="d-flex align-items-center p-0">
        {rowData?.is_active ?
            <Button
                icon="pi pi-check"
                rounded
                outlined
                severity='success'
                disabled
            />
            :
            <Button
                icon="pi pi-times"
                rounded
                outlined
                severity='secondary'
                disabled
            />
        }
    </div>
);

export const usersListActionButtonsBody = (rowData, data) => (
    <div className="dropdown col-2 text-end">
        <Button icon="pi pi-ellipsis-v" severity='secondary' rounded outlined id={'data-' + rowData.id} data-bs-toggle="dropdown" />
        <div className="dropdown-menu dropdown-menu-end px-1 py-2" aria-labelledby={'data-' + rowData.id}>
            <Link to={""} className="dropdown-item my-3 d-flex align-items-center"
                onClick={e => {
                    data?.props?.data.setvisible(true);
                    localStorage.setItem('item', rowData.id);
                    data?.props?.data.setEditCurrentState(rowData)
                }} >
                <FiInfo className='me-3' />View More
            </Link>
            <Link className="dropdown-item my-3 d-flex align-items-center" to={`/co_workers/${rowData.id}`}
                onClick={e => { localStorage.setItem('item', rowData.id) }}>
                <FiExternalLink className='me-3' />More information
            </Link>
            <hr />
            <Button text severity='secondary' className="dropdown-item my-3 d-flex align-items-center"
                onClick={e => (data?.props?.data.confirmReset(), localStorage.setItem('item', rowData.id))}>
                <FiAlertTriangle className='me-3' />Reset user password
            </Button>
            <hr />
            <Link to={""} className="dropdown-item my-3 d-flex align-items-center"
                onClick={e => {
                    data.props.data?.confirmdelete(rowData.id);
                    localStorage.setItem('item', rowData.id)
                }}>
                <FiX className='me-3' />Delete user
            </Link>
        </div>
    </div>
);