import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import {base, bank, partners, languages_url, spheres_url, statuses_url, organization_types} from "../../../../api";
import {BiAt, BiBriefcase, BiErrorCircle} from "react-icons/bi";
import {Dropdown} from "primereact/dropdown";
import {MultiSelect} from "primereact/multiselect";
import {InputText} from "primereact/inputtext";
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";
import {InputNumber} from "primereact/inputnumber";
export default function PartnerForm(props) {
	const toast = useRef(null);
	const [sendData, setSendData] = useState({
		legal: "Physical",
		full_name_am: null,
		full_name_en: null,
		phone: null,
		sex: null,
		email: null,
		birth_date: null,
		address_am: null,
		address_en: null,
		languages: null,
		passport: null,
		social_card: null,
		bank_name: null,
		legal_full_name_am: null,
		legal_full_name_en: null,
		legal_head_am: null,
		legal_head_en: null,
		banking_number: null,
		company_am: null,
		company_en: null,
		tin: null,
		position: null,
		sphere: null,
		accept_date: null,
		accept_contract: null,
		fired_date: null,
		fired_contract: null,
		ngo_accept_date: null,
		ngo_accept_contract: null,
		ngo_fired_contract: null,
		ngo_fired_date: null,
		international_qualification: false,
		local_qualification: false,
		work_experience: null,
	});

	useEffect(() => {
		if (props?.form?.type1 == true) {
			setSendData({...sendData, legal: "Physical"});
		}
		if (props?.form?.type2 == true) {
			setSendData({...sendData, legal: "Individual"});
		}
		if (props?.form?.type3 == true) {
			setSendData({...sendData, legal: "Legal"});
		}
	}, [props?.form]);

	const gender = [
		{
			id: 0,
			name: "Male",
		},
		{
			id: 1,
			name: "Female",
		},
	];
	const [optionLanguages, setOptionLanguages] = useState([]);
	const [multiselectLanguages, setMultiselectLanguages] = useState(null);
	const [optionStatuses, setOptionStatuses] = useState([]);
	const [multiselectStatuses, setMultiselectStatuses] = useState(null);
	const [optionSpheres, setOptionSpheres] = useState([]);
	const [multiselectSpheres, setMultiselectSpheres] = useState(null);
	const [organizationType, setOrganizationType] = useState();
	const [workExperienceNewDate, setWorkExperienceNewDate] = useState();
	const [bankNames, setBankNames] = useState();

	useEffect(() => {
		axios.get(base + languages_url).then(res => {
			const lang = res?.data.map(obj => ({name: obj.title_en, code: obj.title_en, id: obj.id}));
			setOptionLanguages(lang);
		});
		axios.get(base + statuses_url).then(res => {
			const status = res?.data.map(obj => ({name: obj.title_en, code: obj.title_en, id: obj.id}));
			setOptionStatuses(status);
		});
		axios.get(base + spheres_url).then(res => {
			const spheres = res?.data.map(obj => ({name: obj.title_en, code: obj.title_en, id: obj.id}));
			setOptionSpheres(spheres);
		});
		axios.get(base + bank).then(res => {
			setBankNames(res.data);
		});
		axios.get(base + organization_types).then(res => {
			setOrganizationType(res.data);
		});
	}, []);

	function postData() {
		axios
			.post(base + partners, {
				...sendData,
				organization_types: sendData?.organization_types?.id,
				bank: sendData?.bank?.id,
				gender: sendData?.gender?.name,
				languages: sendData?.languages?.map(e => e.id),
				sphere: sendData?.sphere?.map(e => e.id),
				statuses: sendData?.statuses?.map(e => e.id),
				work_experience: workExperienceNewDate ? workExperienceNewDate + "-" + new Date().getMonth() + "-" + new Date().getDay() : sendData?.statuses?.map(e => e.id),
			})
			.then(res => {
				toast.current.show({severity: "success", summary: "Success", detail: "The item is added successfully", life: 3000});
				setTimeout(() => {
					window.location.href = "/partners";
				}, 2000);
			})
			.catch(error => {
				toast.current.show({severity: "error", summary: "Error", detail: "Please add something than press the button ", life: 3000});
			});
	}

	function goBack(e) {
		e.preventDefault();
		window.location.href = "/partners";
	}
	return (
		<div className="col-12 p-0">
			<div className="mt-3 text-center fs-5 fw-bold text-uppercase pb-1 mb-2">
				{props?.form?.type1 ? "Ավելացնել ֆիզիկական անձ գործընկերի ցանկում" : null}
				{props?.form?.type2 ? "Ավելացնել անհատ ձեռներեց գործընկերի ցանկում" : null}
				{props?.form?.type3 ? "Ավելացնել իրավաբանական կազմակերպություն գործընկերի ցանկում" : null}
			</div>
			<form onsubmit="return false">
				<div className="card b-radius b-shadow py-4 px-5 mt-3 mb-3">
					<div className="h5 border-bottom pb-3 mb-4 d-flex align-items-center">
						{props?.form?.type1 ? (
							<>
								<BiAt className="me-3 text-info" /> Անձնական տվյալներ
							</>
						) : null}
						{props?.form?.type2 ? (
							<>
								<BiAt className="me-3 text-info" /> ԱՁ տվյալներ
							</>
						) : null}
						{props?.form?.type3 ? (
							<>
								<BiAt className="me-3 text-info" /> Կազմակերպություն տվյալներ
							</>
						) : null}
					</div>
					<div className="row">
						{/* <div className="col-md-8 col-12 mb-2 stretch-card flex flex-column">
                                            <label htmlFor="company_am" className='pb-3'>company_am</label>
                                            <InputText id="company_am" value={sendData.company_am}
                                                onChange={e => setSendData({...sendData, company_am : e.target.value})}/>
                                        </div> */}
						{props?.form?.type1 ? (
							<div className="row">
								<div className="col-md-6 col-12 mb-2 stretch-card flex flex-column">
									<label htmlFor="full_name_am" className="pb-3">
										Full name armenian
									</label>
									<InputText id="full_name_am" value={sendData.full_name_am} onChange={e => setSendData({...sendData, full_name_am: e.target.value})} />
								</div>
								<div className="col-md-6 col-12 mb-2 stretch-card flex flex-column">
									<label htmlFor="full_name_en" className="pb-3">
										Full name english
									</label>
									<InputText id="full_name_en" value={sendData.full_name_en} onChange={e => setSendData({...sendData, full_name_en: e.target.value})} />
								</div>
							</div>
						) : (
							<div className="row">
								<div className="col-lg-4 col-md-6 col-12 mb-2 stretch-card flex flex-column">
									<label htmlFor="company_am" className="pb-3">
										company_am
									</label>
									<InputText id="company_am" value={sendData.company_am} onChange={e => setSendData({...sendData, company_am: e.target.value})} />
								</div>
								<div className="col-lg-2 col-md-6 col-12 mb-2 stretch-card flex flex-column">
									<label htmlFor="organization_types_am" className="pb-3">
										organization_types_am
									</label>
									<Dropdown
										id="organization_types_am"
										options={organizationType}
										optionLabel="title_am"
										placeholder={sendData?.organization_types?.title_am ? sendData?.organization_types?.title_am : "Select Organization Type"}
										value={sendData?.organization_types}
										onChange={e => {
											setSendData({...sendData, organization_types: e.value});
										}}
									/>
								</div>
								<div className="col-lg-4 col-md-6 col-12 mb-2 stretch-card flex flex-column">
									<label htmlFor="company_en" className="pb-3">
										company_en
									</label>
									<InputText id="company_en" value={sendData.company_en} onChange={e => setSendData({...sendData, company_en: e.target.value})} />
								</div>
								<div className="col-lg-2 col-md-6 col-12 mb-2 stretch-card flex flex-column">
									<label htmlFor="organization_types_en" className="pb-3">
										organization_types_en
									</label>
									<Dropdown
										id="organization_types_en"
										options={organizationType}
										optionLabel="title_en"
										placeholder={sendData?.organization_types?.title_en ? sendData?.organization_types?.title_en : "Select Organization Type"}
										value={sendData?.organization_types}
										onChange={e => {
											setSendData({...sendData, organization_types: e.value});
										}}
									/>
								</div>
								<div className="col-md-6 col-12 mb-2 stretch-card flex flex-column">
									<label htmlFor="legal_head_am" className="pb-3">
										legal_head_am
									</label>
									<InputText id="legal_head_am" value={sendData.legal_head_am} onChange={e => setSendData({...sendData, legal_head_am: e.target.value})} />
								</div>
								<div className="col-md-6 col-12 mb-2 stretch-card flex flex-column">
									<label htmlFor="legal_head_en" className="pb-3">
										legal_head_en
									</label>
									<InputText id="legal_head_en" value={sendData.legal_head_en} onChange={e => setSendData({...sendData, legal_head_en: e.target.value})} />
								</div>
								<div className="col-md-6 col-12 mb-2 stretch-card flex flex-column">
									<label htmlFor="legal_full_name_am" className="pb-3">
										legal_full_name_am
									</label>
									<InputText id="legal_full_name_am" value={sendData.legal_full_name_am} onChange={e => setSendData({...sendData, legal_full_name_am: e.target.value})} />
								</div>
								<div className="col-md-6 col-12 mb-2 stretch-card flex flex-column">
									<label htmlFor="legal_full_name_en" className="pb-3">
										legal_full_name_en
									</label>
									<InputText id="legal_full_name_en" value={sendData.legal_full_name_en} onChange={e => setSendData({...sendData, legal_full_name_en: e.target.value})} />
								</div>
							</div>
						)}
						<div className="col-md-6 col-12 mb-2 stretch-card flex flex-column">
							<label htmlFor="email" className="pb-3">
								e-mail
							</label>
							<InputText id="email" value={sendData.email} onChange={e => setSendData({...sendData, email: e.target.value})} />
						</div>
						{/* <div className="col-md-6 col-12 mb-2 stretch-card flex flex-column">
                            <label htmlFor="position" className='pb-3'>Position</label>
                            <InputText id="position" value={sendData.position}
                                onChange={e => setSendData({...sendData, position : e.target.value})}/>
                        </div> */}
						<div className="col-md-6 col-12 mb-2 stretch-card flex flex-column">
							<label htmlFor="phone" className="pb-3">
								Phone
							</label>
							<InputText id="phone" value={sendData.phone} onChange={e => setSendData({...sendData, phone: e.target.value})} />
						</div>
						{props?.form?.type1 ? (
							<>
								<div className="col-lg-3 col-md-6 col-12 mb-2 stretch-card flex flex-column">
									<label htmlFor="gender" className="pb-3">
										Gender
									</label>
									<Dropdown
										id="gender"
										options={gender}
										optionLabel="name"
										placeholder={sendData?.gender ? sendData?.gender : "Select gender"}
										value={sendData?.gender}
										onChange={e => {
											setSendData({...sendData, gender: e.value});
										}}
									/>
								</div>
								<div className="col-lg-3 col-md-6 col-12 mb-2 stretch-card flex flex-column">
									<label htmlFor="birth_date" className="pb-3">
										Birthday
									</label>
									<input type="date" id="birth_date" className="form-control p-2" value={sendData.birth_date} onChange={e => setSendData({...sendData, birth_date: e.target.value})} />
								</div>
							</>
						) : null}

						<div className="col-md-6 col-12 mb-2 stretch-card flex flex-column">
							<div className="flex align-items-center col-lg-6 col-12">
								<input class="form-check-input" type="checkbox" value={sendData?.local_qualification} checked={sendData?.local_qualification} onChange={e => setSendData({...sendData, local_qualification: !sendData?.local_qualification})} id="local_qualification" />
								<label htmlFor="local_qualification" className="ml-2">
									Տեղական որակավորում
								</label>
							</div>
							<div className="flex align-items-center col-lg-6 col-12">
								<input class="form-check-input" type="checkbox" value={sendData?.international_qualification} checked={sendData?.international_qualification} onChange={e => setSendData({...sendData, international_qualification: !sendData?.international_qualification})} id="international_qualification" />
								<label htmlFor="international_qualification" className="ml-2">
									Միջազգային որակավորում
								</label>
							</div>
							<div className="flex align-items-center col-lg-6 col-12">
								<InputNumber value={sendData?.work_experience ? new Date().getFullYear() - sendData?.work_experience : 0} onChange={e => setWorkExperienceNewDate(new Date().getFullYear() - e.value)} />
								<label htmlFor="international_qualification" className="ml-2">
									Աշխատանքային փորձ
								</label>
							</div>
						</div>
						<div className="col-md-6 col-12 mb-2 stretch-card flex flex-column">
							<label htmlFor="languages" className="pb-3">
								languages
							</label>
							<MultiSelect
								id="languages"
								value={multiselectLanguages}
								placeholder="Select languages"
								optionLabel="name"
								maxSelectedLabels={2}
								options={optionLanguages}
								onChange={e => {
									setSendData({...sendData, languages: e.target.value});
									setMultiselectLanguages(e.value);
								}}
							/>
						</div>
						<div className="col-md-6 col-12 mb-2 stretch-card flex flex-column">
							<label htmlFor="statuses" className="pb-3">
								statuses
							</label>
							<MultiSelect
								id="statuses"
								value={multiselectStatuses}
								placeholder="Select Sphere"
								optionLabel="name"
								maxSelectedLabels={2}
								options={optionStatuses}
								onChange={e => {
									setSendData({...sendData, statuses: e.target.value});
									setMultiselectStatuses(e.value);
								}}
							/>
						</div>
						<div className="col-md-6 col-12 mb-2 stretch-card flex flex-column">
							<label htmlFor="sphere" className="pb-3">
								sphere
							</label>
							<MultiSelect
								id="sphere"
								value={multiselectSpheres}
								placeholder="Select Sphere"
								className="w-100"
								optionLabel="name"
								maxSelectedLabels={2}
								options={optionSpheres}
								onChange={e => {
									setSendData({...sendData, sphere: e.target.value});
									setMultiselectSpheres(e.value);
								}}
							/>
						</div>
						<div className="col-12 mb-2 stretch-card flex flex-column">
							<label htmlFor="address_am" className="pb-3">
								address_am
							</label>
							<InputText id="address_am" value={sendData.address_am} onChange={e => setSendData({...sendData, address: e.target.value})} />
						</div>
						<div className="col-12 mb-2 stretch-card flex flex-column">
							<label htmlFor="address_en" className="pb-3">
								address_en
							</label>
							<InputText id="address_en" value={sendData.address_en} onChange={e => setSendData({...sendData, address_en: e.target.value})} />
						</div>
					</div>
				</div>

				<div className="card b-radius b-shadow py-4 px-5 mt-3 mb-3">
					<div className="h5 border-bottom pb-3 mb-4 d-flex align-items-center">
						<BiBriefcase className="me-3 text-info" /> Բանկային տվյալներ
					</div>
					<div className="row">
						<div className="col-md-6 col-12 mb-2 stretch-card flex flex-column">
							<label htmlFor="passport" className="pb-3">
								Passport / ID card
							</label>
							<InputText id="passport" value={sendData.passport} onChange={e => setSendData({...sendData, passport: e.target.value})} />
						</div>
						<div className="col-md-6 col-12 mb-2 stretch-card flex flex-column">
							<label htmlFor="social_card" className="pb-3">
								social_card
							</label>
							<InputText id="social_card" value={sendData.social_card} onChange={e => setSendData({...sendData, social_card: e.target.value})} />
						</div>
						<div className="col-md-6 col-12 mb-2 stretch-card flex flex-column">
							<label htmlFor="bank" className="pb-3">
								bank
							</label>
							<Dropdown
								id="bank"
								options={bankNames}
								optionLabel="title_am"
								placeholder={sendData?.bank?.title_am ? sendData?.bank?.title_am : "Select bank"}
								value={sendData?.bank}
								onChange={e => {
									setSendData({...sendData, bank: e.value});
									console.log(e.value);
								}}
							/>
						</div>
						<div className="col-md-6 col-12 mb-2 stretch-card flex flex-column">
							<label htmlFor="banking_number" className="pb-3">
								banking_number
							</label>
							<InputText id="banking_number" value={sendData.banking_number} onChange={e => setSendData({...sendData, banking_number: e.target.value})} />
						</div>
						{props?.form?.type1 ? null : (
							<div className="col-md-6 col-12 mb-2 stretch-card flex flex-column">
								<label htmlFor="tinn" className="pb-3">
									tinn
								</label>
								<InputText id="tinn" value={sendData.tin} onChange={e => setSendData({...sendData, tin: e.target.value})} />
							</div>
						)}
					</div>
				</div>

				<div className="card b-radius b-shadow py-4 px-5 mt-3 mb-3">
					<div className="h5 border-bottom pb-3 mb-4 d-flex align-items-center">
						<BiErrorCircle className="me-3 text-info" />
						Այլ
					</div>

					<div className="row">
						<div className="col-lg-6 col-12">
							<div className="row">
								<div className="col-md-4 col-12 mb-2 stretch-card flex flex-column">
									<label htmlFor="accept_date" class="form-label">
										Accept Date
									</label>
									<input type="date" id="accept_date" className="form-control p-2" dateFormat="dd/mm/yy" value={sendData.accept_date} onChange={e => setSendData({...sendData, accept_date: e.target.value})} />
								</div>
								<div className="col-md-8 col-12 mb-2 stretch-card flex flex-column">
									<label htmlFor="accept_contract" class="form-label">
										Accept Contract
									</label>
									<InputText id="accept_contract" value={sendData.accept_contract} onChange={e => setSendData({...sendData, accept_contract: e.target.value})} />
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-12">
							<div className="row">
								<div className="col-md-4 col-12 mb-2 stretch-card flex flex-column">
									<label htmlFor="fired_date" class="form-label">
										Fired date
									</label>
									<input type="date" id="fired_date" className="form-control p-2" dateFormat="dd/mm/yy" value={sendData.fired_date} onChange={e => setSendData({...sendData, fired_date: e.target.value})} />
								</div>
								<div className="col-md-8 col-12 mb-2 stretch-card flex flex-column">
									<label htmlFor="fired_contract" class="form-label">
										Fired contract
									</label>
									<InputText id="fired_contract" value={sendData.fired_contract} onChange={e => setSendData({...sendData, fired_contract: e.target.value})} />
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-lg-6 col-12">
							<div className="row">
								<div className="col-md-4 col-12 mb-2 stretch-card flex flex-column">
									<label htmlFor="ngo_hire_date" class="form-label">
										NGO hire date
									</label>
									<input type="date" className="form-control p-2" dateFormat="dd/mm/yy" id="ngo_hire_date" value={sendData.ngo_accept_date} onChange={e => setSendData({...sendData, ngo_accept_date: e.target.value})} />
								</div>
								<div className="col-md-8 col-12 mb-2 stretch-card flex flex-column">
									<label htmlFor="ngo_hire_contract" class="form-label">
										NGO hire contract
									</label>
									<InputText id="ngo_hire_contract" value={sendData.ngo_accept_contract} onChange={e => setSendData({...sendData, ngo_accept_contract: e.target.value})} />
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-12">
							<div className="row">
								<div className="col-md-4 col-12 mb-2 stretch-card flex flex-column">
									<label htmlFor="ngo_fired_date" class="form-label">
										NGO fired date
									</label>
									<input type="date" className="form-control p-2" dateFormat="dd/mm/yy" id="ngo_fired_date" value={sendData.ngo_fired_date} onChange={e => setSendData({...sendData, ngo_fired_date: e.target.value})} />
								</div>
								<div className="col-md-8 col-12 mb-2 stretch-card flex flex-column">
									<label htmlFor="ngo_fired_contract" class="form-label">
										NGO fired contract
									</label>
									<InputText id="ngo_fired_contract" value={sendData.ngo_fired_contract} onChange={e => setSendData({...sendData, ngo_fired_contract: e.target.value})} />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-12 d-flex justify-content-center">
					<Button
						label="Cencel"
						icon="pi pi-times"
						rounded
						severity="secondary"
						className="m-2"
						onClick={e => {
							goBack(e);
						}}
					/>
					<Button
						label="Add partner"
						icon="pi pi-check"
						rounded
						severity="primary"
						className="m-2"
						onClick={e => {
							e.preventDefault(e);
							postData();
						}}
					/>
				</div>
			</form>
			<Toast ref={toast} />
		</div>
	);
}
