export const docTypeNameMap = {
	work_contract: "__hiring_process__work_contract__",
	job_description: "__hiring_process__job_description__",
	admission_app: "__hiring_process__admission_app__",
	material_responsibility: "__hiring_process__material_responsibility__",
	conflict_of_interest: "__hiring_process__conflict_of_interest__",
	admission_order: "__hiring_process__admission_order__",
	process_duty: "__hiring_process__duty__",
	civil_contract: "__hiring_process__civil_contract__",
	service_delivery_agreement: "__hiring_process__service_delivery_agreement__",
	firing_application: "__firing_process__firing_application__",
	notify: "__firing_process__notify__",
	firing_order_with_contract: "__firing_process__firing_order_with_contract__",
	changes_report: "__hiring_process__changes_report__",
	changes_additional_contract: "__hiring_process__changes_additional_contract__",
};

export const NodeService = [
	{
		key: "0",
		data: {
			name: "__hiring_process__",
			size: "200kb",
			type: "Folder",
		},
		children: [
			{
				key: "0-0",
				data: {
					name: "__hiring_process__official__", //hastiqayin
					size: "200kb",
					type: "Folder",
				},
				children: [
					{
						key: "0-0-0",
						data: {
							row: "work_contract",
							name: "__hiring_process__work_contract__", //1.1 Dimum Yndunman
							is_position_related: false,
						},
					},
					{
						key: "0-0-1",
						data: {
							row: "job_description",
							name: "__hiring_process__job_description__", //1.2 Ashxatanqayin Paymanagir
							is_position_related: true,
						},
					},
					{
						key: "0-0-2",
						data: {
							row: "admission_app",
							name: "__hiring_process__admission_app__", //1.3 Ashxatanqi nkaragir - Soc Ashxatox
							is_position_related: false,
						},
					},
					{
						key: "0-0-3",
						data: {
							row: "material_responsibility",
							name: "__hiring_process__material_responsibility__", //1.4 Nyutakan patasxanatvutyan paymanagir
							is_position_related: false,
						},
					},
					{
						key: "0-0-4",
						data: {
							row: "conflict_of_interest",
							name: "__hiring_process__conflict_of_interest__", //1.5 Shaheri baxum
							is_position_related: false,
						},
					},
					{
						key: "0-0-5",
						data: {
							row: "admission_order",
							name: "__hiring_process__admission_order__", //1.6 Hraman Ashxatanqi Yndunman
							is_position_related: false,
						},
					},
					{
						key: "0-0-6",
						data: {
							row: "process_duty",
							name: "__hiring_process__duty__", //1.7 Hraman Partakanutyunneri Avelacman
							is_position_related: false,
						},
					},
				],
			},
			{
				key: "0-1",
				data: {
					name: "__hiring_process__civil__", //qax.irav
					size: "200kb",
					type: "Folder",
				},
				children: [
					{
						key: "0-1-0",
						data: {
							row: "civil_contract",
							name: "__hiring_process__civil_contract__",
							is_position_related: false,
						},
					},
					{
						key: "0-1-1",
						data: {
							row: "service_delivery_agreement",
							name: "__hiring_process__service_delivery_agreement__",
							is_position_related: false,
						},
					},
				],
			},
		],
	},
	{
		key: "1",
		data: {
			name: "__firing_process__",
			size: "200kb",
			type: "Folder",
		},
		children: [
			{
				key: "0-0-0",
				data: {
					row: "firing_application",
					name: "__firing_process__firing_application__", //1.1 Dimum Yndunman
					is_position_related: false,
				},
			},
			{
				key: "0-0-1",
				data: {
					row: "firing_order",
					name: "__firing_process__firing_order__", //2.2 Azatman Hraman Dimumov
					is_position_related: false,
				},
			},
			{
				key: "0-0-2",
				data: {
					row: "notify",
					name: "__firing_process__notify__", //2.3 Tsanucum
					is_position_related: false,
				},
			},
			{
				key: "0-0-3",
				data: {
					row: "firing_order_with_contract",
					name: "__firing_process__firing_order_with_contract__", //2.4 Azatman Hraman paymanagrov
					is_position_related: false,
				},
			},
			{
				key: "0-0-4",
				data: {
					row: "firing_order_finilizing",
					name: "__firing_process__finilizing__", //2.5 Dadarecman Hraman Partakanutyunneri
					is_position_related: false,
				},
			},
		],
	},
	{
		key: "2",
		data: {
			name: "__work_changes__", //03. Ashxatanqi popoxutyunneri gorcyntac
			size: "200kb",
			type: "Folder",
		},
		children: [
			{
				key: "0-0-0",
				data: {
					row: "changes_report",
					name: "__hiring_process__changes_report__", //3.1 Zekucagir
					is_position_related: false,
				},
			},
			{
				key: "0-0-1",
				data: {
					row: "changes_additional_contract",
					name: "__hiring_process__changes_additional_contract__", //3.2 Lracucich Hamadzaynagir - Ashxatanqain Paymanagri
					is_position_related: false,
				},
			},
			{
				key: "0-0-2",
				data: {
					row: "changes_order",
					name: "__hiring_process__changes_order__", //3.3 Hraman Partakanutyunneri Popoxman
					is_position_related: false,
				},
			},
		],
	},
];
