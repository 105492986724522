import React, {useState, useEffect} from "react";
import RatingItem from "./RatingItem";
import {Button} from "primereact/button";

const RatingsList = ({data, setData, toast, percentages, setPercentages, handleDialogOpen}) => {
	const [value1, setValue1] = useState({question_text: "", percent: ""});
	const [value2, setValue2] = useState({question_text: "", percent: ""});
	const [value3, setValue3] = useState({question_text: "", percent: ""});
	const [copySource, setCopySource] = useState(null); // Track which column data is copied from

	useEffect(() => {
		setValue1({question_text: "Director Rating", percent: percentages.director});
		setValue2({question_text: "Employee Rating", percent: percentages.employee});
		setValue3({question_text: "Self Rating", percent: percentages.self});
	}, [percentages]);

	const handleCopy = column => {
		setCopySource(column);
		toast.current.show({
			severity: "success",
			summary: "Copied",
			detail: `Copied data from ${column}`,
			life: 3000,
		});
	};

	const handlePaste = targetColumn => {
		if (!copySource) return; // Don't paste if there's no copied data
		setData(prevData => ({
			...prevData,
			[targetColumn]: prevData[copySource].map(item => ({...item})), // Cloning data to prevent reference issues
		}));
		toast.current.show({
			severity: "success",
			summary: "Pasted",
			detail: `Pasted data from ${copySource} to ${targetColumn}`,
			life: 3000,
		});
	};

	const renderColumn = (column, label, value, setValue) => (
		<div className="my-3">
			<RatingItem
				column={column}
				label={`${label} (${parseFloat(value.percent)}%)`}
				data={data}
				setData={setData}
				value={value}
				setValue={setValue}
				toast={toast}
			/>
			<div className="text-center">
				<Button rounded text label="Copy" className="me-2" onClick={() => handleCopy(column)} />
				<Button
					rounded
					text
					severity="success"
					label="Paste"
					className="ms-2"
					onClick={() => handlePaste(column)}
					disabled={!copySource} // Disable if nothing is copied
				/>
			</div>
		</div>
	);

	return (
		<div>
			<div className="row justify-content-center">
				<div className="col-lg-4">
					{renderColumn("director_rating", "Director Rating", value1, setValue1)}
				</div>
				<div className="col-lg-4">
					{renderColumn("employee_rating", "Employee Rating", value2, setValue2)}
				</div>
				<div className="col-lg-4">
					{renderColumn("self_rating", "Self Rating", value3, setValue3)}
				</div>
			</div>
			<div className="text-center mt-3">
				<Button
					label="Set Percentages"
					severity="warning"
					rounded
					icon="pi pi-cog"
					onClick={handleDialogOpen}
				/>
			</div>
		</div>
	);
};

export default RatingsList;
