import axios from "axios";

export const downloadFile = async fileUrl => {
	try {
		const response = await axios.get(fileUrl, {
			responseType: "blob",
		});

		// Get the filename from the Content-Disposition header
		const contentDisposition = response.headers["content-disposition"];
		const filenameRegex = /filename\*?=(?:UTF-\d')?['"]?(.*?)['"]?(?:;|$)/i;
		const match = contentDisposition.match(filenameRegex);

		// const match = contentDisposition && contentDisposition.match(/filename=([^;]+)/);
		const fileName = match && match[1] ? decodeURIComponent(match[1]) : "downloaded_file";
		console.log({contentDisposition});

		const blob = new Blob([response.data], {
			type: response.headers["content-type"],
		});
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
	} catch (error) {
		console.error("Error downloading file: ", error);
	}
};
