import React, { useState, useRef } from 'react'
import { Avatar } from 'primereact/avatar';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Checkbox } from "primereact/checkbox";



const fakeFiles = [
  {
    "userId": 1,
    "id": 1,
    "title": "delectus aut autem",
    "first_action": 'review',
    "first_action_color": 'warning',
    "second_action": 'reject',
    "second_action_color": 'danger',
    "completed": false
  },
  {
    "userId": 1,
    "id": 2,
    "title": "quis ut nam facilis et officia qui",
    "first_action": 'review',
    "first_action_color": 'warning',
    "second_action": 'reject',
    "second_action_color": 'danger',
    "completed": false
  },
  {
    "userId": 1,
    "id": 3,
    "title": "fugiat veniam minus",
    "first_action": 'Aprove',
    "first_action_color": 'success',
    "second_action": 'reject',
    "second_action_color": 'danger',
    "completed": false
  },
  {
    "userId": 1,
    "id": 4,
    "title": "et porro tempora",
    "first_action": 'Aprove',
    "first_action_color": 'success',
    "second_action": 'reject',
    "second_action_color": 'danger',
    "completed": true
  },
  {
    "userId": 1,
    "id": 5,
    "title": "laboriosam mollitia et enim quasi adipisci quia provident illum",
    "first_action": 'Aprove',
    "first_action_color": 'success',
    "second_action": 'reject',
    "second_action_color": 'danger',
    "completed": false
  },
  {
    "userId": 1,
    "id": 6,
    "title": "qui ullam ratione quibusdam voluptatem quia omnis",
    "first_action": 'review',
    "first_action_color": 'warning',
    "second_action": 'reject',
    "second_action_color": 'danger',
    "completed": false
  },
  {
    "userId": 1,
    "id": 7,
    "title": "illo expedita consequatur quia in",
    "first_action": 'review',
    "first_action_color": 'warning',
    "second_action": 'reject',
    "second_action_color": 'danger',
    "completed": false
  },
  {
    "userId": 1,
    "id": 8,
    "title": "quo adipisci enim quam ut ab",
    "first_action": 'review',
    "first_action_color": 'warning',
    "second_action": 'reject',
    "second_action_color": 'danger',
    "completed": true
  },
  {
    "userId": 1,
    "id": 9,
    "title": "molestiae perspiciatis ipsa",
    "first_action": 'review',
    "first_action_color": 'warning',
    "second_action": 'reject',
    "second_action_color": 'danger',
    "completed": false
  },
  {
    "userId": 1,
    "id": 10,
    "title": "illo est ratione doloremque quia maiores aut",
    "first_action": 'review',
    "first_action_color": 'warning',
    "second_action": 'reject',
    "second_action_color": 'danger',
    "completed": true
  },
]
export default function TicketsMainBody({ messages, selectedTicket, sendMessage }) {
  const [visibleRight, setVisibleRight] = useState(false);
  const categories = [
    { name: 'Accounting', key: 'E' },
    { name: 'Marketing', key: 'F' },
    { name: 'Production', key: 'G' },
    { name: 'Research', key: 'H' }
  ];
  const [selectedCategories, setSelectedCategories] = useState([categories[1]]);

  const [messageInput, setMessageInput] = useState('')

  const onCategoryChange = (e) => {
    let _selectedCategories = [...selectedCategories];

    if (e.checked)
      _selectedCategories.push(e.value);
    else
      _selectedCategories = _selectedCategories.filter(category => category.key !== e.value.key);

    setSelectedCategories(_selectedCategories);
  };

  const prevousMessageDay = useRef(null)
  const prevousMessageMonth = useRef(null)

  return (
    <div className='col-12' style={{ height: '82vh' }}>
      <div className='col-12 px-4 pb-0' style={{ height: "80px" }}>
      <div className="row">
        <div className='col-10 px-4 pb-0'>
          <h6 className="text-truncate m-0 fs-5 fw-bold pt-2">
            <i className='pi pi-inbox me-3'></i> {selectedTicket.name}
          </h6>
        </div>
        <div className='col-2 px-4 pb-0 text-end'>
          {visibleRight ? 
          <Button icon="pi pi-times" rounded severity="secondary" onClick={() => setVisibleRight(false)}/>
          : 
          <Button icon="pi pi-bookmark" rounded severity="secondary" onClick={() => setVisibleRight(true)}/>
          }
        </div>
      </div>

      </div>

      <ScrollPanel className='overflow-hidden b-shadow b-radius position-relative' style={{ height: '58vh', backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
        <div className='p-4'>
        {messages?.length > 0 ? messages.map((message, i) => {
          const timestamp = new Date(message.created_at);
          const formattedTime = timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

          const day = timestamp.getDate();
          const month = timestamp.getMonth() + 1;
          let formattedDate = null;
          if (prevousMessageMonth.value != month || prevousMessageDay.value != day) {
            formattedDate = `${month.toLocaleString([], { minimumIntegerDigits: 2, useGrouping: false })}/${day.toLocaleString([], { minimumIntegerDigits: 2, useGrouping: false })}`
            prevousMessageMonth.value = month
            prevousMessageDay.value = day
          }

          return (
            <li className="list-group mb-3 ">
              <span className='m-auto'>{formattedDate}</span>
              <div className={`d-flex ${message.is_sent_by_user ? 'flex-row-reverse' : ''}`} >
                <div className="flex-shrink-0">
                  <Avatar label={message.author} shape="circle" />
                </div>
                <div className='d-grid'>
                  <div className="d-grid mx-3 card b-radius b-shadow w-auto p-3">
                    <p className="text-truncate mb-0 text-muted text-wrap">{message.message}</p>
                  </div>
                  <p className="d-flex align-items-center small text-start ms-3 mt-2"><i className='pi pi-clock small me-1'></i>{formattedTime}</p>
                </div>

              </div>
            </li>
          )
        }) :
          'No Messages Yet'}

        </div>

        {visibleRight ? 
          <ScrollPanel style={{ height: '82vh', overflow:'hidden', width:"380px", height: "100%", position: 'absolute', top:'0', right: '0'}}>
            <div className='card p-2'  >
              {fakeFiles.map((file) => {
                return (
                <div className="col-12 p-2">
                  <div className="card b-shadow b-radius border-0 px-2 py-4">
                    <Button text severity="secondary">
                      <i className='pi pi-file me-2'></i> {file.title}
                    </Button>
                    <hr className='m-0 mb-3 p-0'/>
                    <div className="row p-0 m-0 justify-content-center">
                      {file.completed ? 
                      <Button className='btn-label-secondary col-5 mx-2' label="Complated"/>
                      : 
                      <>
                      <Button className={'btn-label-'+ file.first_action_color + ' col-5 mx-2'} label={file.first_action}/>
                      <Button className={'btn-label-'+ file.second_action_color + ' col-5 mx-2'} label={file.second_action}/>
                      </>
                      }
                    </div>
                  </div>
                </div>
                )
              })}

            </div>
          </ScrollPanel>
        : null}

      </ScrollPanel>

    <div className="d-flex justify-content-center align-items-center px-4 overflow-hidden my-2" >
      <div className="row">
        <div className="col-12">
          <div className='d-flex justify-content-center align-items-center col-12'>
            {categories.map((category) => {
              return (
                <div key={category.key} className="flex align-items-center mx-3">
                  <Checkbox inputId={category.key} name="category" value={category} onChange={onCategoryChange} checked={selectedCategories.some((item) => item.key === category.key)} />
                  <label htmlFor={category.key} className="ml-2">{category.name}</label>
                </div>
              );
            })}
          </div>
        </div>
        <div className='col-8 stretch-card'>
          <input type='text' className='form-control py-2 col-12' value={messageInput} onChange={(e) => {
            setMessageInput(e.target.value)
          }} />
        </div>
        <div className='col-4 stretch-card'>
            <Button className='btn-label-success mx-2' label="Upload a file" 
            onClick={() => { }} 
            />
            <Button className='btn-label-primary mx-2' label="Sand" onClick={() => {
              sendMessage(messageInput)
              setMessageInput('')
            }} />
        </div>
      </div>
    </div>
  </div>
  )
}
