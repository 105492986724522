import React, { useState, useEffect } from 'react'
import { useAuth } from "../../../context/AuthContext";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

export default function UserPhoneLogin(props) {

  const [mobileCode, setMobileCode] = useState('')
  const { userMobile, userName, authConfirm, loading } = useAuth();

  useEffect(() => {
    if (mobileCode.length == 4) {
      authConfirm(mobileCode)
    }
  }, [mobileCode])

  return (
    <div className="col-12 p-0">
      <div className='fs-2 fw-bold mb-3'>
        Welcome <span className='text-primary'>{userName}</span>
      </div>
      <div className="col-12 text-center ">
        <div className='text-start'> Now we need to verify you. You maybe wondering how? don't worry it's simple :-).
          please enter your mobile number in international format
          <span className='text-danger fw-bold d-inline-flex mx-1'>( {userMobile} )</span>
          than click next.
        </div>
        <div className='d-inline-flex py-4'>
          <InputText
            keyfilter="num"
            maxLength="4"
            className='me-3 text-center fw-bold'
            placeholder="*  *  *  *"
            value={mobileCode}
            onChange={(e) => setMobileCode(e.target.value)} />
        </div>
      </div>

      <div className="mt-5 mx-auto text-center">
        {loading ?
          <Button
            icon="pi pi-spin pi-spinner"
            severity='success'
            rounded
            disabled
          /> :

          <Button
            label='Identify'
            rounded />
        }
      </div>
    </div>
  )
}
