import React, {useState } from 'react';
import PartnerForm from './components/forms/PartnerForm';

import { BiCopyright, BiUser, BiRegistered } from 'react-icons/bi'


export default function PartnerChooseForm() {
    const [ form, setForm ] = useState({
        type1: true,
        type2: false,
        type3: false,
    })

  return (
    <div className="col-12 p-0">
        <div className="row">
            <div className="col my-3">
                <div className={form.type1 ? 'card form-check custom-option custom-option-icon checked card b-radius b-shadow' : 'form-check custom-option custom-option-icon'}>
                    <label className="form-check-label custom-option-content d-flex justify-content-between align-items-center" for="radioForm1">
                        <span className="">
                        <BiUser style={{width:'50px'}} className='text-success h3 mt-1'/>
                            <span className="custom-option-title">Ֆիզ անձ</span>
                        </span>
                        <input name="radioForm" className="form-check-input" type="radio" id="radioForm1"
                            value={form.type1} 
                            checked={form.type1} 
                            onChange={e => setForm({type1: true, type2: false, type3: false,})} 
                        />
                    </label>
                </div>
            </div>
            <div className="col my-3">
                <div className={form.type2 ? 'form-check custom-option custom-option-icon checked card b-radius b-shadow' : 'form-check custom-option custom-option-icon'}>
                    <label className="form-check-label custom-option-content d-flex justify-content-between align-items-center" for="radioForm2">
                        <span className="">
                        <BiCopyright style={{width:'50px'}} className='text-warning h3 mt-1'/>
                            <span className="custom-option-title"> Անհատ ձեռներեց </span>
                        </span>
                        <input name="radioForm" className="form-check-input" type="radio" id="radioForm2"
                            value={form.type2}
                            checked={form.type2}
                            onChange={e => setForm({type1: false, type2: true, type3: false,})}
                        />
                    </label>
                </div>
            </div>
            <div className="col my-3">
                <div className={form.type3 ? 'form-check custom-option custom-option-icon checked card b-radius b-shadow' : 'form-check custom-option custom-option-icon'}>
                    <label className="form-check-label custom-option-content d-flex justify-content-between align-items-center " for="radioForm3">
                        <span className="">
                        <BiRegistered style={{width:'50px'}} className='text-info h3 mt-1'/>
                            <span className="custom-option-title"> Իրավաբանական անձ </span>
                        </span>
                        <input name="radioForm" className="form-check-input" type="radio" id="radioForm3"
                            value={form.type3}
                            checked={form.type3}
                            onChange={e => setForm({type1: false, type2: false, type3: true,})} 
                        />
                    </label>
                </div>
            </div>
        </div>

        <PartnerForm form={form}/>
    </div>

  )
}
