import React, {useState, useEffect, useRef} from "react";
import {Button} from "primereact/button";
import {ConfirmDialog} from "primereact/confirmdialog";
import {Toast} from "primereact/toast";
import RatingsList from "./RatingsInformer/RatingsList";
import RatingDialog from "./RatingsInformer/RatingDialog";
import {fetchRatingsData, updateRatingsData} from "./RatingsInformer/RatingsService";

export default function RatingsInformerPage() {
	const [data, setData] = useState({
		director_rating: [],
		employee_rating: [],
		self_rating: [],
	});
	const [dialogVisible, setDialogVisible] = useState(false);
	const [percentages, setPercentages] = useState({
		director: 0,
		employee: 0,
		self: 0,
	});
	const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(true);
	const [copySource, setCopySource] = useState(null); // New state for copy source
	const toast = useRef(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const fetchedData = await fetchRatingsData();
				setData(fetchedData);
			} catch (error) {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: "Failed to fetch data",
					life: 3000,
				});
			}
		};
		fetchData();
	}, []);

	const handleDialogOpen = () => setDialogVisible(true);
	const handleDialogClose = () => setDialogVisible(false);

	const handleSave = () => {
		updateRatingsData(data, percentages, setData);
		handleDialogClose();
		toast.current.show({
			severity: "success",
			summary: "Success",
			detail: "Percentages updated successfully",
			life: 3000,
		});
	};

	return (
		<div className="container-fluid">
			<Toast ref={toast} />
			<ConfirmDialog />
			<div className="col-12 p-0">
				<div className="card w-100 b-radius b-shadow p-3">
					<div className="col-12 mx-auto text-start mt-3 mb-4">
						<p className="m-0 small fst-italic text-muted">Informer page description</p>
						<hr />
					</div>
					<div className="col-12 mx-auto mb-3 p-0">
						<RatingsList
							data={data}
							setData={setData}
							toast={toast}
							percentages={percentages}
							setPercentages={setPercentages}
							handleDialogOpen={handleDialogOpen}
							copySource={copySource} // Pass copy source state
							setCopySource={setCopySource} // Pass function to set copy source
						/>
					</div>
				</div>
			</div>
			<RatingDialog
				dialogVisible={dialogVisible}
				handleDialogClose={handleDialogClose}
				percentages={percentages}
				setPercentages={setPercentages}
				handleSave={handleSave}
				isSaveButtonDisabled={isSaveButtonDisabled}
				setSaveButtonDisabled={setSaveButtonDisabled}
			/>
		</div>
	);
}
