import React from 'react'
import image from '../../../assets/images/illustrations/girl-doing-yoga-light.png'

export default function ConnectionStatus() {
  return (
    <div className='card w-100 b-radius b-shadow d-flex justify-content-center align-items-center p-5' style={{ height: "84vh" }}>
      <img src={image} alt="connection status" className='col-lg-4 col-md-5 col-sm-8 mx-auto' />
      <h6 className='pt-5 mb-0'>hmmmmm .... looks like you're not connected!</h6>
      <h2 className='mt-0'>Plase try to reconnect to the internet</h2>
    </div>
  )
}
