import React, {useEffect, useState} from "react";
import axios from "axios";
import {base, projects_url} from "../../api";
import {FiAlertCircle} from "react-icons/fi";
import {Link} from "react-router-dom";
import ChartArea from "../ui/elements/ChartArea";
import {FiArchive, FiAnchor, FiAlignLeft, FiCalendar, FiActivity, FiCheckCircle} from "react-icons/fi";
import {TbAtom2} from "react-icons/tb";
export default function LatestProjectsComponent() {
	const [projects, setProjects] = useState([]);
	const [ongoingProjects, setOngoingProjects] = useState([]);
	const [complatedProjects, setComplatedProjects] = useState([]);
	useEffect(() => {
		axios.get(base + projects_url).then(res => {
			setProjects(res.data.sort((a, b) => b.id - a.id));
			setComplatedProjects(res.data.sort((a, b) => b.id - a.id).filter(item => item.completed === true));
			setOngoingProjects(res.data.sort((a, b) => b.id - a.id).filter(item => item.completed === false));
		});
	}, []);
	return (
		<div className="row mb-3 match-height p-0">
			<div className="col-xl-5 col-lg-12 col-md-12 col-12 stretch-card">
				<div className="card w-100 b-radius b-shadow p-0">
					<div className="card w-100 border-0 text-bg-light p-0">
						<div className="card-body w-100">
							<div className="flex-column w-100">
								<div className="d-flex no-block align-items-center me-3">
									<TbAtom2 className="display-6  me-3 m-0" />
									<div className="m-0">
										<h5 className=" m-0">Projects summery</h5>
									</div>
								</div>
								<div className="row text-center  mt-4">
									<div className="col-6 d-grid">
										<p className="fs-3 d-flex justify-content-center align-items-center">{complatedProjects.length}</p>
										<span className="text-uppercase small">Ongoing projects</span>
									</div>
									<div className="col-6 d-grid">
										<p className="fs-3 d-flex justify-content-center align-items-center">{ongoingProjects.length}</p>
										<span className="text-uppercase small">Complated projects</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="list-group border-0">
						{projects?.slice(0, 5).map((p, i) => {
							return (
								<Link to={"/project/" + p.id} key={i} class="d-flex border-top p-3">
									<div className="d-grid my-1 text-truncate ">
										<code className="d-flex align-items-center text-truncate">
											<FiAlignLeft className="me-2" />
											{p?.full_name_am && (
												<div className="">
													{p?.full_name_am.substring(0, 50)} {p?.full_name_am?.length > 50 ? "..." : null}
												</div>
											)}
										</code>
										<code className="d-flex align-items-center mt-2">
											<FiArchive className="me-2" />
											{p?.number}
										</code>
										<span className="text-primary small mt-2 d-flex align-items-center">
											<FiAnchor className="me-2" />
											{p?.name}
										</span>
									</div>
								</Link>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
}
