import React from 'react';
import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';

import avatar2 from '../../../assets/images/avatars/2.png'
import avatar3 from '../../../assets/images/avatars/3.png'
import avatar4 from '../../../assets/images/avatars/4.png'
import avatar5 from '../../../assets/images/avatars/5.png'
import { Button } from 'primereact/button';

export default function GroupedUsers(props) {
  console.log(props);
  return (
    <div className="card hover w-100 border-0 b-radius b-shadow m-2">
      <div className='d-flex justify-content-end position-absolute w-100 p-4 mt-2'>
        <Button icon={'pi pi-pencil'} rounded outlined severity='primary' className='mx-1' onClick={() => props.openGroupEdit(props.group)} />
        <Button icon={'pi pi-times'} rounded outlined severity='danger' className='mx-1' onClick={e => { props.confirmDelete(); localStorage.setItem('item', props.id) }} />
      </div>
      <div className="d-flex justify-content-between mb-2 p-4 mt-2">
        <AvatarGroup>

          <Avatar image={avatar2} size="large" shape="circle" />
          <Avatar image={avatar3} size="large" shape="circle" />
          <Avatar image={avatar4} size="large" shape="circle" />
          <Avatar image={avatar5} size="large" shape="circle" />
          <Avatar label="+2" shape="circle" size="large" style={{ backgroundColor: '#9c27b0', color: '#ffffff' }} />
        </AvatarGroup>
      </div>
      <div className="px-4">
        <div className='fs-5 fw-bold text-uppercase text-primary border-bottom mb-1 pb-2'>
          {props?.group.name}
        </div>
        <div className='small fw-bold text-uppercase mb-1 pb-3'>
          <span className='text-info me-1'>{props.totalUsers}</span> users
        </div>
      </div>
    </div>
  )
}
