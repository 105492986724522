// AuthContext.js
import React, {createContext, useContext, useState, useEffect, useLayoutEffect, useCallback} from "react";
import axios from "axios";
import {base, authURL, authConfirmURL, userProfile} from "../api";
import {configureInterceptors} from "../http/interceptors/configureInterceptors";
import {logout as removeToken} from "../utils/authUtils";
import {useCombinedPermissions} from "../hooks/useCombinedPermissions";
import {Dialog} from "primereact/dialog";
const AuthContext = createContext();
export const AuthProvider = ({children}) => {
	const [time, setTime] = useState(1800000);

	const [logoutDialogVisible, setLogoutDialogVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [auth, setAuth] = useState();
	const [user, setUser] = useState(null);
	const [profile, setProfile] = useState();
	const [mobileAuth, setMobileAuth] = useState(false);
	const [userName, setUserName] = useState(false);
	const [userPassword, setUserPassword] = useState("");
	const [userMobile, setUserMobile] = useState("");
	const [passwordChangeRequired, setPasswordChangeRequired] = useState(false);

	const [error, setError] = useState(false);
	const combinedPermissions = useCombinedPermissions(profile);
	const logout = manual => {
		if (!manual) {
			setLogoutDialogVisible(true);
			setTimeout(() => {
				setAuth(false);
				setUser(null);
				removeToken();
				setLogoutDialogVisible(false);
			}, 3);
		} else {
			setAuth(false);
			setUser(null);
			removeToken();
			setLogoutDialogVisible(false);
		}
	};

	useLayoutEffect(() => configureInterceptors(logout, () => setTime(600000)), []);

	const authentication = async (username, password) => {
		setLoading(true);
		try {
			const response = await axios.post(base + authURL, {
				username: username,
				password: password,
			});
			if (response.data?.mobile) {
				setUserName(username);
				setUserPassword(password);
				setUserMobile(response.data.mobile);
				setMobileAuth(true);
			} else if (response.data?.token) {
				const authToken = response.data.token;
				console.log([response.data.token]);
				localStorage.setItem("__data", authToken);
				console.log(`new token us ${authToken}`);
				// window.location.href = "/";
				setAuth(true);
			}
			if (response.data?.password_change_required == true) {
				setPasswordChangeRequired(true);
			}
			return true;
		} catch (error) {
			setError(true);
		} finally {
			setLoading(false);
		}
	};
	const authConfirm = async mobileCode => {
		setLoading(true);
		try {
			const response = await axios.post(base + authConfirmURL, {
				username: userName,
				password: userPassword,
				code: mobileCode,
			});
			if (response.data?.token) {
				const authToken = response.data.token;
				localStorage.setItem("__data", authToken);
				setAuth(true);
				if (!passwordChangeRequired) {
					window.location.href = "/";
				}
				setMobileAuth(false);
			}
		} catch (error) {
			setError(true);
		} finally {
			setLoading(false);
		}
	};

	const fetchUserProfile = useCallback(() => {
		axios.get(base + userProfile).then(res => {
			setProfile(res.data);
		});
	}, []);

	useEffect(() => {
		if (profile) {
			setPasswordChangeRequired(profile?.profile?.password_change_required);
		}
	}, [profile?.profile?.password_change_required]);

	useEffect(() => {
		if (auth) {
			fetchUserProfile();
		}
	}, [auth]);

	useEffect(() => {
		if (localStorage.getItem("__data")) {
			setAuth(true);
		} else {
			setAuth(false);
		}
	}, []);

	return (
		<AuthContext.Provider
			value={{
				auth,
				user,
				logout,
				profile,
				fetchUserProfile,
				authentication,
				authConfirm,
				mobileAuth,
				userName,
				userMobile,
				passwordChangeRequired,
				error,
				loading,
				combinedPermissions,
				time,
				setTime,
			}}>
			{children}
			<Dialog visible={logoutDialogVisible} closable={false}>
				<div className="text-center pt-4 pb-5 px-5">
					<div className="text-danger fw-bold">You have reached the 30-minute time limit, and the system will automatically sign you out.</div>
				</div>
			</Dialog>
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	return useContext(AuthContext);
};
