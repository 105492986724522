import { Toast } from "primereact/toast";
import React, { createContext, useContext, useState, useRef } from "react";
import UploadProgressWindow from "../components/MediaComp/components/UploadProgressWindow/UploadProgressWindow";

import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
  base,
  file_manager,
  locations_url,
  file_tags_url,
  projects_url,
} from "../api";
import { usePreventTabClose } from "../hooks/usePreventTabClose";
const FileUploadContext = createContext();

export const FileUploadProvider = ({ children }) => {
  const { addActiveProcess, subtractActiveProcess, activeProcesses } =
    usePreventTabClose();
  const [uploadProgress, setUploadProgress] = useState([]);
  const [uploadWindow, setUploadWindow] = useState(false);
  const toast = useRef(null);
  const removeFileUploadProgress = (id) => {
    console.log({ id });
    setUploadProgress((uploadProgress) =>
      uploadProgress.filter((file) => file.id !== id)
    );
  };
  const handleFileUploadProgress = (event, id) => {
    setUploadWindow(true);
    const progress = (event.loaded / event.total) * 100;

    setUploadProgress((uploadProgress) =>
      uploadProgress.map((file) => {
        if (file.id === id) {
          return {
            ...file,
            progress,
            status: "in_progress",
          };
        }

        return file;
      })
    );
  };

  const handleFileUploadComplete = (id) => {
    setUploadProgress(
      uploadProgress.map((file) => {
        if (file.id === id) {
          return {
            ...file,
            status: "completed",
          };
        }

        return file;
      })
    );
  };

  const uploadPublicFiles = (
    event,
    multiselectLocationsByFile,
    multiselectTagsByFile,
    datesByFile,
    multiselectProjectsByFile,
    loadPublicFiles,
    setVisible
  ) => {
    const files = event.files;
    const formDataList = [];
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append("file", files[i]);
      formData.append(
        "locations",
        multiselectLocationsByFile[`${files[i].size}-${files[i].name}`].map(
          (obj) => obj.id
        )
      );
      formData.append(
        "tags",
        multiselectTagsByFile[`${files[i].size}-${files[i].name}`].map(
          (obj) => obj.id
        )
      );
      formData.append("date", datesByFile[`${files[i].size}-${files[i].name}`]);
      formData.append(
        "project",
        multiselectProjectsByFile[`${files[i].size}-${files[i].name}`][0].id
      );
      formDataList.push({ formData, id: uuidv4(), fileName: files[i].name });
    }
    axios
      .all(
        formDataList.map(({ formData, id, fileName }) => {
          // axios.post(base + file_manager.upload_public_file, formData)
          setUploadProgress((uploadProgress) => [
            ...uploadProgress,
            {
              id,
              fileName,
              progress: 0,
              status: "new",
            },
          ]);
          addActiveProcess();
          const fileUploadRequest = axios
            .post(base + file_manager.upload_public_file, formData, {
              onUploadProgress: (e) => handleFileUploadProgress(e, id),
            })
            .then(() => {
              subtractActiveProcess();
              // Update the upload progress state for the file.
              setUploadProgress((uploadProgress) =>
                uploadProgress.map((file) => {
                  if (file.id === id) {
                    return {
                      ...file,
                      status: "completed",
                    };
                  }

                  return file;
                })
              );

              // Handle the completion of the upload.
            });
          return fileUploadRequest;
        })
      )
      .then((responses) => {
        loadPublicFiles();
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "The item is added successfully",
          life: 3000,
        });
      });

    setVisible((visible) => ({ ...visible, uploadPublicFilesDialog: false }));
  };

  return (
    <FileUploadContext.Provider
      value={{
        uploadPublicFiles,
        uploadProgress,
        uploadWindow,
        setUploadWindow,
      }}
    >
      {children}
      <Toast ref={toast} />
      {uploadWindow ? (
        uploadProgress.length > 0 ? (
          <UploadProgressWindow
            removeFileUploadProgress={removeFileUploadProgress}
          />
        ) : null
      ) : null}
    </FileUploadContext.Provider>
  );
};

export const useFileUpload = () => {
  return useContext(FileUploadContext);
};
