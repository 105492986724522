import React, {useState, useEffect, useRef} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Avatar} from "primereact/avatar";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {base, coworker_url} from "../../api";
import axios from "axios";
import {Rating} from "primereact/rating";
import {Dialog} from "primereact/dialog";
import {ScrollPanel} from "primereact/scrollpanel";

const userRating = [
	{id: 1, subject: "subject 1", rate: "subject_1"},
	{id: 2, subject: "subject 2", rate: "subject_2"},
	{id: 3, subject: "subject 3", rate: "subject_3"},
	{id: 4, subject: "subject 4", rate: "subject_4"},
	{id: 5, subject: "subject 5", rate: "subject_5"},
	{id: 6, subject: "subject 6", rate: "subject_6"},
	{id: 7, subject: "subject 7", rate: "subject_7"},
	{id: 8, subject: "subject 8", rate: "subject_8"},
	{id: 9, subject: "subject 9", rate: "subject_9"},
	{id: 10, subject: "subject 10", rate: "subject_10"},
	{id: 11, subject: "subject 11", rate: "subject_11"},
	{id: 12, subject: "subject 12", rate: "subject_12"},
];

export default function UsersRatingBoard() {
	const dt = useRef(null);
	const toast = useRef(null);
	const headers = {"Content-Type": "application/json"};
	const credentials = "same-origin";
	const [UserContract, setUserContract] = useState();
	const [currentUserInfo, setCurrentUserInfo] = useState();
	const [UserRatingValue, setUserRatingValue] = useState();
	const [showRaitingDialog, setShowRatingDialog] = useState(false);

	useEffect(() => {
		loadContent();
	}, []);

	const loadContent = () => {
		axios.get(base + coworker_url).then((...response) => {
			setUserContract(response[0].data?.filter(i => i.contract_type === 1));
		});
	};

	const userRatingNameField = rowData => {
		return (
			<div
				className="d-flex align-items-center gap-3"
				style={{minHeight: "60px"}}
				onClick={() => {
					setShowRatingDialog(true);
					setCurrentUserInfo(rowData?.partner?.full_name_am);
				}}
			>
				<Avatar label={rowData?.partner?.full_name_am?.slice(0, 1)} size="large" shape="circle" style={{borderRadius: "100%", padding: "1px", overflow: "visible"}} className={rowData?.busy_percentage <= 15 ? `text-white bg-secondary text-uppercase` : rowData?.busy_percentage <= 30 ? `text-white bg-danger text-uppercase` : rowData?.busy_percentage <= 55 ? `text-white bg-info text-uppercase` : rowData?.busy_percentage <= 75 ? `text-white bg-warning text-uppercase` : rowData?.busy_percentage <= 100 ? `text-white bg-success text-uppercase` : "null"}></Avatar>
				<div className="fw-bold">{rowData?.partner?.full_name_am}</div>
			</div>
		);
	};

	const userRatingRatingField = rowData => {
		return (
			<div
				className="d-flex align-items-center"
				style={{minHeight: "60px"}}
				onClick={() => {
					setShowRatingDialog(true);
					setCurrentUserInfo(rowData?.partner?.full_name_am);
				}}
			>
				<Rating value={UserRatingValue} readOnly cancel={false} onChange={e => setUserRatingValue(e.value)} stars={10} />
			</div>
		);
	};
	const userRatingBuzzynesField = rowData => {
		return (
			<div className="w-100">
				<div
					className="d-flex justify-content-between align-items-center"
					style={{minHeight: "60px", minWidth: "180px"}}
					onClick={() => {
						setShowRatingDialog(true);
						setCurrentUserInfo(rowData?.partner?.full_name_am);
					}}
				>
					<div className="progress w-100 b-radius border-0" style={{height: "6px"}}>
						<div className={rowData?.busy_percentage <= 15 ? `progress-bar bg-secondary` : rowData?.busy_percentage <= 30 ? `progress-bar bg-danger` : rowData?.busy_percentage <= 55 ? `progress-bar bg-info` : rowData?.busy_percentage <= 75 ? `progress-bar bg-warning` : rowData?.busy_percentage <= 100 ? `progress-bar bg-success` : "null"} role="progressbar" style={{width: rowData?.busy_percentage + "%"}} aria-valuenow={rowData?.busy_percentage} aria-valuemin="0" aria-valuemax="100"></div>
					</div>
					<span className="fw-semibold ms-4">{rowData?.busy_percentage + "%"}</span>
				</div>
			</div>
		);
	};
	const [ratingList, setRatingList] = useState({});
	const [totalRating, setTotalRating] = useState(0);
	const [submitted, setSubmitted] = useState(false);

	useEffect(() => {
		let total = 0;
		for (const itemId in ratingList) {
			total += parseInt(ratingList[itemId]);
		}
		setTotalRating(total);
	}, [ratingList]);

	const ratingPercentage = Math.round((totalRating / (userRating.length * 10)) * 100);

	const handleInputChange = (value, itemId) => {
		const ratingValue = isNaN(value) ? 0 : value;

		setRatingList(prevState => ({
			...prevState,
			[itemId]: ratingValue,
		}));
	};

	const handleSubmit = () => {
		setSubmitted(true);
	};

	const header = rowData => {
		console.log(rowData);
		return (
			<div className="ms-3 me-1 my-1">
				<div className="d-flex justify-content-between align-items-center mb-2">
					<span className="small fw-normal">{currentUserInfo}</span>
					<span className="small fw-normal">Rated {ratingPercentage} %</span>
				</div>
				<div className="progress w-100 b-radius border-0" style={{height: "6px"}}>
					<div className={ratingPercentage <= 15 ? `progress-bar bg-secondary` : ratingPercentage <= 30 ? `progress-bar bg-danger` : ratingPercentage <= 55 ? `progress-bar bg-info` : ratingPercentage <= 75 ? `progress-bar bg-warning` : ratingPercentage <= 100 ? `progress-bar bg-success` : "null"} role="progressbar" style={{width: ratingPercentage + "%"}} aria-valuenow={ratingPercentage} aria-valuemin="0" aria-valuemax="100"></div>
				</div>
			</div>
		);
	};

	return (
		<>
			<div className="col-12 p-0 my-4">
				<div className="card b-radius b-shadow p-3">
					<div className="card flex justify-content-center p-3">
						{UserContract ? (
							<DataTable className="row p-0" value={UserContract} ref={dt} paginator rows={20} rowsPerPageOptions={[20, 30, 50, 100]} dataKey="id" emptyMessage="No customers found.">
								<Column header="User" field={"partner.full_name_am"} body={userRatingNameField} sortable sortField={"partner.full_name_am"} className="border-top-0" />
								<Column header="Rating" field="Rating" body={userRatingRatingField} sortable sortField="Rating" className="border-top-0" />
								<Column header="Buzzy" field="busy_percentage" body={userRatingBuzzynesField} sortable sortField="busy_percentage" className="border-top-0" />
							</DataTable>
						) : (
							<div className="col-12 d-flex justify-content-center align-items-center my-5 py-5">
								<span class="loader"></span>
							</div>
						)}
					</div>
				</div>
			</div>

			<Dialog header={header} visible={showRaitingDialog} onHide={() => setShowRatingDialog(false)} className="col-lg-6 col-12 p-0 border-bottom">
				<ScrollPanel style={{width: "100%", height: "350px"}}>
					{userRating.map((item, index) => (
						<div className="card shadow rounded border-0 mb-3 mx-3 px-3 py-1" key={item.id}>
							<div className="row">
								<div className="col-8">
									{item.id}. {item.subject} {!ratingList[item.id] && submitted && <span className="small text-danger">(field is requred)</span>}
								</div>
								<div className="col-4">
									<Rating name={`rate_${item.id}`} cancel={false} value={ratingList[item.id] || 0} onChange={e => handleInputChange(e.target.value, item.id)} stars={10} required={true} className={submitted && !ratingList[item.id] ? "not-rated" : ""} />
								</div>
							</div>
						</div>
					))}
				</ScrollPanel>
				<div className="row justify-content-center my-3 ">
					<Button label="Cancel" rounded severity="secondary" className="col-4 mx-2" />
					<Button label="Submit" rounded severity="primary" className="col-4 mx-2" onClick={handleSubmit} />
				</div>
			</Dialog>

			<Toast ref={toast} />
		</>
	);
}
