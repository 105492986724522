
import React, { useEffect, useRef, useState } from 'react';
import { base, organization_details } from '../../api';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import OrganizationDetailsDialogForm from './InformersComponents/OrganizationDetailsDialogForm'
import AddOrganizationDialogForm from './InformersComponents/AddOrganizationDialogForm'
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { PermOnly } from '../PermOnly';

export default function OrganizationDetails(props) {
    const toast = useRef(null);

    const [editOrganizationDialog, setEditOrganizationDialog] = useState(false);
    const [addOrganizationDialog, setAddOrganizationDialog] = useState(false);
    const [dialogState, setDialogState] = useState(false);
    const [currentState, setCurrentState] = useState(null);

    function loadContent() {
        axios.get(base + organization_details).then((res => {
            setCurrentState(res.data)
        }))
    }
    useEffect(() => { loadContent() }, []);

    return (

        <div className='container-fluid'>
            <div className="col-12 p-0">
                <div className="card w-100 b-radius b-shadow p-3">
                    <div className="col-md-8 col-12 mx-auto text-center mt-3 mb-4">
                        <p className="m-0 small fst-italic text-muted">
                            Արտարծույքները կարող եք ավելացնել ներքոհիշհալ դաշտում նշելով ցանկացած արտարժուք, որից հետո անհրաժեշտ է սեղմել "Ավելացնել" կոճակը: Իսկ եթե արտարժուքն ավելացնելուց տեղի է ունեցել վրիպում ապա ավելացված արտաժույքների դաշտում սեղմեք փոփոխել կոճակը և կատարեք փոփխությունները: Ցանկանում եք հեռացնել ոչ պիտանի արտարժուքը ապա սեղմեք ռեռացնել կոճակը
                        </p>
                        <hr />
                    </div>

                    <div className="row">
                        <div className='col-12 my-3'>
                            <PermOnly perm={props.perm} add>
                                <div className="d-flex justify-content-end mb-5">
                                    <Button
                                        label='Add an organization'
                                        icon="pi pi-plus"
                                        rounded
                                        severity="info"
                                        tooltip="Click to add an organization"
                                        tooltipOptions={{ position: "top" }}
                                        onClick={() => { setAddOrganizationDialog(true) }}
                                    />
                                </div>
                            </PermOnly>
                            <PermOnly perm={props.perm} view>
                                <div className="row">
                                    {currentState?.map((state, i) => {
                                        return (
                                            <div className='col-lg-4 col-md-6 col-12 stretch-card mb-2 p-1'>
                                                <div className='card w-100 b-radius b-shadow m-2 p-3 stretch-card'>
                                                    <div className='mb-2 d-flex justify-content-between align-items-center'>
                                                        <div className='d-flex justify-content-between align-items-start fw-bold text-uppercase'>
                                                            <div className="d-grid">
                                                                {state?.organization}
                                                                <div className='mt-1 small'>
                                                                    <Badge value={state?.active ? "ակտիվ է" : 'ակտիվե չէ'} severity={state?.active ? "success" : 'secondary'} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <PermOnly perm={props.perm} change>
                                                            <Button
                                                                icon="pi pi-info-circle"
                                                                rounded
                                                                severity="info"
                                                                tooltip="Click to view or to set as active"
                                                                tooltipOptions={{ position: "top" }}
                                                                onClick={() => { setEditOrganizationDialog(true); setDialogState(state) }}
                                                            />
                                                        </PermOnly>
                                                    </div>
                                                    <hr className='mb-2 mt-1' />
                                                    <div className='fw-bold my-2'> Հասցե՝ {state?.address} </div>
                                                    <div className='my-2'>ՀՎՀՀ՝ {state?.tinn} </div>
                                                    <div className='my-2'> {state?.position_user_1}` {state?.position_full_name_1} </div>
                                                    <div className='my-2'>{state?.position_user_2}` {state?.position_full_name_2}</div>
                                                    <div className='my-2'>{state?.position_user_3}` {state?.position_full_name_3}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </PermOnly>
                        </div>
                    </div>
                </div>
            </div>
            <Toast ref={toast} />

            <Dialog visible={addOrganizationDialog} style={{ width: '50vw' }} onHide={() => { setAddOrganizationDialog(false); loadContent() }}>
                <div className="card mb-4">
                    <AddOrganizationDialogForm loadContent={loadContent} hide={setAddOrganizationDialog} toast={toast} />
                </div>
            </Dialog>

            <Dialog visible={editOrganizationDialog} style={{ width: '50vw' }} onHide={() => { setEditOrganizationDialog(false); loadContent() }}>
                <div className="card mb-4">
                    <OrganizationDetailsDialogForm data={dialogState} hide={setEditOrganizationDialog} loadContent={loadContent} toast={toast} perm={props.perm} />
                </div>
            </Dialog>
        </div>

    )
}
