import { useState } from 'react';
import Nothing from '../../../assets/images/illustrations/girl-unlock-password-light.png'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { usersListAvatarBody, usersListActiveUsers, usersListActionButtonsBody } from './ui/UsersListWidgets'

export default function UsersList(props) {

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        full_name_en: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        phone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    });

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
    };
    const renderHeader = () => {
        const value = filters['global'] ? filters['global'].value : '';
        return (
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
            </span>
        );
    };
    const header = renderHeader();
    return (
        <div className='col-12 mb-3 pt-0'>
            <div className="card px-5 pb-5 pt-0">
                {props?.data ?
                    <DataTable
                        value={props?.data}
                        data={props}
                        paginator rows={10}
                        filters={filters}
                        onFilter={(e) => setFilters(e.filters)}
                        header={header}
                        globalFilterFields={['username', 'full_name_am', 'address_am', 'address_en', 'phone', 'email']}
                        rowsPerPageOptions={[20, 30, 50, 100]}
                        dataKey="id"
                        bodyClassName="table table-hover"
                        emptyMessage="NOTHING TO SHOW."
                    >
                        <Column header="User" field="username" sortable body={usersListAvatarBody}></Column>
                        <Column header="Status" field="is_active" sortable body={usersListActiveUsers}></Column>
                        <Column body={usersListActionButtonsBody}></Column>
                    </DataTable>
                    :
                    <div className='card w-100 text-center align-items-center d-flex justify-content-center' style={{ minHeight: '300px' }}>
                        <img src={Nothing} alt="" style={{ maxHeight: '180px' }} />
                        <div className='mt-5 text-muted'>NOTHING TO SHOW YET</div>
                    </div>
                }
            </div>
        </div>
    )
}
