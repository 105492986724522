import React, { useMemo, useState, useCallback } from 'react'
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { updateFileVisibility } from '../repository'
import { base, file_manager } from "../../../api";



export default function PerosnalFileShareDialog({ refreshFiles }) {
    const node = useMemo(() => JSON.parse(localStorage.getItem('node')), [localStorage.getItem('node')])

    const [state, setState] = useState(false)
    const [checked, setChecked] = useState(node.isPublic)
    const url = useMemo(() => base + file_manager.download_user_file`${node.key}`, [node.key])
    const copyURL = useCallback(() => {
        navigator.clipboard.writeText(url)
        setState(true)
    }, [url])
    const onChange = useCallback(async (e) => {
        setChecked(e.value)
        await updateFileVisibility(node.key, e.value)
        refreshFiles()
    }, [])
    return (
        <div className='col-12 '>
            {checked && <div className='d-flex justify-content-center align-items-center mb-5'>
                <InputText className='p-3 col-9' disabled
                    value={url}
                // onChange={(e) => setState(e.target.value)} 
                />
                <Button icon={state && "pi pi-check"} label="Copy" iconPos="right" className='p-3 ms-3 col-3' onClick={copyURL} />
            </div>}

            <div className='d-flex justify-content-center align-items-center mb-5'>
                Կիսվել տվյալ ֆայլը հղումով
                <InputSwitch className='mx-3' checked={checked}
                    onChange={onChange}
                />
            </div>
        </div>
    )
}
