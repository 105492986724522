import React from 'react';
import { Route, Routes } from 'react-router-dom'
import PartnerChooseForm from './PartnerChooseForm';
import PartnersDashboard from './PartnersDashboard';

export default function PartnersRoutes() {
  return (
    <div className='col-12 mb-3 p-0'>
      <Routes>
          <Route index path="/" element={<PartnersDashboard/>}/>
          <Route exact path="add_a_partner" element={<PartnerChooseForm/>} />
      </Routes>
    </div>
  )
}


