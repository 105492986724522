import axios from "axios";
import {base, file_manager} from "../../api";

export const renameFile = ({fileId, newFilename}) => {
	return axios.post(base + file_manager.rename_file`${fileId}`, {
		new_filename: newFilename,
	});
};

export const renamePublicFile = ({fileId, newFilename}) => {
	return axios.post(base + file_manager.rename_public_file`${fileId}`, {
		new_filename: newFilename,
	});
};

export const updateFileVisibility = (fileId, isPublic) => {
	return axios.put(base + file_manager.file_visibility_update`${fileId}`, {
		public: isPublic,
	});
};

export function downloadFile(url, filename) {
	fetch(url)
		.then(response => response.blob())
		.then(blob => {
			const blobUrl = URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = blobUrl;
			a.download = filename;
			a.click();
			URL.revokeObjectURL(blobUrl);
		});
}

const handleDownload = async fileUrl => {
	try {
		const response = await axios.get(fileUrl, {
			responseType: "blob",
		});
		const blob = new Blob([response.data], {
			type: response.headers["content-type"],
		});

		// Extracting filename from Content-Disposition header
		const contentDisposition = response.headers["content-disposition"];
		const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
		const matches = fileNameRegex.exec(contentDisposition);

		let fileName = contentDisposition; // default filename
		if (matches && matches[1]) {
			fileName = matches[1].replace(/['"]/g, "");
		}

		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
	} catch (error) {
		console.error("Error downloading file: ", error);
	}
};

export const downloadOwnFile = async (fileId, fileName, viewOnly) => {
	console.log({fileId});
	handleDownload(base + file_manager.download_user_file`${fileId}`, fileName, viewOnly);
};
export const downloadPublicFile = async (fileId, fileName, viewOnly) => {
	console.log({fileId});
	handleDownload(base + file_manager.download_public_file`${fileId}`, fileName, viewOnly);
};

const fetchImageForUrl = async url => {
	if (!url) {
		return;
	}
	try {
		const response = await axios.get(url, {
			responseType: "arraybuffer",
		});

		const base64Image = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ""));

		const dataURL = `data:${response.headers["content-type"]};base64,${base64Image}`;
		return dataURL;
	} catch (error) {
		console.error("Error fetching image:", error);
	}
};
export const fetchPublicImage = fileId => {
	if (!fileId) return;
	return fetchImageForUrl(base + file_manager.download_public_file`${fileId}`);
};
export const fetchImage = fileId => {
	if (!fileId) return;
	return fetchImageForUrl(base + file_manager.download_user_file`${fileId}`);
};
