import React from "react";

export default function BeneficareDashboardCard(props) {
  return(
    <div className='col stretch-card'>
    <div className="card w-100 b-radius b-shadow p-4 border-0">
      <div className="row align-items-center justify-content-between">
        <div className="col-4 ">
          <div className="avatar flex-shrink-0">
            <img src={props?.image} alt=" " className="b-radius" style={{ width: '50px' }} />
          </div>
        </div>
        <div className='col-8'>
          <span>{props?.title}</span>
          <h3 className="card-title text-nowrap mb-1">{props?.count}</h3>
          <small className="text-success fw-semibold">{props?.percent}</small>
        </div>
      </div>
    </div>
  </div>
  );
}
