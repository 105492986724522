import React, {useState, useEffect} from "react";
import axios from "axios";
import {base, unit, expance_type, demand_letters, projects_url} from "../../../api";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {AutoComplete} from "primereact/autocomplete";

export default function DemandLetterDialog({setDialogVisibility}) {
	const [unitOptions, setUnitOptions] = useState([]);
	const [projectsOptions, setProjectsOptions] = useState([]);
	const [expanceTypes, setExpanceTypes] = useState([]);
	const [selectedProject, setSelectedProject] = useState(null);
	const headers = {"Content-Type": "application/json"};
	const credentials = "same-origin";

	const [rows, setRows] = useState([{product: "", description: "", unit: "", count: ""}]);

	const [unitSuggestions, setUnitSuggestions] = useState([]);
	const [expanceSuggestions, setExpanceSuggestions] = useState([]);
	const [projectSuggestions, setProjectSuggestions] = useState([]);

	useEffect(() => {
		loadContent();
	}, []);

	const loadContent = () => {
		axios
			.all(
				[axios.get(base + unit), axios.get(base + expance_type), axios.get(base + projects_url)],
				credentials,
				headers
			)
			.then(
				axios.spread((unitResponse, expanceTypesResponse, projectsResponse) => {
					setUnitOptions(unitResponse.data);
					setProjectsOptions(projectsResponse.data);
					setExpanceTypes(expanceTypesResponse.data);
				})
			)
			.catch(error => {
				console.error("Error loading content:", error);
			});
	};

	const handleRowChange = (value, field, index) => {
		const updatedRows = [...rows];
		updatedRows[index][field] = value;
		setRows(updatedRows);
	};

	const addRow = () => {
		setRows([...rows, {product: "", description: "", unit: "", count: ""}]);
	};

	const removeRow = index => {
		setRows(rows.filter((_, i) => i !== index));
	};

	const searchUnits = event => {
		setUnitSuggestions(
			unitOptions.filter(u => u.title_am.toLowerCase().includes(event.query.toLowerCase()))
		);
	};

	const searchExpanceTypes = event => {
		setExpanceSuggestions(
			expanceTypes.filter(e => e.title_am.toLowerCase().includes(event.query.toLowerCase()))
		);
	};

	const searchProjects = event => {
		setProjectSuggestions(
			projectsOptions.filter(
				p =>
					p.full_name_am.toLowerCase().includes(event.query.toLowerCase()) ||
					p.name.toLowerCase().includes(event.query.toLowerCase())
			)
		);
	};

	const renderCellEditor = (field, rowIndex) => {
		if (field === "product") {
			return (
				<AutoComplete
					value={rows[rowIndex][field]}
					suggestions={expanceSuggestions}
					completeMethod={searchExpanceTypes}
					field="title_am" // Display title in suggestions
					onChange={e => handleRowChange(e.value, field, rowIndex)} // Store the selected product object
					dropdown
					maxLength={70}
					className="border-0 w-100 rounded-0 px-0"
				/>
			);
		} else if (field === "unit") {
			return (
				<AutoComplete
					value={rows[rowIndex][field]}
					suggestions={unitSuggestions}
					completeMethod={searchUnits}
					field="title_am"
					onChange={e => handleRowChange(e.value, field, rowIndex)}
					dropdown
					maxLength={70}
					className="border-0 w-100 rounded-0 px-0"
				/>
			);
		} else {
			return (
				<InputText
					className="border-0 w-100 rounded-0 px-0"
					value={rows[rowIndex][field]}
					onChange={e => handleRowChange(e.target.value, field, rowIndex)}
				/>
			);
		}
	};

	const saveData = () => {
		if (!selectedProject) {
			console.error("Project is required");
			return;
		}

		// Filter and validate rows
		const validProducts = rows.filter(row => row.product?.id && !isNaN(Number(row.count)));

		if (validProducts.length === 0) {
			console.error("No valid products to send");
			return;
		}

		const requestData = {
			project_id: selectedProject.id,
			demand_letter_products: validProducts.map(row => ({
				product: row.product.id, // Use the product ID
				count: parseInt(row.count, 10), // Ensure count is an integer
				unit: row.unit.id || null, // Optional field
				description: row.description || null, // Optional field
			})),
		};

		console.log("Request payload:", requestData);

		axios
			.post(base + demand_letters, requestData, {headers})
			.then(response => {
				console.log("Data saved successfully:", response.data);
				setDialogVisibility(false); // Close the dialog on success
			})
			.catch(error => {
				console.error("Error saving data:", error);
			});
	};

	return (
		<>
			<div className="mt-3">
				<div className="fw-bolder mb-3 text-muted">Ընտրել ծրագիրը</div>
				<AutoComplete
					value={selectedProject}
					suggestions={projectSuggestions}
					completeMethod={searchProjects}
					field="full_name_am"
					onChange={e => setSelectedProject(e.value)}
					placeholder="Select Project"
					dropdown
					className=" w-100 "
					panelStyle={{width: "300px"}}
					itemTemplate={item => (
						<div>
							<div>{item.full_name_am}</div>
							<small className="text-muted">{item.name}</small>
						</div>
					)}
				/>
			</div>
			{selectedProject && (
				<div className="mt-5">
					<div className="mb-3 fw-bolder">Ավելացնել պահանջագիր կետեր</div>
					<DataTable value={rows} editMode="cell">
						<Column
							className="p-0 m-0 border-1 text-center"
							header="№"
							body={(_, {rowIndex}) => rowIndex + 1}
							style={{width: "50px"}}
						/>
						<Column
							className="p-0 m-0 border-1"
							field="product"
							header="Ապրանքի անվանում"
							body={(rowData, {rowIndex}) => renderCellEditor("product", rowIndex)}
						/>
						<Column
							className="p-0 m-0 border-1"
							field="unit"
							header="Չափի միավոր"
							body={(rowData, {rowIndex}) => renderCellEditor("unit", rowIndex)}
						/>
						<Column
							className="p-0 m-0 border-1"
							field="count"
							header="Քանակ"
							body={(rowData, {rowIndex}) => renderCellEditor("count", rowIndex)}
						/>
						<Column
							className="p-0 m-0 border-1"
							field="description"
							header="Ապրանքի բնութագիր"
							body={(rowData, {rowIndex}) => renderCellEditor("description", rowIndex)}
						/>
						<Column
							className="p-0 m-0 border-0 text-center"
							header={null}
							body={(_, {rowIndex}) => (
								<Button
									icon="pi pi-minus"
									rounded
									className="p-0 m-1"
									severity="danger"
									onClick={() => removeRow(rowIndex)}
								/>
							)}
						/>
					</DataTable>
					<div className="row align-items-center">
						<div className="col-7 text-end my-4">
							<Button
								label="Cancel"
								icon="pi pi-times"
								rounded
								severity="secondary"
								onClick={() => {
									setRows([{product: "", description: "", unit: "", count: ""}]);
									setDialogVisibility(false);
								}}
							/>
							<Button
								label="Save"
								icon="pi pi-check"
								rounded
								severity="primary"
								className="mx-2"
								onClick={saveData}
							/>
						</div>
						<div className="col-5 my-3 text-end">
							<Button
								label="Add Row"
								icon="pi pi-plus"
								onClick={addRow}
								rounded
								severity="success"
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
