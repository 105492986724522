
import React, { useEffect, useRef, useState } from 'react';
import { base, currency } from '../../api';
import Nothing from '../../assets/images/illustrations/girl-unlock-password-light.png'
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import FlagIcon from '../../FlagIconFactory';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { FiPlus, FiMoreVertical, FiCheck } from 'react-icons/fi'
import { PermOnly } from '../PermOnly';

export default function Currencies(props) {
    const toast = useRef(null);
    const headers = { 'Content-Type': 'application/json', }
    const credentials = "same-origin"
    const [visible, setVisible] = useState(false);
    const [state, setState] = useState();
    const [currencyState, setCurrencyState] = useState();
    const [editCurrencyState, setEditedCurrencyState] = useState();

    function loadContent() {
        axios.get(base + currency).then((res => {
            setCurrencyState(res.data)
        }))
    }

    useEffect(() => { loadContent() }, []);

    function addCurrency() {
        axios({
            method: "POST",
            url: base + currency,
            data: { 'title_am': state, },
            headers: headers,
            credentials: credentials,
        })
            .then(res => {
                setCurrencyState()
                loadContent()
                setState(null)
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'The item is added successfully', life: 3000 });
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please add something than press the button ', life: 3000 });
                loadContent()
                setState(null)
            })
    }

    function getSingleCurrency() {
        axios.get(base + currency + localStorage.getItem('item') + '/').then((res => {
            setEditedCurrencyState(res.data)
        }))
    }

    function editCurrency(e) {
        axios({
            method: "PUT",
            url: base + currency + localStorage.getItem('item') + `/`,
            data: { 'title_am': editCurrencyState, },
            headers: headers,
            credentials: credentials,
        }).then(res => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Message Content' });
            loadContent()
            setCurrencyState(null)
            setVisible(false)
        })
            .catch((error) => {
                loadContent();
                toast.current.show({ severity: 'warn', summary: 'Error', detail: 'Nothing is chenged' });
            })
    }
    function removecurrency() {
        axios.delete(base + currency + localStorage.getItem('item') + `/`, { headers: headers })
            .then((res) => {
                loadContent()
                localStorage.removeItem('item')
            })
            .catch((error) => { loadContent() })
    }

    const accept = () => {
        toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
        removecurrency()
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const confirmDelete = (e) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject

        });
    };

    return (

        <div className='container-fluid'>
            <div className="col-12 p-0">
                <div className="card w-100 b-radius b-shadow p-3">
                    <div className="col-md-8 col-12 mx-auto text-center mt-3 mb-4">
                        <p className="m-0 small fst-italic text-muted">
                            Արտարծույքները կարող եք ավելացնել ներքոհիշհալ դաշտում նշելով ցանկացած արտարժուք, որից հետո անհրաժեշտ է սեղմել "Ավելացնել" կոճակը: Իսկ եթե արտարժուքն ավելացնելուց տեղի է ունեցել վրիպում ապա ավելացված արտաժույքների դաշտում սեղմեք փոփոխել կոճակը և կատարեք փոփխությունները: Ցանկանում եք հեռացնել ոչ պիտանի արտարժուքը ապա սեղմեք ռեռացնել կոճակը
                        </p>
                        <hr />
                    </div>
                    <PermOnly perm={props.perm} add>

                        <div className='col-md-4 col-sm-8 col-12 mx-auto mb-3 p-0'>
                            <div className="card w-100 p-2">
                                <div className="text-center">

                                    <div className='col-md-8 col-10 mx-auto mt-3'>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder={'Ավելացնել արտարժուք'}
                                                value={state}
                                                onChange={e => setState(e.target.value)}
                                            />
                                            <button className="btn btn-secondary small" type="button" onClick={e => addCurrency(e)}>Add  <FiPlus className='ms-2' /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PermOnly>
                    <PermOnly perm={props.perm} view>
                        <div className="col-8 mx-auto m-0 p-0">
                            {currencyState ?
                                <div className="row p-0 m-0">
                                    {currencyState?.map((data, i) =>
                                        <div key={i} className="col-4 stretch-card">
                                            <div className="card w-100 stretch-card b-radius b-shadow m-2 p-3">
                                                <div className="d-flex align-items-start justify-content-between">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <div className="avatar flex-shrink-0">
                                                            <FlagIcon code={data.title_am.toLowerCase().slice(0, 2)} size='2x' className='me-2 b-radius' />
                                                        </div>
                                                        <h4 className="ms-3 text-uppercase">{data.title_am}</h4>
                                                    </div>
                                                    <PermOnly perm={props.perm} change remove>
                                                        <div className="dropdown">
                                                            <button className="btn p-0" type="button" id={'data-' + data.id} data-bs-toggle="dropdown" aria-expanded="false">
                                                                <FiMoreVertical />
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-end px-3" aria-labelledby={'data-' + data.id}>
                                                                <PermOnly perm={props.perm} change>
                                                                    <button type='button' className="dropdown-item my-2" onClick={e => { setVisible(true); localStorage.setItem('item', data.id); getSingleCurrency(data.id) }} >Edit</button>
                                                                </PermOnly>
                                                                <PermOnly perm={props.perm} remove>
                                                                    <button type='button' className="dropdown-item my-2" onClick={e => { confirmDelete(data.id); localStorage.setItem('item', data.id) }}>Delete</button>
                                                                </PermOnly>
                                                            </div>
                                                        </div>
                                                    </PermOnly>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                :
                                <div className='card w-100 text-center align-items-center d-flex justify-content-center' style={{ minHeight: '300px' }}>
                                    <div><img src={Nothing} alt="" style={{ maxHeight: '180px' }} /></div>
                                    <div className='mt-5 text-muted'>NOTHING TO SHOW YET</div>
                                </div>
                            }
                        </div>
                    </PermOnly>
                </div>
            </div>

            <Toast ref={toast} />

            <ConfirmDialog />
            <Dialog header={"Edit currency"} visible={visible} style={{ width: '25vw' }} onHide={() => setVisible(false)} >
                <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder={'Ավելացնել արտարժուք'}
                        value={editCurrencyState?.currency}
                        onChange={e => setEditedCurrencyState(e.target.value)}
                    />
                    <button className="btn btn-secondary small" type="button" onClick={e => editCurrency(e)}>Save  <FiCheck className='ms-2' /></button>
                </div>
            </Dialog>

        </div>
    )
}
