export default function NgoDetails(props) {
	return (
		<div className="card w-100 b-radius b-shadow mb-4 my-3">
			<div className="card-body">
				<div className="fs-4 mb-3">NGO details</div>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<span>Hired on </span>
					<span>
						{props.data?.ngo_accept_date} - {props.data?.ngo_fired_date ? props.data?.ngo_fired_date : "Till now"}{" "}
					</span>
				</div>
			</div>
		</div>
	);
}
