import React, { useState, useLayoutEffect, useEffect } from 'react'
import { Link } from 'react-router-dom'
import imgDay from '../../../assets/images/icons/unicons/sunny.png'
import imgEvening from '../../../assets/images/icons/unicons/full-moon.png'
import imgNight from '../../../assets/images/icons/unicons/moon-and-stars.png'
import imgMorning from '../../../assets/images/icons/unicons/stars.png'
import { Button } from 'primereact/button'

export default function DateFunction(props) {
    const locale = 'en';
    const [today, setDate] = useState(new Date());
    useEffect(() => {
        const timer = setInterval(() => {
            setDate(new Date());
        }, 1000);
        return () => {
            clearInterval(timer);
        }
    }, []);
    const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric' });
    const monthString = ["հունվար", "փետրվար", "մարտ", "ապրիլ", "մայիս", "հունիս", "հուլիս", "օգոստոս", "սեպտեմբեր", "հոկտեմբեր", "նոյեմբեր", "դեկտեմբեր"];
    const weekString = ["կիր", "երկ", "երք", "չրք", "հնգ", "ուրբ", "շբթ"];
    const date = today.getDate() + ' ' + monthString[today.getMonth()] + ' ' + today.getFullYear() + ', ' + weekString[today.getDay()]

    const [dayInterval, setDayInterval] = useState({
        morning: false,
        day: false,
        evening: false,
        night: false,
    })
    useLayoutEffect(() => {
        if (5 <= today.getHours() && today.getHours() < 8) { setDayInterval({ ...dayInterval, morning: true }) }
        if (8 <= today.getHours() && today.getHours() < 17) { setDayInterval({ ...dayInterval, day: true }) }
        if (17 <= today.getHours() && today.getHours() < 21) { setDayInterval({ ...dayInterval, evening: true }) }
        if (21 <= today.getHours() && today.getHours() > 0) { setDayInterval({ ...dayInterval, night: true }) }
        if (0 <= today.getHours() && today.getHours() < 5) { setDayInterval({ ...dayInterval, night: true }) }
    }, [])
    return (
        <div className="card w-100 b-radius b-shadow border-0">
            <div className="row">
                <div className="col-lg-4 col-12 text-center mx-auto d-lg-none">
                    {dayInterval.morning ? <img src={imgMorning} height="150" alt=" " /> : null}
                    {dayInterval.day ? <img src={imgDay} height="150" alt=" " /> : null}
                    {dayInterval.evening ? <img src={imgEvening} height="150" alt=" " /> : null}
                    {dayInterval.night ? <img src={imgNight} height="150" alt=" " /> : null}
                </div>
                <div className="col-lg-8 col-12 text-lg-start text-center p-lg-5">
                    <div className="card-body px-5 pt-3 pb-0">
                        <div className="fs-3 fw-bold text-uppercase text-nowrap text-primary">
                            {dayInterval.morning ? 'Բարի լույս ' : null}
                            {dayInterval.day ? 'Բարի օր ' : null}
                            {dayInterval.evening ? 'Բարի երեկո ' : null}
                            {dayInterval.night ? 'Արդեն գիշեր է ' : null}
                            {props?.profile?.profile?.partner?.full_name_am}</div>
                        <div className="d-block mb-5">Այսօր՝ {date}, {time}</div>
                        <Link to={`/accounts/${props?.profile?.id}`} rounded className='btn btn-label-warning b-radius px-4 py-2 mb-4 small'>Անցնել անձնական էջ</Link>
                    </div>
                </div>
                <div className="col-lg-4 d-none d-lg-block text-end p-5">
                    {dayInterval.morning ? <img src={imgMorning} height="150" alt=" " /> : null}
                    {dayInterval.day ? <img src={imgDay} height="150" alt=" " /> : null}
                    {dayInterval.evening ? <img src={imgEvening} height="150" alt=" " /> : null}
                    {dayInterval.night ? <img src={imgNight} height="150" alt=" " /> : null}
                </div>
            </div>
        </div>
    )
}
