import React, { useState } from "react";
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";

export default function MediaFilter({ data, setSelectedDayRange, selectedDayRange, clearFilter, applyFilter }) {
    return (
        <div className="col-12">
            <MultiSelect
                filter
                placeholder="Select project"
                maxSelectedLabels={0}
                className="col-12 mb-3 p-0 b-radius"
                optionLabel="name"
                options={data.optionProjects}
                value={data.multiselectProjectsFilter}
                onChange={(e) => (data.setMultiselectProjectsFilter(e.value), console.log(e))}
            />
            <div className="col-12 p-0">
                <div className="row">
                    <div className="col-6 ">
                        <Calendar
                            value={selectedDayRange}
                            onChange={setSelectedDayRange}
                            colorPrimary="#0fbcf9" // added this
                            colorPrimaryLight="rgba(75, 207, 250, 0.4)" // and this
                            shouldHighlightWeekends
                        />
                    </div>
                    <div className="col-6">
                        <MultiSelect
                            filter
                            placeholder="Select locations"
                            maxSelectedLabels={0}
                            className="col-12 mb-3 p-0 b-radius"
                            optionLabel="name"
                            options={data.optionLocations}
                            value={data.multiselectLocationsFilter}
                            onChange={(e) => data.setMultiselectLocationsFilter(e.value)}
                        />

                        <MultiSelect
                            filter
                            placeholder="Select tags"
                            maxSelectedLabels={0}
                            className="col-12 mb-3 p-0 b-radius"
                            optionLabel="name"
                            options={data.optionTags}
                            value={data.multiselectTagsFilter}
                            onChange={(e) => data.setMultiselectTagsFilter(e.value)}
                        />

                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
                <Button label={"Clear"} icon="pi pi-times" severity="secondary" rounded className="m-2" onClick={clearFilter}/>
                <Button label="Filter" icon="pi pi-filter" severity="primary" rounded className="m-2" onClick={applyFilter}/>
            </div>
        </div>
    )
}
