import PublicFileNodeTemplate from "./PublicFileNodeTemplate";
import PublicFolderNodeTemplate from "./PublicFolderNodeTemplate";

export default function PublicTreeNodeTemplate({ onSelect, onDeletePressed, refresh }) {
    return (node, options) => PublicTreeNodeTemplateBuilder(node, options, onSelect, onDeletePressed, refresh)
}
function PublicTreeNodeTemplateBuilder(node, options, onSelect, onDeletePressed, refresh) {
    if (node.url) {
        return PublicFileNodeTemplate(node, options, onSelect, onDeletePressed, refresh)
    }
    return PublicFolderNodeTemplate(node, options)
};