import { useMemo } from "react";

export const useCombinedPermissions = (profile) => {
  const groupPermissions = useMemo(
    () => profile?.groups?.flatMap((group) => group.permissions) || [],
    [profile]
  );
  const disabledPermissions = useMemo(
    () => profile?.profile?.disabled_permissions || [],
    [profile]
  );
  const userPermissions = useMemo(
    () => profile?.user_permissions || [],
    [profile]
  );
  const allPermissions = useMemo(() => {
    const filteredPermissions = [
      ...groupPermissions,
      ...userPermissions,
    ].filter((permission) => !disabledPermissions.includes(permission));

    const uniquePermissions = [...new Set(filteredPermissions)];

    return uniquePermissions;
  }, [groupPermissions, disabledPermissions, userPermissions]);

  return allPermissions;
};
