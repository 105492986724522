import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { PermOnly } from '../PermOnly';
import { sections } from '../../json_content/Informer_sections';

export default function InfromersBase() {
    const { t } = useTranslation();

    return (
        <div className="col-12">
            <div className="">
                <div className='row'>
                    {Object.values(sections).map((item, index) =>
                        <PermOnly perm={item.perm}>
                            <div key={index} className="col-xl-3 col-lg-4 col-md-4 col-6 stretch-card p-3">
                                <div className="card hover b-shadow b-radius w-100 pt-3 pb-5 border-0">
                                    <Link to={item.link} className="card-body justify-content-center text-center">
                                        <div className="icon-holder b-shadow mt-3 mb-4">
                                            <i className={item.icon + ' ' + 'pi fs-3 p-3 text-' + item.color}></i>
                                        </div>
                                        <span className="mb-0 text-uppercase ">{t(item.link_text)}</span>
                                    </Link>
                                </div>
                            </div>
                        </PermOnly>
                    )}
                </div>
            </div>
        </div>
    )
}
