// import React, {useState} from "react";
// import {Button} from "primereact/button";
// import {InputText} from "primereact/inputtext";
// import {confirmDialog} from "primereact/confirmdialog";

// const RatingItem = ({column, label, data, setData, toast}) => {
// 	const [editingIndex, setEditingIndex] = useState(null);
// 	const [value, setValue] = useState({question_text: "", percent: ""});

// 	const handleEdit = index => {
// 		setEditingIndex(index);
// 		const itemToEdit = data[column][index];
// 		setValue({question_text: itemToEdit.question_text, percent: itemToEdit.percent});
// 	};

// 	const confirmDelete = index => {
// 		confirmDialog({
// 			message: "Are you sure you want to delete this item?",
// 			header: "Confirmation",
// 			icon: "pi pi-exclamation-triangle",
// 			accept: () => handleDelete(index),
// 		});
// 	};

// 	const handleDelete = index => {
// 		setData(prevData => ({
// 			...prevData,
// 			[column]: prevData[column].filter((_, i) => i !== index),
// 		}));
// 		toast.current.show({
// 			severity: "success",
// 			summary: "Deleted",
// 			detail: "Item deleted successfully",
// 			life: 3000,
// 		});
// 	};

// 	const handleAddOrUpdate = () => {
// 		if (editingIndex !== null) {
// 			setData(prevData => ({
// 				...prevData,
// 				[column]: prevData[column].map((item, index) =>
// 					index === editingIndex ? {...item, ...value} : item
// 				),
// 			}));
// 		} else {
// 			setData(prevData => ({
// 				...prevData,
// 				[column]: [...prevData[column], value],
// 			}));
// 		}

// 		setValue({question_text: "", percent: ""});
// 		setEditingIndex(null);
// 		toast.current.show({
// 			severity: "success",
// 			summary: editingIndex !== null ? "Updated" : "Added",
// 			detail: editingIndex !== null ? "Item updated successfully" : "Item added successfully",
// 			life: 3000,
// 		});
// 	};

// 	// Input validation: Only allow numbers between 0 and 100 for percentage input
// 	const handlePercentageChange = e => {
// 		let input = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers

// 		// Limit input to 100
// 		if (parseInt(input, 10) > 100) {
// 			input = "100";
// 		}

// 		setValue({...value, percent: input});
// 	};

// 	const isFormValid = value.question_text !== "" && value.percent !== "";
// 	const isEditing = editingIndex !== null;

// 	return (
// 		<div className="col-12 border p-5 rounded-4">
// 			<div className="w-100 fs-5 fw-bold mb-3 text-uppercase border-bottom pb-2">{label}</div>
// 			{data[column]?.map((item, index) => (
// 				<div key={index} className="col-12 mb-2 stretch-card">
// 					<div className="card w-100 stretch-card">
// 						<div className="row border-bottom">
// 							<div className="col-10">
// 								<div className="d-flex justify-content-between">
// 									<div className="text-truncate hover-scroll overflow-hidden">
// 										{item.question_text}
// 									</div>
// 									<div className="ms-2 text-danger fw-bold opacity-5">{item.percent}%</div>
// 								</div>
// 							</div>
// 							<div className="col-2 d-flex">
// 								<Button
// 									icon="pi pi-pencil"
// 									text
// 									className="me-1 p-0 px-1 w-auto"
// 									onClick={() => handleEdit(index)}
// 								/>
// 								<Button
// 									icon="pi pi-trash"
// 									text
// 									severity="danger"
// 									className="p-0 px-1 w-auto"
// 									onClick={() => confirmDelete(index)}
// 								/>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			))}
// 			<div className="mt-3">
// 				<InputText
// 					className="col-12 mb-3"
// 					value={value.question_text}
// 					onChange={e => setValue({...value, question_text: e.target.value})}
// 					placeholder="Enter text"
// 				/>
// 				<div className="p-inputgroup col-12 p-0">
// 					<InputText
// 						value={value.percent}
// 						onChange={handlePercentageChange}
// 						placeholder="Enter percentage"
// 					/>
// 					<span className="p-inputgroup-addon">%</span>
// 				</div>
// 				<div className="mt-4 text-center ">
// 					<Button
// 						severity="success"
// 						rounded
// 						label={isEditing ? "Update" : "Add"}
// 						disabled={!isFormValid}
// 						onClick={handleAddOrUpdate}
// 					/>
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

// export default RatingItem;

import React, {useState} from "react";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {confirmDialog} from "primereact/confirmdialog";

const RatingItem = ({column, label, data, setData, toast, copySource, setCopySource}) => {
	const [editingIndex, setEditingIndex] = useState(null);
	const [value, setValue] = useState({question_text: "", percent: ""});

	const handleEdit = index => {
		setEditingIndex(index);
		const itemToEdit = data[column][index];
		setValue({question_text: itemToEdit.question_text, percent: itemToEdit.percent});
	};

	const confirmDelete = index => {
		confirmDialog({
			message: "Are you sure you want to delete this item?",
			header: "Confirmation",
			icon: "pi pi-exclamation-triangle",
			accept: () => handleDelete(index),
		});
	};

	const handleDelete = index => {
		setData(prevData => ({
			...prevData,
			[column]: prevData[column].filter((_, i) => i !== index),
		}));
		toast.current.show({
			severity: "success",
			summary: "Deleted",
			detail: "Item deleted successfully",
			life: 3000,
		});
	};

	const handleAddOrUpdate = () => {
		if (editingIndex !== null) {
			setData(prevData => ({
				...prevData,
				[column]: prevData[column].map((item, index) =>
					index === editingIndex ? {...item, ...value} : item
				),
			}));
		} else {
			setData(prevData => ({
				...prevData,
				[column]: [...prevData[column], value],
			}));
		}

		setValue({question_text: "", percent: ""});
		setEditingIndex(null);
		toast.current.show({
			severity: "success",
			summary: editingIndex !== null ? "Updated" : "Added",
			detail: editingIndex !== null ? "Item updated successfully" : "Item added successfully",
			life: 3000,
		});
	};

	const handlePercentageChange = e => {
		let input = e.target.value.replace(/[^0-9]/g, "");
		if (parseInt(input, 10) > 100) {
			input = "100";
		}

		setValue({...value, percent: input});
	};

	const isFormValid = value.question_text !== "" && value.percent !== "";
	const isEditing = editingIndex !== null;
	const handleCopyData = () => {
		setCopySource(column);
		toast.current.show({
			severity: "success",
			summary: "Copied",
			detail: `Data copied from ${label}`,
			life: 3000,
		});
	};

	const handlePasteData = () => {
		const sourceColumn = copySource;
		if (!Array.isArray(data[sourceColumn])) {
			console.error(`Source column "${sourceColumn}" is not an array.`);
			return;
		}

		setData(prevData => ({
			...prevData,
			[column]: [...prevData[column], ...prevData[sourceColumn]],
		}));

		toast.current.show({
			severity: "success",
			summary: "Pasted",
			detail: `Data pasted into ${label}`,
			life: 3000,
		});
	};

	return (
		<div className="col-12 rounded-4 border p-5">
			<div className="w-100 fs-5 fw-bold mb-3 text-uppercase border-bottom pb-2">{label}</div>
			{data[column]?.map((item, index) => (
				<div key={index} className="col-12 mb-2 stretch-card">
					<div className="card w-100 stretch-card">
						<div className="row border-bottom">
							<div className="col-10">
								<div className="d-flex justify-content-between">
									<div className="text-truncate hover-scroll overflow-hidden">
										{item.question_text}
									</div>
									<div className="ms-2 text-primary fw-bold opacity-5">{item.percent}</div>
								</div>
							</div>
							<div className="col-2 d-flex">
								<Button
									icon="pi pi-pencil"
									text
									className="me-1 p-0 px-1 w-auto"
									onClick={() => handleEdit(index)}
								/>
								<Button
									icon="pi pi-trash"
									text
									severity="danger"
									className="p-0 px-1 w-auto"
									onClick={() => confirmDelete(index)}
								/>
							</div>
						</div>
					</div>
				</div>
			))}
			<div className="mt-3">
				<InputText
					className="col-12 mb-3"
					value={value.question_text}
					onChange={e => setValue({...value, question_text: e.target.value})}
					placeholder="Enter text"
				/>
				<div className="p-inputgroup col-12 p-0">
					<InputText
						value={value.percent}
						onChange={handlePercentageChange}
						placeholder="Enter percentage"
					/>
					<span className="p-inputgroup-addon">%</span>
				</div>
				<div className="mt-4 text-end">
					<Button
						rounded
						severity="success"
						label={isEditing ? "Update" : "Add"}
						disabled={!isFormValid}
						onClick={handleAddOrUpdate}
					/>
				</div>
			</div>
		</div>
	);
};

export default RatingItem;
