import React, { useState, useEffect } from 'react'
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';




export default function TicketsSideBar({ data, openTicket }) {
  console.log(data);
  return (
    <div className="card" style={{ height: '80vh' }}>
      {data.map((ticket, i) => {
        return (
          <>
            {ticket?.new === true && 
              <>
                <li className="list-group row px-3 mt-1" onClick={() => openTicket(ticket)}>
                  <button className="btn text-muted d-flex my-1 b-radius b-shadow overflow-hidden py-1 col-12" >
                    <div className="flex-shrink-0 col-1 pb-0">
                      <Avatar label={ticket.name?.substring(0, 1)} className='p-overlay-badge' shape="circle" />
                    </div>
                    <div className='d-grid ps-4 text-start col-9'>
                      <h6 className="text-truncate fw-bold m-0">{ticket.name?.substring(0, 20)}</h6>
                      {/* {ticket.last_message ? <p className="text-truncate mb-0 text-wrap">{ticket.last_message.message?.substring(0, 30)}</p> : 'No masssage'} */}
                      {ticket.last_message ? <p className="text-truncate mb-0 text-wrap small">{ticket.last_message?.substring(0, 30)}</p> : 'No masssage'}
                    </div>
                    <div className='col-2 p-0'>
                      <div className='d-grid'>
                        <p className="d-flex justify-content-end small text-end">
                          <Badge severity="danger"></Badge>
                        </p>
                        <p className="d-flex align-items-center small text-start m-0">
                          <i className='pi pi-clock small me-1'></i> {ticket.last_message_time}
                        </p>
                      </div>
                    </div>
                  </button>
                </li>
              </>
            }
          </>
        )
      })}

      {data.map((ticket, i) => {
        return (
          <>
            {!ticket?.new === false ? null :
              <>
                <li className="list-group row px-3 mt-1" onClick={() => openTicket(ticket)}>
                  <button className="btn w-100 text-muted d-flex my-1 b-radius b-shadow overflow-hidden col-12">
                    <div className="flex-shrink-0 col-1 pb-0">
                      <Avatar label={ticket.name?.substring(0, 1)} className='p-overlay-badge' shape="circle" />
                    </div>
                    <div className='d-grid ps-4 text-start col-9'>
                      <h6 className="text-truncate fw-bold m-0">{ticket.name?.substring(0, 20)}</h6>
                      {ticket.last_message ? <p className="text-truncate mb-0 text-wrap small">{ticket.last_message?.substring(0, 30)}</p> : 'No masssage'}
                    </div>
                    <div className='col-2'>
                    <div className='d-grid'>
                      {ticket.Finito === true ? null : 
                        <p className="d-flex justify-content-end small text-end">
                          <Badge style={{background:'transparent'}}></Badge>
                        </p>
                      }
                        <p className="d-flex align-items-center small text-start m-0">
                          <i className='pi pi-clock small me-1'></i> {ticket.last_message_time}
                        </p>
                      </div>
                    </div>
                  </button>
                </li>
              </>
            }
          </>
        )
      })}
    </div>

  )
}
