import {useState, useEffect, useRef} from "react";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {Button} from "primereact/button";
import {AutoComplete} from "primereact/autocomplete";
import {Dropdown} from "primereact/dropdown";
import {InputTextarea} from "primereact/inputtextarea";
import axios from "axios";
import {Toast} from "primereact/toast";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";

import {base, donors, currency, acc, workplace_url, projects_url, currencyRateApi} from "../../api";

export default function EditProject(props) {
	const toast = useRef(null);
	const [checked, setChecked] = useState(false);
	const [sendData, setSendData] = useState({
		project_number: "",
		project_start: "",
		project_end: "",
		project_short_title: null,
		project_full_title_am: null,
		project_full_title_en: null,
		project_description_am: null,
		project_description_en: null,
		currency_value: "",
		own_contribution: "",
		grant_amount: "",
		yearly_expenses: "",
		completed: false,
	});
	const [inputList, setInputList] = useState([{year: "", expenses: ""}]);

	const [currencyRate, setCurrencyRate] = useState();

	const [ownContributionConverted, setOwnContributionConverted] = useState();
	const [grantAmountConverted, setGrantAmountConverted] = useState();

	const [DonorCompanies, setDonorCompanies] = useState();
	const [selectedDonorCompanies, setSelectetDonorCompanies] = useState();
	const [filteredDonorCompanies, setFilteredDonorCompanies] = useState();

	const [bankAccount, setBankAccount] = useState();
	const [selectedBankAccount, setSelectetBankAccount] = useState();
	const [filteredBankAccount, setFilteredBankAccount] = useState();

	const [workPlace, setWorkPlace] = useState();
	const [selectedWorkPlace, setSelectetWorkPlace] = useState();
	const [filteredWorkPlace, setFilteredWorkPlace] = useState();

	const [dropdownCurrency, setDropdownCurrency] = useState();
	const [selectedDropdownCurrency, setSelectetDropdownCurrency] = useState();

	const fatchData = () => {
		axios.all([axios.get(base + donors), axios.get(base + workplace_url), axios.get(base + currency), axios.get(base + projects_url + props?.projectID + "/")]).then(
			axios.spread((...response) => {
				setDonorCompanies(response[0].data);
				setWorkPlace(response[1].data);
				setDropdownCurrency(response[2].data);
				setSendData({
					project_number: response[3]?.data.number,
					project_start: response[3]?.data.start_date,
					project_end: response[3]?.data.end_date,
					project_short_title: response[3]?.data.name,
					project_full_title_am: response[3]?.data.full_name_am,
					project_full_title_en: response[3]?.data.full_name_en,
					project_description_am: response[3]?.data.description,
					project_description_en: response[3]?.data.description,
					currency_value: response[3]?.data.currency_value,
					own_contribution: response[3]?.data.own_contribution,
					grant_amount: response[3]?.data.grant_amount,
					yearly_expenses: response[3]?.data.yearly_expenses,
					completed: response[3]?.data.completed,
				});
			})
		);
	};

	useEffect(() => {
		axios.get(currencyRateApi + selectedDropdownCurrency?.title_am).then(response => {
			setCurrencyRate(response?.data?.conversion_rates);
		});
	}, [selectedDropdownCurrency]);

	useEffect(() => {
		fatchData();
	}, []);

	useEffect(() => {
		if (sendData.yearly_expenses.length > 0) {
			setInputList(sendData.yearly_expenses);
		} else {
			setInputList([{year: "", expenses: ""}]);
		}
	}, [sendData.yearly_expenses]);

	const searchDronorCompany = event => {
		let query = event.query;
		let _filteredPosition = DonorCompanies?.filter(item => item?.title_am?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
		setFilteredDonorCompanies(_filteredPosition);
	};
	const searchBankAccount = event => {
		let query = event.query;
		let _filteredBankAccount = bankAccount?.filter(item => item?.title_am?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
		setFilteredBankAccount(_filteredBankAccount);
	};
	const searchWorkPlace = event => {
		let query = event.query;
		let _filteredWorkPlace = workPlace?.filter(item => item?.title_am?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
		setFilteredWorkPlace(_filteredWorkPlace);
	};

	useEffect(() => {
		setOwnContributionConverted(parseInt(sendData?.own_contribution / sendData?.currency_value));
		setGrantAmountConverted(parseInt(sendData?.grant_amount / sendData?.currency_value));
	}, [sendData?.currency_value, sendData.own_contribution, sendData.grant_amount, selectedDropdownCurrency]);

	function leaveThePage(e) {
		setSendData();
		props?.setEditCurrentProject(false);
	}

	function submitData(e) {
		axios
			.put(base + projects_url + props?.projectID + "/", {
				number: sendData?.project_number,
				name: sendData?.project_short_title,
				full_name_am: sendData?.project_full_title_am,
				full_name_en: sendData?.project_full_title_en,
				description: sendData?.project_description_am,
				start_date: sendData?.project_start,
				end_date: sendData?.project_end,
				currency_value: sendData?.currency_value,
				own_contribution: sendData?.own_contribution,
				grant_amount: sendData?.grant_amount,
				yearly_expenses: inputList,
				currency: selectedDropdownCurrency?.id,
				completed: sendData?.completed,

				// partner: props?.data?.id,
				// project: selectedProject?.id,
				// position: selectedPosition?.id,
				// work_place: selectedWorkplace?.id,
				// probation_period: selectedProbPer?.id,
			})
			.then(res => {
				toast.current.show({severity: "success", summary: "Success", detail: "The item is added successfully", life: 3000});
				setTimeout(() => {
					// setSendData()
					props?.setEditCurrentProject(false);
				}, 1000500);
			})
			.catch(error => {
				toast.current.show({severity: "error", summary: "Error", detail: "Please add something than press the button ", life: 3000});
			});
	}

	function removeProject(e) {
		axios
			.delete(base + projects_url + props?.projectID + "/", {})
			.then(res => {
				toast.current.show({severity: "success", summary: "Success", detail: "The item is added successfully", life: 3000});
				setTimeout(() => {
					setSendData();
					window.location.href = "/projects_dashboard";
				}, 1500);
			})
			.catch(error => {
				toast.current.show({severity: "error", summary: "Error", detail: "Please add something than press the button ", life: 3000});
			});
	}

	const accept = () => {
		toast.current.show({severity: "success", summary: "Confirmed", detail: "You have accepted", life: 3000});
		removeProject();
	};
	const reject = () => {
		toast.current.show({severity: "warn", summary: "Rejected", detail: "You have rejected", life: 3000});
	};

	const confirmDelete = e => {
		confirmDialog({
			message: "Do you want to delete this record?",
			header: "Delete Confirmation",
			icon: "pi pi-info-circle",
			acceptClassName: "p-button-danger",
			accept,
			reject,
		});
	};

	const handleInputChange = (e, index) => {
		const {name, value} = e.target;
		const list = [...inputList];
		list[index][name] = value;
		setInputList(list);
	};

	const handleRemoveClick = index => {
		const list = [...inputList];
		list.splice(index, 1);
		setInputList(list);
	};

	const handleAddClick = () => {
		setInputList([...inputList, {year: "", expenses: ""}]);
	};

	return (
		<>
			<div className="col-lg-12 mb-4">
				<div className="card b-radius b-shadow px-4 py-4 mb-3">
					<div className="fs-5 fw-bold text-uppercase border-bottom mb-3 pb-3"> Խմբագրել N {sendData?.project_number} նախագիծը </div>
					<div className="row">
						<div className="col-lg-3 col-md-6 mb-3 col-12">
							<label className="mb-2">Համարը</label>
							<InputText
								className="col-12 p-3"
								value={sendData.project_number}
								onChange={e => {
									setSendData({...sendData, project_number: e.target.value});
								}}
							/>
						</div>
						<div className="col-lg-3 col-md-6 mb-3 col-12">
							<label className="mb-2">Սկիզբ</label>
							<InputText
								type="date"
								className="col-12 p-3"
								value={sendData.project_start}
								onChange={e => {
									setSendData({...sendData, project_start: e.target.value});
								}}
							/>
						</div>
						<div className="col-lg-3 col-md-6 mb-3 col-12">
							<label className="mb-2">Ավարտ</label>
							<InputText
								type="date"
								className="col-12 p-3"
								value={sendData.project_end}
								onChange={e => {
									setSendData({...sendData, project_end: e.target.value});
								}}
							/>
						</div>
						<div className="col-lg-3 col-md-6 mb-3 col-12">
							<label className="mb-2">Կարճ անվանում</label>
							<InputText
								className="col-12 p-3"
								value={sendData.project_short_title}
								onChange={e => {
									setSendData({...sendData, project_short_title: e.target.value});
								}}
							/>
						</div>
						<div className="mb-3 col-12">
							<label className="mb-2">Լրիվ անվանում</label>
							<InputText
								className="col-12 p-3"
								value={sendData.project_full_title_am}
								onChange={e => {
									setSendData({...sendData, project_full_title_am: e.target.value});
								}}
							/>
						</div>
						<div className="mb-3 col-12">
							<label className="mb-2">Անվանումը անգլերեն</label>
							<InputText
								className="col-12 p-3"
								value={sendData.project_full_title_en}
								onChange={e => {
									setSendData({...sendData, project_full_title_en: e.target.value});
								}}
							/>
						</div>
						<div className="col-lg-4 col-md-6 mb-3 col-12">
							<label className="mb-2">Դրամաշնորհատու</label>
							<AutoComplete
								dropdown
								className="col-12 p-0"
								placeholder="Ընտրել ցանկից"
								field={"title_am"}
								completeMethod={searchDronorCompany}
								suggestions={filteredDonorCompanies}
								value={selectedDonorCompanies}
								onChange={e => setSelectetDonorCompanies(e.value)}
								itemTemplate={item => (
									<div className="d-flex align-items-top" onClick={e => setDonorCompanies(item.id)}>
										<div className="d-grid">
											<span className="text-primary">{item.title_am?.slice(0, 30)} </span>
											<span className="text-secondary small"> {item.title_en?.slice(0, 30) ?? "not provided"} </span>
										</div>
									</div>
								)}
							/>
						</div>
						<div className="col-lg-4 col-md-6 mb-3 col-12">
							<label className="mb-2">Բանկի հաշվեհամար</label>
							<AutoComplete
								dropdown
								className="col-12 p-0"
								placeholder="Ընտրել ցանկից"
								field={"title_am"}
								completeMethod={searchBankAccount}
								suggestions={filteredBankAccount}
								value={selectedBankAccount}
								onChange={e => setSelectetBankAccount(e.value)}
								itemTemplate={item => (
									<div className="d-flex align-items-top" onClick={e => setBankAccount(item.id)}>
										<div className="d-grid">
											<span className="text-primary">{item.title_am?.slice(0, 30)} </span>
											<span className="text-secondary small"> {item.title_en?.slice(0, 30) ?? "not provided"} </span>
										</div>
									</div>
								)}
							/>
						</div>
						<div className="col-lg-4 col-md-6 mb-3 col-12">
							<label className="mb-2">Աշխատավայր (ցանկալի է)</label>
							<AutoComplete
								dropdown
								className="col-12 p-0"
								placeholder="Ընտրել ցանկից"
								field={"title_am"}
								completeMethod={searchWorkPlace}
								suggestions={filteredWorkPlace}
								value={selectedWorkPlace}
								onChange={e => setSelectetWorkPlace(e.value)}
								itemTemplate={item => (
									<div className="d-flex align-items-top" onClick={e => setWorkPlace(item.id)}>
										<div className="d-grid">
											<span className="text-primary">{item.title_am?.slice(0, 30)} </span>
											<span className="text-secondary small"> {item.title_en?.slice(0, 30) ?? "not provided"} </span>
										</div>
									</div>
								)}
							/>
						</div>
						<div className="mb-2 col-sm-6">
							<label className="mb-2">Ծրագրի նկարագրություն հայերեն</label>
							<InputTextarea
								autoResize
								className="col-12"
								rows={4}
								cols={30}
								value={sendData.project_description_am}
								onChange={e => {
									setSendData({...sendData, project_description_am: e.target.value});
								}}
							/>
						</div>
						<div className="mb-2 col-sm-6">
							<label className="mb-2">Ծրագրի նկարագրություն անգլերեն</label>
							<InputTextarea
								autoResize
								className="col-12"
								rows={4}
								cols={30}
								value={sendData.project_description_en}
								onChange={e => {
									setSendData({...sendData, project_description_en: e.target.value});
								}}
							/>
						</div>
						<div className="col-6">
							<div className="mb-2 col-sm-12">
								<label className="mb-2">Փոխարժեք</label>
								<div className="d-flex align-items-center p-inputgroup py-1 border-0">
									<span className="p-inputgroup-addon">1</span>
									<Dropdown
										value={selectedDropdownCurrency}
										onChange={e => setSelectetDropdownCurrency(e.value)}
										options={dropdownCurrency}
										optionLabel="title_am"
										placeholder={selectedDropdownCurrency?.title_am ? selectedDropdownCurrency?.title_am : "."}
										className="p-0 m-0"
										disabled={null || undefined}
										style={{backgroundColor: "transparent"}}
									/>
									<span className="p-inputgroup-addon text-center py-3 border-0"> = </span>
									<InputNumber disabled={null || undefined} placeholder={currencyRate?.AMD} value={sendData?.currency_value} onChange={e => setSendData({...sendData, currency_value: e.value})} />
									<span className="p-inputgroup-addon d-grid text-center">
										<span>AMD</span>
									</span>
								</div>
							</div>
							<div className="mb-2 col-sm-12">
								<label className="mb-2">Սեփական ներդրումը</label>
								<div className="d-flex align-items-center p-inputgroup">
									<InputNumber placeholder={sendData?.own_contribution} value={sendData?.own_contribution} onChange={e => setSendData({...sendData, own_contribution: e.value})} />
									<span className="p-inputgroup-addon d-grid text-center py-1 border-0">
										<span>դր.</span>
										<span>=</span>
									</span>
									<InputNumber placeholder={ownContributionConverted} value={ownContributionConverted} />
									<span className="p-inputgroup-addon d-grid text-center">
										<span>{selectedDropdownCurrency?.title_am ? selectedDropdownCurrency?.title_am : "."}</span>
									</span>
								</div>
							</div>
							<div className="mb-2 col-sm-12">
								<label className="mb-2">Դրամաշնորի ներդրումը</label>
								<div className="d-flex align-items-center p-inputgroup">
									<InputNumber placeholder={sendData?.grant_amount} value={sendData?.grant_amount} onChange={e => setSendData({...sendData, grant_amount: e.value})} />
									<span className="p-inputgroup-addon d-grid text-center py-1 border-0">
										<span>դր.</span>
										<span>=</span>
									</span>
									<InputNumber placeholder={grantAmountConverted} value={grantAmountConverted} />
									<span className="p-inputgroup-addon d-grid text-center">
										<span>{selectedDropdownCurrency?.title_am ? selectedDropdownCurrency?.title_am : "."}</span>
									</span>
								</div>
							</div>
							<div className="mb-2 col-sm-12">
								<label className="mb-2">Ընդհանուր բյուջե</label>
								<div className="d-flex align-items-center p-inputgroup">
									<InputNumber placeholder={parseInt(sendData?.own_contribution) + parseInt(sendData?.grant_amount)} disabled />
									<span className="p-inputgroup-addon d-grid text-center py-1 border-0">
										<span>դր.</span>
										<span>=</span>
									</span>
									<InputNumber placeholder={parseInt(ownContributionConverted) + parseInt(grantAmountConverted)} disabled />
									<span className="p-inputgroup-addon d-grid text-center">
										<span>{selectedDropdownCurrency?.title_am ? selectedDropdownCurrency?.title_am : "."}</span>
									</span>
								</div>
							</div>
						</div>
						<div className="col-6">
							{/* {JSON.stringify(inputList)} */}
							<div className="row align-items-center border-bottom">
								<div className="col-10">
									<div className="row">
										<div className="col-4">Տարեթիվ</div>
										<div className="col-4">Բյուջե</div>
									</div>
								</div>
								<div className="col-2">Ավելացնել</div>
							</div>
							{inputList.map((item, index) => {
								return (
									<div key={index} className="row align-items-center">
										<div className="col-10 p-0">
											<div className="row">
												<div className="col-6">
													<InputText type="number" name="year" className="col-12" value={item.year} onChange={e => handleInputChange(e, index)} />
												</div>
												<div className="col-6">
													<InputText type="number" name="expenses" className="col-12" value={parseInt(item.expenses)} onChange={e => handleInputChange(e, index)} />
												</div>
											</div>
										</div>
										<div className="col-2 p-0">
											{inputList.length !== 1 && <Button severity="danger" text rounded icon={"pi pi-trash"} className="ms-2" onClick={() => handleRemoveClick(index)} />}
											{inputList.length - 1 === index && <Button severity="success" text rounded icon={"pi pi-plus"} className="ms-2" onClick={() => handleAddClick()} />}
										</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className="mb-3 row col-4 mx-auto">
						<div className="d-flex justify-content-center">
							<div className="form-check">
								<input className="form-check-input" type="checkbox" id="flexCheckDefault" value={sendData.completed} onChange={e => setSendData({...sendData, completed: !sendData.completed})} />
								<label className="form-check-label" for="flexCheckDefault">
									Ավարտված
								</label>
							</div>
						</div>
					</div>
					<div className="col-12 text-center border-top py-5">
						<Button label="Հեռացնել" rounded className="mx-2 px-5" severity="danger" onClick={e => confirmDelete()} />
						<Button label="Չեղարկել" rounded className="mx-2 px-5" severity="secondary" onClick={e => leaveThePage()} />
						<Button label="Պահպանել" rounded className="mx-2 px-5" severity="primary" onClick={e => submitData(e)} />
					</div>
				</div>
			</div>

			<Toast ref={toast} />
			<ConfirmDialog />
		</>
	);
}
