export const fetchRatingsData = async () => {
	try {
		const response = await fetch("/api/ratings");
		if (!response.ok) throw new Error("Failed to fetch ratings data");
		return await response.json();
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const updateRatingsData = async (data, percentages, setData) => {
	try {
		const response = await fetch("/api/update-ratings", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({data, percentages}),
		});
		if (!response.ok) throw new Error("Failed to update ratings data");
		const updatedData = await response.json();
		setData(updatedData);
	} catch (error) {
		console.error(error);
		throw error;
	}
};
