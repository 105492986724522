import React, {useState, useEffect, useRef} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Toast} from "primereact/toast";
import {base, expances_row_by_project} from "../../../../../api";
import axios from "axios";
import {Dialog} from "primereact/dialog";
import ExpancesNewRowDialog from "./ExpancesNewRowDialog";
import CurrencyValue_data from "../../../widgets/CurrencyValue_data";

export default function RowExpansionDemo(props) {
	const [data, setData] = useState([]);
	const [dialogVisibility, setDialogVisibility] = useState(false);
	const [error, setError] = useState(null);

	const [products, setProducts] = useState([]);
	const [expandedRows, setExpandedRows] = useState(null);
	const toast = useRef(null);

	useEffect(() => {
		fetchData();
	}, [props.data]);

	const fetchData = async () => {
		try {
			const response = await axios.get(`${base}${expances_row_by_project}${props.data}/`);
			setProducts(response.data);
			console.log(response.data);
		} catch (err) {
			setError("Error fetching data");
		}
	};

	const allowExpansion = rowData => {
		return rowData?.expance_rows?.length > 0;
	};

	const categoryBodyTemplate = rowData => {
		return rowData.category?.title_am;
	};

	const categoryCodeBodyTemplate = rowData => {
		return rowData.category?.code;
	};
	const calculateEstimatedCostUSD = rowData => {
		return parseFloat(rowData?.salary).toFixed(2);
	};
	const calculateEstimatedCostAMD = rowData => {
		return parseFloat(rowData?.salary * (props?.currency_value || 0)).toFixed(2);
	};
	const calculateActualCostAMD = rowData => {
		return (
			<div
				className={
					parseFloat(rowData?.remaining_budget_per_expence) < parseFloat(rowData?.salary)
						? "text-danger fw-bold"
						: parseFloat(rowData?.remaining_budget_per_expence) == parseFloat(rowData?.salary)
						? "fw-bold"
						: "fw-bold text-success"
				}
			>
				{parseFloat(rowData?.remaining_budget_per_expence * (props?.currency_value || 0)).toFixed(
					2
				)}
			</div>
		);
	};
	const calculateActualCostUSD = rowData => {
		return (
			<div
				className={
					parseFloat(rowData?.remaining_budget_per_expence) < parseFloat(rowData?.salary)
						? "text-danger fw-bold"
						: parseFloat(rowData?.remaining_budget_per_expence) == parseFloat(rowData?.salary)
						? "fw-bold"
						: "fw-bold text-success"
				}
			>
				{parseFloat(rowData?.remaining_budget_per_expence).toFixed(2)}
			</div>
		);
	};
	const amountUSDTemplate = rowData => {
		return parseFloat(rowData?.amount * rowData?.exchange_rate).toFixed(2);
	};
	const partnerBody = rowData => {
		return (
			<div className="d-grid">
				<span className="d-flex align-items-center fw-bolder small">
					{rowData?.partner?.full_name_am ?? rowData?.partner?.company_am}
					{rowData?.partner?.legal == "Physical" ? (
						<div className="small text-primary ms-1">(Անհատ)</div>
					) : null}
					{rowData?.partner?.legal == "Individual" ? (
						<div className="small text-primary ms-1">(ԱՁ)</div>
					) : null}
					{rowData?.partner?.legal == "Legal" ? (
						<div className="small text-primary ms-1">(Կազմակերպություն)</div>
					) : null}
				</span>
				<span className="small text-muted mt-1">Էլ. հասցե՝ {rowData?.partner?.email}</span>
				<span className="small text-muted mt-1">Հեռ. համար՝ {rowData?.partner?.phone}</span>
			</div>
		);
	};

	const rowExpansionTemplate = data => {
		return (
			<div className="p-3 border">
				<DataTable value={data.expance_rows}>
					<Column field="id" header="Id"></Column>
					<Column header="Partner" body={partnerBody}></Column>
					<Column field="date" header="Date"></Column>
					<Column field="amount" header="Ծախս (Կուրսով)"></Column>
					<Column header="Ծախս (դրամով)" body={amountUSDTemplate}></Column>
				</DataTable>
			</div>
		);
	};

	return (
		<div className="card">
			<Toast ref={toast} />
			{error && <div className="alert alert-danger">{error}</div>}
			<DataTable
				value={products}
				expandedRows={expandedRows}
				onRowToggle={e => setExpandedRows(e.data)}
				rowExpansionTemplate={rowExpansionTemplate}
				dataKey="id"
				tableStyle={{minWidth: "60rem"}}
			>
				<Column expander={allowExpansion} style={{width: "5rem"}} />
				<Column header="N" body={categoryCodeBodyTemplate} />
				<Column header="category" body={categoryBodyTemplate} />
				<Column
					header="Հաշվարկային ծախսը (կուրսով)"
					body={calculateEstimatedCostUSD}
					bodyClassName={"text-center"}
					className="text-center"
				/>
				<Column
					header="Հաշվարկային ծախսը (AMD)"
					body={calculateEstimatedCostAMD}
					bodyClassName={"text-center"}
					className="text-center"
				/>
				<Column
					header="Փաստացի ծախսը (AMD)"
					body={calculateActualCostAMD}
					bodyClassName={"text-center"}
					className="text-center"
				/>
				<Column
					header="Փաստացի ծախսը (կուրսով)"
					body={calculateActualCostUSD}
					bodyClassName={"text-center"}
					className="text-center"
				/>
			</DataTable>

			<div className="card">
				{error && <div className="alert alert-danger">{error}</div>}

				{/* <Dialog
					visible={dialogVisibility}
					modal
					className="col-8 mx-auto"
					onHide={() => setDialogVisibility(false)}
					header={
						<div className="border-bottom">
							<CurrencyValue_data data={props?.projectCurrencyValue} small={true} />
						</div>
					}
				>
					<ExpancesNewRowDialog projectCurrencyValue={props?.projectCurrencyValue} />
				</Dialog> */}
				<Toast ref={toast} />
			</div>
		</div>
	);
}
