import { useCallback, useEffect, useState, useRef } from "react";
import { ticket_chat, usersURL, base, projects_url } from "../../api";
import axios from "axios";

export const useTicketSystem = () => {
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState([]);
  const [messages, setMessages] = useState([]);
  const messageRequestInterval = useRef(null);

  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    axios.get(base + usersURL).then((res) => {
      setUsers(res.data);
    });
    axios.get(base + projects_url).then((res) => {
      const projects = res?.data.map((obj) => ({
        name: obj.full_name_am,
        code: obj.full_name_am,
        id: obj.id,
      }));
      console.log(projects);
      setProjects(projects);
    });
  }, []);

  const fetchTickets = useCallback(async () => {
    const response = await axios.get(ticket_chat.ticket);

    setTickets(response.data);
    console.log(response.data);
  }, []);

  const fetchMessages = useCallback(async (ticketId) => {
    console.log(ticketId);
    const response = await axios.get(ticket_chat.ticket_messages`${ticketId}`);

    setMessages(response.data);
    console.log(response.data);
  }, []);

  const openTicket = useCallback((ticket) => {
    setSelectedTicket(ticket); // TODO Tigran, Error handleing
    fetchMessages(ticket.id);
    if (messageRequestInterval) {
      clearInterval(messageRequestInterval.value);
    }
    messageRequestInterval.value = setInterval(
      () => fetchMessages(ticket.id),
      5000
    );
  }, []);

  useEffect(() => {
    fetchTickets();
    const refreshIntervalId = setInterval(fetchTickets, 10000);
    return () => clearInterval(refreshIntervalId);
  }, []);

  const sendMessage = useCallback(
    async (msg) => {
      const messageData = {
        ticket: selectedTicket.id,
        message: msg,
      };

      await axios.post(ticket_chat.message, messageData);
      //TODO Tigran error handleing
    },
    [selectedTicket]
  );

  const createTicket = useCallback(
    async ({ description, users, project, date }) => {
      const messageData = {
        name: description,
        ticket_members: users.map((user) => user.id),
        project: project.id,
        types: [1],
        date,
      };
      console.log({ messageData });

      await axios.post(ticket_chat.ticket, messageData);
      //TODO Tigran error handleing
    },
    []
  );

  return {
    tickets,
    openTicket,
    messages,
    selectedTicket,
    sendMessage,
    users,
    projects,
    createTicket,
  };
};
