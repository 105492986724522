import React, {useState, useEffect} from "react";
import {Chart} from "primereact/chart";

export default function ChartBar() {
	const [chartData, setChartData] = useState({});
	const [chartOptions, setChartOptions] = useState({});

	useEffect(() => {
		const documentStyle = getComputedStyle(document.documentElement);
		const data = {
			labels: ["Physical Partner", "Individual Partner", "Legal Partner"],
			datasets: [
				{
					// barThickness: 20,
					borderRadius: 10,
					backgroundColor: ["#119c8d", "#047f94", "#d9342b"],
					data: [665, 259, 141],
				},
			],
		};
		const options = {
			plugins: {
				legend: {
					display: false, // Hide the legend (including the switcher button)
				},
			},
			scales: {
				x: {
					barPercentage: 0.9,
					grid: {
						display: false, // Remove x-axis grid lines
					},
				},
				y: {
					display: false,
					grid: {
						display: false, // Remove y-axis grid lines
					},
				},
			},
		};

		setChartData(data);
		setChartOptions(options);
	}, []);

	return (
		<div className="card">
			<Chart type="bar" data={chartData} options={chartOptions} className="w-100  " style={{height: "250px"}} />
		</div>
	);
}
