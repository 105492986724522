import { useEffect, useState } from "react";

export const useConnectionStatus = () => {
  const [status, setStatus] = useState(true);

  useEffect(() => {
    window.addEventListener("offline", () => setStatus(false));
    window.addEventListener("online", () => setStatus(true));
  }, []);

  useEffect(() => {
    if (navigator.onLine) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  });
  return status;
};
