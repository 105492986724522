import { Button } from "primereact/button";

export default (
  onTemplateRemove,
) =>
  (file, props) => (
    <div className="card b-radius b-shadow w-100 flex align-items-center flex-wrap mb-3">
      <div className="col-12 flex align-items-center p-0">
        <img alt={file.name} role="presentation" width={100} className="b-radius b-shadow" src={file.objectURL}/>
        <span className="flex flex-column text-left ml-3">
          {file.name}
          <small className="fst-italic small text-primary">
            {props.formatSize}
          </small>
        </span>
        <Button icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    </div>
  );

