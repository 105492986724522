import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { renameFile, downloadOwnFile } from "../../repository"
import { dateToDisplayFormat } from "../../../../utils/utils"
import { useTranslation } from 'react-i18next';

export default function OwnFileNodeTemplate(node, options, onFileSelect, onDeletePressed, onSharePressed, refresh) {
    const { t } = useTranslation();
    const createdAtText = useMemo(() => dateToDisplayFormat(node.createdAt), [node.label])
    const dateText = useMemo(() => dateToDisplayFormat(node.updatedAt), [node.label])
    const [isEditMode, setIsEditMode] = useState(false)
    const [editedTitle, setEditedTitle] = useState(node.label)
    let label = <div className="text-dark">{node.label}</div>;

    const _buildLabel = () => {
        return <div
            className="d-grid text-start" onClick={() => onFileSelect(node)}>
            <span className="m-0 h5 text-cut">{node.label}</span>
            <div className="d-grid text-muted">
                <span className="small">
                    Created on: {createdAtText}
                </span>
                <span className="small">
                    Last modified on: {dateText}
                </span>
            </div>
        </div>;
    }

    label = isEditMode ? (
        <input type="text" className="form-control" placeholder={node.label} value={editedTitle} onChange={e => setEditedTitle(e.target.value)} />
    ) : _buildLabel();

    return (
        <div className="d-flex justify-content-center align-items-baseline">
            <span className={options.className}>
                {label}
            </span>

            <div className="d-flex justify-content-center align-content-center mx-3">

                <Button
                    download
                    icon="pi pi-download mx-3"
                    tooltip={t('_mediacomp_download_a_file')}
                    tooltipOptions={{ position: "top" }}
                    rounded
                    text
                    severity="halp"
                    onClick={() => {
                        downloadOwnFile(node.key, node.label);
                    }}
                />

                {isEditMode ? (
                    <Button
                        icon="pi pi-save mx-3"
                        tooltip={t('_mediacomp_rename_a_file')}
                        tooltipOptions={{ position: "top" }}
                        rounded
                        text
                        severity="success"
                        onClick={(e) => {
                            // send edit request
                            console.log({ editedTitle });
                            renameFile({ fileId: node.key, newFilename: editedTitle })
                            setIsEditMode(false)
                            refresh()
                        }}
                    />
                ) : (
                    <Button
                        icon="pi pi-file-edit mx-3"
                        tooltip={t('_mediacomp_rename_a_file')}
                        tooltipOptions={{ position: "top" }}
                        rounded
                        text
                        severity="success"
                        onClick={(e) => setIsEditMode(true)}
                    />
                )}
                <Button
                    icon={isEditMode ? "pi pi-times mx-3" : "pi pi-trash mx-3"}
                    tooltip={isEditMode ? t('_mediacomp_cancel') : t('_mediacomp_remove_file')}
                    tooltipOptions={{ position: "top" }}
                    rounded
                    text
                    severity="danger"
                    onClick={isEditMode ? () => setIsEditMode(false) : () => onDeletePressed(node)}
                />
                <Button
                    icon={isEditMode ? "pi pi-file-export mx-3" : "pi pi-share-alt mx-3"}
                    tooltip={isEditMode ? "File is shared" : "Share File"}
                    tooltipOptions={{ position: "top" }}
                    rounded
                    text
                    severity="help"
                    onClick={() => onSharePressed(node)}
                />
            </div>

        </div>
    );
};