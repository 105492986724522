import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { useTranslation } from 'react-i18next';

export default (
  onTemplateRemove,
  multiselectProjectsByFile,
  optionProjects,
  setFileProjects,
  multiselectLocationsByFile,
  optionLocations,
  setFileLocations,
  multiselectTagsByFile,
  optionTags,
  setFileTags,
  datesByFile,
  setFileDate,
  ) => {
    const { t } = useTranslation();
    
    return (file, props) => {
      const imageMimeType = /image\/(png|jpg|jpeg)/i;

    return (
      <div className="card b-radius b-shadow w-100 flex align-items-center flex-wrap mb-3">
        <div className="col-12 flex align-items-center p-0">
          {file.type == imageMimeType &&
            <div className="b-radius b-shadow upload-preview" style={{backgroundImage: 'url('+ file.objectURL +') '}}></div>
          }
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small className="fst-italic small text-primary">{props.formatSize} </small>
          </span>
          <Button icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto"
            onClick={() => onTemplateRemove(file, props.onRemove)}
          />
        </div>

        <div className="col-12 flex justify-content-center align-items-center p-0 ">
          <div className="row">
            <div className="col-lg-3 col-12 ">
              <MultiSelect
                value={multiselectProjectsByFile[`${file.size}-${file.name}`]}
                filter
                placeholder={t('_mediacomp_select_project')}
                maxSelectedLabels={0}
                className="col m-0 p-0 b-radius"
                optionLabel="name"
                options={optionProjects}
                onChange={(e) => setFileProjects(file, e.value)}
              />
            </div>
            <div className="col-lg-3 col-12">
              <MultiSelect
                value={multiselectLocationsByFile[`${file.size}-${file.name}`]}
                filter
                placeholder={t('_mediacomp_select_location')}
                maxSelectedLabels={0}
                className="col-12 m-0 p-0 b-radius"
                optionLabel="name"
                options={optionLocations}
                onChange={(e) => setFileLocations(file, e.value)}
              />
            </div>
            <div className="col-lg-3 col-12">
              <MultiSelect
                value={multiselectTagsByFile[`${file.size}-${file.name}`]}
                filter
                placeholder={t('_mediacomp_select_tags')}
                maxSelectedLabels={0}
                className="col-12 m-0 p-0 b-radius"
                optionLabel="name"
                options={optionTags}
                onChange={(e) => setFileTags(file, e.value)}
              />
            </div>
            <div className="col-lg-3 col-12">
              <InputText
                type="date"
                className="col-12 m-0 p-0 b-radius px-3"
                style={{ height: "43px" }}
                value={datesByFile[`${file.size}-${file.name}`]}
                onChange={(e) => setFileDate(file, e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}
