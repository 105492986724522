import React, {useEffect, useState} from "react";
import axios from "axios";
import {base, partners} from "../../api";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Avatar} from "primereact/avatar";
import {ColorizedAvatars} from "../../components/ui/elements/ColorizedAvatars";
import {TbAtom2} from "react-icons/tb";

export default function PartnersSummeryComponent() {
	const [partnersData, setPartnersData] = useState([]);

	useEffect(() => {
		axios.get(base + partners).then(res => {
			setPartnersData(res.data);
		});
	}, []);

	const nameBody = rowData => {
		return (
			<div className="d-flex align-items-center">
				<Avatar label={rowData.full_name_am?.substring(0, 1)} className="me-4 text-uppercase text-white" shape="circle" size="large" style={{backgroundColor: ColorizedAvatars(rowData?.full_name_am?.substring(0, 2))}} />
				<div className="d-grid text-truncate">
					<div className="">
						<span className="fw-bold text-primary">{rowData?.full_name_am}</span> ({rowData.legal === "Individual" && <span className="small">Անհատ ձեռներեց</span>}
						{rowData.legal === "Physical" && <span className="small">Ֆիզիկական անձ</span>}
						{rowData.legal === "Legal" && <span className="small">Կազմակերպություն</span>})
					</div>
					<div className="my-1 small">{rowData.email}</div>
					<div className="small">{rowData.phone}</div>
				</div>
			</div>
		);
	};
	return (
		<div className="card w-100 b-radius b-shadow p-0">
			<div className="card w-100 border-0 text-bg-light p-0">
				<div className="card-body w-100">
					<div className="flex-column w-100">
						<div className="d-flex no-block align-items-center me-3">
							<TbAtom2 className="display-6  me-3 m-0" />
							<div className="m-0">
								<h5 className=" m-0">Partners summery</h5>
							</div>
						</div>
						<div className="row text-center  mt-4">
							<div className="col-6 d-grid">
								<p className="fs-3 d-flex justify-content-center align-items-center">sdffg</p>
								<span className="text-uppercase small">Ongoing projects</span>
							</div>
							<div className="col-6 d-grid">
								<p className="fs-3 d-flex justify-content-center align-items-center">"efmvdfl"</p>
								<span className="text-uppercase small">Complated projects</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="p-0">
				<DataTable value={partnersData?.slice(0, 5)} rows={6} dataKey="id" emptyMessage="No user found.">
					<Column body={nameBody}></Column>
				</DataTable>
			</div>
		</div>
	);
}
