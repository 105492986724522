import {useCallback, useEffect, useMemo, useState} from "react";
import {Button} from "primereact/button";
import {FiCheck, FiX} from "react-icons/fi";
import permissionsGrouped from "../../static/permissionsGroups.json";
import {MultiSelect} from "primereact/multiselect";
import axios from "axios";
import {updateGroupsAndPermissions} from "../../../api";

const PermissionCell = ({id, enabledPermissions, disabledPermissions, groupPermissions, removePermission, setEnabled, setDisabled}) => {
	const isEnabled = useMemo(() => {
		return enabledPermissions.some(perm => perm == id);
	}, [id, enabledPermissions, disabledPermissions, groupPermissions]);

	const isDisabled = useMemo(() => {
		return disabledPermissions.some(perm => perm == id);
	}, [id, enabledPermissions, disabledPermissions, groupPermissions]);

	const userGroupContainsPermission = useMemo(() => {
		return groupPermissions.some(perm => perm == id);
	}, [id, enabledPermissions, disabledPermissions, groupPermissions]);

	const togglePerm = () => {
		if (isDisabled) {
			return removePermission(id);
		}
		if (userGroupContainsPermission) {
			return setDisabled(id);
		}
		if (isEnabled) {
			return removePermission(id);
		}
		return setEnabled(id);
	};

	if (isDisabled) {
		return (
			<div className="col-lg-2 col-12 d-flex align-items-center text-start small" onClick={togglePerm}>
				<div className="bg-danger opacity-80 b-radius border-1 px-4 py-2 me-1" style={{borderColor: "transparent"}}>
					<FiX className="text-white" />
				</div>
			</div>
		);
	}
	if (userGroupContainsPermission) {
		return (
			<div className="col-lg-2 col-12 d-flex align-items-center text-start small" onClick={togglePerm}>
				<div className="bg-primary opacity-80 b-radius border-1 px-4 py-2 me-1" style={{borderColor: "transparent"}}>
					<FiCheck className="text-white" />
				</div>
			</div>
		);
	}

	if (isEnabled) {
		return (
			<div className="col-lg-2 col-12 d-flex align-items-center text-start small " onClick={togglePerm}>
				<div className="bg-info opacity-80 b-radius border-1 px-4 py-2 me-1" style={{borderColor: "transparent"}}>
					<FiCheck className="text-white" />
				</div>
			</div>
		);
	}

	return (
		<div className="col-lg-2 col-12 d-flex align-items-center text-start small" onClick={togglePerm}>
			<div className="b-radius border-1 opacity-30 px-4 py-2 me-1">
				<FiX className="text-dark" />
			</div>
		</div>
	);
};

export default function PermissionsSelectForm({groups, userInfo, setParam}) {
	const [selectedGroups, setSelectedGroups] = useState(userInfo.groups);
	const [enabledPermissions, setEnabledPermissions] = useState(userInfo.user_permissions);
	const [disabledPermissions, sedDisabledPermissions] = useState(userInfo.profile?.disabled_permissions || []);
	const groupPermissions = useMemo(() => selectedGroups.flatMap(group => group.permissions), [selectedGroups]);

	useEffect(() => {
		setEnabledPermissions(enabledPermissions.filter(permId => !groupPermissions.includes(permId)));
		sedDisabledPermissions(disabledPermissions.filter(permId => groupPermissions.includes(permId)));
	}, [groupPermissions]);

	const setDisabled = id => {
		setEnabledPermissions(enabledPermissions.filter(permID => permID != id));
		sedDisabledPermissions([...disabledPermissions, id]);
	};
	const setEnabled = id => {
		sedDisabledPermissions(disabledPermissions.filter(permID => permID != id));
		setEnabledPermissions([...enabledPermissions, id]);
	};
	const removePermission = id => {
		sedDisabledPermissions(disabledPermissions.filter(permID => permID != id));
		setEnabledPermissions(enabledPermissions.filter(permID => permID != id));
	};

	// const save = useCallback(() => {
	//     return axios.post(updateGroupsAndPermissions, {
	//         enabled_permissions: enabledPermissions,
	//         disabled_permissions: disabledPermissions,
	//         groups: selectedGroups.map(group => group.id),
	//         user_id: userInfo.id,
	//     });
	// }, [selectedGroups, enabledPermissions, disabledPermissions])

	useEffect(() => {
		setParam({
			enabled_permissions: enabledPermissions,
			disabled_permissions: disabledPermissions,
			groups: selectedGroups.map(group => group.id),
			user_id: userInfo.id,
		});
	}, [selectedGroups, enabledPermissions, disabledPermissions]);

	return (
		<div>
			{/* <button onClick={save}>SAVE</button> */}
			<form className="d-grid">
				<div className="col-12 mb-2 stretch-card flex flex-column">
					<MultiSelect
						value={selectedGroups}
						placeholder="Select groups"
						optionLabel="name"
						maxSelectedLabels={3}
						options={groups}
						onChange={e => {
							setSelectedGroups(e.value);
						}}
					/>
				</div>
				<div className="d-flex col-12 p-0">
					<div className="col-4 fw-bold ">Permission name</div>
					<div className="col-2 fw-bold">Add</div>
					<div className="col-2 fw-bold">View</div>
					<div className="col-2 fw-bold">Change</div>
					<div className="col-2 fw-bold">Delete</div>
				</div>
				{permissionsGrouped.map((data, index) => (
					<div key={index} className="d-flex col-12 border-bottom align-items-center text-center">
						<div className="col-4 d-flex text-center p-0">{data.name_en}</div>
						<PermissionCell id={data.add} enabledPermissions={enabledPermissions} disabledPermissions={disabledPermissions} groupPermissions={groupPermissions} removePermission={removePermission} setEnabled={setEnabled} setDisabled={setDisabled} />

						<PermissionCell id={data.view} enabledPermissions={enabledPermissions} disabledPermissions={disabledPermissions} groupPermissions={groupPermissions} removePermission={removePermission} setEnabled={setEnabled} setDisabled={setDisabled} />

						<PermissionCell id={data.change} enabledPermissions={enabledPermissions} disabledPermissions={disabledPermissions} groupPermissions={groupPermissions} removePermission={removePermission} setEnabled={setEnabled} setDisabled={setDisabled} />

						<PermissionCell id={data.delete} enabledPermissions={enabledPermissions} disabledPermissions={disabledPermissions} groupPermissions={groupPermissions} removePermission={removePermission} setEnabled={setEnabled} setDisabled={setDisabled} />
					</div>
				))}
			</form>
		</div>
	);
}
