import React from "react";
import {TbCurrencyDram} from "react-icons/tb";
import {BiRightArrowAlt} from "react-icons/bi";
import {Link} from "react-router-dom";

export default function ReadModeTableData(props) {
	return (
		<tr className="border small">
			<td className="border">{props?.data?.identifier}</td>
			<td className="border word-brake">{props?.data?.type?.title_am}</td>
			<td className="border">{props?.data?.category?.code}</td>
			<td className="border">{props?.data?.category?.title_am}</td>
			<td className="border">{props?.data?.unit?.title_am}</td>
			<td className="border">{props?.data?.count} </td>
			<td className="border">
				<span>
					{parseFloat(props?.data?.salary)} {props?.data?.project?.currency?.title_am}
				</span>
				{/* <span>
									{(
										parseFloat(props?.data?.salary) *
										parseFloat(props?.data?.project?.currency_value)
									).toFixed(2)}
									<TbCurrencyDram />
								</span> */}
			</td>
			<td className="border">
				<span>
					{props?.data?.count * parseFloat(props?.data?.salary)}{" "}
					{props?.data?.project?.currency?.title_am}
				</span>
				{/* <span>
							{(
								props?.data?.count *
								parseFloat(props?.data?.salary) *
								parseFloat(props?.data?.project?.currency_value)
							).toFixed(2)}
							<TbCurrencyDram />
						</span> */}
			</td>
			<td className="border">
				<Link
					to={`/budget/mounthly/${props?.data?.id}`}
					state={{data: props?.data}}
					className="btn btn-primary d-flex justify-content-center align-items-center text-center rounded-4 p-1"
				>
					<BiRightArrowAlt />
				</Link>
			</td>
		</tr>
	);
}
