import React, {useState} from "react";
import {Avatar} from "primereact/avatar";
import {Dialog} from "primereact/dialog";
import {Link} from "react-router-dom";
import {Button} from "primereact/button";

export default function CoWorkerWidget(props) {
	const [visible, setVisible] = useState(false);

	return (
		<>
			<Button
				text
				severity="secondary"
				onClick={() => {
					setVisible(true);
					localStorage.setItem("item", props?.data.id);
				}}
				className="row  text-start"
			>
				<div className="d-flex justify-content-start align-items-center gap-3 py-3 col-lg-3 col-12" style={{minHeight: "60px"}}>
					<Avatar label={props?.data?.partner?.full_name_am?.slice(0, 1)} size="large" shape="circle" style={{borderRadius: "100%", padding: "1px", overflow: "visible"}} className={props?.data?.busy_percentage <= 15 ? `text-white bg-secondary text-uppercase` : props?.data?.busy_percentage <= 30 ? `text-white bg-danger text-uppercase` : props?.data?.busy_percentage <= 55 ? `text-white bg-info text-uppercase` : props?.data?.busy_percentage <= 75 ? `text-white bg-warning text-uppercase` : props?.data?.busy_percentage <= 100 ? `text-white bg-success text-uppercase` : "null"}></Avatar>
					<div className="fw-bold">{props?.data?.partner?.full_name_am}</div>
				</div>
				<div className="col-lg-6 col-12">
					<div className="d-grid" style={{minHeight: "60px"}}>
						{/* {props?.data?.project?.slice(0, 3).map((project) =>
                            <span className=''>{project.name} </span>
                        )} */}
						<span className="text-truncate">{props?.data?.project.full_name_am} </span>
					</div>
				</div>
				<div className="col-lg-3 col-12">
					<div className="d-flex justify-content-between align-items-center" style={{minHeight: "60px", minWidth: "180px"}}>
						<div className="progress w-100 b-radius border-0" style={{height: "8px"}}>
							<div className={props?.data?.busy_percentage <= 15 ? `progress-bar bg-secondary` : props?.data?.busy_percentage <= 30 ? `progress-bar bg-danger` : props?.data?.busy_percentage <= 55 ? `progress-bar bg-info` : props?.data?.busy_percentage <= 75 ? `progress-bar bg-warning` : props?.data?.busy_percentage <= 100 ? `progress-bar bg-success` : "null"} role="progressbar" style={{width: props?.data?.busy_percentage + "%"}} aria-valuenow={props?.data?.busy_percentage} aria-valuemin="0" aria-valuemax="100"></div>
						</div>
						<span className="fw-semibold ms-4">{props?.data?.busy_percentage + "%"}</span>
					</div>
				</div>
			</Button>

			<Dialog header={props?.data?.partner?.full_name_am || props?.data?.partner?.company_am} visible={visible} onHide={() => setVisible(false)} className="col-lg-6 col-12 p-0 border-bottom">
				<div className="row p-0 m-0 ">
					<div className="col-12">
						<div className="d-flex">
							<div className="col">
								<div className="fs-3 fw-bolder text-primary">{props?.data?.project.lenght} 1289</div>
								<div className="text-muted">Ավարտած նախագծեր</div>
							</div>
							<div className="col">
								<div className="fs-3 fw-bolder text-primary">568</div>
								<div className="text-muted">Ընթացիկ նախագծեր</div>
							</div>
						</div>
					</div>
					<div className="row">
						<span className="fw-bolder border-bottom pb-2 w-100 col-12">Նախագծեր:</span>
						<div className="d-grid">
							{/* {props?.data?.projects => */}
							<div className="row">
								<div className="col-md-10">
									<div className="my-3">
										<div className="d-flex justify-content-between align-items-center mb-2">
											<span className="d-grid">
												<span className="fw-bold">{props?.data?.project?.name} </span>
												<span className="small text-truncate">{props?.data?.project?.full_name_am}</span>
											</span>
											<span className="fw-bold ps-5 fs-5">{props?.data?.busy_percentage + "%"}</span>
										</div>
										<div className="progress w-100 b-radius border-0" style={{height: "5px"}}>
											<div className={props?.data.busy_percentage <= 15 ? `progress-bar bg-secondary` : props?.data.busy_percentage <= 30 ? `progress-bar bg-danger` : props?.data.busy_percentage <= 55 ? `progress-bar bg-info` : props?.data.busy_percentage <= 75 ? `progress-bar bg-warning` : props?.data.busy_percentage <= 100 ? `progress-bar bg-success` : "null"} role="progressbar" style={{width: props?.data.busy_percentage + "%"}} aria-valuenow={props?.data.busy_percentage} aria-valuemin="0" aria-valuemax="100"></div>
										</div>
									</div>
								</div>
								<div className="col-md-2 py-3">
									<Button
										icon="pi pi-trash"
										text
										rounded
										severity="danger"
										onClick={e => {
											props?.confirmDelete();
											setVisible(false);
										}}
									/>
									<Button icon="pi pi-pencil" text rounded severity="success" onClick={e => (props?.setEditDialog(true), props.setUserInfo(props.data), setVisible(false))} />
								</div>
							</div>

							{/* {props?.data?.projects?.map((project) =>
                                <div className="my-3">
                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                        <span>{project.name}</span>
                                        <span>{props?.data.buzyness + '%'}</span>
                                    </div>
                                    <div className="progress w-100 b-radius border-0" style={{ height: '8px' }}>
                                        <div className={
                                            props?.data.buzyness <= 15 ? `progress-bar bg-secondary` :
                                                props?.data.buzyness <= 30 ? `progress-bar bg-danger` :
                                                    props?.data.buzyness <= 55 ? `progress-bar bg-info` :
                                                        props?.data.buzyness <= 75 ? `progress-bar bg-warning` :
                                                            props?.data.buzyness <= 100 ? `progress-bar bg-success` : 'null'
                                        }
                                            role="progressbar" style={{ width: props?.data.buzyness + '%' }} aria-valuenow={props?.data.buzyness} aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            )} */}
						</div>
					</div>
					<div className="col-12 text-center my-3">
						<Link to={"/co_workers/" + props?.data.id} className="btn ">
							Մանրամասները անձնական էջում
						</Link>
					</div>
				</div>
			</Dialog>
		</>
	);
}
