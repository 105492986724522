import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useAuth } from "../../../context/AuthContext";
import logo_main from '../../../assets/edm_main.png';
import UserFakeInterface from '../UserFakeInterface';

export default function Login() {
  const [showPass, setShowPass] = useState(false)
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { authentication, mobileAuth, error, loading } = useAuth();

  const authFunc = async () => {
    await authentication(username, password)
  }
  return (
    mobileAuth ?
      <UserFakeInterface mobile={mobileAuth} /> :

      <div className="container-fluid p-0">
        <div className="row align-items-center" style={{ height: '100vh' }}>
          <div className="d-flex justify-content-center align-items-center">
            <form onSubmit={(e) => e.preventDefault(e)} className="card col-xxl-3 col-xl-4 col-lg-5 col-md-7 col-12 b-radius b-shadow px-5 py-4">

              <div className="col-12 text-center">
                <img className="col-10 mb-3 border-bottom pb-4" src={logo_main} alt="Azk" />
                <div className="fs-4 fw-bold text-uppercase pb-3">Welcome to Armavir Developmant center</div>
                <p className="mb-4">Please login to your personal account and start the adventure</p>
                {error ? <p className="mb-4 text-danger">The username or password is incorrect”</p> : null}
              </div>

              <div className='pb-3'>
                <div className="flex flex-column">
                  <label htmlFor="username" className="form-label pb-1">Username</label>
                  <InputText id="username" autoFocus="" value={username} onChange={(e) => setUsername(e.target.value)} />
                </div>
                <div className="flex flex-column py-4">
                  <label htmlFor="password" className="form-label pb-1">Password</label>
                  <span className="p-input-icon-right">
                    <i className={showPass ? "pi pi-eye-slash" : "pi pi-eye"} onClick={() => { setShowPass(!showPass) }} />
                    <InputText type={showPass ? "text" : "password"} id="password" className='w-100' placeholder="············" value={password}
                      onChange={(e) => { setPassword(e.target.value) }} />
                  </span>
                </div>
                <div className="text-center pt-4">
                  {loading ?
                    <Button icon="pi pi-spin pi-spinner" severity='success' rounded
                      disabled
                    />
                    :
                    <Button type='submit' label='Login' icon="pi pi-arrow-right me-3" severity='primary' rounded
                      disabled={loading}
                      onClick={authFunc}
                    />
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>


  )
}
