import { useState, useEffect, useCallback } from "react";

export function useOwnFileFilter(ownFiles, closeDialog) {
  const [filteredOwnFiles, setFilteredOwnFiles] = useState(null);

  const [onwSearchValue, setOwnSearchValue] = useState("");

  const [selectedDayRangeUser, setSelectedDayRangeUser] = useState({
    from: null,
    to: null,
  });

  const customOwnFileFilter = (node) => {
    let searchTextMatches = true;
    let dateMatches = true;
    if (onwSearchValue) {
      searchTextMatches = node.label
        .toLowerCase()
        .includes(onwSearchValue.toLowerCase());
    }

    if (selectedDayRangeUser?.from && selectedDayRangeUser?.to) {
      const createDate = new Date(node.createdAt);
      const updateDate = new Date(node.updatedAt);
      const fromDate = new Date(
        selectedDayRangeUser.from.year,
        selectedDayRangeUser.from.month - 1,
        selectedDayRangeUser.from.day
      );
      const toDate = new Date(
        selectedDayRangeUser.to.year,
        selectedDayRangeUser.to.month - 1,
        selectedDayRangeUser.to.day
      );
      dateMatches =
        (createDate > fromDate && createDate < toDate) ||
        (updateDate > fromDate && updateDate < toDate);
    }

    if (searchTextMatches && dateMatches) {
      return true;
    }

    if (node.children) {
      const childrenMatch = node.children.some((child) =>
        customOwnFileFilter(child, onwSearchValue)
      );
      if (childrenMatch) {
        return true;
      }
    }

    return false;
  };
  const filterOwnFileNodeChildren = (node) => {
    if (!customOwnFileFilter(node)) {
      return undefined;
    }
    if (!node.children || node.children.length == 0) {
      return node;
    }
    const filteredChildren = [...node.children].filter((node) =>
      customOwnFileFilter(node, onwSearchValue)
    );
    return { ...node, children: filteredChildren };
  };
  const filterOwnFiles = () => {
    const hasAnyFilter =
      (onwSearchValue && onwSearchValue.trim()) ||
      (selectedDayRangeUser?.from && selectedDayRangeUser?.to);

    if (!hasAnyFilter) {
      setFilteredOwnFiles(null); // No filter, show all nodes
      return;
    }
    const filteredNodes = [];
    ownFiles.forEach((node) => {
      const filteredNode = filterOwnFileNodeChildren(node);
      if (filteredNode) {
        filteredNodes.push(filteredNode);
      }
    });

    setFilteredOwnFiles(filteredNodes);
  };

  useEffect(filterOwnFiles, [
    onwSearchValue,
    selectedDayRangeUser?.from,
    selectedDayRangeUser?.to,
  ]);

  const clearOwnFileFilter = useCallback(() => {
    setFilteredOwnFiles(null);
    setOwnSearchValue("");
    setSelectedDayRangeUser({
      from: null,
      to: null,
    });

    closeDialog();
  }, []);

  return {
    filteredOwnFiles,
    selectedDayRangeUser,
    setSelectedDayRangeUser,
    setOwnSearchValue,
    onwSearchValue,
    clearOwnFileFilter,
  };
}
