import React, {useState, useEffect, useRef} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Toast} from "primereact/toast";
import {Dialog} from "primereact/dialog";
import {base, supply_contracts} from "../../../api";

import axios from "axios";
import {Button} from "primereact/button";
import NewContractAddForm from "./NewContractAddForm";
import DemandLetterDialog from "./DemandLetterDialog";

export default function DataTableDataContracts(props) {
	const [products, setProducts] = useState([]);
	const [dialogVisibility, setDialogVisibility] = useState(false);
	const toast = useRef(null);

	useEffect(() => {
		loadContent();
	}, []);
	const loadContent = () => {
		axios
			.get(base + supply_contracts)
			.then(res => {
				setProducts(res.data);
				// console.log(res.data);
			})
			.catch(error => {
				console.error("Error loading content:", error);
			});
	};
	const formatCurrency = value => {
		return value.toLocaleString("en-US", {style: "currency", currency: "USD"});
	};

	const amountBodyTemplate = rowData => {
		return formatCurrency(rowData.amount);
	};

	const searchBodyTemplate = () => {
		return <Button icon="pi pi-search" />;
	};

	const rowExpansionTemplate = data => {
		return (
			<div className="p-3">
				<h5>Orders for {data.name}</h5>
				<DataTable value={data.orders}>
					<Column field="id" header="Id" sortable></Column>
					<Column field="customer" header="Customer" sortable></Column>
					<Column field="date" header="Date" sortable></Column>
					<Column field="amount" header="Amount" body={amountBodyTemplate} sortable></Column>
					<Column headerStyle={{width: "4rem"}} body={searchBodyTemplate}></Column>
				</DataTable>
			</div>
		);
	};

	const persentageBody = rowData => {
		return (
			<div className="fst-italic">
				{rowData.rating * rowData.rating} <span className="ms-1">%</span>
			</div>
		);
	};

	const deliveryDateRangeBody = rowData => {
		return <div className="fst-italic">{rowData.price}</div>;
	};
	const warrentyDateRangeBody = rowData => {
		return <div className="fst-italic">{rowData.price * rowData.rating}</div>;
	};
	const contractDateBody = rowData => {
		const contractDate = rowData.orders?.[0]?.date || "N/A"; // Safely access the first order's date or fallback to "N/A"
		return <div className="fst-italic">{contractDate}</div>;
	};
	const contractIDBody = rowData => {
		return <div className="fst-italic">#{rowData?.id}</div>;
	};
	const contractStartEndDateBody = rowData => {
		return <div className="fst-italic">{rowData?.startDate + " - " + rowData?.endDate}</div>;
	};

	return (
		<div className="card">
			<Toast ref={toast} />
			<div className="row pb-5">
				<div className="col text-end">
					<Button label={"Նոր պայմանագիր"} rounded onClick={() => setDialogVisibility(true)} />
				</div>
			</div>
			<div style={{maxHeight: "500px", overflowY: "auto"}}>
				<DataTable
					value={products}
					rowExpansionTemplate={rowExpansionTemplate}
					dataKey="id"
					tableStyle={{minWidth: "60rem"}}
				>
					<Column field="id" header="Պայմանագրի համար" body={contractIDBody} className="col-1" />
					<Column field="name" header="Company" className="col-3" />
					<Column header="Մատակարարման ժամկետ" body={deliveryDateRangeBody} className="col-1" />
					<Column header="Երաշխիքային ժամկետ" body={warrentyDateRangeBody} className="col-1" />
					<Column field="name" header="Ծրագիր" className="col-3" />
					<Column header="Սկիզբ / Ավարտ" body={contractStartEndDateBody} className="col-3" />
					<Column header="Պայմանագրի ամսաթիվ" body={contractDateBody} />
					<Column header="Կանխավճարի տոկոս" body={persentageBody} />
				</DataTable>
			</div>
			<Dialog
				visible={dialogVisibility}
				modal
				className="col-8 mx-auto"
				onHide={() => setDialogVisibility(false)}
				header={<div className="border-bottom pt-2">Նոր պայմանագիր</div>}
			>
				<NewContractAddForm setDialogVisibility={setDialogVisibility} />
			</Dialog>
		</div>
	);
}
