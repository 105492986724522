import React, {useState, useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import {base, partners, partnersLegal, partnersIndividual, partnersPhysical} from "../../api";
import axios from "axios";
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {TabView, TabPanel} from "primereact/tabview";
import {Dialog} from "primereact/dialog";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FilterMatchMode, FilterOperator} from "primereact/api";
import {InputText} from "primereact/inputtext";
import PhisicalEditableForm from "./components/forms/PhisicalEditableForm";
import LegalEditableForm from "./components/forms/LegalEditableForm";
import {nameBody, detailsBody, bankDetailsBody, statusDetailsBody, companyNameBody, companyDetailsBody} from "./components/widgets/Widgets";
import PartnersDashboardStatisticCard from "./components/PartnersDashboardStatisticCard";

export default function PartnersDashboard() {
	const toast = useRef(null);
	const [visiblePhisical, setVisiblePhisical] = useState(false);
	const [visibleLegal, setVisibleLegal] = useState(false);
	const [userInfo, setUserInfo] = useState(null);
	const [loading, setLoading] = useState(true);
	const [activeIndex, setActiveIndex] = useState(0);

	const [visibleSelectedPhisicalPartner, setVisibleSelectedPhisicalPartner] = useState(false);
	const [selectedPhisicalPartner, setSelectedPhisicalPartner] = useState(null);

	const [visibleSelectedLegalPartner, setVisibleSelectedLegalPartner] = useState(false);
	const [selectedLegalPartner, setSelectedLegalPartner] = useState(null);

	const [dataPysical, setDataPysiscal] = useState([]);
	const [dataLegal, setDataLegal] = useState([]);
	const [dataIndividual, setDataIndividual] = useState([]);

	const [filters, setFilters] = useState({
		global: {value: null, matchMode: FilterMatchMode.CONTAINS},
		full_name_en: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
		phone: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
		email: {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
		phone: {value: null, matchMode: FilterMatchMode.IN},
		email: {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
	});

	function loadContent() {
		setLoading(false);
		axios.all([axios.get(base + partnersLegal), axios.get(base + partnersIndividual), axios.get(base + partnersPhysical)]).then(
			axios.spread((...response) => {
				console.log(response);
				setDataLegal(response[0].data);
				setDataIndividual(response[1].data);
				setDataPysiscal(response[2].data);
				setLoading(true);
			})
		);
	}

	useEffect(() => {
		loadContent();
	}, []);

	function removeUser(e) {
		axios.delete(base + partners + localStorage.getItem("item")).then(res => loadContent());
	}

	const accept = () => {
		toast.current.show({severity: "success", summary: "Confirmed", detail: "You have accepted", life: 3000});
		removeUser();
	};

	const reject = () => {
		toast.current.show({severity: "warn", summary: "Rejected", detail: "You have rejected", life: 3000});
	};

	const confirmDelete = e => {
		confirmDialog({
			message: "Do you want to delete this record?",
			header: "Delete Confirmation",
			icon: "pi pi-info-circle",
			acceptClassName: "p-button-danger",
			accept,
			reject,
		});
	};

	const footerbuttonsphisical = (
		<div>
			<Button label="Cancel" icon="pi pi-times" onClick={() => setVisiblePhisical(false)} className="btn small px-4 py-2 b-radius p-button-text" />
			<Button
				label="Save"
				icon="pi pi-check"
				onClick={e => {
					setVisiblePhisical(false);
				}}
				className="btn btn-label-primary small px-4 py-2 b-radius"
			/>
		</div>
	);
	const footerbuttonLegal = (
		<div>
			<Button label="Cancel" icon="pi pi-times" onClick={() => setVisibleLegal(false)} className="btn small px-4 py-2 b-radius p-button-text" />
			<Button
				label="Save"
				icon="pi pi-check"
				onClick={e => {
					setVisibleLegal(false);
				}}
				className="btn btn-label-primary small px-4 py-2 b-radius"
			/>
		</div>
	);
	const actionsPhisical = rowData => {
		return (
			<div className="d-flex justify-content-end">
				<Button
					icon={"pi pi-pencil"}
					severity="primary"
					className="mx-1"
					outlined
					rounded
					onClick={() => {
						setVisiblePhisical(true);
						setUserInfo(rowData);
					}}
				/>
				<Button
					icon={"pi pi-trash"}
					severity="danger"
					className="mx-1"
					outlined
					rounded
					onClick={e => {
						confirmDelete(rowData);
						localStorage.setItem("item", rowData.id);
					}}
				/>
			</div>
		);
	};
	const actionsLegal = rowData => {
		return (
			<div className="d-flex justify-content-end">
				<Button
					icon={"pi pi-pencil"}
					severity="primary"
					className="mx-1"
					outlined
					rounded
					onClick={() => {
						setVisibleLegal(true);
						setUserInfo(rowData);
					}}
				/>
				<Button
					icon={"pi pi-trash"}
					severity="danger"
					className="mx-1"
					outlined
					rounded
					onClick={e => {
						confirmDelete(rowData);
						localStorage.setItem("item", rowData.id);
					}}
				/>
			</div>
		);
	};
	const onGlobalFilterChange = event => {
		const value = event.target.value;
		let _filters = {...filters};
		_filters["global"].value = value;
		setFilters(_filters);
	};

	const renderHeader = () => {
		const value = filters["global"] ? filters["global"].value : "";
		return (
			<span className="p-input-icon-left">
				<i className="pi pi-search" />
				<InputText type="search" value={value || ""} onChange={e => onGlobalFilterChange(e)} placeholder="Global Search" />
			</span>
		);
	};

	const header = renderHeader();

	return (
		<div className="col-12 mb-3 p-0">
			<div className="row mb-3 match-height p-0">
				<PartnersDashboardStatisticCard title={"Registered benificiares"} color={"success"} icon={"pi pi-users"} data={dataPysical.length} />
				<PartnersDashboardStatisticCard title={"registered individuals"} color={"secondary"} icon={"pi pi-users"} data={dataIndividual.length} />
				<PartnersDashboardStatisticCard title={"Registered legal"} color={"danger"} icon={"pi pi-users"} data={dataLegal.length} />

				<div className="col stretch-card">
					<Link to={"add_a_partner"} className="card w-100 b-radius b-shadow px-4 py-3 border-0">
						<div className="row align-items-center justify-content-between">
							<div className="col-3">
								<Button icon="pi pi-plus" rounded severity="help" disabled />
							</div>
							<div className="col-9">
								<div className="fs-5">Add new benificiare</div>
							</div>
						</div>
					</Link>
				</div>
			</div>
			{loading ? (
				<TabView className="card b-radius b-shadow p-4">
					<TabPanel header="Ֆիզիկական անձ" rightIcon="pi pi-user ml-2" headerClassName="p-0" className="p-0">
						<DataTable
							value={dataPysical}
							selectionMode="single"
							selection={selectedPhisicalPartner}
							onSelectionChange={e => {
								setSelectedPhisicalPartner(e.value);
								setVisibleSelectedPhisicalPartner(true);
								setUserInfo(e.value);
							}}
							paginator
							rows={10}
							rowsPerPageOptions={[20, 30, 50, 100]}
							dataKey="id"
							filters={filters}
							onFilter={e => setFilters(e.filters)}
							header={header}
							globalFilterFields={["full_name_am", "full_name_en", "address_am", "address_en", "phone", "email"]}
							emptyMessage="No user found."
							bodyClassName="table table-hover"
						>
							<Column header="Partner" sortable style={{width: "25%"}} body={nameBody} bodyClassName="small"></Column>
							<Column header="Details" sortable style={{width: "25%"}} body={detailsBody} bodyClassName="small"></Column>
							<Column header="Bank" sortable style={{width: "25%"}} body={bankDetailsBody} bodyClassName="small"></Column>
							<Column header="Status" style={{width: "25%"}} body={statusDetailsBody} bodyClassName="small"></Column>
							<Column header="Actions" style={{width: "25%"}} body={actionsPhisical} bodyClassName="text-center"></Column>
						</DataTable>
					</TabPanel>
					<TabPanel header="Անհատ ձեռներեց" rightIcon="pi pi-user ml-2" headerClassName="p-0" className="p-0">
						<DataTable
							value={dataIndividual}
							selectionMode="single"
							selection={selectedLegalPartner}
							onSelectionChange={e => {
								setSelectedLegalPartner(e.value);
								setVisibleSelectedLegalPartner(true);
								setUserInfo(e.value);
							}}
							paginator
							rows={10}
							rowsPerPageOptions={[20, 30, 50, 100]}
							dataKey="id"
							filters={filters}
							onFilter={e => setFilters(e.filters)}
							header={header}
							globalFilterFields={["company_am", "company_en", "legal_full_name_am", "legal_full_name_en", "address_am", "address_en", "phone", "email"]}
							emptyMessage="No user found."
							bodyClassName="table table-hover"
						>
							<Column header="Company details" sortable style={{width: "25%"}} body={companyNameBody} bodyClassName="small"></Column>
							<Column header="Details" sortable style={{width: "25%"}} body={companyDetailsBody} bodyClassName="small"></Column>
							<Column header="Bank" sortable style={{width: "25%"}} body={bankDetailsBody} bodyClassName="small"></Column>
							<Column header="Status" sortable style={{width: "25%"}} bodyClassName="small"></Column>
							<Column header="Actions" style={{width: "25%"}} bodyClassName="text-center" body={actionsLegal}></Column>
						</DataTable>
					</TabPanel>
					<TabPanel header="Կազմակերպություն" rightIcon="pi pi-user ml-2" headerClassName="p-0" className="p-0">
						<DataTable
							value={dataLegal}
							selectionMode="single"
							selection={selectedLegalPartner}
							onSelectionChange={e => {
								setSelectedLegalPartner(e.value);
								setVisibleSelectedLegalPartner(true);
								setUserInfo(e.value);
							}}
							paginator
							rows={10}
							rowsPerPageOptions={[20, 30, 50, 100]}
							dataKey="id"
							filters={filters}
							onFilter={e => setFilters(e.filters)}
							header={header}
							globalFilterFields={["company_am", "company_en", "legal_full_name_am", "legal_full_name_en", "address_am", "address_en", "phone", "email"]}
							emptyMessage="No user found."
							bodyClassName="table table-hover"
						>
							<Column header="Company name" sortable style={{width: "25%"}} body={companyNameBody} bodyClassName="small"></Column>
							<Column header="Details" sortable style={{width: "25%"}} body={companyDetailsBody} bodyClassName="small"></Column>
							<Column header="Bank" sortable style={{width: "25%"}} body={bankDetailsBody} bodyClassName="small"></Column>
							<Column header="Status" sortable style={{width: "25%"}} body={bankDetailsBody} bodyClassName="small"></Column>
							<Column header="Actions" style={{width: "25%"}} bodyClassName="text-center" body={actionsLegal}></Column>
						</DataTable>
					</TabPanel>
				</TabView>
			) : (
				<div className="col-12 d-flex justify-content-center align-items-center my-5 py-5">
					<span class="loader"></span>
				</div>
			)}

			<Toast ref={toast} />

			<ConfirmDialog />

			<Dialog
				className="col-lg-8 col-md-12"
				header={userInfo?.full_name_am || selectedPhisicalPartner?.full_name_am}
				visible={visibleSelectedPhisicalPartner}
				onHide={() => {
					setVisibleSelectedPhisicalPartner(false);
					setSelectedPhisicalPartner(false);
				}}
			>
				<PhisicalEditableForm data={selectedPhisicalPartner?.id || userInfo?.id} setVisible={setVisibleSelectedPhisicalPartner} loadContent={loadContent} />
			</Dialog>

			<Dialog
				className="col-lg-8 col-md-12"
				header={userInfo?.company_am || selectedLegalPartner?.company_am}
				visible={visibleSelectedLegalPartner}
				onHide={() => {
					setVisibleSelectedLegalPartner(false);
					setSelectedLegalPartner(false);
				}}
			>
				<LegalEditableForm data={userInfo?.id || selectedPhisicalPartner?.id} setVisible={setVisibleSelectedLegalPartner} loadContent={loadContent} />
			</Dialog>

			<Dialog
				header={userInfo?.full_name_am}
				visible={visiblePhisical}
				className="col-lg-8 col-md-12"
				onHide={() => {
					setVisiblePhisical(false);
				}}
			>
				<PhisicalEditableForm data={userInfo?.id} setVisible={setVisiblePhisical} loadContent={loadContent} />
			</Dialog>

			<Dialog
				header={userInfo?.company_am}
				visible={visibleLegal}
				className="col-lg-8 col-md-12"
				onHide={() => {
					setVisibleLegal(false);
				}}
			>
				<LegalEditableForm data={userInfo?.id} setVisible={setVisibleLegal} loadContent={loadContent} />
			</Dialog>
		</div>
	);
}
