export const ProductService = {
    getProductsWithOrdersData() {
        return [
            {
                id: '1000',
                code: 'f230fh0g3',
                name: 'Bamboo Watch',
                description: 'Product Description',
                image: 'bamboo-watch.jpg',
                price: 65,
                category: 'Accessories',
                quantity: 24,
                inventoryStatus: 'INSTOCK',
                rating: 5,
                startDate: '2020-09-01',
                endDate: '2020-12-31',
                orders: [
                    {
                        id: '1000-0',
                        productCode: 'f230fh0g3',
                        date: '2020-09-13',
                        amount: 65,
                        quantity: 1,
                        customer: 'David James',
                        status: 'PENDING',
                        product_name: 'Բնական Արևածաղկի Ձեթ',
                        startDate: '2020-09-10',
                        endDate: '2020-09-13'
                    },
                    {
                        id: '1000-1',
                        productCode: 'f230fh0g3',
                        date: '2020-05-14',
                        amount: 130,
                        quantity: 2,
                        customer: 'Leon Rodrigues',
                        status: 'DELIVERED',
                        product_name: 'Օրգանական Թարմ Մրգեր',
                        startDate: '2020-05-10',
                        endDate: '2020-05-14'
                    },
                    {
                        id: '1000-2',
                        productCode: 'f230fh0g3',
                        date: '2019-01-04',
                        amount: 65,
                        quantity: 1,
                        customer: 'Juan Alejandro',
                        status: 'RETURNED',
                        product_name: 'Հավի Կրծքամիս Կոնսերվ',
                        startDate: '2018-12-30',
                        endDate: '2019-01-04'
                    },
                    {
                        id: '1000-3',
                        productCode: 'f230fh0g3',
                        date: '2020-09-13',
                        amount: 195,
                        quantity: 3,
                        customer: 'Claire Morrow',
                        status: 'CANCELLED',
                        product_name: 'Բնական Թեյ Կոլեկցիա',
                        startDate: '2020-09-09',
                        endDate: '2020-09-13'
                    }
                ]
            },
            {
                id: '1001',
                code: 'nvklal433',
                name: 'Black Watch',
                description: 'Product Description',
                image: 'black-watch.jpg',
                price: 72,
                category: 'Accessories',
                quantity: 61,
                inventoryStatus: 'INSTOCK',
                rating: 4,
                startDate: '2020-01-01',
                endDate: '2020-12-31',
                orders: [
                    {
                        id: '1001-0',
                        productCode: 'nvklal433',
                        date: '2020-05-14',
                        amount: 72,
                        quantity: 1,
                        customer: 'Maisha Jefferson',
                        status: 'DELIVERED',
                        product_name: 'Բարձրորակ Չոր Չիրեր',
                        startDate: '2020-05-10',
                        endDate: '2020-05-14'
                    },
                    {
                        id: '1001-1',
                        productCode: 'nvklal433',
                        date: '2020-02-28',
                        amount: 144,
                        quantity: 2,
                        customer: 'Octavia Murillo',
                        status: 'PENDING',
                        product_name: 'Նրբին Օրիգանո Աղցան',
                        startDate: '2020-02-25',
                        endDate: '2020-02-28'
                    }
                ]
            },
            {
                id: '1002',
                code: 'zz21cz3c1',
                name: 'Blue Band',
                description: 'Product Description',
                image: 'blue-band.jpg',
                price: 79,
                category: 'Fitness',
                quantity: 2,
                inventoryStatus: 'LOWSTOCK',
                rating: 3,
                startDate: '2019-06-01',
                endDate: '2020-06-30',
                orders: [
                    {
                        id: '1002-0',
                        productCode: 'zz21cz3c1',
                        date: '2020-07-05',
                        amount: 79,
                        quantity: 1,
                        customer: 'Stacey Leja',
                        status: 'DELIVERED',
                        product_name: 'Համեղ Թարմ Պանիր',
                        startDate: '2020-07-01',
                        endDate: '2020-07-05'
                    },
                    {
                        id: '1002-1',
                        productCode: 'zz21cz3c1',
                        date: '2020-02-06',
                        amount: 79,
                        quantity: 1,
                        customer: 'Ashley Wickens',
                        status: 'DELIVERED',
                        product_name: 'Արևածաղկի Նշանակության Պտուղներ',
                        startDate: '2020-02-01',
                        endDate: '2020-02-06'
                    }
                ]
            },
            // Additional products can be added here with startDate and endDate for both root object and orders.
        ];
    },

    getProductsWithOrdersSmall() {
        return Promise.resolve(this.getProductsWithOrdersData().slice(0, 10));
    },
};
