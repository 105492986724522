
import React, { useEffect, useRef, useState } from 'react';
import { base, tax } from '../../api';
import Nothing from '../../assets/images/illustrations/girl-unlock-password-light.png'
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { FiPlus, FiMoreVertical, FiCheck, FiTrendingUp } from 'react-icons/fi'
import { Avatar } from 'primereact/avatar';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { PermOnly } from '../PermOnly';


export default function Taxes(props) {
    const toast = useRef(null);
    const headers = { 'Content-Type': 'application/json', }
    const credentials = "same-origin"
    const [visible, setVisible] = useState(false);
    const [addTaxxVisibility, setAddTaxxVisibility] = useState(null);
    const [value1, setValue1] = useState();
    const [value2, setValue2] = useState();
    const [value3, setValue3] = useState();


    const [currentState, setCurrentState] = useState(null);
    const [editCurrentState, setEditCurrentState] = useState(null);

    function loadContent() {
        axios.get(base + tax).then((res => {
            setCurrentState(res.data)
        }))
    }

    useEffect(() => { loadContent() }, []);

    function add() {
        axios({
            method: "POST",
            url: base + tax,
            data: {
                'S1': value1,
                'S2': value2,
                'percent': value3,
            },
            headers: headers,
            credentials: credentials,
        })
            .then(res => {
                setCurrentState()
                loadContent()
                setValue1()
                setValue2()
                setValue3()
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'The item is added successfully', life: 3000 });
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please add something than press the button ', life: 3000 });
                setValue1()
                setValue2()
                setValue3()
                console.log(error);
            })
    }

    function getCurrent() {
        axios.get(base + tax + localStorage.getItem('item') + '/').then((res => {
            setValue1(res.data.S1)
            setValue2(res.data.S2)
            setValue3(res.data.percent)
        }))
    }

    function edit(e) {
        axios({
            method: "PUT",
            url: base + tax + localStorage.getItem('item') + `/`,
            data: {
                'S1': value1,
                'S2': value2,
                'percent': value3,
            },
            headers: headers,
            credentials: credentials,
        }).then(res => {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Message Content' });
            loadContent()
            setValue1()
            setValue2()
            setValue3()
            setVisible(false)
        })
            .catch((error) => {
                loadContent();
                toast.current.show({ severity: 'warn', summary: 'Error', detail: 'Nothing is chenged' });
            })
    }
    function remove() {

        axios.delete(base + tax + localStorage.getItem('item') + `/`, { headers: headers })
            .then((res) => {
                loadContent()
                localStorage.removeItem('item')
            })
            .catch((error) => { loadContent() })
    }

    const accept = () => {
        toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
        remove()
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const confirmDelete = (e) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject

        });
    };
    const footerContent = (
        <div>
            <Button label="Չեղարկել" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            <Button label="Հիշել" icon="pi pi-check" onClick={() => edit()} />
        </div>
    );
    const addfooterContent = (
        <div>
            <Button label="Չեղարկել" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            <Button label="Հիշել" icon="pi pi-check" onClick={() => { add(); setAddTaxxVisibility(false) }} />
        </div>
    );
    return (

        <div className='container-fluid'>
            <div className="col-12 p-0">
                <div className="card w-100 b-radius b-shadow p-3">
                    <div className="col-md-8 col-12 mx-auto text-center mt-3 mb-4">
                        <p className="m-0 small fst-italic text-muted">
                            Արտարծույքները կարող եք ավելացնել ներքոհիշհալ դաշտում նշելով ցանկացած արտարժուք, որից հետո անհրաժեշտ է սեղմել "Ավելացնել" կոճակը: Իսկ եթե արտարժուքն ավելացնելուց տեղի է ունեցել վրիպում ապա ավելացված արտաժույքների դաշտում սեղմեք փոփոխել կոճակը և կատարեք փոփխությունները: Ցանկանում եք հեռացնել ոչ պիտանի արտարժուքը ապա սեղմեք ռեռացնել կոճակը
                        </p>
                        <hr />
                    </div>

                    <div className='col-md-4 col-sm-8 col-12 mx-auto mb-3 p-0'>
                        <div className="card w-100 p-2">
                            <div className="text-center">
                                <PermOnly perm={props.perm} add>
                                    <div className='col-md-8 col-10 mx-auto mt-3'>
                                        <button type="button" className="btn btn-label-primary me-2" onClick={e => setAddTaxxVisibility(true)}>
                                            <span className="tf-icons bx bx-pie-chart-alt me-1"></span> Add  <FiPlus className='ms-2' />
                                        </button>
                                    </div>
                                </PermOnly>
                            </div>
                        </div>
                    </div>
                    <PermOnly perm={props.perm} view>
                        <div className="row m-0 p-0">
                            {currentState ?
                                <div className='row justify-content-center p-0 m-0'>
                                    {currentState?.map((data, i) =>
                                        <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                                            <div className="card b-radius b-shadow pb-4">
                                                <div className="card-body text-center">
                                                    <Avatar size="xlarge" shape="circle" className='btn-label-success mb-4'><FiTrendingUp /></Avatar>
                                                    <h4 className="card-title mb-1 me-2 text-dark">{data.S1} <span className='small text-muted'>դ.</span></h4>
                                                    <small className="d-block mb-2">{data.S2} <span>դ.</span></small>
                                                    <span className="text-success">{data.percent + ' %'}</span>
                                                </div>
                                                <PermOnly perm={props.perm} change remove>
                                                    <div className="dropdown text-center mt-3">
                                                        <button type='button' className='btn btn-label-primary rounded small' id={'data-' + data.id} data-bs-toggle="dropdown" aria-expanded="false">Edit</button>
                                                        <div className="dropdown-menu dropdown-menu-center" aria-labelledby={'data-' + data.id}>
                                                            <PermOnly perm={props.perm} change >
                                                                <button type='button' className="dropdown-item" onClick={e => { setVisible(true); localStorage.setItem('item', data.id); getCurrent(data.id) }}>փոփոխել</button>
                                                            </PermOnly>
                                                            <PermOnly perm={props.perm} remove>
                                                                <button type='button' className="dropdown-item" onClick={e => { confirmDelete(data.id); localStorage.setItem('item', data.id) }}>հեռացնել</button>
                                                            </PermOnly>
                                                        </div>
                                                    </div>
                                                </PermOnly>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                :
                                <div className='card w-100 text-center align-items-center d-flex justify-content-center' style={{ minHeight: '300px' }}>
                                    <div><img src={Nothing} alt="" style={{ maxHeight: '180px' }} /></div>
                                    <div className='mt-5 text-muted'>NOTHING TO SHOW YET</div>
                                </div>
                            }
                        </div>
                    </PermOnly>
                </div>
            </div>

            <Toast ref={toast} />

            <ConfirmDialog />
            <Dialog header={"Edit tax"} visible={visible} style={{ width: '25vw' }} onHide={() => setVisible(false)} footer={footerContent} >
                <p className="m-0">
                    <div className="input-group mb-3">
                        <div className="flex-auto mb-3 col-12">
                            <label htmlFor="integeronly" className="font-bold block mb-2">S1</label>
                            <InputNumber inputId="integeronly" className='col-12 p-0' value={value1} onValueChange={(e) => setValue1(e.value)} />
                        </div>
                        <div className="flex-auto mb-3 col-12">
                            <label htmlFor="integeronly" className="font-bold block mb-2">S2</label>
                            <InputNumber inputId="integeronly" className='col-12 p-0' value={value2} onValueChange={(e) => setValue2(e.value)} />
                        </div>
                        <div className="flex-auto mb-3 col-12">
                            <label htmlFor="integeronly" className="font-bold block mb-2">%</label>
                            <InputNumber inputId="integeronly" className='col-12 p-0' value={value3} onValueChange={(e) => setValue3(e.value)} />
                        </div>
                    </div>
                </p>
            </Dialog>
            <Dialog header={"Add tax"} visible={addTaxxVisibility} style={{ width: '25vw' }} onHide={() => setAddTaxxVisibility(false)} footer={addfooterContent} >
                <p className="m-0">
                    <div className="input-group mb-3">
                        <div className="flex-auto mb-3 col-12">
                            <label htmlFor="integeronly" className="font-bold block mb-2">S1</label>
                            <InputNumber inputId="integeronly" className='col-12 p-0' value={value1} onValueChange={(e) => setValue1(e.value)} />
                        </div>
                        <div className="flex-auto mb-3 col-12">
                            <label htmlFor="integeronly" className="font-bold block mb-2">S2</label>
                            <InputNumber inputId="integeronly" className='col-12 p-0' value={value2} onValueChange={(e) => setValue2(e.value)} />
                        </div>
                        <div className="flex-auto mb-3 col-12">
                            <label htmlFor="integeronly" className="font-bold block mb-2">%</label>
                            <InputNumber inputId="integeronly" className='col-12 p-0' value={value3} onValueChange={(e) => setValue3(e.value)} />
                        </div>
                    </div>
                </p>
            </Dialog>
        </div>
    )
}
