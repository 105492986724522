import React, {useRef, useState} from "react";
import {Toast} from "primereact/toast";
import {FileUpload} from "primereact/fileupload";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {InputSwitch} from "primereact/inputswitch";
import userIcon from "../../../assets/images/illustrations/girl-doing-yoga-light.png";

export default function EditUserForm(props) {
	const [check, setChecked] = useState(false);
	const [avatar, setAvatar] = useState(null);
	const [imagePreviewUrl, setImagePreviewURL] = useState(props?.editCurrentState?.profile?.avatar);

	function handleImageChange(e) {
		props?.setEditCurrentState({...props?.editCurrentState, avatar: e.target.files[0]});
		setImagePreviewURL(URL.createObjectURL(e.target.files[0]));
	}

	function removeImage() {
		setAvatar(null);
		setImagePreviewURL(null);
	}

	return (
		<div className="col-12 mx-auto">
			{/* <div className="row">
                <div className="d-flex justify-content-between align-items-center pb-4">
                    <div className="col-md-3 col-12 p-0">
                        <div className="image w-100 b-radius b-shadow" style={{ backgroundImage: `url(${imagePreviewUrl ? imagePreviewUrl : userIcon})` }} alt="upload an avatar" />
                    </div>
                    <div className='col-9 text-end'>
                        <label htmlFor="upload" rounded severity="help" className="btn btn-primary" tabindex="0">
                            <span className="d-none d-sm-block">Upload avatar</span>
                            <InputText type="file" hidden id="upload" accept="image/png, image/jpeg, image/gif" onChange={handleImageChange} />
                        </label>
                        {imagePreviewUrl && <Button icon={'pi pi-times'} rounded outlined severity='danger' className='mx-3' onClick={removeImage} />}
                        <p className="col-12 text-muted mb-0 mt-2 small ">Allowed JPG, GIF or PNG. Max size of 2MB</p>
                    </div>
                </div>
            </div> */}

			<div className="flex flex-column text-start">
				<label htmlFor="username" className="py-3">
					Username
				</label>
				<InputText id="username" keyfilter="alphanum" value={props?.editCurrentState?.username} onChange={e => props?.setEditCurrentState({...props?.editCurrentState, username: e.target.value})} />
			</div>
			<div className="flex flex-column text-start">
				<label htmlFor="mobile" className="py-3">
					Mobile
				</label>
				<InputText id="mobile" placeholder={props?.editCurrentState?.mobile ?? "Mobile number not provided"} value={props?.editCurrentState?.mobile} onChange={e => props?.setEditCurrentState({...props?.editCurrentState, mobile: e.target.value})} />
			</div>

			<div className="d-flex justify-content-between align-items-center pt-3 text-start">
				<label htmlFor="active_user" className="py-3">
					{props?.editCurrentState?.is_active ? "Disable user" : "Enable user"}
				</label>
				<InputSwitch inputId="active_user" checked={props?.editCurrentState?.is_active} value={props?.editCurrentState?.is_active} onChange={e => props?.setEditCurrentState({...props?.editCurrentState, is_active: e.target.value})} />
			</div>

			{/* <div className="d-flex justify-content-between align-items-center pt-3 text-start text-danger">
                <label htmlFor="reset_password" className='py-3'>Reset password</label>
                <InputSwitch inputId="reset_password" checked={check}
                    value={check}
                    onChange={(e) => props?.setEditCurrentState({ ...props?.editCurrentState, reset: check })}
                />
            </div> */}

			<div className="col-12 d-flex justify-content-around align-items-center mt-4 border-top pt-4">
				<Button label={"Save changes"} severity="secondary" rounded onClick={() => props?.setvisible()} />
				<Button label={"Save changes"} severity="primary" rounded onClick={() => props?.edit()} />
			</div>
		</div>
	);
}
