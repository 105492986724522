import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import {base, docs_list, docs_upload, docs_remove, spheres_url} from "../../api";

import {Toast} from "primereact/toast";
import {Keys} from "./InformersComponents/static/keys";
import CopyToClipboard from "./InformersComponents/CopyToClipboard";
import {Dialog} from "primereact/dialog";
import {ScrollPanel} from "primereact/scrollpanel";
import {Tooltip} from "primereact/tooltip";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import {Column} from "primereact/column";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {TreeTable} from "primereact/treetable";
import {InputText} from "primereact/inputtext";
import {useTranslation} from "react-i18next";
import {NodeService, docTypeNameMap} from "./InformersComponents/static/document_template_names";
import {Dropdown} from "primereact/dropdown";

export default function DocumentsTemplates() {
	const {t} = useTranslation();
	const toast = useRef(null);
	const headers = {"Content-Type": "multipart/form-data"};
	const credentials = "same-origin";
	const [visible, setVisible] = useState(false);
	const [dialogHeader, setDialogHeader] = useState();
	const [docs, setdocs] = useState();
	const [filelog, setFilelog] = useState();
	const [fileError, setFileError] = useState();
	const [optionSpheres, setOptionSpheres] = useState([]);
	const [multiselectSpheres, setMultiselectSpheres] = useState();
	const [englishDoc, setEnglishDoc] = useState(false);

	useEffect(() => {
		loadContent();
		axios.get(base + spheres_url).then(res => {
			const spheres = res?.data.map(obj => ({title_en: obj.title_en, title_am: obj.title_am, id: obj.id}));
			setOptionSpheres(spheres);
		});
	}, []);

	console.log(multiselectSpheres?.id);

	async function loadContent() {
		const data = await axios.get(base + docs_list);
		const meredNodeService = NodeService.map(node => {
			const mergedCildren = node.children.map(child => {
				if (child.children) {
					const merggedChildren = child.children.map(child => {
						const type = child.data.row;
						const dataToMerge = data.data.find(file_type => file_type.type == type);
						if (dataToMerge) {
							const mergedChild = {...child, file: dataToMerge.file, id: dataToMerge.id};
							return mergedChild;
						} else {
							return child;
						}
					});
					return {...child, children: merggedChildren};
				} else {
					const type = child.data.row;
					const dataToMerge = data.data.find(file_type => file_type.type == type);
					if (dataToMerge) {
						const mergedChild = {...child, file: dataToMerge.file, id: dataToMerge.id};
						return mergedChild;
					} else {
						return child;
					}
				}
			});
			return {...node, children: mergedCildren};
		});
		setdocs(meredNodeService);
	}

	function handleUpload(e) {
		e.preventDefault();
		const formData = new FormData();
		formData.append("file", filelog?.file);
		formData.append("type", filelog?.type);
		formData.append("is_english", englishDoc);
		formData.append("position", multiselectSpheres?.id);
		axios({
			method: "POST",
			url: base + docs_upload,
			data: formData,
			headers: headers,
			credentials: credentials,
		})
			.then(res => {
				loadContent();
				setVisible(false);
				toast.current.show({severity: "success", summary: "Success", detail: "The item is added successfully", life: 3000});
			})
			.catch(error => {
				setFileError(error.response.data.type);
				toast.current.show({severity: "error", summary: "Error", detail: "An error occurred while uploading the file", life: 3000});
				loadContent();
			});
	}

	const actionTemplate = rowData => {
		const isExpanded = rowData && rowData.children && rowData.children.length > 0;
		return (
			<div className={isExpanded ? "d-none" : "action d-flex justify-content-end align-items-center"}>
				{rowData.file ? (
					<Link to={base + rowData.file} className="text-primary tooltiper" target="_blank" download data-pr-tooltip="Download File" data-pr-position="bottom">
						<i className="pi pi-download"></i>
					</Link>
				) : null}
				{rowData?.file ? (
					<Button
						icon="pi pi-trash"
						severity="danger"
						text
						className="ms-2"
						rounded
						tooltip="Remove file"
						tooltipOptions={{
							position: "bottom",
							mouseTrack: true,
							mouseTrackTop: 15,
						}}
						onClick={e => {
							confirmDelete(rowData.id);
							localStorage.setItem("item", rowData.id);
						}}
					></Button>
				) : (
					<Button
						icon="pi pi-upload"
						severity="success"
						text
						rounded
						tooltip="Upload file"
						tooltipOptions={{
							position: "bottom",
							mouseTrack: true,
							mouseTrackTop: 15,
						}}
						onClick={() => (setVisible(true), setDialogHeader(rowData))}
					></Button>
				)}
			</div>
		);
	};

	const titleTemplate = rowData => {
		return <>{t(rowData.data.name)}</>;
	};

	function remove() {
		axios
			.delete(base + docs_remove + localStorage.getItem("item") + `/`, {headers: headers})
			.then(res => {
				loadContent();
				localStorage.removeItem("item");
			})
			.catch(error => {
				loadContent();
			});
	}

	const accept = () => {
		toast.current.show({severity: "success", summary: "Confirmed", detail: "You have accepted", life: 3000});
		remove();
	};

	const reject = () => {
		toast.current.show({severity: "warn", summary: "Rejected", detail: "You have rejected", life: 3000});
		localStorage.removeItem("item");
	};

	const confirmDelete = e => {
		confirmDialog({
			message: "Do you want to delete this record?",
			header: "Delete Confirmation",
			icon: "pi pi-info-circle",
			acceptClassName: "p-button-danger",
			accept,
			reject,
		});
	};

	return (
		<div className="col-12">
			<Tooltip target=".tooltiper" />
			<ConfirmDialog />
			<Toast ref={toast} />

			<div className="card w-100 b-radius b-shadow p-3">
				<div className="row">
					<div className="col-lg-8 ">
						<div className="b-radius b-shadow m-1 p-3">
							<div className="fs-5 fw-bold mb-3 border-bottom pb-3">Փաստաթղթերի ձևանմուշներ ({docs?.length} Հատ)</div>
							<TreeTable value={docs} className="col-12" header={null}>
								<Column body={titleTemplate} header={null} expander className="col-10"></Column>
								<Column body={actionTemplate} header={null} className="col-2" />
							</TreeTable>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="position-sticky b-radius b-shadow m-1 p-3" style={{top: "90px"}}>
							<div className="fs-5 fw-bold mb-3 border-bottom pb-3">{t("__tags_list")}</div>
							<ScrollPanel style={{width: "100%", height: "400px"}}>
								{Keys?.map((item, i) => (
									<div key={i} className="my-1 border-bottom">
										<CopyToClipboard data={item} t={t} />
									</div>
								))}
							</ScrollPanel>
						</div>
					</div>
				</div>
			</div>
			<Dialog header={t(dialogHeader?.data.name)} visible={visible} className="col-xl-3 col-lg-4 col-md-5 col-10 card rounded " onHide={() => (setVisible(false), setDialogHeader(), setFileError(""), setMultiselectSpheres(), setEnglishDoc(), setFilelog())}>
				<form enctype="multipart/form-data" onSubmit={e => e.preventDefault()}>
					<div className="col-12 text-center mt-5 mb-0">
						<label htmlFor="upload" className="btn btn-outline-primary " tabIndex="0">
							<span className="d-none d-sm-block">{filelog ? t("__content_change_document") : t("__content_click_to_add_a_document")}</span>
							<InputText type="file" onChange={e => setFilelog({...filelog, type: dialogHeader?.data.row, file: e.target.files[0]})} hidden id="upload" accept=".doc, .docx," />
						</label>
						{filelog ? <p className="mt-2">{filelog.file.property}</p> : null}
						{fileError ? <div className="text-danger text-center">{fileError}</div> : null}
						<p className="col-12 text-muted mb-0 mt-2 small">Allowed only .doc, docx filetypes</p>
					</div>
					<div class="form-check justify-content-center align-items-center d-flex mb-5">
						<input class="form-check-input me-2" type="checkbox" value={englishDoc} onChange={e => setEnglishDoc(!englishDoc)} id="is_english" />
						<label class="form-check-label" for="is_english">
							Is Inglish
						</label>
					</div>

					{dialogHeader?.data.is_position_related && <Dropdown value={multiselectSpheres} onChange={e => setMultiselectSpheres(e.value)} options={optionSpheres} optionLabel="title_en" placeholder="Select a position" className="w-100" checkmark={true} highlightOnSelect={false} />}
					<hr />
					<div className="text-center mt-5">
						<Button label={t("__content_upload_document")} rounded onClick={handleUpload} />
					</div>
				</form>
			</Dialog>
		</div>
	);
}
