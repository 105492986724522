import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {base, projects_url, currency} from "../../api";
import {Button} from "primereact/button";
import EditProject from "./EditProject";
import {FaRegCalendarCheck} from "react-icons/fa";
import {MdOutlineCurrencyExchange} from "react-icons/md";
import {FiCheck, FiActivity, FiAlignLeft, FiAward, FiDatabase} from "react-icons/fi";
import BudgetProjects from "./Components/Budget/BudgetProjects";

import ExpancesProjectsMounthly from "./Components/Budget/ExpancesProjectsMounthly";

export default function SingleProject() {
	const {id} = useParams();
	const [state, setState] = useState();
	const [editCurrentProject, setEditCurrentProject] = useState(false);
	const [contentLoadTrigger, setContentLoadTrigger] = useState();
	const [currencySymbol, setCurrencySymbol] = useState(false);

	useEffect(() => {
		setCurrencySymbol(false);
	}, [contentLoadTrigger]);

	console.log(contentLoadTrigger);

	useEffect(() => {
		axios.all([axios.get(base + projects_url + id + "/"), axios.get(base + currency)]).then(
			axios.spread((...response) => {
				setState(response[0]?.data);
				setCurrencySymbol(response[1]?.data);
			})
		);
	}, [editCurrentProject]);

	return (
		<div className="col-12 p-0">
			{editCurrentProject ? (
				<EditProject setEditCurrentProject={setEditCurrentProject} projectID={state?.id} />
			) : (
				<div>
					<div className="row">
						<div className="col-lg-12 col-md-6 col-12 ">
							<div className="d-flex bg-white border-0 b-radius b-shadow p-2">
								<div className="col-9 p-3 pb-0 ">
									<div className="d-grid align-items-center">
										<code className="fs-6 me-3 mb-1">
											<FiAward FiDatabase className="me-3" /> {state?.number}
										</code>

										<div className="d-flex align-items-center text-uppercase">
											{state?.completed ? (
												<div className="text-danger">
													<FiActivity className="me-3 text-danger" />
													<span className="small">Ծրագիրն ընթացքում է</span>
												</div>
											) : (
												<div className="text-success">
													<FiCheck className="me-3 text-success" />
													<span className="small">Ծրագիրն ավարտված է</span>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className="col-3 text-end">
									<Button
										label="Խմբագրել"
										icon="pi pi-pencil"
										text
										rounded
										onClick={e => setEditCurrentProject(true)}
									></Button>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-lg-12 col-md-6 col-12 px-3 py-2">
							<div className="bg-white border-0 b-radius b-shadow p-4">
								<div className="fw-bolder d-flex align-items-center border-bottom pb-2 text-primary">
									<FiAward FiDatabase className="me-3" />
									{state?.full_name_am}
								</div>
								<div className="small mt-2 d-flex align-items-center">Հայերեն անվանումը</div>
							</div>
						</div>
						<div className="col-lg-9 col-md-6 col-12 px-3 py-2">
							<div className="bg-white border-0 b-radius b-shadow p-4">
								<div className="fw-bolder d-flex align-items-center border-bottom pb-2 text-primary">
									<FiAward FiDatabase className="me-3" />
									{state?.full_name_en}
								</div>
								<div className="small mt-2 d-flex align-items-center">Անգլերեն անվանումը</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 col-12 px-3 py-2">
							<div className="bg-white border-0 b-radius b-shadow p-4">
								<div className="fw-bolder d-flex align-items-center border-bottom pb-2 text-primary">
									<FiAward FiDatabase className="me-3" />
									{state?.name}
								</div>
								<div className="small mt-2 d-flex align-items-center">Կարճ անվանումը</div>
							</div>
						</div>

						<div className="col-lg-3 col-md-6 col-12 px-3 py-2">
							<div className="bg-white border-0 b-radius b-shadow p-4">
								<div className="fw-bolder d-flex align-items-center border-bottom pb-2 text-primary">
									<FaRegCalendarCheck className="me-3" />
									{state?.start_date} - {state?.end_date ? state?.end_date : "---"}
								</div>
								<div className="small mt-2 d-flex align-items-center">Ծրագրի սկիզբ և ավարտ</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 col-12 px-3 py-1">
							<div className="bg-white border-0 b-radius b-shadow p-4">
								<div className="fw-bolder d-flex align-items-center border-bottom pb-2 text-primary">
									<MdOutlineCurrencyExchange className="me-3" />
									{parseFloat(state?.currency_value)?.toFixed(1)}
									{state?.currency?.title_am}
								</div>
								<div className="small mt-2 d-flex align-items-center">Արտարժույթ</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 col-12 px-3 py-1">
							<div className="bg-white border-0 b-radius b-shadow p-4">
								<div className="fw-bolder d-flex align-items-center border-bottom pb-2 text-primary">
									<FiDatabase className="me-3" />
									{parseFloat(state?.own_contribution)?.toFixed(1)}
								</div>
								<div className="small mt-2 d-flex align-items-center">Սեփական ներդրում</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6 col-12 px-3 py-1">
							<div className="bg-white border-0 b-radius b-shadow p-4">
								<div className="fw-bolder d-flex align-items-center border-bottom pb-2 text-primary">
									<FiDatabase className="me-3" />
									{parseFloat(state?.grant_amount)?.toFixed(1)}
								</div>
								<div className="small mt-2 d-flex align-items-center">Դրամաշնորի ներդրում</div>
							</div>
						</div>

						<div className="col-12 px-3 py-1">
							<div className="bg-white border-0 b-radius b-shadow p-4">
								<div className="fw-bolder d-flex align-items-center border-bottom pb-2 text-primary">
									<FiAlignLeft className="me-3" />
									Նկարագրություն
								</div>
								<div className="mt-2">{state?.description}</div>
							</div>
						</div>
					</div>
				</div>
			)}

			<BudgetProjects
				projectID={id}
				project={state}
				setContentLoadTrigger={setContentLoadTrigger}
			/>

			<ExpancesProjectsMounthly
				projectID={id}
				project={state}
				contentLoadTrigger={contentLoadTrigger}
				currency_value={state?.currency_value}
			/>
		</div>
	);
}
