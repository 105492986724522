import React, { useCallback, useState, useEffect } from 'react'
import axios from 'axios';
import { base, changePasswordURL } from '../../../api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import UserPhoneLogin from './UserPhoneLogin';
import { useAuth } from "../../../context/AuthContext";


export default function UserFirstLogin() {
    const { fetchUserProfile, userName } = useAuth();
    const [pass, setPass] = useState('')
    const [passRepeat, setPassRepeat] = useState('')
    const [showPass, setShowPass] = useState(false)
    const [loading, setLoading] = useState(false)

    const [showError, setShowError] = useState(false)
    const [form, setForm] = useState(false)

    const [newPass, setNewPass] = useState()

    const setPassword = useCallback(
        (newPass) => {
            setShowError(false)
            setPass(newPass)
        }, [])
    const setPasswordRepeat = useCallback(
        (newPassRepeat) => {
            setShowError(false)
            setPassRepeat(newPassRepeat)
        }, [])

    useEffect(() => {
        if (pass == passRepeat) {
            setNewPass(pass)
        }
    }, [pass, passRepeat])

    const changePassword = useCallback(async () => {
        setLoading(true)
        setForm(true)
        try {
            if (pass == passRepeat) {
                const res = await axios.post(base + changePasswordURL, { new_password: pass })
                setTimeout(() => {
                    fetchUserProfile()
                    window.location.href = '/'
                    setLoading(false)
                }, 3000);
                return
            }
        } catch (error) {
            setShowError(true)
        } finally {
            setLoading(false)
        }

    }, [pass, passRepeat])

    return (
        <div>


            <form onSubmit={(e) => e.preventDefault(e)} className='w-100'>
                {!form ?
                    <>
                        <div className='fs-2 fw-bold mb-3'>
                            Welcome <span className='text-primary'>{userName}</span>
                        </div>
                        <div>This is your first login, we have given you a randomly generated password and we understand that it is not easy to remember, so feel free to change it as you wish.</div>
                        <div className='text-danger fw-bold mt-3'>{showError && 'the passwords are not match'}</div>
                        <div className="flex flex-column py-4">
                            <label htmlFor="password" className="form-label pb-1">Password</label>
                            <span className="p-input-icon-right">
                                <i className={showPass ? "pi pi-eye-slash" : "pi pi-eye"} onClick={() => { setShowPass(!showPass) }} />
                                <InputText type={showPass ? "text" : "password"} htmlFor="password" className='w-100 mt-3' placeholder="* * * * * * * *"
                                    value={pass}
                                    onChange={(e) => setPassword(e.target.value)} />
                            </span>
                        </div>
                        <div className="flex flex-column py-4">
                            <label htmlFor="password" className="form-label pb-1">Password</label>
                            <span className="p-input-icon-right">
                                <i className={showPass ? "pi pi-eye-slash" : "pi pi-eye"} onClick={() => { setShowPass(!showPass) }} />
                                <InputText type={showPass ? "text" : "password"} htmlFor="repeatPassword" className='w-100 mt-3' placeholder="* * * * * * * *"
                                    value={passRepeat}
                                    onChange={(e) => setPasswordRepeat(e.target.value)} />
                            </span>
                        </div>
                        <div className="mt-5 mx-auto text-center">
                            {loading ?
                                <Button
                                    icon="pi pi-spin pi-spinner"
                                    severity='success'
                                    rounded
                                    disabled
                                />
                                :
                                <Button
                                    type='submit'
                                    label='Set new password'
                                    icon="pi pi-save"
                                    severity='primary'
                                    rounded
                                    disabled={loading}
                                    onClick={(e) => changePassword()}
                                />
                            }
                        </div>
                    </>

                    :
                    <div className='col-12 text-center py-5'>
                        <div className='col-12 text-success'>
                            <i className='pi pi-check display-1 fw-bold'></i>
                        </div>
                        <span className='col-12 py-5'>
                            Thank you! <br />
                            your password has changed!
                        </span>
                    </div>
                }

            </form>



        </div>
    )
}
