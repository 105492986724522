import { useState, useMemo } from 'react'
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from "primereact/button";
import { useFileUpload } from '../../../../context/FileUploadContext'
import { WithHover } from '../../../../hocs/WithHover';

export default function UploadProgressWindow({ removeFileUploadProgress }) {
    const [accardion, setAccardion] = useState(true)

    const { uploadProgress, setUploadWindow, uploadWindow } = useFileUpload()
    const completedFilesCount = useMemo(() => uploadProgress.filter(file => file.status == "completed").length, [uploadProgress])
    const animated = 'progress-bar-striped progress-bar-animated'

    return (
        <div className="position-relative">
            <div className="uploader-wrapper card b-radius b-shadow col-lg-4 col-12 p-0  ">
                <div className="col-12 p-3 border-bottom">
                    <div className="d-flex justify-content-between align-items-center">
                        <i className='pi pi-times close-button b-shadow' onClick={(e) => setUploadWindow(!uploadWindow)}></i>
                        <span>
                            {`Uploading ${uploadProgress.length - completedFilesCount} file(s)`}
                            {completedFilesCount && `, completed ${completedFilesCount} file(s)`}
                        </span>
                        <Button icon={accardion ? 'pi pi-chevron-down' : 'pi pi-chevron-up'} rounded severity="primary" onClick={e => setAccardion(!accardion)} />
                    </div>
                </div>
                <ScrollPanel style={accardion ? { width: '100%', height: '350px' } : { width: '100%', height: '0px' }}>
                    {uploadProgress?.map(file =>
                        <div key={file.id} className="d-flex align-items-center b-radius b-shadow m-2 p-2">
                            <div className="col-12">
                                <div className="row p-0 m-0 align-items-center">
                                    <div className="col-10 p-0">
                                        <span className='text-truncate'>{file.fileName}</span>
                                        <WithHover>
                                            {isHovered => {
                                                return file.status == "new" &&
                                                    <div className="progress mt-2 col-11 p-0" style={{ height: "7px" }}>
                                                        <div className={`progress-bar bg-secondary ${isHovered && animated}`} role="progressbar" style={{ width: file.progress + '%', height: '7px' }}></div>
                                                    </div>
                                            }
                                            }
                                        </WithHover>
                                        <WithHover>
                                            {isHovered => {
                                                return file.status == "completed" &&
                                                    <div className="progress mt-2 col-11 p-0" style={{ height: "7px" }}>
                                                        <div className={`progress-bar bg-success ${isHovered ? animated : ''}`} role="progressbar" style={{ width: file.progress + '%', height: '7px' }}></div>
                                                    </div>
                                            }
                                            }
                                        </WithHover>
                                        <WithHover>
                                            {isHovered => {
                                                return file.status == "in_progress" &&
                                                    <div className="progress mt-2 col-11 p-0" style={{ height: "7px" }}>
                                                        <div className={`progress-bar bg-primary ${isHovered && animated}`} role="progressbar" style={{ width: file.progress + '%', height: '7px' }}></div>
                                                    </div>
                                            }
                                            }
                                        </WithHover>
                                    </div>
                                    <div className="col-2 p-0 text-center">
                                        <WithHover>

                                            {ishovered => {
                                                file.status == "new" &&
                                                    <div className="text-center">
                                                        <Button label="Waiting" text rounded disabled severity="secondary" />
                                                    </div>
                                            }
                                            }
                                        </WithHover>

                                        <WithHover>
                                            {ishovered => {
                                                return file.status == "completed" &&
                                                    <div className="text-center">
                                                        <Button icon={!ishovered ? 'pi pi-check' : 'pi pi-times'} rounded severity={!ishovered ? 'success' : 'danger'} className='opacity-75' onClick={() => removeFileUploadProgress(file.id)} />
                                                    </div>
                                            }
                                            }
                                        </WithHover>

                                        {file.status == "in_progress" &&
                                            <div className="text-center d-inline-flex">
                                                <Button label={parseInt(file.progress) + '%'} text rounded disabled severity="primary" />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </ScrollPanel>
            </div>
        </div>
    )
}
