import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Children,
} from "react";
import { classNames } from "primereact/utils";
import axios from "axios";
import {
  base,
  file_manager,
  locations_url,
  file_tags_url,
  projects_url,
} from "../../api";
import { transformFolder, mapPublicFilesToTreeNodes } from "../../utils/utils";
import { usePublicFileFilter } from "./usePublicFileFilter";
import { useOwnFileFilter } from "./useOwnFileFilter";
import { useFileUpload } from "../../context/FileUploadContext";

export function useMediaIndex() {
  const [publicFiles, setPublicFiles] = useState(null);
  const [myFiles, setMyFiles] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editNode, setEditNode] = useState(false);
  const [contentPreview, setContentPreview] = useState(false);
  const [contentPreviewPublic, setContentPreviewPublic] = useState(false);
  const [popupInputValue, setPopupInputValue] = useState("");
  const [totalSize, setTotalSize] = useState(0);

  const [multiselectTagsByFile, setMultiselectTagsByFile] = useState({}); // selected values to sand

  const [optionTags, setOptionTags] = useState(null); // options to show
  const [multiselectLocationsByFile, setMultiselectLocationsByFile] = useState(
    {}
  );

  const [optionLocations, setOptionLocations] = useState(null); // options to show
  const [multiselectProjectsByFile, setMultiselectProjectsByFile] = useState(
    {}
  );

  const [datesByFile, setDatesByFile] = useState({});
  const [optionProjectsShort, setOptionProjectsShort] = useState(null); // options to show
  const [optionProjects, setOptionProjects] = useState(null); // options to show

  const [visible, setVisible] = useState({
    createFolderDialog: false,
    deleteFolderDialog: false,
    deleteFileDialog: false,
    deletePublicFileDialog: false,
    uploadFilesDialog: false,
    uploadPublicFilesDialog: false,
    advencedSearchDialog: false,
    userSearchDialog: false,
    fileShareDialog: false,
  });
  const closePublicSearchDialog = useCallback(
    () => setVisible({ ...visible, advencedSearchDialog: false }),
    []
  );
  const closeUserSearchDialog = useCallback(
    () => setVisible({ ...visible, userSearchDialog: false }),
    []
  );
  const {
    filteredPublicFiles,
    selectedDayRange,
    setSelectedDayRange,
    multiselectTagsFilter,
    setMultiselectTagsFilter,
    multiselectLocationsFilter,
    setMultiselectLocationsFilter,
    multiselectProjectsFilter,
    setMultiselectProjectsFilter,
    setPublicSearchValue,
    publicSearchValue,
    clearPublicFilter,
  } = usePublicFileFilter(publicFiles, closePublicSearchDialog);
  const {
    filteredOwnFiles,
    selectedDayRangeUser,
    setSelectedDayRangeUser,
    setOwnSearchValue,
    onwSearchValue,
    clearOwnFileFilter,
  } = useOwnFileFilter(myFiles, closeUserSearchDialog);

  const fileUploadRef = useRef(null);
  const toast = useRef(null);
  const { uploadPublicFiles, uploadProgress } = useFileUpload();
  const accept = () => {
    toast.current.show({
      severity: "info",
      summary: "Confirmed",
      detail: "You have accepted",
      life: 3000,
    });
  };
  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };
  ///////
  useEffect(() => {
    axios.get(base + file_tags_url).then((res) => {
      const tags = res?.data.map((obj) => ({
        name: obj.title_am,
        code: obj.title_am,
        id: obj.id,
      }));
      setOptionTags(tags);
    });
    axios.get(base + locations_url).then((res) => {
      const locations = res?.data.map((obj) => ({
        name: obj.title_am,
        code: obj.title_am,
        id: obj.id,
      }));
      setOptionLocations(locations);
    });
    axios.get(base + projects_url).then((res) => {
      const projects = res?.data.map((obj) => ({
        name: obj.full_name_am.substring(0, 60),
        code: obj.full_name_am.substring(0, 60),
        id: obj.id,
      }));
      setOptionProjects(projects);
    });
  }, []);

  function loadPublicFiles() {
    axios.get(base + file_manager.get_public_folders).then((res) => {
      setPublicFiles(mapPublicFilesToTreeNodes(res.data));
    });
  }

  function loadMyFiles() {
    axios.get(base + file_manager.get_my_folders).then((res) => {
      setMyFiles(res.data.map(transformFolder));
    });
  }

  function reloadFiles() {
    console.log("loadingre");
    loadPublicFiles();
    loadMyFiles();
  }

  useEffect(() => {
    loadPublicFiles();
    loadMyFiles();
  }, []);

  //////////

  const setFileLocations = useCallback(
    (file, locations) => {
      setMultiselectLocationsByFile({
        ...multiselectLocationsByFile,
        [`${file.size}-${file.name}`]: locations,
      });
    },
    [multiselectLocationsByFile]
  );

  const setFileProjects = useCallback(
    (file, projects) => {
      setMultiselectProjectsByFile({
        ...multiselectProjectsByFile,
        [`${file.size}-${file.name}`]: projects,
      });
    },
    [multiselectProjectsByFile]
  );

  const setFileTags = useCallback(
    (file, tags) => {
      setMultiselectTagsByFile({
        ...multiselectTagsByFile,
        [`${file.size}-${file.name}`]: tags,
      });
    },
    [multiselectTagsByFile]
  );

  const setFileDate = useCallback(
    (file, date) => {
      setDatesByFile({
        ...datesByFile,
        [`${file.size}-${file.name}`]: date,
      });
    },
    [datesByFile]
  );

  async function createSubfolder(folderData) {
    try {
      await axios.post(base + file_manager.create_folder, folderData);
      reloadFiles();
      setVisible({ ...visible, createFolderDialog: false });
      localStorage.removeItem("node");
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "The item is added successfully",
        life: 3000,
      });
    } catch (error) {
      console.error("Error:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "File not selected",
        life: 3000,
      });
    }
  }

  async function deleteFolder(folderId) {
    const response = await axios.delete(base + file_manager.delete_folder, {
      data: {
        folder_id: parseInt(localStorage.getItem("node")),
      },
    });
    try {
      // if (!response.ok) {
      //   throw new Error("Network response was not ok");
      // }
      localStorage.removeItem("node");
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "The item is removed successfully",
        life: 3000,
      });
      reloadFiles();
      const jsonResponse = await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const handleFileChange = (event) => {
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = (folderId, file) => {
    if (!file) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "File not selected",
        life: 3000,
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    axios
      .post(base + file_manager.upload_file`${folderId}`, formData)
      .then((response) => {
        reloadFiles();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleFileDelete = (fileId) => {
    axios
      .delete(base + file_manager.delete_file`${localStorage.getItem("node")}`)
      .then((response) => {
        localStorage.removeItem("node");
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "The item is removed successfully",
          life: 3000,
        });
        reloadFiles();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handlePublicFileDelete = async (fileId) => {
    try {
      const response = await axios.delete(
        base + file_manager.delete_public_file`${localStorage.getItem("node")}`
      );
      localStorage.removeItem("node");
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "The item is removed successfully",
        life: 3000,
      });
      reloadFiles();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const togglerTemplate = (node, options) => {
    if (!node) {
      return;
    }
    const expanded = options.expanded;
    const iconClassName = classNames("p-tree-toggler-icon pi pi-fw", {
      "pi-folder": !expanded,
      "pi-folder-open": expanded,
    });
    return (
      <button
        type="button"
        className="p-tree-toggler p-link"
        tabIndex={-1}
        onClick={options.onClick}
      >
        <span className={iconClassName}></span>
      </button>
    );
  };

  // FILE UPLOAD

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    let files = e.files;

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
  };

  const uploadFiles = (event) => {
    const files = event.files;
    const formDataList = [];
    for (let i = 0; i < files.length; i++) {
      const formData = new FormData();
      formData.append("file", files[i]);
      formDataList.push(formData);
    }
    axios
      .all(
        formDataList.map((formData) =>
          axios.post(
            base + file_manager.upload_file`${localStorage.getItem("node")}`,
            formData
          )
        )
      )
      .then((responses) => {
        loadMyFiles();
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "The item is uploaded successfully",
          life: 3000,
        });
      });
    setVisible({ ...visible, uploadFilesDialog: false });
  };

  const onTemplateUpload = (e) => {
    // let _totalSize = 0;
    // e.files.forEach((file) => {
    //   _totalSize += file.size || 0;
    // });
    // setTotalSize(_totalSize);
    // reloadFiles();
    // toast.current.show({
    //   severity: "info",
    //   summary: "Success",
    //   detail: "File Uploaded",
    // });
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const changeFileFolder = async (fileId, folderId) => {
    try {
      const response = await axios.patch(
        base + file_manager.change_file_folder,
        {
          file_id: fileId,
          folder_id: folderId,
        }
      );

      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

  const changeFolderParent = async (folderId, parent_folder_id) => {
    try {
      const response = await axios.patch(
        base + file_manager.change_folder_parent,
        {
          parent_folder_id: parent_folder_id,
          folder_id: folderId,
        }
      );

      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

  const onDragAndDrop = (e, updateFiles) => {
    const draggedNode = e.dragNode;
    const targetNode = e.dropNode;
    const dropPosition = e.dropPosition;

    // check if is dropped on file
    if (targetNode?.url) {
      return;
    }

    //changed file location
    if (draggedNode.url && targetNode?.key) {
      changeFileFolder(draggedNode.key, targetNode.key)
        .then(() => updateFiles(e.value))
        .catch(); //TODO show notification
    }

    //changed folder parent
    if (!draggedNode.url) {
      changeFolderParent(draggedNode.key, targetNode.key)
        .then(() => updateFiles(e.value))
        .catch(); //TODO show notification
    }
  };

  return {
    publicFiles,
    setContentPreview,
    contentPreview,
    setContentPreviewPublic,
    contentPreviewPublic,
    setVisible,
    visible,
    togglerTemplate,
    onDragAndDrop,
    setMyFiles,
    myFiles,
    setPopupInputValue,
    popupInputValue,
    createSubfolder,
    fileUploadRef,
    uploadFiles,
    uploadPublicFiles: (e) =>
      uploadPublicFiles(
        e,
        multiselectLocationsByFile,
        multiselectTagsByFile,
        datesByFile,
        multiselectProjectsByFile,
        loadPublicFiles,
        setVisible
      ),
    onTemplateUpload,
    onTemplateSelect,
    onTemplateClear,
    deleteFolder,
    reject,
    handleFileDelete,
    handlePublicFileDelete,
    toast,
    totalSize,
    onTemplateRemove,
    multiselectProjectsByFile,
    optionProjects,
    setFileProjects,
    multiselectLocationsByFile,
    optionLocations,
    setFileLocations,
    multiselectTagsByFile,
    optionTags,
    setFileTags,
    datesByFile,
    setFileDate,
    reloadFiles,

    // FILTER START
    filteredPublicFiles,
    filteredOwnFiles,
    selectedDayRange,
    setSelectedDayRange,
    selectedDayRangeUser,
    setSelectedDayRangeUser,
    multiselectTagsFilter,
    setMultiselectTagsFilter,
    multiselectLocationsFilter,
    setMultiselectLocationsFilter,
    multiselectProjectsFilter,
    setMultiselectProjectsFilter,
    setPublicSearchValue,
    publicSearchValue,
    setOwnSearchValue,
    onwSearchValue,
    clearPublicFilter,
    clearOwnFileFilter,
    applyFilter: closeUserSearchDialog,
    // FILTER END
    uploadProgress,
  };
}
