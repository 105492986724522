import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { Dialog } from 'primereact/dialog';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Checkbox } from "primereact/checkbox";

import TicketsSideBar from './TicketsSideBar'
import TicketsMainBody from './TicketsMainBody'
import { useTicketSystem } from './useTicketSystem';
// import UsersSelector from './UsersSelector';



const ticketData = [
  {
    id: '01',
    name: "sunt aut facere repellat provident occaecati excepturi optio reprehenderit",
    last_message: "quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto",
    message: [
      {
        0: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
        1: 'Morbi mi arcu, ',
        2: 'eleifend vel tincidunt vel, bibendum vel magna.'
      }
    ],
    last_message_time: '08:28',
    new:true
  },
  {
    id: '02',
    name: "qui est esse",
    last_message: "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla",
    message: [
      {
        0: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
        1: 'Morbi mi arcu, ',
        2: 'eleifend vel tincidunt vel, bibendum vel magna.'
      }
    ],
    last_message_time: '08:32',
    new:true
  },
  {
    id: '03',
    name: "ea molestias quasi exercitationem repellat qui ipsa sit aut",
    last_message: "et iusto sed quo iure\nvoluptatem occaecati omnis eligendi aut ad\nvoluptatem doloribus vel accusantium quis pariatur\nmolestiae porro eius odio et labore et velit aut",
    message: [
      {
        0: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
        1: 'Morbi mi arcu, ',
        2: 'eleifend vel tincidunt vel, bibendum vel magna.'
      }
    ],
    last_message_time: '08:46',
    new:true
  },
  {
    id: '04',
    name: "eum et est occaecati",
    last_message: "ullam et saepe reiciendis voluptatem adipisci\nsit amet autem assumenda provident rerum culpa\nquis hic commodi nesciunt rem tenetur doloremque ipsam iure\nquis sunt voluptatem rerum illo velit",
    message: [
      {
        0: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
        1: 'Morbi mi arcu, ',
        2: 'eleifend vel tincidunt vel, bibendum vel magna.'
      }
    ],
    last_message_time: '09:11',
    new:true
  },
  {
    id: '05',
    name: "nesciunt quas odio",
    last_message: "repudiandae veniam quaerat sunt sed\nalias aut fugiat sit autem sed est\nvoluptatem omnis possimus esse voluptatibus quis\nest aut tenetur dolor neque",
    message: [
      {
        0: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
        1: 'Morbi mi arcu, ',
        2: 'eleifend vel tincidunt vel, bibendum vel magna.'
      }
    ],
    last_message_time: '09:31',
    new:true
  },
  {
    id: '06',
    name: "dolorem eum magni eos aperiam quia",
    last_message: "ut aspernatur corporis harum nihil quis provident sequi\nmollitia nobis aliquid molestiae\nperspiciatis et ea nemo ab reprehenderit accusantium quas\nvoluptate dolores velit et doloremque molestiae",
    message: [
      {
        0: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
        1: 'Morbi mi arcu, ',
        2: 'eleifend vel tincidunt vel, bibendum vel magna.'
      }
    ],
    last_message_time: '09:52',
    new:false
  },
  {
    id: '07',
    name: "magnam facilis autem",
    last_message: "dolore placeat quibusdam ea quo vitae\nmagni quis enim qui quis quo nemo aut saepe\nquidem repellat excepturi ut quia\nsunt ut sequi eos ea sed quas",
    message: [
      {
        0: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
        1: 'Morbi mi arcu, ',
        2: 'eleifend vel tincidunt vel, bibendum vel magna.'
      }
    ],
    last_message_time: '10:34',
    new:false
  },
  {
    id: '08',
    name: "dolorem dolore est ipsam",
    last_message: "dignissimos aperiam dolorem qui eum\nfacilis quibusdam animi sint suscipit qui sint possimus cum\nquaerat magni maiores excepturi\nipsam ut commodi dolor voluptatum modi aut vitae",
    message: [
      {
        0: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
        1: 'Morbi mi arcu, ',
        2: 'eleifend vel tincidunt vel, bibendum vel magna.'
      }
    ],
    last_message_time: '11:24',
    new:false
  },
  {
    id: '09',
    name: "nesciunt iure omnis dolorem tempora et accusantium",
    last_message: "consectetur animi nesciunt iure dolore\nenim quia ad\nveniam autem ut quam aut nobis\net est aut quod aut provident voluptas autem voluptas",
    message: [
      {
        0: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ',
        1: 'Morbi mi arcu, ',
        2: 'eleifend vel tincidunt vel, bibendum vel magna.'
      }
    ],
    last_message_time: '12:54',
    new:false
  },
]


export default function TicketSystem() {
  const [visible, setVisible] = useState(false);
  const [dubleDialog, setDubleDialog] = useState(false);
  const [multiselectUserSelect, setMultiselectUserSelect] = useState(null);
  const { tickets, openTicket, messages, selectedTicket, sendMessage, createTicket, users, projects } = useTicketSystem([]);
  const [selectedProjectSelect, setSelectedProjectSelect] = useState(null);

  // TODO remove from here
  const [description, setDescription] = useState('')
  const [date, setDate] = useState('')

  const categories = [
    { name: 'Accounting', key: 'A' },
    { name: 'Marketing', key: 'B' },
    { name: 'Production', key: 'C' },
    { name: 'Research', key: 'D' }
  ];
  const [selectedCategories, setSelectedCategories] = useState([categories[1]]);

  const onCategoryChange = (e) => {
    let _selectedCategories = [...selectedCategories];

    if (e.checked)
      _selectedCategories.push(e.value);
    else
      _selectedCategories = _selectedCategories.filter(category => category.key !== e.value.key);

    setSelectedCategories(_selectedCategories);
  };

  const userSelectTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div conClick={() => setDubleDialog(true)}>{option.username} </div>
      </div>
    );
  };
  const selectedProject = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.name?.substring(0, 40)} {option.name?.length > 40 ? '...' : null} ({option.projID})</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };
  const optionProject = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.name?.substring(0, 40)} {option.name?.length > 40 ? '...' : null} ({option.projID})</div>
      </div>
    );
  };

  
  return (
    <div>
      <Splitter className='' style={{ height: '82vh' }}>

        <SplitterPanel className="d-flex overflow-hidden b-radius b-shadow position-relative" size={20} >
          <div className="row">
            <div className='col-12 px-4' style={{ height: "60px" }}>
              <div className="row d-flex align-items-center justify-content-center  ">
                <div className='col-6'>
                  <div className='d-flex align-items-center'>
                    <Avatar className='p-overlay-badge' shape="circle" />
                    <div className='ms-3'> User Name</div>
                  </div>
                </div>
                <div className='col-6 text-end small'>
                  You have {tickets.filter(ticket => {
                    if (!ticket.last_message) {
                      return true
                    }

                    return !ticket.last_message.viewed
                  }).length} unread Tickets
                </div>
              </div>
            </div>
            <ScrollPanel className=' position-relative my-2' style={{ height: "calc(72vh - 60px)" }}>
              {/* <TicketsSideBar data={tickets} openTicket={openTicket} /> */}
              <TicketsSideBar data={ticketData} openTicket={openTicket} />
            </ScrollPanel>
            <div className='overflow-hidden' style={{ height: "60px" }}>
              <div className="d-flex justify-content-center align-items-center">
                Create a new ticket
                <Button icon="pi pi-plus" rounded className='ms-3' onClick={() => setVisible(true)} />
              </div>
            </div>
          </div>
        </SplitterPanel>
        <SplitterPanel className="overflow-hidden px-3" size={75}>
          <ScrollPanel style={{ height: "85vh" }}>
            <TicketsMainBody messages={messages} selectedTicket={selectedTicket} sendMessage={sendMessage} />
          </ScrollPanel>
        </SplitterPanel>
      </Splitter>

      <Dialog header="Add new ticket" visible={visible} onHide={() => setVisible(false)} style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
        <div className='card'>
          <Dropdown value={selectedProjectSelect} onChange={(e) => setSelectedProjectSelect(e.value)} options={projects} optionLabel="name" placeholder="Select a Country"
            filter valueTemplate={selectedProject} itemTemplate={optionProject} className="w-100 mb-3 md:w-14rem" />
          <MultiSelect value={multiselectUserSelect}  placeholder="Select UserSelect" className="w-100 mb-3" optionLabel="name" display="chip"
            itemTemplate={userSelectTemplate}
            maxSelectedLabels={10}
            options={users}
            onChange={e => { setMultiselectUserSelect(e.value); }}
          />
          <InputTextarea autoResize rows={5} cols={30} className='w-100 mb-3' value={description} onChange={(e) => {
            setDescription(e.target.value)
          }} />
          <input type='date' className='form-control p-3' placeholder='Birth date' value={date} onChange={(e) => {
            setDate(e.target.value)
          }}
          />
          <div className="flex flex-wrap justify-content-center my-4">
            {/* gorcoxutyunner -> galis a texekatuneric (chka norn a petq sarqel) */}
            <div className='d-flex justify-content-center align-items-center col-12'>
              {categories.map((category) => {
                return (
                  <div key={category.key} className="flex align-items-center mx-3">
                    <Checkbox inputId={category.key} name="category" value={category} onChange={onCategoryChange} checked={selectedCategories.some((item) => item.key === category.key)} />
                    <label htmlFor={category.key} className="ml-2">{category.name}</label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className='text-center mt-3'>
          <Button icon="pi pi-times" label={"Cancel"} className='btn-label-secondary mx-2' onClick={(e) => setVisible(false)} />
          <Button icon="pi pi-plus" label={"Add a ticket"} className='btn-label-primary mx-2' onClick={() => {
            createTicket({ description, date, project: selectedProjectSelect, users: multiselectUserSelect })
          }} />
        </div>
      </Dialog>

      <Dialog header="Add new ticket" visible={dubleDialog} onHide={() => setDubleDialog(false)} style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
      
  <div className="card">
    test
  </div>

      </Dialog>
    </div>
  )
}
