import { ProgressBar } from "primereact/progressbar"
  export default (totalSize, fileUploadRef, multiselectTagsByFile, multiselectLocationsByFile, multiselectProjectsByFile, datesByFile) => (options) => {
  const { className, chooseButton, uploadButton, cancelButton } = options;
  const value = totalSize / 10000;
  const formatedValue =
    fileUploadRef && fileUploadRef.current
      ? fileUploadRef.current.formatSize(totalSize)
      : "0 B";

  return (
    <div className={className} style={{ backgroundColor: "transparent", display: "flex", alignItems: "center", }}>
      {chooseButton}
      {uploadButton}
      {cancelButton}
      <div className="flex align-items-center gap-3 ml-auto">
        <span>{formatedValue}</span>
        <ProgressBar
          value={value}
          showValue={false}
          style={{ width: "10rem", height: "12px" }}
        ></ProgressBar>
      </div>
    </div>
  );
};
