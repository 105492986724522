import {Button} from "primereact/button";
import {downloadFile} from "../../../utils/downlodFiles";
import {base, docs_application_download_zip} from "../../../api";

export const not_civil_documents = [
	{default: true, type: "work_contract", title: "__hiring_process__work_contract__"},
	{default: true, type: "admission_app", title: "__hiring_process__admission_app__"},
	{default: false, type: "job_description", title: "__hiring_process__job_description__"},
	{default: false, type: "material_responsibility", title: "__hiring_process__material_responsibility__"},
	{default: false, type: "conflict_of_interest", title: "__hiring_process__conflict_of_interest__"},
	{default: false, type: "admission_order", title: "__hiring_process__admission_order__"},
	{default: false, type: "process_duty", title: "__hiring_process__duty__"},
];
export const civil_documents = [
	{default: true, type: "civil_contract", title: "__hiring_process__civil_contract__"},
	{default: false, type: "service_delivery_agreement", title: "__hiring_process__service_delivery_agreement__"},
];

export const downloadTemplate = rowData => {
	return (
		<Button
			icon="pi pi-download"
			severity="primary"
			rounded
			tooltip="Download .zip file"
			tooltipOptions={{
				position: "bottom",
				mouseTrack: true,
				mouseTrackTop: 15,
			}}
			onClick={e => {
				downloadFile(base + docs_application_download_zip + rowData.id);
			}}
		/>
	);
};

export const statusTemplate = rowData => {
	return (
		<div className="d-flex">
			{[...rowData.documents]
				.sort(document => (document.needs_revising ? -1 : 1))
				.sort(document => (document.file == null ? 1 : -1))
				.map((item, i) => (
					<Button key={item.id} severity={item.needs_revising ? "danger" : item.file ? "success" : "secondary"} style={{width: "7px", height: "auto", margin: "2px"}} className="p-0" disabled />
				))}
		</div>
	);
};
