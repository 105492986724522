import React from "react";
import {TabView, TabPanel} from "primereact/tabview";
import DataTableDataContracts from "./components/DataTableDataContracts";
import DataTableDataACT from "./components/DataTableDataACT";
import {ProductService} from "../projects/Components/Budget/components/json";
import DataTableDataDemandLetter from "./components/DataTableDataDemandLetter";
import DataTableDataApplications from "./components/DataTableDataApplications";

export default function SupplyContracts() {
	return (
		<TabView>
			<TabPanel header="Պահանջագրեր" className="card">
				<div className="card-body">
					<p className="m-0">
						<DataTableDataDemandLetter productService={ProductService} />
					</p>
				</div>
			</TabPanel>
			<TabPanel header="Մատակարարման պայմանագրեր" className="card">
				<div className="card-body">
					<p className="m-0">
						<DataTableDataContracts productService={ProductService} />
					</p>
				</div>
			</TabPanel>
			<TabPanel header="Հավելվածներ" className="card">
				<div className="card-body">
					<p className="m-0">
						<DataTableDataApplications productService={ProductService} />
					</p>
				</div>
			</TabPanel>

			<TabPanel header="Հանձնման Ակտ" className="card">
				<div className="card-body">
					<p className="m-0">
						<DataTableDataACT productService={ProductService} />
					</p>
				</div>
			</TabPanel>
		</TabView>
	);
}
