import {useEffect, useState, useRef} from "react";
import axios from "axios";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {Checkbox} from "primereact/checkbox";
import {base, probation_period_url, workplace_url, position_and_service, currency, coworker_url, projects_url} from "../../api";
import {AutoComplete} from "primereact/autocomplete";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {BiCopyright, BiUser, BiRegistered} from "react-icons/bi";

export default function EditCoWorker(props) {
	const [form, setForm] = useState({
		type1: false,
		type2: false,
		type3: false,
		disabled: false,
	});

	const [checked, setChecked] = useState(false);
	const [currentUser, setCurrentUser] = useState();

	const [sendData, setSendData] = useState(props?.userinfo);

	const [position, setPosition] = useState();
	const [selectedPosition, setSelectetPosition] = useState();
	const [filteredPosition, setFilteredPosition] = useState();

	const [workplace, setWorkplace] = useState();
	const [selectedWorkplace, setSelectetWorkplace] = useState();
	const [filteredWorkplace, setFilteredWorkplace] = useState();

	const [probper, setProbPer] = useState();
	const [selectedProbPer, setSelectetProbPer] = useState();
	const [filteredProbPer, setFilteredProbPer] = useState();

	const [project, setProject] = useState();
	const [selectedProject, setSelectetProject] = useState();
	const [filteredProject, setFilteredProject] = useState();

	const [dropdownCurrency, setDropdownCurrency] = useState();
	const [selectedDropdownCurrency, setSelectetDropdownCurrency] = useState();

	const fatchData = () => {
		axios.all([axios.get(base + position_and_service), axios.get(base + workplace_url), axios.get(base + probation_period_url), axios.get(base + currency), axios.get(base + projects_url), axios.get(base + coworker_url + localStorage.getItem("item") + "/")]).then(
			axios.spread((...response) => {
				setPosition(response[0].data);
				setWorkplace(response[1].data);
				setProbPer(response[2].data);
				setDropdownCurrency(response[3].data);
				setProject(response[4].data);
			})
		);
	};

	useEffect(() => {
		fatchData();
	}, [props.selectedPartner]);

	useEffect(() => {
		setSelectetProject(props?.userInfo?.project);
		setSelectetPosition(props?.userInfo?.position);
		setSelectetWorkplace(props?.userInfo?.work_place);
		setSelectetProbPer(props?.userInfo?.probation_period);
		setSelectetDropdownCurrency(props?.userInfo?.currency);
		setSendData(props.userInfo);
	}, [props.userInfo]);

	const searchPosition = event => {
		let query = event.query;
		let _filteredPosition = position?.filter(item => item?.title_am?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
		setFilteredPosition(_filteredPosition);
	};
	const searchWorkplace = event => {
		let query = event.query;
		let _filteredWorkplace = workplace?.filter(item => item?.title_am?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
		setFilteredWorkplace(_filteredWorkplace);
	};
	const searchProbper = event => {
		let query = event.query;
		let _filteredProbper = probper?.filter(item => item?.title_am?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
		setFilteredProbPer(_filteredProbper);
	};
	const searchProject = event => {
		let query = event.query;
		let _filteredProject = project?.filter(item => item?.title_am?.toLowerCase().indexOf(query?.toLowerCase()) !== -1);
		setFilteredProject(_filteredProject);
	};
	function editPostData() {
		axios
			.put(base + coworker_url + localStorage.getItem("item") + "/", {
				...sendData,
				project: selectedProject?.id,
				position: selectedPosition?.id ? selectedPosition.id : sendData.position,
				work_place: selectedWorkplace?.id,
				probation_period: selectedProbPer?.id,
				currency: selectedDropdownCurrency?.id,
				partner: sendData?.partner?.id,
			})
			.then(res => {
				props?.toast.current.show({severity: "success", summary: "Success", detail: "The item is added successfully", life: 3000});
				props?.setEditDialog(false);
				props.loadContent();
			})
			.catch(error => {
				props?.toast.current.show({severity: "error", summary: "Error", detail: "Please add something than press the button ", life: 3000});
			});
	}

	return (
		<div>
			<form className="border-top" onSubmit={e => e.preventDefault.e}>
				{props.userInfo.partner?.legal} - {sendData?.contract_type}
				<div className="row mb-4">
					<div className={sendData?.partner?.legal === "Legal" ? "col my-3 opacity-50 pe-none" : "col my-3"}>
						<div className={form.type1 ? "card form-check custom-option custom-option-icon checked card b-radius b-shadow" : "form-check custom-option custom-option-icon"}>
							<label className="form-check-label custom-option-content d-flex justify-content-between align-items-center" for="radio1">
								<span className={sendData?.partner?.legal === "Legal" && "opacity-25"}>
									<BiUser style={{width: "50px"}} className="text-success h3 mt-1" />
									<span className="custom-option-title">Հաստիքային աշխատակից</span>
								</span>
								<input
									name="radio"
									className="form-check-input"
									type="radio"
									id="radio1"
									disabled={sendData?.partner?.legal === "Legal"}
									value={form.type1}
									checked={form.type1}
									onChange={e => {
										setSendData({...sendData, contract_type: 1});
										setForm({type1: true, type2: false, type3: false});
									}}
								/>
							</label>
						</div>
					</div>
					<div className="col my-3">
						<div className={form.type2 ? "form-check custom-option custom-option-icon checked card b-radius b-shadow" : "form-check custom-option custom-option-icon"}>
							<label className="form-check-label custom-option-content d-flex justify-content-between align-items-center" for="radioForm2">
								<span className="">
									<BiCopyright style={{width: "50px"}} className="text-warning h3 mt-1" />
									<span className="custom-option-title"> Քաղ. իրավ. </span>
								</span>
								<input
									name="radioForm"
									className="form-check-input"
									type="radio"
									id="radioForm2"
									value={form.type2}
									checked={form.type2}
									onChange={e => {
										setSendData({...sendData, contract_type: 2});
										setForm({type1: false, type2: true, type3: false});
									}}
								/>
							</label>
						</div>
					</div>
					<div className={sendData?.partner?.legal === "Physical" ? "col my-3 opacity-50 pe-none" : "col my-3"}>
						<div className={form.type3 ? "form-check custom-option custom-option-icon checked card b-radius b-shadow" : "form-check custom-option custom-option-icon"}>
							<label className="form-check-label custom-option-content d-flex justify-content-between align-items-center " for="radio3">
								<span className="">
									<BiRegistered style={{width: "50px"}} className="text-info h3 mt-1" />
									<span className="custom-option-title"> Մատակարար </span>
								</span>
								<input
									name="radio"
									className="form-check-input"
									type="radio"
									id="radio3"
									disabled={sendData?.partner?.legal === "Physical"}
									value={form.type3}
									checked={form.type3}
									onChange={e => {
										setSendData({...sendData, contract_type: 3});
										setForm({type1: false, type2: false, type3: true});
									}}
								/>
							</label>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-6 col-12 flex flex-column mb-4">
						<label className="pb-3" htmlFor="ashxatavayr">
							{" "}
							Նախագիծ{" "}
						</label>
						<AutoComplete
							dropdown
							field={"full_name_am"}
							completeMethod={searchProject}
							suggestions={filteredProject}
							value={selectedProject}
							onChange={e => setSelectetProject(e.value)}
							itemTemplate={item => (
								<div className="d-flex align-items-top" onClick={e => setSelectetProject(item.id)}>
									<div className="d-grid">
										<span className="text-primary">{item.full_name_am?.slice(0, 20)} </span>
										<span className="text-secondary small"> {item.full_name_en?.slice(0, 20) ?? "not provided"} </span>
									</div>
								</div>
							)}
						/>
					</div>
					<div className="col-lg-4 col-md-6 col-12 flex flex-column mb-4">
						<label className="pb-3" htmlFor="anun_azganun">
							{" "}
							Հաստիք{" "}
						</label>
						<AutoComplete
							dropdown
							placeholder="Հաստիք"
							field={"title_am"}
							completeMethod={searchPosition}
							suggestions={filteredPosition}
							value={selectedPosition}
							onChange={e => setSelectetPosition(e.value)}
							itemTemplate={item => (
								<div className="d-flex align-items-top" onClick={e => setSelectetPosition(item.id)}>
									<div className="d-grid">
										<span className="text-primary">{item.title_am} </span>
										<span className="text-secondary small">{item.title_en ?? "not provided"}</span>
									</div>
								</div>
							)}
						/>
					</div>

					<div className="col-lg-4 col-md-6 col-12 flex flex-column mb-4">
						<label className="pb-3" htmlFor="ashxatavayr">
							{" "}
							Աշխատավայր{" "}
						</label>
						<AutoComplete
							dropdown
							placeholder="Աշխատավայր"
							field={"title_am"}
							completeMethod={searchWorkplace}
							suggestions={filteredWorkplace}
							value={selectedWorkplace}
							onChange={e => setSelectetWorkplace(e.value)}
							itemTemplate={item => (
								<div className="d-flex align-items-top" onClick={e => setSelectetWorkplace(item.id)}>
									<div className="d-grid">
										<span className="text-primary">{item.title_am} </span>
										<span className="text-secondary small"> {item.title_en ?? "not provided"} </span>
									</div>
								</div>
							)}
						/>
					</div>
					<div className="col-lg-4 col-md-6 col-12 flex flex-column mb-4">
						<label className="pb-3" htmlFor="porcashrjan">
							Փորձաշրջան
						</label>
						<AutoComplete
							dropdown
							placeholder="Փորձաշրջան"
							field={"title_am"}
							completeMethod={searchProbper}
							suggestions={filteredProbPer}
							value={selectedProbPer}
							onChange={e => setSelectetProbPer(e.value)}
							itemTemplate={item => (
								<div className="d-flex align-items-top" onClick={e => setSelectetProbPer(item.id)}>
									<div className="d-grid">
										<span className="text-primary">{item.title_am} </span>
										<span className="text-secondary small"> {item.title_en ?? "not provided"} </span>
									</div>
								</div>
							)}
						/>
					</div>

					<div className="col-lg-4 col-md-6 col-12 flex flex-column mb-4">
						<label className="pb-3" htmlFor="anun_azganun">
							Աշխատակից
						</label>
						<InputText id="anun_azganun" value={props?.userInfo.partner?.full_name_am} />
					</div>

					<div className="col-lg-4 col-md-6 col-12 flex flex-column mb-4">
						<label className="pb-3" htmlFor="paymanner">
							Պայմնաններ
						</label>
						<InputText id="paymanner" placeholder="Նորմալ" />
					</div>

					<div className="col-lg-4 col-md-6 col-12 flex flex-column mb-4">
						<label className="pb-3" htmlFor="zbaxvacutyun">
							Զբաղվածություն
						</label>
						<InputNumber
							id="zbaxvacutyun"
							value={sendData?.busy_percentage}
							onChange={e => {
								setSendData({...sendData, busy_percentage: e.value});
							}}
						/>
					</div>

					<div className="col-lg-8 col-md-6 col-12 pt-0">
						<div className="row">
							<div className="col-4 flex flex-column mb-4">
								<label className="pb-3" htmlFor="skizb">
									Սկիզբ
								</label>
								<InputText type="date" id="skizb" value={sendData?.start_date} onChange={e => setSendData({...sendData, start_date: e.target.value})} />
							</div>
							<div className="col-4 flex flex-column mb-4">
								<label className="pb-3" htmlFor="avart">
									Ավարտ
								</label>
								<InputText type="date" id="avart" value={sendData?.end_date} onChange={e => setSendData({...sendData, end_date: e.target.value})} />
							</div>
							<div className="col-2 flex align-items-center">
								<Checkbox
									inputId="passive"
									checked={checked}
									onChange={e => {
										setChecked(e.checked);
										setSendData({...sendData, passive: new Date().getFullYear()});
									}}
								/>
								<label htmlFor="passive" className="ml-2">
									պասիվ
								</label>
							</div>
						</div>
					</div>

					<div className="col-lg-8 col-md-6 col-12 flex flex-column mb-4">
						<label className="pb-3" htmlFor="anun_azganun">
							Աշխատավարձ
						</label>
						<div className="p-inputgroup flex  mb-4">
							<InputNumber
								placeholder="Միավոր արժեք"
								value={sendData?.salary}
								onChange={e => {
									setSendData({...sendData, salary: e.value});
								}}
							/>
							<span className="p-inputgroup-addon">*</span>
							<InputNumber
								placeholder="Քանակ"
								value={sendData?.salary_count}
								onChange={e => {
									setSendData({...sendData, salary_count: e.value});
								}}
							/>
							<span className="p-inputgroup-addon p-0">
								<Dropdown value={selectedDropdownCurrency} onChange={e => setSelectetDropdownCurrency(e.value)} options={dropdownCurrency} optionLabel="title_am" placeholder="$" className="border-0 p-0 m-0" style={{backgroundColor: "transparent"}} />
							</span>
						</div>
					</div>

					<div className="col-lg-4 col-md-6 col-12 flex flex-column mb-4">
						<label className="pb-3" htmlFor="anun_azganun">
							{" "}
							Ընդհանուր գումար{" "}
						</label>
						<div className="p-inputgroup flex mb-4">
							<InputNumber placeholder="Ընդհանուր" disabled value={sendData?.salary * sendData?.salary_count} />
							<span className="p-inputgroup-addon">{selectedDropdownCurrency ? selectedDropdownCurrency.title_am : "$"}</span>
						</div>
					</div>
				</div>
			</form>
			<div className="col-12 d-flex justify-content-center align-items-center mt-4 border-top pt-4">
				<Button label={"Cancel"} severity="secondary" className="mx-2" rounded onClick={() => props?.setCoWorkerDialog(false)} />
				<Button label={"Save changes"} severity="primary" className="mx-2" rounded onClick={() => editPostData()} />
			</div>
		</div>
	);
}
