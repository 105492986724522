import React, {useState, useEffect} from "react";
import axios from "axios";
import {base, projects_url, unit, expance_type, contract_appendices} from "../../../api";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Checkbox} from "primereact/checkbox";
import {AutoComplete} from "primereact/autocomplete";

export default function ApplicationsDialog({setDialogVisibility}) {
	const [projectsOptions, setProjectsOptions] = useState([]);
	const [unitOptions, setUnitOptions] = useState([]);
	const [expanceTypes, setExpanceTypes] = useState([]);
	const [projectSuggestions, setProjectSuggestions] = useState([]);
	const [unitSuggestions, setUnitSuggestions] = useState([]);
	const [expanceSuggestions, setExpanceSuggestions] = useState([]);
	const [selectedProject, setSelectedProject] = useState(null);
	const [withoutVAT, setWithoutVAT] = useState(false);

	const [rows, setRows] = useState([
		{name: "", description: "", unit: "", unitPrice: "", total: "", comments: ""},
	]);

	useEffect(() => {
		loadContent();
	}, []);

	const loadContent = () => {
		axios
			.all([axios.get(base + projects_url), axios.get(base + unit), axios.get(base + expance_type)])
			.then(
				axios.spread((projectsResponse, unitResponse, expanceTypesResponse) => {
					setProjectsOptions(projectsResponse.data);
					setUnitOptions(unitResponse.data);
					setExpanceTypes(expanceTypesResponse.data);
				})
			)
			.catch(error => console.error("Error loading data:", error));
	};

	const saveData = () => {
		if (!selectedProject) {
			console.error("Project is required.");
			return;
		}

		const validRows = rows.filter(row => {
			const isValidName = !!row.name?.id; // Validate name as an object with ID
			const isValidUnit = !isNaN(parseFloat(row.unit)); // Validate unit as a number
			const isValidUnitPrice = !isNaN(parseFloat(row.unitPrice)); // Validate unitPrice as a number

			return isValidName && isValidUnit && isValidUnitPrice;
		});

		if (validRows.length === 0) {
			console.error("No valid rows to send.");
			return;
		}

		const requestData = {
			project_id: selectedProject.id,
			data: validRows.map(row => ({
				name: row.name.id, // Send name ID
				description: row.description || null,
				unit: parseFloat(row.unit),
				unitPrice: parseFloat(row.unitPrice),
				total: parseFloat(row.total),
				comments: row.comments || null,
			})),
			withoutVAT: withoutVAT,
		};

		axios
			.post(base + contract_appendices, requestData, {
				headers: {"Content-Type": "application/json"},
				withCredentials: true,
			})
			.then(response => {
				console.log("Data saved successfully:", response.data);
				setDialogVisibility(false);
			})
			.catch(error => {
				console.error("Error saving data:", error);
			});
	};

	const handleRowChange = (value, field, index) => {
		const updatedRows = [...rows];
		updatedRows[index][field] = value;

		if (field === "unit" || field === "unitPrice") {
			const unit = parseFloat(updatedRows[index].unit) || 0;
			const unitPrice = parseFloat(updatedRows[index].unitPrice) || 0;
			updatedRows[index].total = (unit * unitPrice).toFixed(2);
		}

		setRows(updatedRows);
	};

	const addRow = () => {
		setRows([
			...rows,
			{name: "", description: "", unit: "", unitPrice: "", total: "", comments: ""},
		]);
	};

	const removeRow = index => {
		setRows(rows.filter((_, i) => i !== index));
	};

	const searchProjects = event => {
		setProjectSuggestions(
			projectsOptions.filter(
				p =>
					p.full_name_am.toLowerCase().includes(event.query.toLowerCase()) ||
					p.name.toLowerCase().includes(event.query.toLowerCase())
			)
		);
	};

	const searchUnits = event => {
		setUnitSuggestions(
			unitOptions.filter(u => u.title_am.toLowerCase().includes(event.query.toLowerCase()))
		);
	};

	const searchExpanceTypes = event => {
		setExpanceSuggestions(
			expanceTypes.filter(e => e.title_am.toLowerCase().includes(event.query.toLowerCase()))
		);
	};

	const renderCellEditor = (field, rowIndex) => {
		if (field === "name") {
			return (
				<AutoComplete
					value={rows[rowIndex][field]}
					suggestions={expanceSuggestions}
					completeMethod={searchExpanceTypes}
					field="title_am"
					onChange={e => handleRowChange(e.value, field, rowIndex)}
					dropdown
					className="border-0 w-100 rounded-0 px-0"
					panelStyle={{width: "250px"}}
					itemTemplate={item => (
						<div>
							<div>{item.title_am}</div>
							<small className="text-muted">{item.name}</small>
						</div>
					)}
				/>
			);
		} else if (field === "unit") {
			return (
				<AutoComplete
					value={rows[rowIndex][field]}
					suggestions={unitSuggestions}
					completeMethod={searchUnits}
					field="title_am"
					onChange={e => handleRowChange(e.value, field, rowIndex)}
					dropdown
					className="border-0 w-100 rounded-0 px-0"
					panelStyle={{width: "150px"}}
				/>
			);
		} else {
			return (
				<InputText
					className="border-0 w-100 rounded-0 px-0"
					value={rows[rowIndex][field]}
					onChange={e => handleRowChange(e.target.value, field, rowIndex)}
				/>
			);
		}
	};

	const totalSum = rows.reduce((sum, row) => sum + parseFloat(row.total || 0), 0).toFixed(2);

	return (
		<>
			<div className="mt-3">
				<div className="fw-bolder mb-3 text-muted">Ընտրել ծրագիրը</div>
				<AutoComplete
					value={selectedProject}
					suggestions={projectSuggestions}
					completeMethod={searchProjects}
					field="full_name_am"
					onChange={e => setSelectedProject(e.value)}
					placeholder="Select Project"
					dropdown
					className="w-100"
					panelStyle={{width: "300px"}}
					itemTemplate={item => (
						<div>
							<div>{item.full_name_am}</div>
							<small className="text-muted">{item.name}</small>
						</div>
					)}
				/>
			</div>
			{selectedProject && (
				<>
					<div className="mt-5">
						<div className="mb-3 fw-bolder">Ավելացնել հայտի կետեր</div>
						<DataTable value={rows} editMode="cell">
							<Column header="№" body={(_, {rowIndex}) => rowIndex + 1} style={{width: "50px"}} />
							<Column
								field="name"
								header="Անվանում"
								body={(rowData, {rowIndex}) => renderCellEditor("name", rowIndex)}
							/>
							<Column
								field="description"
								header="Բնութագիր"
								body={(rowData, {rowIndex}) => renderCellEditor("description", rowIndex)}
							/>
							<Column
								field="unit"
								header="Չափի միավոր"
								body={(rowData, {rowIndex}) => renderCellEditor("unit", rowIndex)}
							/>
							<Column
								field="unitPrice"
								header="Միավոր գին"
								body={(rowData, {rowIndex}) => renderCellEditor("unitPrice", rowIndex)}
							/>
							<Column
								field="total"
								header="Գումարը"
								body={(rowData, {rowIndex}) => <span>{rowData.total}</span>}
							/>
							<Column
								field="comments"
								header="Նշումներ"
								body={(rowData, {rowIndex}) => renderCellEditor("comments", rowIndex)}
							/>
							<Column
								header={null}
								body={(_, {rowIndex}) => (
									<Button
										icon="pi pi-minus"
										className="p-0 m-1"
										severity="danger"
										onClick={() => removeRow(rowIndex)}
									/>
								)}
							/>
						</DataTable>
					</div>
					<div className="row align-items-center mt-3">
						<div className="col-7 text-start">
							<strong>Ընդհանուր Գումարը:</strong> {totalSum}
						</div>
						<div className="col-5 text-end">
							<Checkbox
								inputId="withoutVAT"
								checked={withoutVAT}
								onChange={e => setWithoutVAT(e.checked)}
							/>
							<label htmlFor="withoutVAT" className="ml-2">
								Առանց ԱԱՀ
							</label>
						</div>
					</div>
					<div className="row align-items-center mt-3 pt-3 border-top">
						<div className="col-7 text-end">
							<Button
								label="Cancel"
								icon="pi pi-times"
								className="mr-2"
								onClick={() => setDialogVisibility(false)}
							/>
							<Button label="Save" icon="pi pi-check" className="ml-2" onClick={saveData} />
						</div>
						<div className="col-5 text-end">
							<Button label="Add Row" icon="pi pi-plus" onClick={addRow} />
						</div>
					</div>
				</>
			)}
		</>
	);
}
