import {useState, useEffect} from "react";
import {base, project_events_url} from "../../api";
import axios from "axios";

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {TabView, TabPanel} from "primereact/tabview";
import {Button} from "primereact/button";
import walletInfo from "../../assets/images/icons/unicons/wallet-info.png";
import computer from "../../assets/images/icons/unicons/computer.png";
import chartSuccess from "../../assets/images/icons/unicons/chart-success.png";
import briefcase from "../../assets/images/icons/unicons/briefcase.png";
import BeneficareDashboardCard from "./components/BeneficareDashboardCard";

import {EventDataRow} from "./widgets/Widget";
import BeneficareAddForm from "./components/BeneficareAddForm";
import {Link} from "react-router-dom";

import {eventLocation, eventProject, eventDate, eventName, eventAction} from "./widgets/Widget";

export default function BeneficiariesDashboard() {
	const [enents, setEvents] = useState();
	const [form, setForm] = useState(false);
	const [formData, setFormData] = useState();
	const [selectedProducts, setSelectedProducts] = useState([]);

	useEffect(() => {
		loadData();
	}, []);

	function loadData() {
		axios.get(base + project_events_url).then(res => {
			setEvents(res.data);
		});
	}
	return (
		<>
			<div className="row mb-3 match-height p-0">
				<BeneficareDashboardCard image={walletInfo} title={"Budget"} count={"$23211"} percent={"+23%"} />
				<BeneficareDashboardCard image={computer} title={"Total on gloing projects"} count={451} percent={((365 + 451) * 100) / 451 + "%"} />
				<BeneficareDashboardCard image={briefcase} title={"Total completed projects"} count={365} percent={((365 + 451) * 100) / 451 + "%"} />
				<BeneficareDashboardCard image={chartSuccess} title={"Total completed projects"} count={451} percent={((365 + 451) * 100) / 451 + "%"} />
			</div>

			{form ? (
				<BeneficareAddForm setForm={setForm} setFormData={setFormData} loadData={loadData} formData />
			) : (
				<>
					<div className="col-lg-12 mb-4">
						<div className="card b-radius b-shadow p-3">
							<div className="d-flex justify-content-center">
								<Link to={"/beneficiaries/new_event"} className="btn btn-primary px-5">
									Ավելացնել միջոցառում
								</Link>
							</div>
						</div>
					</div>
					<div className="col-lg-12 mb-4">
						<div className="card w-100 b-radius b-shadow px-3 py-5">
							<DataTable value={enents} paginator rows={10} rowsPerPageOptions={[20, 30, 50, 100]} dataKey="id" globalFilterFields={["full_name"]} emptyMessage="Միջոցառումներ չէն գտնվել">
								<Column className="col-2" header="Name" body={eventName}></Column>
								<Column className="col-2" header="Project" body={eventProject}></Column>
								<Column className="col-2" header="Location" body={eventLocation}></Column>
								<Column className="col-2" header="Date" body={eventDate}></Column>
								<Column className="col-1" header={null} body={eventAction}></Column>
							</DataTable>
						</div>
					</div>
				</>
			)}
		</>
	);
}
