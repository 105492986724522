import { useState, useEffect, useCallback } from "react";
import { listsContainIntersectingField } from "../../utils/utils";

export function usePublicFileFilter(publicFiles, closeDialog) {
  ///////// FILTER START
  const [filteredPublicFiles, setFilteredPublicFiles] = useState(null);

  const [publicSearchValue, setPublicSearchValue] = useState("");

  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });

  const [multiselectTagsFilter, setMultiselectTagsFilter] = useState([]);
  const [multiselectProjectsFilter, setMultiselectProjectsFilter] = useState(
    []
  );
  const [multiselectLocationsFilter, setMultiselectLocationsFilter] = useState(
    []
  );

  const customPublicFilter = (node) => {
    let searchTextMatches = true;
    let dateMatches = true;
    let selectedTagsMatch = true;
    let selectedProjectsMatch = true;
    let selectedLocationsMatch = true;
    if (publicSearchValue) {
      searchTextMatches = node.label
        .toLowerCase()
        .includes(publicSearchValue.toLowerCase());
      if (node.tags) {
        searchTextMatches =
          searchTextMatches ||
          node.tags.some(
            (tag) =>
              tag.title_am
                .toLowerCase()
                .includes(publicSearchValue.toLowerCase()) ||
              tag.title_en
                .toLowerCase()
                .includes(publicSearchValue.toLowerCase())
          );
      }
    }

    if (selectedDayRange?.from && selectedDayRange?.to) {
      const createDate = new Date(node.createdAt);
      const eventDate = new Date(node.date);
      const fromDate = new Date(
        selectedDayRange.from.year,
        selectedDayRange.from.month - 1,
        selectedDayRange.from.day
      );
      const toDate = new Date(
        selectedDayRange.to.year,
        selectedDayRange.to.month - 1,
        selectedDayRange.to.day
      );
      dateMatches =
        (createDate > fromDate && createDate < toDate) ||
        (eventDate > fromDate && eventDate < toDate);
    }
    if (multiselectTagsFilter.length > 0) {
      selectedTagsMatch = listsContainIntersectingField(
        node.tags,
        multiselectTagsFilter,
        "id"
      );
    }
    if (multiselectLocationsFilter.length > 0) {
      selectedLocationsMatch = listsContainIntersectingField(
        node.locations,
        multiselectLocationsFilter,
        "id"
      );
    }
    if (multiselectProjectsFilter.length > 0) {
      if (!node.project) {
        selectedProjectsMatch = false;
      } else {
        selectedProjectsMatch = multiselectProjectsFilter.some(
          (project) => node.project.id == project.id
        );
      }
    }

    if (searchTextMatches) {
      console.log({
        searchTextMatches,
        dateMatches,

        selectedTagsMatch,
        selectedLocationsMatch,
        selectedProjectsMatch,
      });
    }

    if (
      searchTextMatches &&
      dateMatches &&
      selectedTagsMatch &&
      selectedLocationsMatch &&
      selectedProjectsMatch
    ) {
      console.log({ node });
      return true;
    }

    if (node.children) {
      const childrenMatch = node.children.some((child) =>
        customPublicFilter(child, publicSearchValue)
      );
      if (childrenMatch) {
        return true;
      }
    }

    return false;
  };
  const filterPublicFileNodeChildren = (node) => {
    if (!customPublicFilter(node)) {
      return undefined;
    }
    if (!node.children || node.children.length == 0) {
      return node;
    }
    const filteredChildren = [...node.children].filter((node) =>
      customPublicFilter(node, publicSearchValue)
    );
    return { ...node, children: filteredChildren };
  };
  const filterPublicFiles = () => {
    const hasAnyFilter =
      (publicSearchValue && publicSearchValue.trim()) ||
      multiselectTagsFilter.length > 0 ||
      multiselectLocationsFilter.length > 0 ||
      multiselectProjectsFilter.length > 0 ||
      (selectedDayRange?.from && selectedDayRange?.to);

    if (!hasAnyFilter) {
      setFilteredPublicFiles(null); // No filter, show all nodes
      return;
    }

    const filteredNodes = [];
    publicFiles.forEach((node) => {
      const filteredNode = filterPublicFileNodeChildren(node);
      if (filteredNode) {
        filteredNodes.push(filteredNode);
      }
    });

    setFilteredPublicFiles(filteredNodes);
  };
  useEffect(filterPublicFiles, [
    publicSearchValue,
    ...multiselectTagsFilter,
    ...multiselectLocationsFilter,
    ...multiselectProjectsFilter,
    selectedDayRange?.from,
    selectedDayRange?.to,
  ]);

  const clearPublicFilter = useCallback(() => {
    setFilteredPublicFiles(null);
    setPublicSearchValue("");
    setSelectedDayRange({
      from: null,
      to: null,
    });
    setMultiselectTagsFilter([]);
    setMultiselectProjectsFilter([]);
    setMultiselectLocationsFilter([]);

    closeDialog();
  }, []);

  return {
    filteredPublicFiles,
    selectedDayRange,
    setSelectedDayRange,
    multiselectTagsFilter,
    setMultiselectTagsFilter,
    multiselectLocationsFilter,
    setMultiselectLocationsFilter,
    multiselectProjectsFilter,
    setMultiselectProjectsFilter,
    setPublicSearchValue,
    publicSearchValue,
    clearPublicFilter,
  };
}
