import React, {useEffect, useState} from "react";
import axios from "axios";
import {base, partners} from "../api";
import {FiAlertCircle} from "react-icons/fi";
import walletInfo from "../assets/images/icons/unicons/wallet-info.png";
import computer from "../assets/images/icons/unicons/computer.png";
import chartSuccess from "../assets/images/icons/unicons/chart-success.png";
import briefcase from "../assets/images/icons/unicons/briefcase.png";
import ChartBar from "./ui/elements/ChartBar";
import ChartPie from "./ui/elements/ChartPie";
import ChartLine from "./ui/elements/ChartLine";
import ChartArea from "./ui/elements/ChartArea";
import DateFunction from "./ui/elements/date";
import InfoCard from "./ui/elements/InfoCard";
import UserPerformanceComponent from "./DashboardComponents/UserPerformanceComponent";
import LatestProjectsComponent from "./DashboardComponents/LatestProjectsComponent";
import TicketsPreview from "./TicketSystem/TicketsPreview";
import {TbCreditCard, TbUsers, TbCalendar, TbSettings} from "react-icons/tb";
import PartnersSummeryComponent from "./DashboardComponents/PartnersSummeryComponent";
import {TbAtom2} from "react-icons/tb";

export default function MainPageDashboard(props) {
	const [partnersData, setPartnersData] = useState([]);

	useEffect(() => {
		axios.get(base + partners).then(res => {
			setPartnersData(res.data);
		});
	}, []);

	return (
		<div className="container-fluid p-0">
			<div className="row mb-3 match-height p-0">
				<div className="col-xl-7 col-lg-12 col-md-12 col-12">
					<DateFunction profile={props.profile} />
					<div className="row">
						<div className="col-lg-7 col-md-6 col-sm-6 col-12 mt-3">
							<div className="card w-100 b-radius b-shadow stretch-card border-0">
								<div className="px-4 py-3 h6 border-bottom align-items-center text-uppercase">
									<FiAlertCircle className="me-3" /> Budget chart
								</div>
								<ChartBar />
							</div>
						</div>
						<div className="col-lg-5 col-md-6 col-sm-6 col-12 mt-3">
							<div className="card w-100 b-radius b-shadow stretch-card border-0">
								<div className="px-4 py-3 h6 border-bottom align-items-center text-uppercase">
									<FiAlertCircle className="me-3" /> Budget chart
								</div>
								<ChartPie />
							</div>
						</div>
					</div>
				</div>
				<div className="col-xl-5 col-lg-12 col-md-12 col-12 stretch-card ">
					<TicketsPreview />
				</div>
			</div>

			<div className="row mb-3 match-height p-0">
				<InfoCard image={walletInfo} title={"Բյուջե"} digits={"$12,679,930"} percent={"+40.22%"} color={"text-bg-success"} icon={<TbCreditCard />} />
				<InfoCard image={computer} title={"Նախագծեր"} digits={"108"} percent={"+18.42%"} color={"text-bg-info"} icon={<TbUsers />} />
				<InfoCard image={chartSuccess} title={"Այլ"} digits={"$4,679"} percent={" +28.42%"} color={"text-bg-warning"} icon={<TbCalendar />} />
				<InfoCard image={briefcase} title={"ԳՈՐԾԸՆԿԵՐՆԵՐ"} digits={partnersData.length} percent={"անձ"} color={"text-bg-danger"} icon={<TbSettings />} />
			</div>

			<LatestProjectsComponent />

			<div className="row mb-3 match-height p-0">
				<div className="col-xl-6 col-lg-12 col-md-12 col-12 stretch-card ">
					<div className="card w-100 b-radius b-shadow p-0">
						<div className="px-4 py-3 h6 border-bottom align-items-center text-uppercase">
							<FiAlertCircle className="me-3" /> Budget chart
						</div>
						<ChartLine />
					</div>
				</div>
			</div>

			<div className="row mb-3 match-height p-0">
				<div className="col-xl-4 col-lg-12 col-md-12 col-12 stretch-card ">
					<PartnersSummeryComponent />
				</div>

				<div className="col-xl-8 col-lg-12 col-12 stretch-card">
					<div className="card w-100 b-radius b-shadow p-0">
						<ChartArea />
					</div>
				</div>
			</div>
		</div>
	);
}
