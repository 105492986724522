import {useState} from "react";
import userIcon from "../../../assets/images/illustrations/girl-doing-yoga-light.png";
import {Button} from "primereact/button";
import axios from "axios";
import {InputText} from "primereact/inputtext";

export default function ChangeProfileAvatar(props) {
	const headers = {"Content-Type": "multipart/form-data"};
	const credentials = "same-origin";
	const [userAvatar, setUserAvatar] = useState();
	const [imagePreviewUrl, setImagePreviewURL] = useState();

	const [filelog, setFilelog] = useState();

	function handleImageChange(e) {
		setUserAvatar(e.target.files);
		setImagePreviewURL(URL.createObjectURL(e.target.files[0]));
	}

	function removeImage(e) {
		setUserAvatar(null);
		setImagePreviewURL(null);
	}
	console.log(props.username);
	function edit(e) {
		const formData = new FormData();
		formData.append("avatar", userAvatar);
		axios({
			method: "patch",
			url: props.userUrl + "/",
			data: formData,
			headers: headers,
			credentials: credentials,
		})
			.then(res => {
				props.toast.current.show({severity: "success", summary: "Success", detail: "Message Content"});
				props.loadContent();
				props.setProfileAvatarDialog(false);
				setUserAvatar(null);
			})
			.catch(error => {
				props.loadContent();
				props.toast.current.show({severity: "warn", summary: "Error", detail: "Nothing is changed"});
			});
	}

	return (
		<form encType="multipart/form-data" onSubmit={e => e.preventDefault()}>
			<div className="col-6 mx-auto mb-3">
				<div className="image w-100 b-radius b-shadow" style={{backgroundImage: `url(${imagePreviewUrl ? imagePreviewUrl : userIcon})`}} alt="upload an avatar" />
			</div>
			<div className="col-12 text-center">
				<label htmlFor="upload" className="btn btn-primary" tabIndex="0">
					<span className="d-none d-sm-block">Upload avatar</span>
					<input type="file" hidden id="upload" size={"2048"} accept="image/png, image/jpeg, image/gif" onChange={e => (handleImageChange(e), setUserAvatar(e.target.files[0]))} />
				</label>

				{imagePreviewUrl && <Button icon={"pi pi-times"} rounded outlined severity="danger" className="mx-3" onClick={e => removeImage(e)} />}
				{userAvatar && <p className="col-12 text-muted mb-0 mt-4 small">{userAvatar.name}</p>}
				<p className="col-12 text-muted mb-0 mt-4 small">Allowed JPG, GIF or PNG. Max size of 2MB</p>
			</div>
			<div className="text-center border-top pt-4">
				<Button label="Cancel" icon="pi pi-times" rounded severity="secondary" onClick={() => props.setProfileAvatarDialog(false)} className="p-button-text" />
				<Button label="Save" icon="pi pi-check" rounded severity="primary" onClick={e => edit(e)} autoFocus />
			</div>
		</form>
	);
}
