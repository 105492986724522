import React from 'react'
import errorImage from '../../assets/images/illustrations/page-misc-error-light.png'
import { Link } from 'react-router-dom'


export default function ErrorPage() {
  return (
    <div className='col-12 text-center mt-5 pt-5'>

    <div className="misc-wrapper">
    <h2 className="mb-2 mx-2">Page Not Found :(</h2>
    <p className="mb-4 mx-2">Oops! 😖 The requested URL was not found on this server.</p>
    <Link to="/" className="btn btn-primary">Back to home</Link>
    <div className="mt-3">
      <img src={errorImage} alt="error page not found" width="500" className="img-fluid"/>
    </div>
  </div>
    </div>
  )
}
