import { useMemo, useState } from 'react'
import { FiCheck, FiX } from 'react-icons/fi';
import permissionsGrouped from '../../static/permissionsGroups.json'

const PermissionCell = ({
    id,
    enabledPermissions,
    removePermission,
    setEnabled,

}) => {
    const isEnabled = useMemo(() => {
        return enabledPermissions.some((perm) => perm == id)
    }, [id, enabledPermissions])

    const togglePerm = () => {

        if (isEnabled) {
            return removePermission(id)
        }

        return setEnabled(id)
    }


    if (isEnabled) {
        return <div className='col-lg-2 col-12 d-flex align-items-center text-start small ' onClick={togglePerm}>
            <div className='bg-info opacity-80 b-radius border-1 px-4 py-2 me-1' style={{ borderColor: 'transparent' }}>
                <FiCheck className='text-white' />
            </div>
        </div>
    }



    return <div className='col-lg-2 col-12 d-flex align-items-center text-start small' onClick={togglePerm}>
        <div className='b-radius border-1 opacity-30 px-4 py-2 me-1'>
            <FiX className='text-dark' />
        </div>
    </div>

}

export default function PermissionsGroupForm({ enabledPermissions, setEnabledPermissions }) {

    const setEnabled = (id) => {
        setEnabledPermissions([...enabledPermissions, id])
    }
    const removePermission = (id) => {
        setEnabledPermissions(enabledPermissions.filter((permID) => permID != id))
    }



    return (
        <div>
            <form className='d-grid'>

                <div className='d-flex col-12 p-0'>
                    <div className='col-4 fw-bold '>Permission name</div>
                    <div className='col-2 fw-bold'>Add</div>
                    <div className='col-2 fw-bold'>View</div>
                    <div className='col-2 fw-bold'>Change</div>
                    <div className='col-2 fw-bold'>Delete</div>
                </div>
                {permissionsGrouped.map((data, index) =>
                    <div key={index} className='d-flex col-12 border-bottom align-items-center text-center'>
                        <div className='col-4 d-flex text-center p-0'>{data.name_en}</div>
                        <PermissionCell
                            id={data.add}
                            enabledPermissions={enabledPermissions}
                            removePermission={removePermission}
                            setEnabled={setEnabled}
                        />

                        <PermissionCell
                            id={data.view}
                            enabledPermissions={enabledPermissions}
                            removePermission={removePermission}
                            setEnabled={setEnabled}
                        />

                        <PermissionCell
                            id={data.change}
                            enabledPermissions={enabledPermissions}
                            removePermission={removePermission}
                            setEnabled={setEnabled}
                        />

                        <PermissionCell id={data.delete}
                            enabledPermissions={enabledPermissions}
                            removePermission={removePermission}
                            setEnabled={setEnabled}
                        />
                    </div>
                )
                }
            </form >
        </div >
    )
}
