import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios'
import { base, usersURL, groupsURL, permissionsURL } from '../../api';

import GroupedUsers from '../ui/elements/GroupedUsers'
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
// import { PickList } from 'primereact/picklist';

import manWithLaptop from '../../assets/images/illustrations/man-with-laptop-light.png'
import PermissionsSelectForm from './components/PermissionsSelectForm';
import { groupCreateFooterContent, footerContent, headerContent, avatarBody, detailsBody, statusBody, UserPermissionDialog } from './components/ui/widgets';

import permisstionsGroup from '../static/permissionsGroups.json'
import PermissionsGroupForm from './components/PermissionsGroupForm';

export default function PermissionsDashboard() {
  const toast = useRef(null);
  // const opener = useRef(null);
  const headers = { 'Content-Type': 'application/json', }
  const credentials = "same-origin"
  const [visible, setVisible] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [visibleGroupCreate, setVisibleGroupCreate] = useState(false);
  const [visibleGroupEdit, setVisibleGroupEdit] = useState(false);
  const [userInfo, setUserInfo] = useState(null)
  const [enabledPermissions, setEnabledPermissions] = useState([]);
  const [users, setUsers] = useState()
  const [groupName, setGroupName] = useState()
  const [groups, setGroups] = useState()

  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    full_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    phone: { value: null, matchMode: FilterMatchMode.IN },
    email: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
  });

  const refreshContent = useCallback(() => {
    axios.get(base + usersURL).then(res => { setUsers(res.data) })
    axios.get(base + groupsURL).then(res => { setGroups(res.data); })
  }, [])

  useEffect(refreshContent, [])

  function addGroup() {
    axios({
      method: "POST",
      url: base + groupsURL,
      data: {
        "name": groupName,
        "permissions": enabledPermissions
      },
      headers: headers,
      credentials: credentials,
    })
      .then(res => {
        getGroups()
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'The item is added successfully', life: 3000 });
      })
      .catch(error => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please add something than press the button ', life: 3000 });
        // console.log(error);
      })
  }

  function editGroup() {
    axios({
      method: "PUT",
      url: base + groupsURL + selectedID + '/',
      data: {
        "name": groupName,
        "permissions": enabledPermissions
      },
      headers: headers,
      credentials: credentials,
    })
      .then(res => {
        getGroups()
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'The item is added successfully', life: 3000 });
      })
      .catch(error => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please add something than press the button ', life: 3000 });
        // console.log(error);
      })
  }
  function getGroups() {
    axios.get(base + groupsURL).then(res => { setGroups(res.data); })
  }

  function removeGroup() {
    axios.delete(base + groupsURL + localStorage.getItem('item') + `/`, { headers: headers })
      .then((res) => {
        getGroups()
        localStorage.removeItem('item')
      })
  }

  const accept = () => {
    toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    removeGroup()
  }
  const reject = () => {
    toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
  }
  const confirmDelete = (e) => {
    confirmDialog({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept,
      reject
    });
  };

  const actions = (rowData) => {
    return (
      <div className='d-flex justify-content-center'>
        <Button icon="pi pi-pencil" rounded outlined className='mx-1' onClick={() => { setVisible(true); setUserInfo(rowData) }} />
        <Button icon="pi pi-times" severity='danger' rounded className='mx-1' outlined />
      </div>
    );
  };
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
  }
  const renderHeader = () => {
    const value = filters['global'] ? filters['global'].value : '';
    return (
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Global Search" />
      </span>
    );
  };
  const openGroupEdit = (group) => {
    setEnabledPermissions(group.permissions)
    setGroupName(group.name)
    setSelectedID(group.id)
    setVisibleGroupEdit(true)
  }
  const closeGroupEdit = (group) => {
    setEnabledPermissions([])
    setGroupName('')
    setSelectedID(group.id)
    setVisibleGroupEdit(false)
  }


  const header = renderHeader();

  // console.log(permisstionsGroup);
  return (
    <div>
      <div className='row'>
        {groups?.map((g, i) =>
          <div key={i} className="col-xl-4 col-lg-6 col-md-6 mb-4 stretch-card">
            <GroupedUsers group={g} totalUsers={g.users} id={g.id} setVisible={setVisibleGroupEdit} openGroupEdit={openGroupEdit} closeGroupEdit={closeGroupEdit} confirmDelete={confirmDelete} />
          </div>
        )}

        <div className="col-xl-4 col-lg-6 col-md-6 mb-4 stretch-card">
          <div className="card w-100 b-radius b-shadow px-3 pt-3">
            <div className="row h-100">
              <div className="col-sm-7 px-4 py-3 text-center">
                <div className="mb-5">Ստեղծել նոր խումբ, նոր լիազորություններով</div>
                <Button label="Ստեղծել խումբ" rounded className='text-uppercase' onClick={e => setVisibleGroupCreate(true)} />
              </div>
              <div className="col-sm-5">
                <div className="d-flex align-items-end h-100 justify-content-center mt-sm-0 mt-3">
                  <img src={manWithLaptop} className="w-100" alt="Image" width="220" />
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className='col-12 mb-3'>
          <div className="card p-5">
            <div className="card-body px-0 py-2">
              <DataTable
                value={users}
                paginator
                rows={10}
                rowsPerPageOptions={[20, 30, 50, 100]}
                dataKey="id"
                filters={filters}
                onFilter={(e) => setFilters(e.filters)}
                header={header}
                globalFilterFields={['username', 'full_name_am']}
                emptyMessage="No customers found."
              >
                <Column body={avatarBody} bodyClassName="small" style={{ width: '100px' }} ></Column>
                <Column header="Username" field="username" sortable style={{ width: '25%' }} bodyClassName="fs-5 fw-bold"></Column>
                <Column header="User Details" field="full_name_am" body={detailsBody} style={{ width: '25%' }}></Column>
                <Column header="Groups" field="groups" sortable body={statusBody}></Column>
                <Column header="Actions" bodyClassName="text-end" body={actions} ></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>

      <UserPermissionDialog visible={visible} onHide={() => { setVisible(false) }} groups={groups} userInfo={userInfo} refreshContent={refreshContent} toast={toast} />

      <Dialog header={userInfo?.full_name} visible={visibleGroupCreate} style={{ width: '50vw' }} onHide={() => { setVisibleGroupCreate(false) }} footer={() => groupCreateFooterContent({ setVisible: setVisibleGroupCreate, accept: addGroup, userInfo })}>
        <div className="card">
          <InputText className='col-10 mx-auto p-2 mb-4' placeholder="Group name" value={groupName} onChange={e => setGroupName(e.target.value)} />
          <PermissionsGroupForm enabledPermissions={enabledPermissions} setEnabledPermissions={setEnabledPermissions} />
        </div>
      </Dialog>

      <Dialog header={userInfo?.full_name} visible={visibleGroupEdit} style={{ width: '50vw' }} onHide={() => { setVisibleGroupEdit(false) }} footer={() => groupCreateFooterContent({ setVisible: setVisibleGroupEdit, accept: editGroup, userInfo })}>
        <div className="card">
          <InputText className='col-10 mx-auto p-2 mb-4' placeholder="Group name" value={groupName} onChange={e => setGroupName(e.target.value)} />
          <PermissionsGroupForm enabledPermissions={enabledPermissions} setEnabledPermissions={setEnabledPermissions} />
        </div>
      </Dialog>

      <Toast ref={toast} />

      <ConfirmDialog />

    </div>
  )
}
