import React, {useEffect, useRef, useState} from "react";
import Nothing from "../../assets/images/illustrations/girl-unlock-password-light.png";
import axios from "axios";
import {useParams} from "react-router-dom";
import {FiMoreVertical, FiInfo} from "react-icons/fi";
import {Dialog} from "primereact/dialog";
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {ScrollPanel} from "primereact/scrollpanel";
import {PermOnly} from "../PermOnly";
import PermLessCard from "../ui/elements/PermLessCard";

export default function Infromers(props) {
	const toast = useRef(null);
	const headers = {"Content-Type": "application/json"};
	const credentials = "same-origin";
	const [visible, setVisible] = useState(false);

	const [stateAM, setStateAM] = useState(null);
	const [stateEN, setStateEN] = useState(null);

	const [currentState, setCurrentState] = useState([]);
	const [editCurrentStateAM, setEditCurrentStateAM] = useState(null);
	const [editCurrentStateEN, setEditCurrentStateEN] = useState(null);

	function loadContent() {
		axios.get(props.url).then(res => {
			setCurrentState(res.data);
		});
	}

	useEffect(() => {
		loadContent();
	}, []);

	function add() {
		axios({
			method: "POST",
			url: props.url,
			data: {title_am: stateAM, title_en: stateEN},
			headers: headers,
			credentials: credentials,
		})
			.then(res => {
				setCurrentState();
				loadContent();
				setStateAM();
				setStateEN();
				console.log(res.data);
				toast.current.show({severity: "success", summary: "Success", detail: "The item is added successfully", life: 3000});
			})
			.catch(error => {
				toast.current.show({severity: "error", summary: "Error", detail: "Please add something than press the button ", life: 3000});
				loadContent();
				setStateAM();
				setStateEN();
			});
		setStateAM();
		setStateEN();
	}

	function getCurrent() {
		axios.get(props.url + localStorage.getItem("item") + "/").then(res => {
			setEditCurrentStateAM(res.data.title_am);
			setEditCurrentStateEN(res.data.title_en);
		});
	}

	function edit(e) {
		axios({
			method: "PUT",
			url: props.url + localStorage.getItem("item") + `/`,
			data: {title_am: editCurrentStateAM, title_en: editCurrentStateEN},
			headers: headers,
			credentials: credentials,
		})
			.then(res => {
				toast.current.show({severity: "success", summary: "Success", detail: "Message Content"});
				loadContent();
				setCurrentState(null);
				setVisible(false);
			})
			.catch(error => {
				loadContent();
				toast.current.show({severity: "warn", summary: "Error", detail: "Nothing is chenged"});
			});
	}
	function remove() {
		axios
			.delete(props.url + localStorage.getItem("item") + `/`, {headers: headers})
			.then(res => {
				loadContent();
				localStorage.removeItem("item");
			})
			.catch(error => {
				loadContent();
			});
	}

	const accept = () => {
		toast.current.show({severity: "success", summary: "Confirmed", detail: "You have accepted", life: 3000});
		remove();
	};

	const reject = () => {
		toast.current.show({severity: "warn", summary: "Rejected", detail: "You have rejected", life: 3000});
	};

	const confirmDelete = e => {
		confirmDialog({
			message: "Do you want to delete this record?",
			header: "Delete Confirmation",
			icon: "pi pi-info-circle",
			acceptClassName: "p-button-danger",
			accept,
			reject,
		});
	};

	const footerbuttons = (
		<div className="text-center">
			<Button label="Cancel" icon="pi pi-times" severity="warning" outlined rounded onClick={() => setVisible(false)} />
			<Button
				label="Save"
				icon="pi pi-check"
				severity="primary"
				outlined
				rounded
				onClick={e => {
					setVisible(false);
					edit(e);
				}}
			/>
		</div>
	);
	return (
		<div>
			<PermOnly perm={props.perm} permless>
				<PermLessCard />
			</PermOnly>
			<PermOnly perm={props.perm}>
				<div className="col-12 card w-100 b-radius b-shadow p-3 mt-3 mb-5">
					<div className=" col-12 mx-auto text-center py-5">
						<p className="m-0 fst-italic text-muted">{props?.description}</p>
					</div>
				</div>
			</PermOnly>

			<PermOnly perm={props.perm}>
				<div className="d-flex">
					<PermOnly perm={props.perm} add>
						<div className="col-lg-5 col-md-5 col-12 p-0 pe-lg-3">
							<div className="card b-radius b-shadow px-4 pt-4 pb-5">
								<p className="text-muted mb-0 text-uppercase">
									<FiInfo className="me-2" />
									Ավելացնել տեղեկատու
								</p>
								<hr />
								<div className="text-center px-5 py-3">
									<input type="text" className="form-control mb-4 b-radius" placeholder={"Հայերեն տարբերակ"} value={stateAM} onChange={e => setStateAM(e.target.value)} />
									<input type="text" className="form-control mb-4 b-radius" placeholder={"Անգլերեն տարբերակ"} value={stateEN} onChange={e => setStateEN(e.target.value)} />
									<Button label="Add informer" severity="primary" rounded onClick={e => add(e)} />
								</div>
							</div>
						</div>
					</PermOnly>
					<PermOnly perm={props.perm} view>
						<div className="col p-0 ps-lg-3">
							<div className="card w-100 b-radius b-shadow p-2">
								<ScrollPanel style={{width: "100%", height: "550px"}}>
									{currentState?.length > 0 ? (
										<div className="row">
											{currentState?.map((data, i) => (
												<div className="col-12 mb-1">
													<div className="card w-100 b-radius b-shadow p-3">
														<div className="d-flex align-items-start justify-content-between">
															<div className="d-grid">
																<div className="text-dark mb-1">{data.title_am}</div>
																<div className="text-muted small">{data.title_en}</div>
															</div>
															<PermOnly perm={props.perm} change remove>
																<div className="dropdown">
																	<button className="btn p-0" type="button" id={"data-" + data.id} data-bs-toggle="dropdown" aria-expanded="false">
																		<FiMoreVertical />
																	</button>
																	<div className="dropdown-menu dropdown-menu-end px-3" aria-labelledby={"data-" + data.id}>
																		<PermOnly perm={props.perm} change>
																			<a
																				className="dropdown-item my-2"
																				href="javascript:void(0);"
																				onClick={e => {
																					setVisible(true);
																					localStorage.setItem("item", data.id);
																					getCurrent(data.id);
																				}}
																			>
																				Edit
																			</a>
																		</PermOnly>
																		<PermOnly perm={props.perm} remove>
																			<a
																				className="dropdown-item my-2"
																				href="javascript:void(0);"
																				onClick={e => {
																					confirmDelete(data.id);
																					localStorage.setItem("item", data.id);
																				}}
																			>
																				Delete
																			</a>
																		</PermOnly>
																	</div>
																</div>
															</PermOnly>
														</div>
													</div>
												</div>
											))}
										</div>
									) : (
										<div className="card w-100 text-center align-items-center d-flex justify-content-center" style={{minHeight: "300px"}}>
											<div>
												<img src={Nothing} alt="" style={{maxHeight: "280px"}} />
											</div>
											<div className="mt-5 text-muted">NOTHING TO SHOW HERE YET 😕</div>
										</div>
									)}
								</ScrollPanel>
							</div>
						</div>
					</PermOnly>
				</div>
			</PermOnly>

			<Toast ref={toast} />
			<ConfirmDialog />
			<Dialog header={"Փոփոխել"} icon="pi pi-external-link" visible={visible} style={{width: "25vw"}} onHide={() => setVisible(false)} footer={footerbuttons}>
				<hr className="mt-0 pt-0" />
				<div className="text-center px-5 pt-5 pb-1">
					<input type="text" className="form-control mb-4 b-radius" placeholder={"Հայերեն տարբերակ"} value={editCurrentStateAM} onChange={e => setEditCurrentStateAM(e.target.value)} />
					<input type="text" className="form-control mb-4 b-radius" placeholder={"Անգլերեն տարբերակ"} value={editCurrentStateEN} onChange={e => setEditCurrentStateEN(e.target.value)} />
				</div>
			</Dialog>
		</div>
	);
}
