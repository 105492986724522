import React from "react";

export default function UserProductivity(props) {
	return (
		<div className="card w-100 b-radius b-shadow mb-4 my-3">
			<div className="card-body">
				<div className="fs-4 mb-3">User productivity</div>
				<div className="d-flex justify-content-between align-items-center mb-2">
					<span>Completed projects</span>
					<span>75% </span>
				</div>
				<div className="progress mt-3 mb-1" style={{height: "5px"}}>
					<div className="progress-bar bg-success" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: "75%"}}></div>
				</div>
			</div>
		</div>
	);
}
