import React from "react";

export default function InfoCard(props) {
	return (
		<div className="col-lg-3 col-md-6">
			<div className="card border-0 my-2 b-radius b-shadow">
				<div className="card-body">
					<div className="d-flex flex-row align-items-center">
						<div className={`round-40 fs-4 rounded-circle text-white d-flex align-items-center justify-content-center p-2 ` + `${props.color}`}>{props.icon}</div>
						<div className="ms-3 align-self-center">
							<h3 className="mb-0 fs-6">{props.digits}</h3>
							<span className="text-muted">{props.title}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
