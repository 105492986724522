import React from "react";
import {InputNumber} from "primereact/inputnumber";

export default function CurrencyValue_data(props) {
	return (
		<div className="col text-start">
			<div className="row">
				<div className="col-3">
					<div className={`d-grid fw-bold ${props?.small ? "small" : ""}`}>
						<span className="text-muted ">Project value</span>
						<span className="text-primary mt-3 ">{props?.data} AMD</span>
					</div>
				</div>
				<div className="col-4 p-0">
					<div className={`d-grid fw-bold ${props?.small ? "small" : ""}`}>
						<span className="text-muted ">Enter a value</span>
						<InputNumber className="w-100 mt-1" />
					</div>
				</div>
				<div className="col-5">
					<div className={`d-grid fw-bold ${props?.small ? "small" : ""}`}>
						<span className="text-muted small">Currency Links</span>
						<span className="text-primary mt-1">
							<a href="https://www.ecb.europa.eu/home/html/index.en.html" className="small">
								European central bank
							</a>
						</span>
						<span className="text-primary mt-1">
							<a
								target="blank"
								href="https://www.cba.am/am/SitePages/Default.aspx"
								className="small"
							>
								ՀՀ կենտրոնական բանկ
							</a>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}
