import React, { useState } from "react";
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";

export default function MediaFilterUser({ setSelectedDayRange, selectedDayRange, clearFilter, applyFilter }) {

  return (
    <div className="col-12">
      <div className="col-12 p-0">
        <div className="row">
          <Calendar
            value={selectedDayRange}
            onChange={setSelectedDayRange}
            colorPrimary="#0fbcf9" // added this
            colorPrimaryLight="rgba(75, 207, 250, 0.4)" // and this
            shouldHighlightWeekends
          />
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Button label="Clear" icon="pi pi-times" severity="secondary" rounded className="m-2" onClick={clearFilter}/>
        <Button label="Filter" icon="pi pi-filter" severity="primary" rounded className="m-2" onClick={applyFilter}/>
      </div>
    </div>
  )
}
