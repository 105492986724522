import React, { useState, useRef } from 'react'
import axios from 'axios'
import { base, organization_details } from '../../../api';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';

export default function OrganizationDetailsDialogForm(props) {
    const headers = { 'Content-Type': 'application/json', }
    const credentials = "same-origin"

    const [checked, setChecked] = useState(props?.data.active);
    const [state, setState] = useState(props.data);
    function edit(e) {
        axios({
            method: "PUT",
            url: base + organization_details + props?.data.id + `/`,
            data: { ...state },
            headers: headers,
            credentials: credentials,
        }).then(res => {
            props?.loadContent()
            props?.hide(false)
            props.toast.current.show({ severity: 'success', summary: 'Success', detail: 'Message Content' });
        })
            .catch((error) => {
                props?.loadContent();
                props.toast.current.show({ severity: 'warn', summary: 'Error', detail: 'Nothing is chenged' });
            })
    }
    return (
        <div className='col-12 mb-2'>
            <div className='card w-100 px-2'>
                <form className='card w-100 px-2'>

                    <div className="flex flex-column mb-4">
                        <label className="pb-2" htmlFor="org_name">Կազմակերպության անվանում</label>
                        <InputText id="org_name" className='w-100'
                            value={state.organization}
                            onChange={(e) => setState({ ...state, organization: e.target.value })}
                        />
                    </div>

                    <div className="flex flex-column mb-4">
                        <label className="pb-2" htmlFor="address">Հասցե </label>
                        <InputText id="address" className='w-100'
                            value={state.address}
                            onChange={(e) => setState({ ...state, address: e.target.value })}
                        />
                    </div>

                    <div className="flex flex-column mb-4">
                        <label className="pb-2" htmlFor="tinn">ՀՎՀՀ </label>
                        <InputText id="tinn" className='w-100'
                            value={state.tinn}
                            onChange={(e) => setState({ ...state, tinn: e.target.value })}
                        />
                    </div>

                    <div className="flex flex-column mb-4">
                        <label className="pb-2" htmlFor="position_user_1">Զբաղեցրած պաշտոնը N1 </label>
                        <InputText id="position_user_1" className='w-100'
                            value={state.position_user_1}
                            onChange={(e) => setState({ ...state, position_user_1: e.target.value })}
                        />
                    </div>

                    <div className="flex flex-column mb-4">
                        <label className="pb-2" htmlFor="position_full_name_1">Անուն ազգանուն N1 </label>
                        <InputText id="position_full_name_1" className='w-100'
                            value={state.position_full_name_1}
                            onChange={(e) => setState({ ...state, position_full_name_1: e.target.value })}
                        />
                    </div>

                    <div className="flex flex-column mb-4">
                        <label className="pb-2" htmlFor="position_user_2">Զբաղեցրած պաշտոնը N2 </label>
                        <InputText id="position_user_2" className='w-100'
                            value={state.position_user_2}
                            onChange={(e) => setState({ ...state, position_user_2: e.target.value })}
                        />
                    </div>

                    <div className="flex flex-column mb-4">
                        <label className="pb-2" htmlFor="position_full_name_2">Անուն ազգանուն N2 </label>
                        <InputText id="position_full_name_2" className='w-100'
                            value={state.position_full_name_2}
                            onChange={(e) => setState({ ...state, position_full_name_2: e.target.value })}
                        />
                    </div>

                    <div className="flex flex-column mb-4">
                        <label className="pb-2" htmlFor="position_user_3">Զբաղեցրած պաշտոնը N3 </label>
                        <InputText id="position_user_3" className='w-100'
                            value={state.position_user_3}
                            onChange={(e) => setState({ ...state, position_user_3: e.target.value })}
                        />
                    </div>

                    <div className="flex flex-column mb-4">
                        <label className="pb-2" htmlFor="position_full_name_3">Անուն ազգանուն N3 </label>
                        <InputText id="position_full_name_3" className='w-100'
                            value={state.position_full_name_3}
                            onChange={(e) => setState({ ...state, position_full_name_3: e.target.value })}
                        />
                    </div>

                    <hr className='mb-2 mt-1' />

                    <div className="flex align-items-center">
                        <Checkbox inputId="active" onChange={(e) => setState({ ...state, active: !checked })} checked={state.active} />
                        <label htmlFor="active" className="ml-2">Ակտիվացնել</label>
                    </div>
                </form>


                <div className='d-flex justify-content-center my-5'>
                    <Button
                        label={'Չեղարկել'}
                        icon={'pi pi-times'}
                        severity={'warning'}
                        rounded
                        className='mx-3'
                        onClick={e => { props?.hide(false) }}
                    />
                    <Button
                        label={'Փոփոխել'}
                        icon={'pi pi-plus'}
                        severity={'success'}
                        rounded
                        className='mx-3'
                        onClick={e => { edit() }}
                    />
                </div>
            </div>
        </div>
    )
}
