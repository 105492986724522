export function transformFolder(folder) {
  const transformedFolder = {
    key: folder.id,
    label: folder.name,
    children: [],
  };
  folder.subfolders.forEach((subfolder) => {
    transformedFolder.children.push(transformFolder(subfolder));
  });
  folder.files.forEach((file) => {
    console.log({ file });
    transformedFolder.children.push({
      key: file.id,
      icon: mapExtentionToIcon(getFileExtention(file.file.name)),
      label: file.file.name.split("/").pop(),
      url: file.file.url,
      isPublic: file.public,
      createdAt: file.created_at,
      updatedAt: file.updated_at,
    });
  });

  return transformedFolder;
}
export function getFileExtention(fileName) {
  return fileName.substr(fileName.lastIndexOf("."));
}

function mapExtentionToIcon(extention) {
  if (
    extention === ".jpg" ||
    extention === ".png" ||
    extention === ".svg" ||
    extention === ".jpeg"
  ) {
    return "pi pi-image";
  }
  if (extention == ".pdf") {
    return "pi pi-file-pdf";
  }
  return "pi pi-file";
}

export function dateToDisplayFormat(dateString) {
  const parsedDate = new Date(dateString);

  const day = parsedDate.getUTCDate();
  const month = parsedDate.getUTCMonth() + 1;
  const year = parsedDate.getUTCFullYear();

  const formattedDate = `${day}.${month}.${year}`;

  return formattedDate;
}

export const mapPublicFilesToTreeNodes = (data) => {
  const groupedFiles = data.reduce((acc, item) => {
    const projectKey = item.project.id.toString();
    if (!acc[projectKey]) {
      acc[projectKey] = {
        key: projectKey,
        label: item.project.full_name_en,
        children: [],
      };
    }

    acc[projectKey].children.push({
      key: item.id.toString(),
      icon: mapExtentionToIcon(getFileExtention(item.file)),
      label: item.name.split("/").pop(),
      url: item.file,
      createdAt: item.created_at,
      date: item.date,
      // createdAt: dateToDisplayFormat(item.created_at),
      // date: dateToDisplayFormat(item.date),
      tags: item.tags,
      project: item.project,
      locations: item.locations,
    });

    return acc;
  }, {});

  return Object.values(groupedFiles);
};
export const listsContainIntersectingField = (firstList, secondList, field) => {
  if (!firstList || firstList.length == 0) {
    return false;
  }
  if (!secondList || secondList.length == 0) {
    return false;
  }

  for (const firstListItem of firstList) {
    for (const secondListItem of secondList) {
      if (
        secondListItem[field] != undefined &&
        firstListItem[field] != undefined &&
        secondListItem[field] == firstListItem[field]
      ) {
        console.log({ firstListItem, secondListItem });

        return true;
      }
    }
  }
  return false;
};
