import React, {useState, useEffect, useRef, useCallback} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {base, budget_by_project_row_get} from "../../../../api";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FiActivity, FiAlignLeft, FiCalendar} from "react-icons/fi";
import {useLocation} from "react-router-dom";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Toast} from "primereact/toast";
import BudgetMounthlyAddForm from "./components/BudgetMounthlyAddForm";

export default function BudgetMounthly(props) {
	const {id} = useParams();
	const toast = useRef(null);

	const [state, setState] = useState();
	const location = useLocation();
	const data = location.state?.data;
	const [dialogVisibility, setDialogVisibility] = useState(false);

	const dateStringToObject = dateStr => {
		return new Date(dateStr);
	};

	const dateObjectToString = dateObj => {
		if (!dateObj) return "";
		const year = dateObj.getFullYear();
		const month = String(dateObj.getMonth() + 1).padStart(2, "0");
		const day = String(dateObj.getDate()).padStart(2, "0");
		return `${year}-${month}-${day}`;
	};

	const minimumDate = data?.minmax_range.from ? dateStringToObject(data?.minmax_range.from) : null;
	const maximumDate = data?.minmax_range.to ? dateStringToObject(data?.minmax_range.to) : null;

	const [selectedDayRange, setSelectedDayRange] = useState({
		from: minimumDate ? dateStringToObject(minimumDate) : null,
		to: maximumDate ? dateStringToObject(maximumDate) : null,
	});

	// console.log(selectedDayRange);
	const loadData = (params = null) => {
		axios.get(`${base}${budget_by_project_row_get}${id}/${params ? params : ""}`).then(res => {
			setState(res.data);
			// setBudgetByProject(res.data.filter(budget => budget.count > 0));
		});
	};

	const loadDataForThisMonth = useCallback(() => {
		loadData(
			`?from_date=${dateObjectToString(selectedDayRange.from)}&to_date=${dateObjectToString(
				selectedDayRange.to
			)}`
		);
	}, [selectedDayRange.from, selectedDayRange.to]);

	useEffect(() => {
		loadDataForThisMonth();
	}, [id]);

	const yearBody = rowData => {
		const dateString = rowData?.date;
		const date = new Date(dateString);
		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();
		return (
			<span className=" text-primary d-flex align-items-center">
				<FiCalendar className="me-2 fs-6" />
				{day}.{month}.{year}
			</span>
		);
	};
	const articleBody = rowData => {
		return (
			<span className=" text-dark d-flex align-items-center">
				<FiAlignLeft className="me-2 fs-6" /> {rowData?.budget.category?.title_am}
			</span>
		);
	};
	const codeBody = rowData => {
		return (
			<span className=" text-dark d-flex align-items-center">
				<FiActivity className="me-2 fs-6" /> {rowData?.budget.category.code}
			</span>
		);
	};
	const priceBody = rowData => {
		return (
			<span className=" text-dark d-flex align-items-center">
				<FiActivity className="me-2 fs-6" /> {parseFloat(rowData?.budget?.salary)}
			</span>
		);
	};
	return (
		<div className="col-12 p-0">
			<div className="col-12 text-end my-5">
				<Button
					label="Ավելացնել ծախս"
					rounded
					severity="primary"
					onClick={() => setDialogVisibility(true)}
				/>
			</div>
			<div className="card w-10- border-0 b-radius b-shadow p-4">
				<DataTable
					value={state}
					paginator
					rows={20}
					rowsPerPageOptions={[30, 50, 100]}
					editMode="row"
					dataKey="id"
					tableStyle={{minWidth: "50rem"}}
				>
					<Column field="date" header="Ամիս/ամսաթիվ" body={yearBody} className="col-2"></Column>
					<Column field="budget" header="Կոդ" body={codeBody} className="col-2"></Column>
					<Column
						field="budget"
						header="Հոդվածի անվանում"
						body={articleBody}
						className="col-6"
					></Column>
					<Column field="count" header="Քանակ" className="col-1"></Column>
				</DataTable>
			</div>

			<Dialog
				visible={dialogVisibility}
				modal
				style={{width: "50rem"}}
				onHide={() => {
					if (!dialogVisibility) return;
					setDialogVisibility(false);
				}}
			>
				<BudgetMounthlyAddForm
					data={state}
					loadData={loadDataForThisMonth}
					toast={toast}
					setDialogVisibility={setDialogVisibility}
				/>
			</Dialog>
			<Toast ref={toast} />
		</div>
	);
}
