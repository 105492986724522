import OwnFileNodeTemplate from "./OwnFileNodeTemplate";
import OwnFolderNodeTemplate from "./OwnFolderNodeTemplate";

export default function OwnTreeNodeTemplate({
    onSelect,
    onDeleteFile,
    onSharePressed,
    onCreateSubfolder,
    onDeleteFolder,
    onUploadPressed,
    refresh,
}) {
    return (node, options) => OwnTreeNodeTemplateBuilder(
        node,
        options,
        onSelect,
        onDeleteFile,
        onSharePressed,
        onCreateSubfolder,
        onDeleteFolder,
        onUploadPressed,
        refresh,
    )
}
function OwnTreeNodeTemplateBuilder(
    node,
    options,
    onSelect,
    onDeleteFile,
    onSharePressed,
    onCreateSubfolder,
    onDeleteFolder,
    onUploadPressed,
    refresh,
) {
    if (node.url) {
        return OwnFileNodeTemplate(node, options, onSelect, onDeleteFile, onSharePressed, refresh)
    }
    return OwnFolderNodeTemplate(
        node,
        options,
        onCreateSubfolder,
        onDeleteFolder,
        onUploadPressed,
    )
};