import React from "react";
import { Button } from "primereact/button";
import { useTranslation } from 'react-i18next';

export default function OwnFolderNodeTemplate( node, options, onCreateSubfolder, onDeleteFolder,onUploadPressed,) {
    const { t } = useTranslation();

    let label = <div className="text-dark">{node.label}</div>;
    return (
        <>
            <span className={options.className}>{label}</span>
            <div className="d-flex justify-content-center align-content-center mx-3">
                <Button
                    icon="pi pi-plus mx-3"
                    rounded
                    text
                    tooltip="Create a subfolder"
                    tooltipOptions={{ position: "top" }}
                    onClick={() => onCreateSubfolder(node)}
                />
                <Button
                    icon="pi pi-times mx-3"
                    tooltip="Delete folder"
                    tooltipOptions={{ position: "top" }}
                    rounded
                    text
                    severity="danger"
                    onClick={() => onDeleteFolder(node)}
                    />
                <Button
                    icon="pi pi-upload mx-3"
                    tooltip={t('_mediacomp_upload')}
                    tooltipOptions={{ position: "top" }}
                    rounded
                    text
                    severity="help"
                    onClick={() => onUploadPressed(node)}
                />
            </div>
        </>
    );
};