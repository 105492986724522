export default function BankingDetails(props) {
	return (
		<div className="card w-100 b-radius b-shadow mb-4 my-3">
			<div className="card-body">
				<div className="fs-4 mb-3">Banking details</div>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<span>Bank</span>
					<span>{props?.data?.bank} </span>
				</div>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<span>Bank account</span>
					<span>{props?.data?.banking_number} </span>
				</div>
			</div>
		</div>
	);
}
