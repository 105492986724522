export const Keys = [
	{
		property: "partner_name",
		description: "__list_of_variables__partner_location__",
	},
	{
		property: "partner_location",
		description: "__list_of_variables__partner_location__",
	},
	{
		property: "start_date",
		description: "__list_of_variables__start_date__",
	},
	{
		property: "end_date",
		description: "__list_of_variables__end_date__",
	},
	{
		property: "project_name",
		description: "__list_of_variables__project_name__",
	},
	{
		property: "position",
		description: "__list_of_variables__position__",
	},
	{
		property: "application_date",
		description: "__list_of_variables__application_date__",
	},
	{
		property: "contract_number",
		description: "__list_of_variables__contract_number__",
	},
	{
		property: "work_place",
		description: "__list_of_variables__work_place__",
	},
	{
		property: "probation_period",
		description: "__list_of_variables__probation_period__",
	},
	{
		property: "busy_percentage",
		description: "__list_of_variables__busy_percentage__",
	},
	{
		property: "work_day",
		description: "__list_of_variables__work_day__",
	},
	{
		property: "salary_count",
		description: "__list_of_variables__salary_count__",
	},
	{
		property: "salary",
		description: "__list_of_variables__salary__",
	},
	{
		property: "organization_head_position",
		description: "__list_of_variables__organization_head_position__",
	},
	{
		property: "organization_head_name",
		description: "__list_of_variables__organization_head_name__",
	},
	{
		property: "organization_accauntant_position",
		description: "__list_of_variables__organization_accauntant_position__",
	},
	{
		property: "organization_accauntant_name",
		description: "__list_of_variables__organization_accauntant_name__",
	},
	{
		property: "organization_hr_position",
		description: "__list_of_variables__organization_hr_position__",
	},
	{
		property: "organization_hr_name",
		description: "__list_of_variables__organization_hr_name__",
	},
	{
		property: "organization_name",
		description: "__list_of_variables__organization_name__",
	},
	{
		property: "organization_tinn",
		description: "__list_of_variables__organization_tinn__",
	},
	{
		property: "organization_address",
		description: "__list_of_variables__organization_address__",
	},
	{
		property: "order_number",
		description: "__list_of_variables__order_number__",
	},
	{
		property: "salary_count_with_words",
		description: "__list_of_variables__salary_count_with_words__",
	},
	{
		property: "unit_of_measurement ",
		description: "__list_of_variables__unit_of_measurement __",
	},
	{
		property: "salary_with_words",
		description: "__list_of_variables__salary_with_words__",
	},
	{
		property: "total_salary",
		description: "__list_of_variables__total_salary__",
	},
	{
		property: "total_salary_count_with_words",
		description: "__list_of_variables__total_salary_count_with_words__",
	},
	{
		property: "act_date",
		description: "__list_of_variables__act_date__",
	},
	{
		property: "act_count",
		description: "__list_of_variables__act_count__",
	},
	{
		property: "act_count_with_words",
		description: "__list_of_variables__act_count_with_words__",
	},
	{
		property: "act_start_date",
		description: "__list_of_variables__act_start_date__",
	},
	{
		property: "act_end_date",
		description: "__list_of_variables__act_end_date__",
	},
	{
		property: "act_total_salary",
		description: "__list_of_variables__act_total_salary__",
	},
	{
		property: "workplace",
		description: "__list_of_variables__workplace__",
	},
	{
		property: "contract_date",
		description: "__list_of_variables__contract_date__",
	},
	{
		property: "project_number",
		description: "__list_of_variables__project_number__",
	},
	{
		property: "organization_bank",
		description: "__list_of_variables__organization_bank__",
	},
	{
		property: "organization_bank_account_number",
		description: "__list_of_variables__organization_bank_account_number__",
	},
	{
		property: "partner_passport_number",
		description: "__list_of_variables__partner_passport_number__",
	},
	{
		property: "partner_bank",
		description: "__list_of_variables__partner_bank__",
	},
	{
		property: "partner_bank_account_number",
		description: "__list_of_variables__partner_bank_account_number__",
	},
	{
		property: "date_of_dismissal",
		description: "__list_of_variables__date_of_dismissal__",
	},
	{
		property: "dismissal_application_date",
		description: "__list_of_variables__dismissal_application_date__",
	},
	{
		property: "notification_date",
		description: "__list_of_variables__notification_date__",
	},
	{
		property: "date_of_additional_agreement",
		description: "__list_of_variables__date_of_additional_agreement__",
	},
	{
		property: "number_of_additional_agreement",
		description: "__list_of_variables__number_of_additional_agreement__",
	},
	{
		property: "validity_date_of_additional_agreement",
		description: "__list_of_variables__validity_date_of_additional_agreement__",
	},
	{
		property: "report_date",
		description: "__list_of_variables__report_date__",
	},
];
