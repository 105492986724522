import React, { useState } from 'react';

export const WithHover = ({ children }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <span
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {typeof children === 'function' ? children(isHovered) : children}
        </span>
    );
};