
import React, { useEffect, useRef, useState } from 'react';
import { base, acc, bank, currency } from '../../api';
import Nothing from '../../assets/images/illustrations/girl-unlock-password-light.png'
import axios from 'axios';
import {FiPlus, FiMoreVertical, FiCheck, FiInfo} from 'react-icons/fi'
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { AutoComplete } from "primereact/autocomplete";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';


export default function AddAcc() {
    const toast = useRef(null);
    const headers = {'Content-Type': 'application/json',}
    const credentials = "same-origin"
    const [visible, setVisible] = useState(false);

    const [ stateAM, setStateAM ] = useState(null);
    const [ stateEN, setStateEN ] = useState(null);

    const [ currentState, setCurrentState ] = useState(null);
    const [ editCurrentState, setEditCurrentState ] = useState(null);

    const [ accountNumber, setAccountNumber ] = useState()

    const [bankAccount, setBankAccount] = useState();
    const [selectedBankAccount, setSelectetBankAccount] = useState();
    const [filteredBankAccount, setFilteredBankAccount] = useState();

    const [dropdownCurrency, setDropdownCurrency] = useState();
    const [selectedDropdownCurrency, setSelectetDropdownCurrency] = useState();

    const [editStatebankAccount, setEditStatebankAccount] = useState();
    const [editStateselectedBankAccount, setEditStateSelectetBankAccount] = useState();
    const [editStatefilteredBankAccount, setEditStateFilteredBankAccount] = useState();

    const [editStatedropdownCurrency, setEditStateDropdownCurrency] = useState();
    const [editStateselectedDropdownCurrency, setEditStateSelectetDropdownCurrency] = useState();

console.log(editCurrentState);

    function loadContent(){
        axios.all([
          axios.get(base + bank),
          axios.get(base + currency),
          axios.get(base + acc)
        ]).then(axios.spread((...response) => {
            setBankAccount(response[0].data)
            setDropdownCurrency(response[1].data)
            setCurrentState(response[2].data)
            setEditStatebankAccount(response[0].data)
            setEditStateDropdownCurrency(response[1].data)
          }))
      }


    const searchBankAccount = (e) => {
        let query = e.query;
        let _filteredBankAccount = bankAccount?.filter((item) => item?.title_am?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
        setFilteredBankAccount(_filteredBankAccount);
    };

    const editStateSearchBankAccount = (e) => {
        let query = e.query;
        let _filteredBankAccount = bankAccount?.filter((item) => item?.title_am?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
        setFilteredBankAccount(_filteredBankAccount);
    };

    useEffect(() => { loadContent()}, []);

    function add(){
        axios({
            method: "POST",
            url: base + acc,
            data:{
                bank_id: selectedBankAccount?.id,
                currency_id: selectedDropdownCurrency?.id,
                acc:accountNumber
            },
            headers: headers,
            credentials: credentials,
        })
        .then( res => {
            loadContent()
            setAccountNumber()
            setSelectetBankAccount()
            setSelectetDropdownCurrency()
            toast.current.show({severity:'success', summary: 'Success', detail:'The item is added successfully', life: 3000});
        })
        .catch(error => {
            toast.current.show({severity:'error', summary: 'Error', detail:'Please add something than press the button ', life: 3000});
            loadContent()
            setAccountNumber()
            setSelectetBankAccount()
            setSelectetDropdownCurrency()
        })
    }

    function getCurrent(){
        axios.get(base + acc + localStorage.getItem('item') + '/').then((res => {
            setEditCurrentState(res.data)
            // setSelectetBankAccount(res.data)
            console.log(res.data);
        }))
    }

    function edit(e){
        axios({
          method: "PUT",
          url: base + acc + localStorage.getItem('item') + `/`,
          data:{
            bank_id: selectedBankAccount?.id,
            currency_id: selectedDropdownCurrency?.id,
            acc: editCurrentState.acc
          },
          headers: headers,
          credentials: credentials,
      }).then( res => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Message Content' });
        loadContent()
        setCurrentState(null)
        setVisible(false)
    })
    .catch((error) => {
        loadContent();
        toast.current.show({ severity: 'warn', summary: 'Error', detail: 'Nothing is chenged' });
    })
      }
      function remove(){
        axios.delete(base + acc + localStorage.getItem('item') + `/`, {headers: headers})
        .then((res) => {
            loadContent()
            localStorage.removeItem('item')
        })
        .catch((error) => {loadContent()})
    }

    const accept = () => {
        toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
        remove()
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const confirmDelete = (e) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject

        });
    };

    const footerbuttons = (
        <div>
            <Button label="Cancel" icon="pi pi-times" onClick={() => setVisible(false)} className="btn small px-4 py-2 b-radius p-button-text" />
            <Button label="Save" icon="pi pi-check" onClick={(e) => {setVisible(false); edit(e)}} className='btn btn-label-primary small px-4 py-2 b-radius' />
        </div>
    );

    return (

    <div className='container-fluid'>
        <div className="col-12 p-0">
            <div className="card w-100 b-radius b-shadow p-3">
                <div className="col-md-8 col-12 mx-auto text-center mt-3 mb-4">
                    <p className="m-0 small fst-italic text-muted">
                        Արտարծույքները կարող եք ավելացնել ներքոհիշհալ դաշտում նշելով ցանկացած արտարժուք, որից հետո անհրաժեշտ է սեղմել "Ավելացնել" կոճակը: Իսկ եթե արտարժուքն ավելացնելուց տեղի է ունեցել վրիպում ապա ավելացված արտաժույքների դաշտում սեղմեք փոփոխել կոճակը և կատարեք փոփխությունները: Ցանկանում եք հեռացնել ոչ պիտանի արտարժուքը ապա սեղմեք ռեռացնել կոճակը
                    </p>
                    <hr/>
                </div>

                <div className='col-md-4 col-sm-8 col-10 mx-auto my-3'>
                    <div className="card b-radius b-shadow p-3">
                        <p className='text-muted mb-0 text-uppercase'><FiInfo className='me-2'/>Ավելացնել տեղեկատու</p>
                        <hr />
                        <div className="col-12 mb-4">
                            <label className="mb-2">Բանկ</label>
                            <AutoComplete
                                dropdown
                                className='col-12 p-0'
                                placeholder="Ընտրել ցանկից"
                                field={"title_am"}
                                completeMethod={searchBankAccount}
                                suggestions={filteredBankAccount}
                                value={selectedBankAccount}
                                onChange={(e) => setSelectetBankAccount(e.value)}
                                itemTemplate={(item) => (
                                <div className="d-flex align-items-top">
                                    <div className="d-grid">
                                        <span className="text-primary">{item.title_am?.slice(0,30)} </span>
                                        <span className="text-secondary small"> {item.title_en?.slice(0,30) ?? "not provided"} </span>
                                    </div>
                                </div>
                                )}
                            />
                        </div>
                        <div className="mb-5 col-12 p-2">
                            <label className="mb-2">հաշվեհամար</label>
                            <div className="d-flex align-items-center p-inputgroup py-1 border-0">
                                <InputText
                                    value={accountNumber}
                                    onChange={e => setAccountNumber(e.target.value)}/>
                                <Dropdown
                                    value={selectedDropdownCurrency}
                                    onChange={(e) => setSelectetDropdownCurrency(e.value)}
                                    options={dropdownCurrency}
                                    optionLabel="title_am"
                                    placeholder={selectedDropdownCurrency?.title_am ? selectedDropdownCurrency?.title_am : '.'}
                                    className="p-0 m-0"
                                    style={{backgroundColor:'transparent'}}
                                />
                            </div>
                        </div>
                        <Button label='Save' severity='primary' rounded onClick={e => add(e)}/>
                    </div>
                </div>

                <div className="row m-0 pb-5 justify-content-center">
                <ScrollPanel style={{ width: '100%', height: '90vh' }}>
                    {currentState ?

                        <div className='row'>
                            {currentState?.map((data, i) =>
                                <div className="col-3 mx-auto mb-1">
                                    <div className="card w-100 b-radius b-shadow p-3">
                                        <div className="d-flex align-items-start justify-content-between">
                                            <div className='d-grid'>
                                                <div className="text-dark opacity-50 fw-bold mb-1">{data?.acc} ({data?.currency?.title_am})</div>
                                                <div className="text-dark mb-1">{data?.bank?.title_am}</div>
                                                <div className="text-muted small">{data?.bank?.title_en}</div>
                                            </div>
                                            <div className="dropdown">
                                                <button className="btn p-0" type="button" id={'data-'+data.id} data-bs-toggle="dropdown" aria-expanded="false">
                                                    <FiMoreVertical/>
                                                </button>
                                                <div className="dropdown-menu dropdown-menu-end px-3" aria-labelledby={'data-'+data.id}>
                                                    <a className="dropdown-item my-2" href="javascript:void(0);" onClick={e => {setVisible(true); localStorage.setItem('item', data.id); getCurrent(data.id)}} >View More</a>
                                                    <a className="dropdown-item my-2" href="javascript:void(0);" onClick={e => {confirmDelete(data.id); localStorage.setItem('item', data.id)}}>Delete</a>
                                                </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        :
                        <div className='card w-100 text-center align-items-center d-flex justify-content-center' style={{minHeight:'300px'}}>
                            <div><img src={Nothing} alt="" style={{maxHeight:'180px'}}/></div>
                            <div className='mt-5 text-muted'>NOTHING TO SHOW YET</div>
                        </div>
                    }
                    </ScrollPanel>
                </div>
            </div>
        </div>

    <Toast ref={toast}  />

    <ConfirmDialog />
    <Dialog header={"Փոփոխել"} icon="pi pi-external-link"  visible={visible} style={{ width: '25vw' }} onHide={() => setVisible(false)} footer={footerbuttons}>
        <hr className='mt-0 pt-0'/>
        <div className="col-12 mb-4">
            <label className="mb-2">Բանկ</label>
            <AutoComplete
                dropdown
                className='col-12 p-0'
                placeholder="Ընտրել ցանկից"
                field={"title_am"}
                completeMethod={searchBankAccount}
                suggestions={filteredBankAccount}
                value={selectedBankAccount}
                onChange={(e) => setSelectetBankAccount(e.value)}
                itemTemplate={(item) => (
                <div className="d-flex align-items-top">
                    <div className="d-grid">
                        <span className="text-primary">{item.title_am?.slice(0,30)} </span>
                        <span className="text-secondary small"> {item.title_en?.slice(0,30) ?? "not provided"} </span>
                    </div>
                </div>
                )}
            />
        </div>
        <div className="mb-5 col-12 p-2">
            <label className="mb-2">հաշվեհամար</label>
            <div className="d-flex align-items-center p-inputgroup py-1 border-0">
                <InputText
                    value={editCurrentState?.acc}
                    onChange={e => setEditCurrentState({...editCurrentState, acc: e.target.value})}/>
                <Dropdown
                    value={selectedDropdownCurrency}
                    onChange={(e) => setSelectetDropdownCurrency(e.value)}
                    options={dropdownCurrency}
                    optionLabel="title_am"
                    placeholder={selectedDropdownCurrency}
                    className="p-0 m-0"
                    style={{backgroundColor:'transparent'}}
                />
            </div>
        </div>

    </Dialog>

        </div>
  )
}
