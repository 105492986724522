import {Avatar} from "primereact/avatar";

export const nameBody = rowData => {
	return (
		<div className="flex align-items-center">
			<Avatar label={rowData.gender?.substring(0, 1)} className={rowData.gender === "Male" ? "me-3 btn-label-primary" : "me-3 btn-label-danger"} size="large" shape="circle" />
			<div className="d-grid">
				<div className="fw-bold mb-1">{rowData.full_name_am}</div>
				<div className="my-1">{rowData.full_name_en}</div>
				<div className="text-primary fst-italic">{rowData.address_am}</div>
			</div>
		</div>
	);
};

export const detailsBody = rowData => {
	return (
		<div className="d-grid">
			<div className="fw-bold">{rowData.phone}</div>
			<div className="my-1">{rowData.email}</div>
			<div className="text-primary fst-italic">{rowData.birth_date}</div>
		</div>
	);
};

export const bankDetailsBody = rowData => {
	return (
		<div className="d-grid">
			<div className="fw-bold">{rowData?.bank?.title_am}</div>
			<div className="my-1">{rowData.banking_number}</div>
			<div className="text-primary fst-italic">
				{rowData.passport} / {rowData.social_card}
			</div>
		</div>
	);
};

export const statusDetailsBody = rowData => {
	return (
		<div className="d-grid">
			<div className="fw-bold">
				{rowData?.languages?.map((item, index) => (
					<span key={index} className="me-2">
						{item.title_am}
					</span>
				))}
			</div>
			<div className="my-1">
				{rowData?.sphere?.map((item, index) => (
					<span key={index} className="me-2">
						{item.title_am}
					</span>
				))}
			</div>
			<div className="">
				{rowData?.statuses?.map((item, index) => (
					<span key={index} className="me-2">
						{item.title_am}
					</span>
				))}
			</div>
		</div>
	);
};

export const companyNameBody = rowData => {
	return (
		<div className="flex align-items-center">
			<Avatar label={rowData.full_name_am?.substring(0, 1)} className="p-overlay-badge me-3 text-uppercase text-white" size="large" shape="circle" />
			<div className="d-grid">
				<div className="fw-bold mb-0 text-primary">
					«{rowData.full_name_am}» {rowData?.organization_types?.title_am}
				</div>
				<div>
					"{rowData.company_en}" {rowData?.organization_types?.title_en}{" "}
				</div>
				<div>
					{rowData.legal_head_am} , {rowData?.legal_head_en}{" "}
				</div>
				<div>
					{rowData.legal_full_name_am} , {rowData?.legal_full_name_en}{" "}
				</div>
			</div>
		</div>
	);
};

export const companyDetailsBody = rowData => {
	return (
		<div className="d-grid">
			<div className="fw-bold mb-0">{rowData.legal_head_am}</div>
			<div className="">{rowData.email}</div>
		</div>
	);
};
