import React, {useState, useEffect, useRef} from "react";

import {BiCollapse, BiMoon, BiSun, BiRevision, BiExpand} from "react-icons/bi";
import {Link} from "react-router-dom";
import {Avatar} from "primereact/avatar";
import {Badge} from "primereact/badge";
import {Button} from "primereact/button";
import fakeAvatar_7 from "../../assets/images/avatars/1.png";
import FlagIcon from "../../FlagIconFactory";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {useAuth} from "../../context/AuthContext";
import {ColorizedAvatars} from "../ui/elements/ColorizedAvatars";

export default function NavBar(props) {
	const {profile, logout, time, setTime} = useAuth();
	const intervalRef = useRef();

	const [screen, setScreen] = useState(true);
	const [lngChanger, setLngChanger] = useState();
	const [dark, setDark] = useState(false);
	const {t} = useTranslation();
	function handleClick(lang) {
		i18next.changeLanguage(lang);
	}

	const toggle = () => {};

	function openFullscreen() {
		if (!document.fullscreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen();
			} else if (document.documentElement.msRequestFullscreen) {
				document.documentElement.msRequestFullscreen();
			}
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		}
	}

	useEffect(() => {
		intervalRef.current = setInterval(() => {
			setTime(t => t - 1000);
		}, 1000);
		return () => clearInterval(intervalRef.current);
	}, [time]);

	useEffect(() => {
		if (time <= 0) {
			clearInterval(intervalRef.current);
			logout();
		}
	}, [time]);

	const timeLeft = {
		minutes: Math.floor(time / 60 / 1000) % 60,
		seconds: Math.floor(time / 1000) % 60,
	};

	return (
		<nav className="navbar navbar-expand position-sticky top-0 w-100 b-shadow b-radius p-0 justify-content-between">
			<ul className="navbar-nav d-flex col-md-6 mb-2 mb-lg-0 justify-content-start align-items-center p-2">
				<li className="nav-item d-xl-none d-block ms-2">
					<Button
						rounded
						outlined
						severity="secondary"
						icon={props?.asideToggler ? "pi pi-arrow-left" : "pi pi-arrow-right"}
						onClick={() => {
							props?.setAsideToggler(!props?.asideToggler);
							localStorage.setItem("aside", JSON.stringify(!props.asideToggler));
						}}
					/>
				</li>
			</ul>
			<ul className="navbar-nav d-flex col-md-6 mb-2 mb-lg-0 justify-content-end align-items-center p-2">
				<li className="nav-item"></li>
				<li className="nav-item d-flex align-items-center">
					<Button
						className="p-2 fs-4 me-2 animate__animated animate__bounce animate__faster"
						text
						outlined
						rounded
						tooltip="Reset counter"
						tooltipOptions={{position: "bottom"}}
						severity="secondary"
						onClick={e => {
							setTime(600000);
						}}
					>
						<BiRevision />
					</Button>
					<div className={timeLeft.minutes <= 1 ? `fw-bold fs-4 text-danger me-3 flash_animation` : timeLeft.minutes <= 3 ? `fw-bold fs-4 text-warning me-3` : timeLeft.minutes <= 7 ? `fw-bold fs-4 text-primary me-3` : timeLeft.minutes <= 10 ? `fw-bold fs-4 text-success me-3` : "null"}>
						{timeLeft.minutes < 10 ? "0" + timeLeft.minutes : timeLeft.minutes}:{timeLeft.seconds < 10 ? "0" + timeLeft.seconds : timeLeft.seconds}
					</div>
				</li>
				<li className="nav-item">
					<Avatar
						size="large"
						shape="circle"
						className="bg-transparent"
						onClick={e => {
							openFullscreen();
							setScreen(!screen);
						}}
					>
						{screen ? <BiExpand /> : <BiCollapse />}
					</Avatar>
				</li>
				<li className="nav-item">
					<Avatar size="large" shape="circle" className="bg-transparent" onClick={e => toggle()}>
						{dark ? <BiSun /> : <BiMoon />}
					</Avatar>
				</li>
				<li className="nav-item mx-1">
					{lngChanger ? (
						<button
							type="button"
							className="btn p-0"
							onClick={() => {
								handleClick("en-US");
								setLngChanger(false);
							}}
						>
							<FlagIcon code={"us"} size={"2x"} className="b-radius" />
						</button>
					) : (
						<button
							type="button"
							className="btn p-0"
							onClick={() => {
								handleClick("am_AM");
								setLngChanger(true);
							}}
						>
							<FlagIcon code={"am"} size={"2x"} className="b-radius" />
						</button>
					)}
				</li>

				<li className="nav-item mx-3 dropdown">
					<a className="dropdown-toggle d-flex align-items-center" id="drop" data-bs-toggle="dropdown" aria-expanded="false">
						<i className="pi pi-bell p-overlay-badge" style={{fontSize: "1.6rem"}}>
							<Badge value="7" />
						</i>
					</a>
					<ul className="dropdown-menu dropdown-menu-end p-3 mt-4 list-unstyled px-3" aria-labelledby="drop" style={{minWidth: "380px"}}>
						<li className="list-group border-bottom mb-3">
							<a className="d-flex align-items-center">
								<div className="flex-shrink-0">
									<Avatar image={fakeAvatar_7} className="p-overlay-badge" size="large" shape="circle" style={{borderRadius: "100%", padding: "1px", overflow: "visible"}}>
										<Badge value="2" />
									</Avatar>
								</div>
								<div className="flex-grow-1 ms-3">
									<h6 className="text-truncate m-0">Amy Johnson</h6>
									<p className="text-truncate mb-0 text-muted">Could you please help me to find it out? 🤔</p>
								</div>
								<small className="text-muted mb-auto">1 day ago</small>
							</a>
						</li>

						<li className="list-group mb-3 pt-2">
							<a className="d-flex align-items-center justify-content-center text-center">
								<div className="flex-grow-1 ms-3">
									<h6 className="text-truncate m-0">More ...</h6>
								</div>
							</a>
						</li>
					</ul>
				</li>

				<li className="nav-item d-flex align-items-center mx-3">
					<Link to={`/accounts/${profile?.id}`} className="d-flex align-items-center">
						{profile?.profile?.avatar ? <Avatar image={profile?.profile?.avatar} size="large" shape="circle" /> : <Avatar label={profile?.username?.substring(0, 1)} size="large" shape="circle" className="text-white text-uppercase" style={profile && {backgroundColor: ColorizedAvatars(profile?.username?.substring(0, 2))}} />}
						<div className="d-none d-lg-grid ms-1">
							<span className="text-primary fw-bold ms-2">{profile?.username}</span>
							{profile?.profile?.partner?.full_name_en && <span className="text-muted ms-2 small">{profile?.profile?.partner?.full_name_en}</span>}
						</div>
					</Link>
				</li>
			</ul>
		</nav>
	);
}
