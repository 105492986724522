import React, {useState} from "react";
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {InputNumber} from "primereact/inputnumber";
import {Button} from "primereact/button";

export default function NewActAddForm({setDialogVisibility}) {
	const [contractData, setContractData] = useState({
		contractNumber: "",
		startDate: null,
		quantity: 0,
		unitPrice: 0,
		totalPrice: 0,
		items: [{itemName: "", quantity: 0, unitPrice: 0}],
	});

	const handleChange = (e, field) => {
		setContractData({...contractData, [field]: e.value || e.target.value});
	};

	const handleItemChange = (index, field, value) => {
		const updatedItems = [...contractData.items];
		updatedItems[index][field] = value;
		setContractData({...contractData, items: updatedItems});
	};

	const addItem = () => {
		setContractData({
			...contractData,
			items: [...contractData.items, {itemName: "", quantity: 0, unitPrice: 0}],
		});
	};

	const removeItem = index => {
		const updatedItems = contractData.items.filter((_, i) => i !== index);
		setContractData({...contractData, items: updatedItems});
	};

	return (
		<div className="">
			<div className="row mb-3">
				<div className="col-md-6">
					<label htmlFor="contractNumber" className="form-label">
						Contract Number
					</label>
					<InputText
						id="contractNumber"
						value={contractData.contractNumber}
						onChange={e => handleChange(e, "contractNumber")}
						className="col-12 py-3"
					/>
				</div>

				<div className="col-md-6">
					<label htmlFor="startDate" className="form-label">
						Start Date
					</label>
					<Calendar
						id="startDate"
						value={contractData.startDate}
						onChange={e => handleChange(e, "startDate")}
						dateFormat="yy-mm-dd"
						className="col-12 p-0"
					/>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-md-4">
					<label htmlFor="quantity" className="form-label">
						Quantity
					</label>
					<InputNumber
						id="quantity"
						value={contractData.quantity}
						onValueChange={e => handleChange(e, "quantity")}
						className="col-12 p-0"
					/>
				</div>

				<div className="col-md-4">
					<label htmlFor="unitPrice" className="form-label">
						Unit Price
					</label>
					<InputNumber
						id="unitPrice"
						value={contractData.unitPrice}
						onValueChange={e => handleChange(e, "unitPrice")}
						className="col-12 p-0"
					/>
				</div>

				<div className="col-md-4">
					<label htmlFor="totalPrice" className="form-label">
						Total Price
					</label>
					<InputNumber
						id="totalPrice"
						value={contractData.totalPrice}
						onValueChange={e => handleChange(e, "totalPrice")}
						mode="currency"
						currency="USD"
						className="col-12 p-0"
					/>
				</div>
			</div>

			<h4 className="mb-3 fw-bold text-secondary"> Անվանացանկ???</h4>
			{contractData.items.map((item, index) => (
				<div key={index} className="row mb-3 align-items-end">
					<div className="col-md-5">
						<label htmlFor={`itemName-${index}`} className="form-label">
							Item Name
						</label>
						<InputText
							id={`itemName-${index}`}
							value={item.itemName}
							onChange={e => handleItemChange(index, "itemName", e.target.value)}
							className="form-control rounded-3 py-3"
						/>
					</div>
					<div className="col-md-3">
						<label htmlFor={`quantity-${index}`} className="form-label">
							Quantity
						</label>
						<InputNumber
							id={`quantity-${index}`}
							value={item.quantity}
							onValueChange={e => handleItemChange(index, "quantity", e.value)}
							className="col-12 p-0"
						/>
					</div>
					<div className="col-md-3">
						<label htmlFor={`unitPrice-${index}`} className="form-label">
							Unit Price
						</label>
						<InputNumber
							id={`unitPrice-${index}`}
							value={item.unitPrice}
							onValueChange={e => handleItemChange(index, "unitPrice", e.value)}
							className="col-12 p-0"
						/>
					</div>
					<div className="col-md-1">
						<Button
							icon="pi pi-minus"
							rounded
							className="p-button-danger"
							onClick={() => removeItem(index)}
						/>
					</div>
				</div>
			))}

			<div className="mb-4 col text-end">
				<Button
					label="Add Item"
					icon="pi pi-plus"
					rounded
					className="p-button-secondary col"
					onClick={addItem}
					style={{backgroundColor: "#6c757d", color: "#ffffff", padding: "10px"}}
				/>
			</div>

			<div className="d-flex justify-content-center gap-3">
				<Button
					label="Cancel"
					icon="pi pi-times"
					rounded
					className="p-button-secondary"
					style={{backgroundColor: "#6c757d", color: "#ffffff", padding: "10px 20px"}}
					onClick={() => {
						setContractData({
							contractNumber: "",
							startDate: null,
							quantity: 0,
							unitPrice: 0,
							totalPrice: 0,
							items: [{itemName: "", quantity: 0, unitPrice: 0}],
						});
						setDialogVisibility(false); // Close the dialog
					}}
				/>
				<Button
					label="Save"
					icon="pi pi-check"
					rounded
					style={{backgroundColor: "#6f42c1", color: "#ffffff", padding: "10px 20px"}}
					onClick={() => console.log("Contract Data:", contractData)}
				/>
			</div>
		</div>
	);
}
