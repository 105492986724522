import React from 'react'
import { Button } from 'primereact/button';

export default function PartnersDashboardStatisticCard(props) {
  return (
    <div className='col stretch-card'>
      <div className="card w-100 b-radius b-shadow p-4 border-0">
        <div className="row align-items-center justify-content-between">
          <div className="col-3">
            <Button icon={props.icon} rounded severity={props.color} disabled/>
          </div>
          <div className='col-9'> 
            <div>{props.title}</div>
            <div className="fs-4 fw-bold">{props.data}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
