import lightTheme from "primereact/resources/themes/lara-light-indigo/theme.css";
// import darkTheme from "primereact/resources/themes/lara-dark-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";

import "bootstrap";
import "./assets/css/bootstrap-utilities.css";
import "./assets/css/core.css";
import "./assets/fonts/calibri.css";
import "./assets/css/styles.css";
import "./assets/css/animate.css";
import { FileUploadProvider } from "./context/FileUploadContext";

window.scrollTo(0, 0);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <AuthProvider>
      <FileUploadProvider>
        <Suspense fallback={"Loading"}>
          <BrowserRouter>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </FileUploadProvider>
    </AuthProvider>
  </React.StrictMode>
);
