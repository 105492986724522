import React, {useEffect, useState} from "react";
import {Routes, Route} from "react-router-dom";
import {useTranslation} from "react-i18next";
import "./i18next";
import InformersRoutes from "./components/informers/InformersRoutes";
import UsersDashboard from "./components/UsersDashboard";

import PartnersRoutes from "./components/partners/PartnersRoutes";
import ConnectionStatus from "./components/ui/elements/ConnectionStatus";
import ProjectsDashboard from "./components/projects/ProjectsDashboard";
import SingleProject from "./components/projects/SingleProject";
import EditProject from "./components/projects/EditProject";
import NewProject from "./components/projects/NewProject";
import BeneficiariesDashboard from "./components/beneficiaries/BeneficiariesDashboard";
import BeneficareAddForm from "./components/beneficiaries/components/BeneficareAddForm";
import BeneficareEditViewForm from "./components/beneficiaries/components/BeneficareEditViewForm";
import MediaIndex from "./components/MediaComp/MediaIndex";
import TicketSystem from "./components/TicketSystem/TicketSystem";
import DocumentationIndex from "./components/documentation/DocumentationIndex";
//Dashboards
import MainPageDashboard from "./components/MainPageDashboard";

//User interactions
import Login from "./components/users/user_phone_login/Login";
import Logout from "./components/users/user_phone_login/Logout";
import AccountDetail from "./components/users/AccountDetail";

// UI Decorations
import NavBar from "./components/ui/NavBar";
import Aside from "./components/ui/Aside";
import Footer from "./components/ui/Footer";
import ErrorPage from "./components/ui/ErrorPage";
import CoWorkersDashboard from "./components/co_workers/CoWorkersDashboard";
import UserFakeInterface from "./components/users/UserFakeInterface";

import {useAuth} from "./context/AuthContext";
import {useConnectionStatus} from "./hooks/useConnectionStatus";
import UsersRatingBoard from "./components/user_rating/UsersRatingBoard";
import BudgetMounthly from "./components/projects/Components/Budget/BudgetMounthly";
import VolunteersDashboard from "./components/volunteers/VolunteersDashboard";
import VolunteersForm from "./components/volunteers/VolunteersForm";

export default function App() {
	const {auth, mobileAuth, profile, passwordChangeRequired} = useAuth();

	const {t} = useTranslation();
	const [asideToggler, setAsideToggler] = useState(true);
	const status = useConnectionStatus();

	useEffect(() => {
		const handleResize = () => {
			setAsideToggler(window.matchMedia("(min-width: 1199px)").matches);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div className="d-flex flex-column h-100">
			<div className="content-wrapper">
				{auth ? (
					!passwordChangeRequired && !mobileAuth ? (
						<div className="col-sm-12 p-0">
							<div className="d-flex">
								<Aside asideToggler={asideToggler} setAsideToggler={setAsideToggler} />
								<div className="w-100 mx-auto position-sticky float-start px-3 d-flex flex-column p-0">
									<NavBar asideToggler={asideToggler} setAsideToggler={setAsideToggler} profile={profile} />
									<div className="container-fluid p-0 mt-3">
										{status === false && <ConnectionStatus />}

										{status === true && (
											<Routes>
												<Route path="/" element={<MainPageDashboard profile={profile} />} />
												<Route path="/projects_dashboard" element={<ProjectsDashboard />} />
												<Route exact path="/accounts/:id" element={<AccountDetail />} />
												<Route path="/users-dashboard" element={<UsersDashboard />} />
												<Route path="/partners/*" element={<PartnersRoutes />} />
												<Route path="/co_workers/" element={<CoWorkersDashboard />} />
												<Route path="/co_workers/:id" element={<AccountDetail />} />
												<Route path="/informers/*" element={<InformersRoutes />} />
												<Route path="/project/:id" element={<SingleProject />} />
												<Route path="/project/new_project" element={<NewProject />} />
												<Route path="/project/edit_project" element={<EditProject />} />
												<Route path="/beneficiaries" element={<BeneficiariesDashboard />} />
												<Route path="/beneficiaries/new_event" element={<BeneficareAddForm />} />
												<Route path="/beneficiaries/edit_event/:id" element={<BeneficareEditViewForm />} />
												<Route path="/documentation" element={<DocumentationIndex />} />
												<Route path="/volunteers" element={<VolunteersDashboard />} />
												<Route path="/volunteers/add_new" element={<VolunteersForm />} />
												<Route path="/volunteers/:id" element={<VolunteersForm />} />
												<Route path="/budget/mounthly/:id" element={<BudgetMounthly />} />
												<Route path="/user_rating" element={<UsersRatingBoard />} />
												<Route path="/tickets" element={<TicketSystem />} />
												<Route path="/media" element={<MediaIndex t={t} />} />
												<Route path="*" element={<ErrorPage />} />
												<Route path="/login" element={<Login />} />
												<Route path="/logout" element={<Logout />} />
											</Routes>
										)}
									</div>
									{/* <MessangerSideBar /> */}
									<Footer />
								</div>
								{/* <AppSettings/> */}
							</div>
						</div>
					) : (
						<UserFakeInterface />
					)
				) : (
					<Login />
				)}
			</div>
		</div>
	);
}
