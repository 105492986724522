import React, {useState, useEffect, useRef} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Toast} from "primereact/toast";
import {ProductService} from "../../projects/Components/Budget/components/json";
import {Dialog} from "primereact/dialog";

// import {base, expances_row_by_project} from "../../../../../api";
// import axios from "axios";
import {Button} from "primereact/button";
import {Tooltip} from "primereact/tooltip";
import NewActAddForm from "./NewActAddForm";

export default function DataTableDataACT(props) {
	const [products, setProducts] = useState([]);
	const [dialogVisibility, setDialogVisibility] = useState(false);
	const [expandedRows, setExpandedRows] = useState(null);
	const toast = useRef(null);

	useEffect(() => {
		ProductService.getProductsWithOrdersSmall().then(data => setProducts(data));
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const onRowExpand = event => {};

	const onRowCollapse = event => {};

	const formatCurrency = value => {
		return value.toLocaleString("en-US", {style: "currency", currency: "USD"});
	};

	const amountBodyTemplate = rowData => {
		return formatCurrency(rowData.amount);
	};

	const searchBodyTemplate = () => {
		return <Button icon="pi pi-search" />;
	};

	const imageBodyTemplate = rowData => {
		return (
			<div className="d-flex ">
				<Button
					icon={"pi pi-download"}
					rounded
					severity="success"
					className="mx-1"
					placeholder="Top"
					tooltip="Ներբեռնել ֆայլը"
					tooltipOptions={{position: "top"}}
				/>
				<Button
					icon={"pi pi-upload"}
					rounded
					severity="primary"
					className="mx-1"
					placeholder="Top"
					tooltip="Վերբեռնել հաստատված ֆայլը"
					tooltipOptions={{position: "top"}}
				/>
			</div>
		);
	};

	const priceBodyTemplate = rowData => {
		return formatCurrency(rowData.price);
	};

	const allowExpansion = rowData => {
		return rowData.orders.length > 0;
	};

	const rowExpansionTemplate = data => {
		return (
			<div className="p-3">
				<DataTable value={data.orders}>
					<Column field="product_name" header="Անուն" bodyClassName="lowercase"></Column>
					<Column field="quantity" header="քանակ"></Column>
					<Column field="amount" header="Միավորի արժեք"></Column>
				</DataTable>
			</div>
		);
	};
	const persentageBody = rowData => {
		return (
			<div className="fst-italic">
				{rowData.rating * rowData.rating} <span className="ms-1">%</span>
			</div>
		);
	};

	const deliveryDateRangeBody = rowData => {
		return <div className="fst-italic">{rowData.price}</div>;
	};
	const warrentyDateRangeBody = rowData => {
		return <div className="fst-italic">{rowData.price * rowData.rating}</div>;
	};
	const contractDateBody = rowData => {
		return <div className="fst-italic">{rowData.orders[0]?.date}</div>;
	};
	const contractIDBody = rowData => {
		return <div className="fst-italic">#{rowData?.id.substring(2, 4)}</div>;
	};
	const amoutSomethingRangeBody = rowData => {
		return <div className="fst-italic">{rowData?.rating * rowData?.price * 2600}</div>;
	};
	return (
		<div className="card">
			<Toast ref={toast} />
			<div className="row pb-5">
				<div className="col text-end">
					<Button label={"Նոր պայմանագիր"} rounded onClick={() => setDialogVisibility(true)} />
				</div>
			</div>
			<DataTable
				value={products}
				expandedRows={expandedRows}
				onRowToggle={e => setExpandedRows(e.data)}
				onRowExpand={onRowExpand}
				onRowCollapse={onRowCollapse}
				rowExpansionTemplate={rowExpansionTemplate}
				dataKey="id"
				tableStyle={{minWidth: "60rem"}}
			>
				<Column expander={allowExpansion} style={{width: "5rem"}} />
				<Column field="id" header="Պայմանագրի համար" body={contractIDBody} />
				<Column field="id" header="Ակտի համար" body={contractIDBody} />
				<Column field="id" header="Ակտի Ամսաթիվ" body={contractDateBody} />
				{/* <Column header="Փոփոխված սկիզբ" body={contractDateBody} /> */}
				{/* <Column header="Փոփոխված ավարտ" body={contractDateBody} /> */}
				<Column header="Ընդունման Հանձման ամսաթիվ" body={warrentyDateRangeBody} />
				<Column header="Ընդունման Հանձման գին" body={amoutSomethingRangeBody} />
				<Column header="Ֆայլ" body={imageBodyTemplate} />
			</DataTable>

			<Dialog
				visible={dialogVisibility}
				modal
				className="col-8 mx-auto"
				onHide={() => setDialogVisibility(false)}
				header={<div className="border-bottom pt-2">Նոր պայմանագիր</div>}
			>
				<NewActAddForm setDialogVisibility={setDialogVisibility} />
			</Dialog>
		</div>
	);
}
