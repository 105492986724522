import React, {useEffect, useRef, useState} from "react";
import {base, expance} from "../../api";
import Nothing from "../../assets/images/illustrations/girl-unlock-password-light.png";
import axios from "axios";
import {FiPlus, FiMoreVertical, FiCheck, FiInfo} from "react-icons/fi";
import {Dialog} from "primereact/dialog";
import {Toast} from "primereact/toast";
import {Button} from "primereact/button";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {ScrollPanel} from "primereact/scrollpanel";
import {PermOnly} from "../PermOnly";
import PermLessCard from "../ui/elements/PermLessCard";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";

export default function Expances(props) {
	const toast = useRef(null);
	const headers = {"Content-Type": "application/json"};
	const credentials = "same-origin";
	const [visible, setVisible] = useState(false);

	const [stateAM, setStateAM] = useState(null);
	const [stateEN, setStateEN] = useState(null);
	const [stateCode, setCode] = useState(null);

	const [currentState, setCurrentState] = useState(null);
	const [editCurrentStateAM, setEditCurrentStateAM] = useState(null);
	const [editCurrentStateEN, setEditCurrentStateEN] = useState(null);
	const [editCurrentCode, setEditCurrentCode] = useState(null);

	function loadContent() {
		axios.get(base + expance).then(res => {
			setCurrentState(res.data);
		});
	}

	useEffect(() => {
		loadContent();
	}, []);

	function add() {
		axios({
			method: "POST",
			url: base + expance,
			data: {title_am: stateAM, title_en: stateEN, code: stateCode},
			headers: headers,
			credentials: credentials,
		})
			.then(res => {
				setCurrentState();
				loadContent();
				setStateAM(null);
				setStateEN(null);
				setCode(null);
				toast.current.show({severity: "success", summary: "Success", detail: "The item is added successfully", life: 3000});
			})
			.catch(error => {
				toast.current.show({severity: "error", summary: "Error", detail: "Please add something than press the button ", life: 3000});
				loadContent();
				setStateAM(null);
				setStateEN(null);
				setCode(null);
				console.log(error);
			});
	}

	function getCurrent() {
		axios.get(base + expance + localStorage.getItem("item") + "/").then(res => {
			setEditCurrentStateAM(res.data.title_am);
			setEditCurrentStateEN(res.data.title_en);
			setEditCurrentCode(res.data.stateCode);
		});
	}

	function edit(e) {
		axios({
			method: "PUT",
			url: base + expance + localStorage.getItem("item") + `/`,
			data: {title_am: editCurrentStateAM, title_en: editCurrentStateEN, code: editCurrentCode},
			headers: headers,
			credentials: credentials,
		})
			.then(res => {
				toast.current.show({severity: "success", summary: "Success", detail: "Message Content"});
				loadContent();
				setCurrentState(null);
				setVisible(false);
			})
			.catch(error => {
				loadContent();
				toast.current.show({severity: "warn", summary: "Error", detail: "Nothing is chenged"});
			});
	}
	function remove() {
		axios
			.delete(base + expance + localStorage.getItem("item") + `/`, {headers: headers})
			.then(res => {
				loadContent();
				localStorage.removeItem("item");
			})
			.catch(error => {
				loadContent();
			});
	}

	const accept = () => {
		toast.current.show({severity: "success", summary: "Confirmed", detail: "You have accepted", life: 3000});
		remove();
	};

	const reject = () => {
		toast.current.show({severity: "warn", summary: "Rejected", detail: "You have rejected", life: 3000});
	};

	const confirmDelete = e => {
		confirmDialog({
			message: "Do you want to delete this record?",
			header: "Delete Confirmation",
			icon: "pi pi-info-circle",
			acceptClassName: "p-button-danger",
			accept,
			reject,
		});
	};

	const footerbuttons = (
		<div>
			<Button label="Cancel" icon="pi pi-times" onClick={() => setVisible(false)} className="btn small px-4 py-2 b-radius p-button-text" />
			<Button
				label="Save"
				icon="pi pi-check"
				onClick={e => {
					setVisible(false);
					edit(e);
				}}
				className="btn btn-label-primary small px-4 py-2 b-radius"
			/>
		</div>
	);

	return (
		<div>
			<PermOnly perm={props.perm} permless>
				<PermLessCard />
			</PermOnly>
			<PermOnly perm={props.perm}>
				<div className="col-12 card w-100 shadow rounded-4 p-3 mt-3 mb-5">
					<div className=" col-12 mx-auto text-center py-5">
						<p className="m-0 fst-italic text-muted">{props?.description}</p>
					</div>
				</div>
			</PermOnly>

			<PermOnly perm={props.perm}>
				<div className="row m-0 p-0 ">
					<PermOnly perm={props.perm} add>
						<div className="col-lg-5 col-12 p-0 pe-lg-3">
							<div className="card shadow rounded-4 px-4 pt-4 pb-5">
								<p className="text-muted mb-0 text-uppercase">
									<FiInfo className="me-2" />
									Ավելացնել տեղեկատու
								</p>
								<hr />
								<div className="text-center px-5 py-3">
									<input type="text" className="form-control mb-4 b-radius" placeholder={"Ծախսերի կատեգորիա հայերեն"} value={stateAM} onChange={e => setStateAM(e.target.value)} />
									<input type="text" className="form-control mb-4 b-radius" placeholder={"Ծախսերի կատեգորիա անգլերեն"} value={stateEN} onChange={e => setStateEN(e.target.value)} />
									<input type="text" className="form-control mb-4 b-radius" placeholder={"Կոդ"} value={stateCode} onChange={e => setCode(e.target.value)} />
									<button className="btn btn-label-primary small px-4 py-2 b-radius" type="button" onClick={e => add(e)}>
										Add <FiPlus className="ms-2" />
									</button>
								</div>
							</div>
						</div>
					</PermOnly>

					<PermOnly perm={props.perm} view>
						<div className="col-lg-7 col-12 mt-3 mt-lg-0 p-0 ps-lg-3">
							<div className="card w-100 shadow rounded-4 p-3">
								<ScrollPanel style={{width: "100%", height: "550px"}}>
									{currentState?.length > 0 ? (
										<div>
											{currentState?.map((data, i) => (
												<div className="col-12 ">
													<div className="card w-100 border-0 shadow rounded-4 px-3 py-2">
														<div className="d-flex align-items-start justify-content-between">
															<div className="d-flex">
																<div className="text-danger opacity-70 me-3 mt-1 fw-bold fw-bolder" style={{minWidth: "50px"}}>
																	{data.code}
																</div>
																<div className="d-grid">
																	<div className="text-muted">{data.title_am}</div>
																	<div className="text-muted">{data.title_en}</div>
																</div>
															</div>
															<PermOnly perm={props.perm} change remove>
																<div className="dropdown">
																	<button className="btn p-0" type="button" id={"data-" + data.id} data-bs-toggle="dropdown" aria-expanded="false">
																		<FiMoreVertical />
																	</button>
																	<div className="dropdown-menu dropdown-menu-end px-3" aria-labelledby={"data-" + data.id}>
																		<PermOnly perm={props.perm} change>
																			<a
																				className="dropdown-item my-2"
																				href="javascript:void(0);"
																				onClick={e => {
																					setVisible(true);
																					localStorage.setItem("item", data.id);
																					getCurrent(data.id);
																				}}
																			>
																				Edit
																			</a>
																		</PermOnly>
																		<PermOnly perm={props.perm} remove>
																			<a
																				className="dropdown-item my-2"
																				href="javascript:void(0);"
																				onClick={e => {
																					confirmDelete(data.id);
																					localStorage.setItem("item", data.id);
																				}}
																			>
																				Delete
																			</a>
																		</PermOnly>
																	</div>
																</div>
															</PermOnly>
														</div>
													</div>
												</div>
											))}
										</div>
									) : (
										<div className="card w-100 text-center align-items-center d-flex justify-content-center" style={{minHeight: "300px"}}>
											<div>
												<img src={Nothing} alt="" style={{maxHeight: "280px"}} />
											</div>
											<div className="mt-5 text-muted">NOTHING TO SHOW HERE YET 😕</div>
										</div>
									)}
								</ScrollPanel>
							</div>
						</div>
					</PermOnly>
				</div>
			</PermOnly>

			<Toast ref={toast} />

			<ConfirmDialog />
			<Dialog header={"Փոփոխել"} icon="pi pi-external-link" visible={visible} style={{width: "25vw"}} onHide={() => setVisible(false)} footer={footerbuttons}>
				<hr className="mt-0 pt-0" />
				<div className="text-center px-5 pt-5 pb-1">
					<input type="text" className="form-control mb-4 b-radius" placeholder={"Ծախսերի կատեգորիա հայերեն"} value={editCurrentStateAM} onChange={e => setEditCurrentStateAM(e.target.value)} />
					<input type="text" className="form-control mb-4 b-radius" placeholder={"Ծախսերի կատեգորիա հայերեն"} value={editCurrentStateEN} onChange={e => setEditCurrentStateEN(e.target.value)} />
					<input type="text" className="form-control mb-4 b-radius" placeholder={"Կոդ"} value={editCurrentCode} onChange={e => setEditCurrentCode(e.target.value)} />
				</div>
			</Dialog>
		</div>
	);
}
