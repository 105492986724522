import React, {useState, useEffect, useRef} from "react";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {Toast} from "primereact/toast";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Link} from "react-router-dom";
import {Button} from "primereact/button";

export default function VolunteersDashboard() {
	const toast = useRef(null);
	const [volunteers, setVolunteers] = useState([]);
	useEffect(() => {
		fetch("https://jsonplaceholder.typicode.com/users")
			.then(response => response.json())
			.then(json => setVolunteers(json));
	}, [volunteers]);

	const accept = () => {
		toast.current.show({severity: "info", summary: "Confirmed", detail: "You have accepted", life: 3000});
	};

	const reject = () => {
		toast.current.show({severity: "warn", summary: "Rejected", detail: "You have rejected", life: 3000});
	};
	const removeItem = () => {
		confirmDialog({
			message: "Do you want to delete this record?",
			header: "Delete Confirmation",
			icon: "pi pi-info-circle",
			acceptClassName: "p-button-danger",
			accept,
			reject,
		});
	};
	const nameBody = item => {
		return (
			<div className="d-grid my-1 text-truncate ">
				<span className="d-flex fw-bold text-primary">{item?.name}</span>
				<span className="d-flex small">{item?.email}</span>
				<span className="d-flex small">{item?.phone}</span>
			</div>
		);
	};

	const contactsBody = item => {
		return (
			<div className="d-grid my-1 text-truncate ">
				<span className="d-flex ">{item?.company.name}</span>
				<span className="d-flex small">{item?.company.catchPhrase}</span>
				<span className="d-flex small">{item?.company.bs}</span>
			</div>
		);
	};

	const addressBody = item => {
		return (
			<div className="d-grid my-1 text-truncate ">
				<span className="d-flex ">{item?.address.city}</span>
				<span className="d-flex small">{item?.address.street}</span>
				<span className="d-flex small">{item?.address.zipcode}</span>
			</div>
		);
	};

	const actionBody = item => {
		return (
			<div className="d-flex align-items-center justify-content-center">
				<Link to={"/volunteers/" + item.id} className="mx-1">
					<Button icon={"pi pi-pencil"} severity="success" rounded className="mx-1" />
				</Link>

				<Button icon={"pi pi-trash"} onClick={removeItem} severity="danger" rounded className="mx-1" />
			</div>
		);
	};

	return (
		<div className="">
			<div className="col-12 text-end my-3">
				<Link to={"/volunteers/add_new"} className="btn btn-primary rounded-4">
					Add new
				</Link>
			</div>
			<div className="card b-radius b-shadow p-3">
				<DataTable value={volunteers}>
					<Column header="Volunteer" body={nameBody} className="col-4"></Column>
					<Column header="Contacts" body={contactsBody} className="col-4"></Column>
					<Column header="Address" body={addressBody} className="col-3"></Column>
					<Column body={actionBody} className="col-1"></Column>
				</DataTable>
			</div>
			<Toast ref={toast} />
			<ConfirmDialog />
		</div>
	);
}
