import { useCallback, useEffect, useState } from "react";

export const usePreventTabClose = () => {
  const [activeProcesses, setActiveProcesses] = useState(0);
  useEffect(() => {
    const alertUser = (event) => {
      console.log({ activeProcesses });
      // if (activeProcesses) {
      //   event.preventDefault();
      //   event.returnValue = `there are still ${activeProcesses} active processes`;
      //   return "";
      // }
    };

    window.onbeforeunload = alertUser;
    return () => {
      window.onbeforeunload = null;
    };
  }, [activeProcesses]);
  const addActiveProcess = useCallback(
    () => setActiveProcesses((activeProcesses) => activeProcesses + 1),
    []
  );
  const subtractActiveProcess = useCallback(
    () => setActiveProcesses((activeProcesses) => activeProcesses - 1),
    []
  );
  return { addActiveProcess, subtractActiveProcess, activeProcesses };
};
