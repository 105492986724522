import React, {useState, useEffect, useRef} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Toast} from "primereact/toast";
import {Dialog} from "primereact/dialog";
import {base, demand_letters} from "../../../api";
import axios from "axios";
import {Button} from "primereact/button";
import DemandLetterDialog from "./DemandLetterDialog";

export default function DataTableDataDemandLetter(props) {
	const [products, setProducts] = useState([]);
	const [dialogVisibility, setDialogVisibility] = useState(false);
	const toast = useRef(null);

	useEffect(() => {
		loadContent();
	}, []);

	const loadContent = () => {
		axios
			.get(base + demand_letters)
			.then(res => {
				setProducts(res.data);
				// console.log(res.data);
			})
			.catch(error => {
				console.error("Error loading content:", error);
			});
	};

	const formatCurrency = value => {
		return value.toLocaleString("en-US", {style: "currency", currency: "USD"});
	};

	const amountBodyTemplate = rowData => {
		return formatCurrency(rowData.amount);
	};

	const searchBodyTemplate = () => {
		return <Button icon="pi pi-search" />;
	};

	const rowExpansionTemplate = data => {
		return (
			<div className="p-3">
				<h5>Orders for {data.name}</h5>
				<DataTable value={data.orders}>
					<Column field="id" header="Id" sortable></Column>
					<Column field="customer" header="Customer" sortable></Column>
					<Column field="date" header="Date" sortable></Column>
					<Column field="amount" header="Amount" body={amountBodyTemplate} sortable></Column>
					<Column headerStyle={{width: "4rem"}} body={searchBodyTemplate}></Column>
				</DataTable>
			</div>
		);
	};

	const persentageBody = rowData => {
		return (
			<div className="fst-italic">
				{rowData.rating * rowData.rating} <span className="ms-1">%</span>
			</div>
		);
	};

	const letterCountBody = rowData => {
		const count = rowData.demand_letter_products?.length || 0;
		return <div className="fst-italic">{count}</div>;
	};

	const projectNameBody = rowData => {
		return (
			<div className="d-grid">
				<div className="fst-italic">{rowData.project.full_name_am}</div>
				<div className="fst-italic">{rowData.project.name}</div>
			</div>
		);
	};

	return (
		<div className="card">
			<Toast ref={toast} />
			<div className="row pb-5">
				<div className="col text-end">
					<Button label={"Նոր պահանջագիր"} rounded onClick={() => setDialogVisibility(true)} />
				</div>
			</div>
			<DataTable
				value={products}
				rowExpansionTemplate={rowExpansionTemplate}
				dataKey="id"
				tableStyle={{minWidth: "60rem"}}
			>
				<Column field="id" header="Պահանջագրի համար" className="col-1 " />
				<Column header="Նախագիծ" body={projectNameBody} className="col-10" />
				<Column header="Քանակ" body={letterCountBody} className="col-1" />
			</DataTable>
			<Dialog
				visible={dialogVisibility}
				modal
				className="col-8 mx-auto"
				onHide={() => setDialogVisibility(false)}
				header={<div className="border-bottom text-uppercase py-2">Նոր պահանջագիր</div>}
			>
				<DemandLetterDialog setDialogVisibility={setDialogVisibility} />
			</Dialog>
		</div>
	);
}
