import axios from "axios";
import {authTokenInterceptor} from "./authInterceptor";

export const configureInterceptors = (logout, updateTimeLeft) => {
	axios.interceptors.request.use(authTokenInterceptor, error => {
		return Promise.reject(error);
	});

	axios.interceptors.response.use(
		response => {
			updateTimeLeft();
			return response;
		},
		error => {
			if (error.response && error.response.status === 401) {
				logout();
			}
			return Promise.reject(error);
		}
	);
};
