import React, { useState } from 'react'
import i18next from 'i18next';
import FlagIcon from '../../../../FlagIconFactory';
import { Skeleton } from 'primereact/skeleton';

export default function FakeNavbar() {

    const [lngChanger, setLngChanger] = useState()
    function handleClick(lang) {
        i18next.changeLanguage(lang)
    }

    return (
        <nav className="navbar navbar-expand position-sticky top-0 w-100 b-shadow b-radius p-0 justify-content-between py-2">
            <ul className="navbar-nav d-flex col-md-6 col-12 mb-2 mb-lg-0 justify-content-start align-items-center p-2"></ul>
            <ul className="navbar-nav d-flex col-md-6 col-12 mb-2 mb-lg-0 justify-content-end align-items-center p-2">
                <li className="nav-item mx-2">
                    <Skeleton size="2rem"></Skeleton>
                </li>
                <li className="nav-item mx-2">
                    <Skeleton size="2rem"></Skeleton>
                </li>
                <li className="nav-item mx-2">
                    {lngChanger ?
                        <button type='button' className='btn p-0' onClick={() => { handleClick('en-US'); setLngChanger(false) }}>
                            <FlagIcon code={'us'} size={'2x'} className='b-radius' />
                        </button>
                        :
                        <button type='button' className='btn p-0' onClick={() => { handleClick('am_AM'); setLngChanger(true) }}>
                            <FlagIcon code={'am'} size={'2x'} className='b-radius' />
                        </button>
                    }
                </li>
                <li className="nav-item mx-2">
                    <Skeleton size="2rem"></Skeleton>
                </li>
                <li className="nav-item mx-2" width="200px">
                    <div className="d-flex justify-content-center align-items-center" width="200px">
                        <Skeleton shape="circle" size="3rem" width='80px'></Skeleton>
                        <div className="d-grid ms-3" width="100px">
                            <Skeleton width="80px" height='15px' className="mb-2"></Skeleton>
                            <Skeleton width="150px" height='10px'></Skeleton>
                        </div>
                    </div>
                </li>
            </ul>
        </nav>
    )
}
