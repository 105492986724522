import React, {useState} from "react";
import axios from "axios";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {base, budget_budget_row_create} from "../../../../../api";

export default function BudgetMounthlyAddForm({data, loadData, toast, setDialogVisibility}) {
	const [value, setValue] = useState({});
	const headers = {"Content-Type": "application/json"};
	const credentials = "same-origin";

	function submitData() {
		const budget = data[0].budget.id;
		const {date, count} = value;

		axios({
			method: "POST",
			url: base + budget_budget_row_create,
			data: {budget, count, date},
			headers: headers,
			credentials: credentials,
		})
			.then(res => {
				loadData();
				setDialogVisibility(false);
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: "The item is added successfully",
					life: 3000,
				});
			})
			.catch(error => {
				setDialogVisibility(false);
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: "Please add something than press the button ",
					life: 3000,
				});
				loadData();

				// console.log(error);
			});
	}

	let isFilled = Object.keys(value).length >= 2;
	for (const key in value) {
		if (!value[key]) {
			isFilled = false;
		}
	}
	return (
		<div className="col-12 p-3">
			<div className="row">
				<div className="col-lg-6">
					<InputText
						type="date"
						onChange={e => setValue({...value, date: e.target.value})}
						className="py-3 w-100 m-2"
						placeholder="No:"
					/>
				</div>
				<div className="col-lg-6">
					<InputText
						onChange={e => setValue({...value, count: e.target.value})}
						className="py-3 w-100 m-2"
						placeholder="Քանակ"
					/>
				</div>

				<div className="col-lg-12 text-center mt-4">
					<Button
						icon={"pi pi-save"}
						rounded
						label="պահպանել"
						severity={isFilled ? "success" : "secondary"}
						onClick={submitData}
						disabled={!isFilled}
					/>
				</div>
			</div>
		</div>
	);
}
