import logo_main from "../../assets/edm_main.png";
import logo_small from "../../assets/edm_small.png";
import {navigation_items} from "../../json_content/navigation_items";
import {Link, NavLink} from "react-router-dom";
import {ScrollPanel} from "primereact/scrollpanel";
import {PermOnly} from "../PermOnly";
import {Button} from "primereact/button";
import {Tooltip} from "primereact/tooltip";
import {useTranslation} from "react-i18next";

export default function Aside(props) {
	const {t} = useTranslation();
	return (
		<aside id="sidebar" className={`card b-radius b-shadow ${props?.asideToggler ? "open" : "hide"}`}>
			<div className="col-sm-12 text-center logo-wrapper">
				<Link to={"/"}>{props?.asideToggler ? <img className="logo" src={logo_main} alt="" /> : <img className="logo" src={logo_small} alt="" />}</Link>
			</div>
			<div className="col-12 text-center my-2 border-top border-bottom py-3">
				<Button
					rounded
					icon={props?.asideToggler ? "pi pi-arrow-left" : "pi pi-arrow-right"}
					onClick={() => {
						props?.setAsideToggler(!props?.asideToggler);
						localStorage.setItem("aside", JSON.stringify(!props.asideToggler));
					}}
				/>
			</div>
			<ScrollPanel className="px-4 overflow-hidden" style={{width: "100%", height: "60%"}}>
				{navigation_items.map((item, index) => (
					<PermOnly perm={item?.perm}>
						<NavLink key={index} to={item.path} className={props?.asideToggler ? "sidebar_link flex align-items-center w-100" : "sidebar_link flex align-items-center justify-content-center w-100"}>
							{props?.asideToggler ? null : <Tooltip target=".icon" mouseTrack mouseTrackLeft={20} />}
							<i data-pr-tooltip={t(item.label)} className={props?.asideToggler ? item.icon + " icon fw-bold me-3" : item.icon + " icon fw-bold"}></i>
							{props?.asideToggler ? t(item.label) : null}
						</NavLink>
					</PermOnly>
				))}
			</ScrollPanel>
		</aside>
	);
}
