import {Link} from "react-router-dom";
import {RiArrowRightLine} from "react-icons/ri";

export const eventProject = item => {
	return item?.project.full_name_am;
};

export const eventLocation = item => {
	return item?.location.title_am;
};

export const eventDate = item => {
	return item?.date;
};

export const eventName = item => {
	return item?.name;
};

export const eventAction = item => {
	return (
		<Link to={"/beneficiaries/edit_event/" + item.id + "/"} className="btn btn-outline-primary d-flex justify-content-center align-items-center">
			Մանրամասներ <RiArrowRightLine className="ms-2" />
		</Link>
	);
};
