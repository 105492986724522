import React, {useEffect, useState} from "react";
import {Tree} from "primereact/tree";
import {TabView, TabPanel} from "primereact/tabview";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {FileUpload} from "primereact/fileupload";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {ConfirmDialog} from "primereact/confirmdialog";
import {base, file_manager} from "../../api";
import no_file from "../../assets/images/illustrations/girl-with-laptop-light.png";
import {Tooltip} from "primereact/tooltip";

import PublicTreeNodeTemplate from "./components/PublicNode/PublicTreeNodeTemplate";
import OwnTreeNodeTemplate from "./components/OwnNode/OwnTreeNodeTemplate";
import HeaderTemplate from "./components/HeaderTemplate/HeaderTemplate";
import ItemTemplate from "./components/ItemTemplate/ItemTemplate";
import {EmptyTemplate} from "./components/EmptyTemplate/EmptyTemplate";
import {useMediaIndex} from "./useMediaIndex";
import PublicItemTemplate from "./components/PublicItemTemplate/PublicItemTemplate";

import MediaFilter from "./components/Filter/MediaFilter";
import MediaFilterUser from "./components/Filter/MediaFilterUser";
import PerosnalFileShareDialog from "./dialog/PerosnalFileShareDialog";
import {downloadPublicFile, fetchImage, fetchPublicImage, downloadOwnFile} from "./repository";

const type = ["png", "jpg", "jpeg", "pdf", "gif", "mp3", "mp4", "webm", "xslx", "csv", "docx"];

export default function MediaIndex(props) {
	const {
		publicFiles,
		setContentPreview,
		contentPreview,
		setContentPreviewPublic,
		contentPreviewPublic,
		setVisible,
		visible,
		togglerTemplate,
		onDragAndDrop,
		setMyFiles,
		myFiles,
		setPopupInputValue,
		popupInputValue,
		createSubfolder,
		fileUploadRef,
		uploadFiles,
		uploadPublicFiles,
		onTemplateUpload,
		onTemplateSelect,
		onTemplateClear,
		deleteFolder,
		reject,
		handleFileDelete,
		handlePublicFileDelete,
		toast,
		totalSize,
		onTemplateRemove,
		multiselectProjectsByFile,
		optionProjects,
		setFileProjects,
		multiselectLocationsByFile,
		optionLocations,
		setFileLocations,
		multiselectTagsByFile,
		optionTags,
		setFileTags,
		datesByFile,
		setFileDate,
		reloadFiles,
		filteredPublicFiles,
		filteredOwnFiles,
		selectedDayRange,
		setSelectedDayRange,
		selectedDayRangeUser,
		setSelectedDayRangeUser,
		multiselectTagsFilter,
		setMultiselectTagsFilter,
		multiselectLocationsFilter,
		setMultiselectLocationsFilter,
		multiselectProjectsFilter,
		setMultiselectProjectsFilter,
		setPublicSearchValue,
		publicSearchValue,
		setOwnSearchValue,
		onwSearchValue,
		clearPublicFilter,
		clearOwnFileFilter,
		applyFilter,
		uploadProgress,
	} = useMediaIndex();

	const [previewImageData, setPreviewImageData] = useState();
	const [previewImageDataPublic, setPreviewImageDataPublic] = useState();
	useEffect(() => {
		(async () => {
			const dataURL = await fetchPublicImage(contentPreviewPublic?.key);
			setPreviewImageDataPublic(dataURL);
		})();
	}, [contentPreviewPublic?.key]);
	useEffect(() => {
		(async () => {
			const dataURL = await fetchImage(contentPreview?.key);
			setPreviewImageData(dataURL);
		})();
	}, [contentPreview?.key]);

	const chooseOptions = {
		icon: "pi pi-file",
		iconOnly: true,
		className: "custom-choose-btn p-button-rounded opacity-50",
	};
	const uploadOptions = {
		icon: "pi pi-upload",
		iconOnly: true,
		className: "custom-upload-btn p-button-success p-button-rounded opacity-50",
	};
	const cancelOptions = {
		icon: "pi pi-times",
		iconOnly: true,
		className: "custom-cancel-btn p-button-danger p-button-rounded opacity-50",
	};

	return (
		<div className="col-lg-12 mb-4">
			<div className="card w-100 b-radius b-shadow px-3 ">
				<div className="card flex justify-content-center">
					<TabView>
						<TabPanel header={props.t("_meidacomp_public_files")} leftIcon="pi pi-box mr-3 ">
							<div className="row">
								<div className="col-lg-6">
									<div className="col-12 p-0 mb-3">
										<div className="row align-items-center p-0 m-0">
											<div className="col-11 p-0">
												<input type="text" className="form-control col-10 py-2 me-3" placeholder={props.t("_mediacomp_search")} onChange={e => setPublicSearchValue(e.target.value)} value={publicSearchValue} />
											</div>
											<div className="col-1 p-0 text-end">
												<Button icon="pi pi-filter" rounded outlined severity="info" onClick={() => setVisible({...visible, advencedSearchDialog: true})} />
											</div>
										</div>
									</div>

									<Tree
										funk={props.t}
										className="border-0"
										style={{minHeight: "50vh"}}
										value={filteredPublicFiles ?? publicFiles}
										nodeTemplate={PublicTreeNodeTemplate({
											onSelect: node => setContentPreviewPublic(node),
											onDeletePressed: node => (localStorage.setItem("node", node.key), setVisible({...visible, deletePublicFileDialog: true}), console.log({key: node.key})),
											refresh: reloadFiles,
										})}
										togglerTemplate={togglerTemplate}
									/>

									<div className="d-flex justify{-content-center">
										<Button label={props.t("_mediacomp_upload")} icon="pi pi-upload" rounded severity="primary" onClick={() => setVisible({...visible, uploadPublicFilesDialog: true})} />
									</div>
								</div>
								<div className="col-lg-6 d-flex justify-content-center align-items-center overflow-hidden" style={{height: "65vh"}}>
									{contentPreviewPublic ? (
										<div className="card py-5">
											<div className="col mx-auto" style={{maxHeight: "50vh"}}>
												<img src={previewImageDataPublic} header style={{width: "100%", maxHeight: "50vh"}} />
											</div>
											<div className="text-center mt-5 mb-3">{contentPreviewPublic.label}</div>
											<div className="col mx-auto">
												<Button
													label={props.t("_mediacomp_view")}
													icon="pi pi-external-link"
													severity="warning"
													rounded
													download
													className="mx-2"
													rel="noreferrer"
													onClick={() => {
														downloadPublicFile(contentPreviewPublic.key, contentPreviewPublic.label, true);
													}}
												/>
												<Button
													label={props.t("_mediacomp_download")}
													icon="pi pi-download"
													severity="success"
													rounded
													download
													className="mx-2"
													rel="noreferrer"
													onClick={() => {
														downloadPublicFile(contentPreviewPublic.key, contentPreviewPublic.label);
													}}
												/>
											</div>
										</div>
									) : (
										<div className="d-flex justify-content-center w-100 align-items-center card">
											<img src={no_file} alt="no image" className="col-8 mb-4" />
											<div className="fs-5 fw-bold text-center">
												nothing to show here <br /> please select a file
											</div>
										</div>
									)}
								</div>
							</div>
						</TabPanel>
						<TabPanel header={props.t("_meidacomp_private_files")} leftIcon="pi pi-sitemap mr-3">
							<div className="row">
								<div className="col-lg-6">
									<div className="col-12 p-0 mb-3">
										<div className="row align-items-center p-0 m-0">
											<div className="col-11 p-0">
												<input type="text" className="form-control col-10 py-2 me-3" placeholder={props.t("_mediacomp_search")} onChange={e => setOwnSearchValue(e.target.value)} value={onwSearchValue} />
											</div>
											<div className="col-1 p-0 text-end">
												<Button icon="pi pi-filter" rounded outlined severity="info" onClick={() => setVisible({...visible, userSearchDialog: true})} />
											</div>
										</div>
									</div>
									<Tree
										className="border-0"
										style={{minHeight: "50vh"}}
										dragdropScope="demo"
										onDragDrop={e => onDragAndDrop(e, setMyFiles)}
										value={filteredOwnFiles ?? myFiles}
										nodeTemplate={OwnTreeNodeTemplate({
											onCreateSubfolder: node => (setVisible({createFolderDialog: true}), setPopupInputValue(node.label), localStorage.setItem("node", node.key)),
											onDeleteFolder: node => (setVisible({...visible, deleteFolderDialog: true}), localStorage.setItem("node", node.key)),
											onUploadPressed: node => {
												setVisible({...visible, uploadFilesDialog: true});
												localStorage.setItem("node", node.key);
											},
											onSelect: node => setContentPreview(node),
											onDeleteFile: node => (localStorage.setItem("node", node.key), setVisible({...visible, deleteFileDialog: true})),
											onSharePressed: node => (localStorage.setItem("node", JSON.stringify(node)), setVisible({...visible, fileShareDialog: true})),
											refresh: reloadFiles,
										})}
										togglerTemplate={togglerTemplate}
									/>
									<div className="d-flex justify-content-center">
										<Button
											label={props.t("_mediacomp_create_folder")}
											icon="pi pi-plus"
											rounded
											severity="primary"
											onClick={() => {
												setVisible({...visible, createFolderDialog: true});
												localStorage.removeItem("node");
											}}
										/>
									</div>
								</div>
								<div className="col-lg-6">
									{contentPreview ? (
										<div className="card py-5">
											<div className="col mx-auto" style={{maxHeight: "50vh"}}>
												<img src={previewImageData} style={{width: "100%", maxHeight: "50vh"}} />
											</div>
											<div className="text-center mt-5 mb-3">{contentPreview.label}</div>
											<div className="col mx-auto">
												<Button
													label={props.t("_mediacomp_view")}
													icon="pi pi-external-link"
													severity="warning"
													rounded
													download
													className="mx-2"
													rel="noreferrer"
													onClick={() => {
														downloadOwnFile(contentPreview.key, contentPreview.label, true);
													}}
												/>
												<Button
													label={props.t("_mediacomp_download")}
													icon="pi pi-download"
													severity="success"
													rounded
													download
													className="mx-2"
													rel="noreferrer"
													onClick={() => {
														downloadOwnFile(contentPreview.key, contentPreview.label);
													}}
												/>
											</div>
										</div>
									) : (
										<div className="d-flex justify-content-center w-100 align-items-center card">
											<img src={no_file} alt="no image" className="col-8 mb-4" />
											<div className="fs-5 fw-bold text-center">
												nothing to show here <br /> please select a file
											</div>
										</div>
									)}
								</div>
							</div>
						</TabPanel>
					</TabView>

					<Dialog header={props.t("_mediacomp_create_folder")} className="col-lg-6 col-md-8 col-10 text-center" visible={visible.createFolderDialog} onHide={() => setVisible({...visible, createFolderDialog: false})}>
						<div className="col-12 mb-3">
							<InputText placeholder="add a text" className="col-lg-6 col-md-8 col-10" onChange={e => setPopupInputValue(e.target.value)} value={popupInputValue} />
						</div>
						<div className="col-12">
							<Button
								label="save"
								rounded
								severity="primary"
								onClick={() => {
									const parent = localStorage.getItem("node");
									parent
										? createSubfolder({
												name: popupInputValue,
												parent: localStorage.getItem("node"),
										  })
										: createSubfolder({
												name: popupInputValue,
										  });
								}}
							/>
						</div>
					</Dialog>

					<Dialog header={props.t("_mediacomp_upload")} className="col-lg-6 col-md-8 col-10 text-center" visible={visible.uploadFilesDialog} onHide={() => setVisible({...visible, uploadFilesDialog: false})}>
						<div className="col-12 mb-3">
							<Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
							<Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
							<Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />
							<FileUpload multiple ref={fileUploadRef} name="file" url={base + file_manager.upload_file`${localStorage.getItem("node")}`} accept="*" maxFileSize={100000000000} customUpload={uploadFiles} onUpload={onTemplateUpload} uploadHandler={uploadFiles} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear} headerTemplate={HeaderTemplate(totalSize, fileUploadRef)} itemTemplate={ItemTemplate(onTemplateRemove)} emptyTemplate={() => EmptyTemplate({t: props.t})} chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
						</div>
					</Dialog>

					<Dialog header={props.t("_mediacomp_upload")} className="col-lg-6 col-md-8 col-10 text-center" visible={visible.uploadPublicFilesDialog} onHide={() => setVisible({...visible, uploadPublicFilesDialog: false})}>
						<div className="col-12 mb-3">
							<FileUpload
								multiple
								ref={fileUploadRef}
								name="file"
								url={base + file_manager.upload_file`${localStorage.getItem("node")}`}
								accept="*"
								maxFileSize={100000000000}
								customUpload={uploadPublicFiles}
								onUpload={onTemplateUpload}
								uploadHandler={uploadPublicFiles}
								onSelect={onTemplateSelect}
								onError={onTemplateClear}
								onClear={onTemplateClear}
								headerTemplate={HeaderTemplate(totalSize, fileUploadRef)}
								itemTemplate={PublicItemTemplate(onTemplateRemove, multiselectProjectsByFile, optionProjects, setFileProjects, multiselectLocationsByFile, optionLocations, setFileLocations, multiselectTagsByFile, optionTags, setFileTags, datesByFile, setFileDate)}
								emptyTemplate={() => EmptyTemplate({t: props.t})}
								chooseOptions={chooseOptions}
								uploadOptions={uploadOptions}
								cancelOptions={cancelOptions}
							/>
						</div>
					</Dialog>

					<Dialog header="Advenced search or Filter Files" className="col-lg-6 col-md-8 col-10 text-center" visible={visible.advencedSearchDialog} onHide={() => setVisible({...visible, advencedSearchDialog: false})}>
						<div className="col-12 mb-3">
							<MediaFilter
								data={{
									optionProjects,
									optionLocations,
									optionTags,
									multiselectTagsFilter,
									setMultiselectTagsFilter,
									multiselectLocationsFilter,
									setMultiselectLocationsFilter,
									multiselectProjectsFilter,
									setMultiselectProjectsFilter,
								}}
								selectedDayRange={selectedDayRange}
								setSelectedDayRange={setSelectedDayRange}
								clearFilter={clearPublicFilter}
								applyFilter={applyFilter}
							/>
						</div>
					</Dialog>

					<Dialog header="Advenced search or Filter Files" className="col-4 text-center" visible={visible.userSearchDialog} onHide={() => setVisible({...visible, userSearchDialog: false})}>
						<div className="col-12 mb-3">
							<MediaFilterUser selectedDayRange={selectedDayRangeUser} setSelectedDayRange={setSelectedDayRangeUser} clearFilter={clearOwnFileFilter} applyFilter={applyFilter} />
						</div>
					</Dialog>

					<Dialog header="Share file as a link" className="col-4 text-center" visible={visible.fileShareDialog} onHide={() => setVisible({...visible, fileShareDialog: false})}>
						<div className="col-12 mb-3">
							<PerosnalFileShareDialog refreshFiles={reloadFiles} />
						</div>
					</Dialog>

					<ConfirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" message="Are you sure you want to proceed?" visible={visible.deleteFolderDialog} onHide={() => setVisible({...visible, deleteFolderDialog: false})} accept={deleteFolder} reject={reject} />

					<ConfirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" message="Are you sure you want to proceed?" visible={visible.deleteFileDialog} onHide={() => setVisible({...visible, deleteFileDialog: false})} accept={handleFileDelete} reject={reject} />

					<ConfirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" message="Are you sure you want to proceed?" visible={visible.deletePublicFileDialog} onHide={() => setVisible({...visible, deletePublicFileDialog: false})} accept={handlePublicFileDelete} reject={reject} />

					<Toast ref={toast} />
				</div>
			</div>
		</div>
	);
}
