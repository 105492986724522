
import React, { useEffect, useRef, useState } from 'react';
import { base, partners, usersURL, resetPasswordURL } from '../../api';
import axios from 'axios';
import { FiInfo } from 'react-icons/fi'
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { AutoComplete } from "primereact/autocomplete";

import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import UsersList from './components/UsersList';
import EditUserForm from './components/EditUserForm';
import AddUserForm from './components/AddUserForm';

export default function CreateUser() {
    const toast = useRef(null);
    const headers = { 'Content-Type': 'multipart/form-data', }
    const credentials = "same-origin"
    const [visible, setVisible] = useState(false);
    const [form, setForm] = useState(false);

    const [partnerInfo, setPartnerInfo] = useState(null)
    const [selectedPartner, setSelectetPartner] = useState(null);
    const [filteredPartner, setFilteredPartner] = useState(null);

    const [userInfo, setUserInfo] = useState({
        username: null,
        password: null,
        partner: null,
        avatar: null,
    });

    const [currentState, setCurrentState] = useState(null);
    const [editCurrentState, setEditCurrentState] = useState(null);

    function loadContent() {
        axios.get(base + usersURL).then((res => {
            setCurrentState(res.data)
        }))
    }

    useEffect(() => {
        axios.get(base + partners).then((res => { setPartnerInfo(res?.data?.filter(i => i.legal === 'Physical')) }))
        loadContent()
    }, []);

    const add = async (e) => {
        try {
            const formData = new FormData();
            formData.append('username', userInfo.username);
            formData.append('password', userInfo.password);
            formData.append('mobile', userInfo.mobile);
            formData.append('partner_id', userInfo.partner);
            formData.append('avatar', userInfo.avatar);
            const response = await axios.post(base + usersURL, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Message Content' });
            setUserInfo('')
            setSelectetPartner('')
            setForm(false)
            loadContent()
        } catch (error) {
            loadContent();
            toast.current.show({ severity: 'warn', summary: 'Error', detail: error.response.data.username });
        }
    }

    const edit = async (e) => {
        try {
            const formData = new FormData();
            formData.append('username', editCurrentState?.username);
            formData.append('mobile', editCurrentState?.mobile);
            formData.append('is_active', editCurrentState?.is_active);
            if (editCurrentState.avatar) { formData.append('avatar', editCurrentState?.avatar) }
            const response = await axios.patch(base + usersURL + localStorage.getItem('item') + `/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Message Content' });
            loadContent()
            setCurrentState(null)
            setVisible(false)
        } catch (error) {
            loadContent();
            toast.current.show({ severity: 'warn', summary: 'Error', detail: 'Nothing is chenged' });
            console.error('Error creating user:', error);
        }
    }

    function remove() {
        axios.delete(base + usersURL + localStorage.getItem('item') + `/`, { headers: headers })
            .then((res) => {
                loadContent()
                localStorage.removeItem('item')
            })
            .catch((error) => { loadContent() })
    }

    function resetPassword() {
        axios.post(base + resetPasswordURL + localStorage.getItem('item') + '/', { headers: headers })
            .then((res) => {
                loadContent()
                localStorage.removeItem('item')
            })
            .catch((error) => { loadContent() })
    }

    const accept = () => {
        toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
        remove()
    }
    const reset = () => {
        resetPassword()
        toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const confirmDelete = (e) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    };

    const confirmReset = (e) => {
        confirmDialog({
            message: 'Do you want to reset password?',
            header: 'Reset Confirmation',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: reset,
            reject
        });
    };

    const search = (event) => {
        let query = event.query;
        let _filteredPartner = partnerInfo?.filter((item) => item.full_name_en?.toLowerCase().indexOf(query?.toLowerCase()) !== -1);
        setFilteredPartner(_filteredPartner);
    }

    return (
        <div className="col-12">
            <div className="card w-100 b-radius b-shadow p-3">
                <div className="col-md-8 col-12 mx-auto text-center mt-3 mb-4">
                    <p className="m-0 small fst-italic text-muted">
                        Արտարժույքները կարող եք ավելացնել ներքոհիշհալ դաշտում նշելով ցանկացած արտարժուք, որից հետո անհրաժեշտ է սեղմել "Ավելացնել" կոճակը: Իսկ եթե արտարժուքն ավելացնելուց տեղի է ունեցել վրիպում ապա ավելացված արտաժույքների դաշտում սեղմեք փոփոխել կոճակը և կատարեք փոփխությունները: Ցանկանում եք հեռացնել ոչ պիտանի արտարժուքը ապա սեղմեք ռեռացնել կոճակը
                    </p>
                    <hr />
                </div>

                <div className='row'>
                    <div className='col-md-5 col-12'>
                        <div className="card b-radius b-shadow p-3">
                            <p className='d-flex align-items-center text-muted mb-0 text-uppercase'><FiInfo className='me-2' />Ստեղծել համակարգի օգտատեր</p>
                            <hr />
                            <div className="card flex justify-content-center p-3">
                                <AutoComplete
                                    dropdown
                                    placeholder="Մուտքագրեք օգտատեր"
                                    field={"full_name_en"}
                                    completeMethod={search}
                                    suggestions={filteredPartner}
                                    value={selectedPartner}
                                    onChange={(e) => { setSelectetPartner(e.value); setForm(true) }}
                                    itemTemplate={(item) => (
                                        <div className='d-flex align-items-top'>
                                            <div className='d-grid'>
                                                <span className='text-primary'>{item.full_name_en} </span>
                                                <span className='text-secondary small'>{item.address_en ?? 'no address provided'}</span>
                                                <span className='text-secondary small'>{item.passport ?? 'not provided'}</span>
                                            </div>
                                        </div>
                                    )}
                                />
                                {form ? <AddUserForm data={selectedPartner} setUserInfo={setUserInfo} userInfo={userInfo} add={add} /> : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 col-12">
                        <UsersList
                            data={currentState}
                            setvisible={setVisible}
                            setEditCurrentState={setEditCurrentState}
                            confirmdelete={confirmDelete}
                            confirmReset={confirmReset}
                        />
                    </div>
                </div>
            </div>

            <Toast ref={toast} />

            <ConfirmDialog />

            <Dialog header={"Փոփոխել"} icon="pi pi-external-link" visible={visible} onHide={() => { setVisible(false); setEditCurrentState('') }} className='col-lg-3 col-10 p-0'>
                <EditUserForm
                    setEditCurrentState={setEditCurrentState}
                    editCurrentState={editCurrentState}
                    edit={edit}
                    setvisible={setVisible}
                />
            </Dialog>
        </div>
    )
}
