import { useMemo } from 'react';
import { useAuth } from '../context/AuthContext'
import permissionsGroups from '../components/static/permissionsGroups.json'

export const PermOnly = ({ perm, add, change, remove, view, permless, children }) => {
    const { combinedPermissions } = useAuth();

    const hasPerm = useMemo(() => {
        if (!perm) {
            return true
        }
        const perms = perm.split(',');

        if (!perms || !perms.length) {
            return false;
        }

        const hasPermission = perms.some(perm => {
            const permGroups = permissionsGroups.find(group => group.name === perm);
            if (!permGroups) {
                return false;
            }

            const hasAddPerm = combinedPermissions.includes(permGroups?.add);
            if (add && hasAddPerm) {
                return true
            }
            const hasChangePerm = combinedPermissions.includes(permGroups?.change);
            if (change && hasChangePerm) {
                return true
            }
            const hasDeletePerm = combinedPermissions.includes(permGroups?.delete);
            if (remove && hasDeletePerm) {
                return true
            }
            const hasViewPerm = combinedPermissions.includes(permGroups?.view);
            if (view && hasViewPerm) {
                return true
            }
            // console.log({ hasAddPerm, hasChangePerm, hasDeletePerm, hasViewPerm });

            return (!add && !change && !remove && !view) && (hasAddPerm || hasChangePerm || hasDeletePerm || hasViewPerm);
        });

        return hasPermission;
    }, [combinedPermissions, perm]);
    // console.log({ hasPerm, permless });
    if (permless) {
        if (!hasPerm) {
            console.error({ hasPerm, permless });
            return children
        }
    }

    else if (hasPerm) {
        return children
    }

    return <></>
}