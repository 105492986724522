import React from "react";

export default function UserDetails(props) {
	return (
		<div className="card w-100 b-radius b-shadow mb-4 my-3">
			<div className="card-body">
				<div className="fs-4 mb-3">User details</div>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<span>Phone</span>
					<span>{props?.data?.phone} </span>
				</div>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<span>Email</span>
					<span>{props?.data?.email} </span>
				</div>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<span>Address</span>
					<span>{props?.data?.address_am} </span>
				</div>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<span>Passport / ID card</span>
					<span>{props?.data?.passport} </span>
				</div>
				<div className="d-flex justify-content-between align-items-center mb-3">
					<span>Social card</span>
					<span>{props?.data?.social_card} </span>
				</div>
			</div>
		</div>
	);
}
