import React, { useState, useMemo } from "react";
import { Button } from "primereact/button";
import { renamePublicFile, downloadPublicFile } from "../../repository"
import { dateToDisplayFormat } from "../../../../utils/utils"
import { useTranslation } from 'react-i18next';


export default function PublicFileNodeTemplate(node, options, onFileSelect, onDeletePressed, refresh) {
    const { t } = useTranslation();
    const labelText = useMemo(() => node.label, [node.label])
    const createdAtText = useMemo(() => dateToDisplayFormat(node.createdAt), [node.label])
    const dateText = useMemo(() => dateToDisplayFormat(node.date), [node.label])
    const [isEditMode, setIsEditMode] = useState(false)
    const [editedTitle, setEditedTitle] = useState(labelText)
    let label = <div className="text-dark">{labelText}</div>;
    const _buildLabel = () => {
        return <div className="d-grid text-start" onClick={() => onFileSelect(node)} >
            <span className="m-0 h5 text-cut">{labelText}</span>
            <div className="d-grid text-muted">
                <span className="small">
                    {t('_mediacomp_uploaded_on')}: {createdAtText}
                </span>
                <span className="small">
                    {t('_mediacomp_date')}: {dateText}
                </span>
                <span className="small">
                    {t('_mediacomp_tags')}: {node.tags.map(tag => tag.title_en).join(', ')}
                </span>
                <span className="small">
                    {t('_mediacomp_locations')}: {node.locations.map(location => location.title_en).join(', ')}
                </span>
            </div>

        </div>;
    }

    label = isEditMode ? (
        <input type="text" className="form-control" placeholder={labelText} value={editedTitle} onChange={e => setEditedTitle(e.target.value)} />
    ) : _buildLabel();



    return (
        <div className="d-flex justify-content-center align-items-baseline">
            <span className={options.className}>{label}</span>

            <div className="d-flex justify-content-center align-content-center mx-3">
                <Button
                    download
                    icon="pi pi-download mx-3"
                    tooltip={t('_mediacomp_download_a_file')}
                    tooltipOptions={{ position: "top" }}
                    rounded
                    text
                    severity="halp"
                    onClick={() => {
                        downloadPublicFile(node.key, labelText);
                    }}
                />
                {isEditMode ? (
                    <Button
                        icon="pi pi-save mx-3"
                        tooltip={t('_mediacomp_rename_a_file')}
                        tooltipOptions={{ position: "top" }}
                        rounded
                        text
                        severity="success"
                        onClick={(e) => {
                            // send edit request
                            console.log({ editedTitle });
                            renamePublicFile({ fileId: node.key, newFilename: editedTitle })
                            setIsEditMode(false)
                            refresh()
                        }}
                    />
                ) : (
                    <Button
                        icon="pi pi-file-edit mx-3"
                        tooltip={t('_mediacomp_rename_a_file')}
                        tooltipOptions={{ position: "top" }}
                        rounded
                        text
                        severity="success"
                        onClick={(e) => setIsEditMode(true)}
                    />
                )}
                <Button
                    icon={isEditMode ? "pi pi-times mx-3" : "pi pi-trash mx-3"}
                    tooltip={isEditMode ? t('_mediacomp_cancel') : t('_mediacomp_remove_file')}
                    tooltipOptions={{ position: "top" }}
                    rounded
                    text
                    severity="danger"
                    onClick={isEditMode ? () => setIsEditMode(false) : () => onDeletePressed(node)}
                />
            </div>

        </div>
    );
};