import {informersGroupPermision, asidePartnerPermision, asideProjectsPermision} from "../json_content/StaticTexts";

export const navigation_items = [
	{
		label: "_aside_menu_dashboard",
		icon: "pi pi-home",
		path: "/",
	},
	{
		label: "_aside_menu_projects",
		icon: "pi pi-flag",
		path: "/projects_dashboard",
		perm: asideProjectsPermision,
	},
	{
		label: "_aside_menu_documents",
		icon: "pi pi-file-pdf",
		path: "/documentation",
	},
	{
		label: "_aside_menu_partners",
		icon: "pi pi-shield",
		path: "/partners",
		perm: asidePartnerPermision,
	},
	{
		label: "_aside_menu_coworkers",
		icon: "pi pi-user",
		path: "/co_workers",
	},
	{
		label: "_aside_menu_benificares",
		icon: "pi pi-at",
		path: "/beneficiaries",
	},
	{
		label: "_aside_menu_volunteers",
		icon: "pi pi-users",
		path: "/volunteers",
	},
	{
		label: "_aside_menu_messages",
		icon: "pi pi-inbox",
		path: "/tickets",
	},
	{
		label: "_aside_menu_informers",
		icon: "pi pi-tag",
		path: "/informers",
		perm: informersGroupPermision,
	},
	{
		label: "_aside_menu_mediacomps",
		icon: "pi pi-copy",
		path: "/media",
		perm: "publicfile,folder,file",
	},
	{
		label: "_aside_menu_rating",
		icon: "pi pi-star",
		path: "/user_rating",
		perm: "publicfile,folder,file",
	},
	// {
	// label: '_aside_menu_reports',
	// icon: 'pi pi-exclamation-circle',
	// path: '/reports'
	// },
	// {
	// label: '_aside_menu_expances',
	// icon: 'pi pi-chart-pie',
	// path: '/spandings'
	// },
	// {
	// label: '_aside_menu_help',
	// icon: 'pi pi-question-circle',
	// path: '/documentation'
	// },
	{
		label: "_aside_menu_exit",
		icon: "pi pi-sign-out",
		path: "/logout",
	},
];
