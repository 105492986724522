import React, {useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

const RatingDialog = ({
	dialogVisible,
	handleDialogClose,
	percentages,
	setPercentages,
	handleSave,
	setSaveButtonDisabled,
}) => {
	const [errorMessage, setErrorMessage] = useState(""); // State for error message
	const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true); // Disable Save button initially

	useEffect(() => {
		const total = Object.values(percentages).reduce(
			(acc, value) => acc + parseFloat(value || 0),
			0
		);
		const diff = total - 100;

		if (diff !== 0) {
			setErrorMessage(
				diff > 0
					? `You've exceeded by ${diff.toFixed(2)}%.`
					: `You have ${Math.abs(diff).toFixed(2)}% left.`
			);
			setIsSaveButtonDisabled(true); // Disable save button if total is not 100
		} else {
			setErrorMessage(""); // Clear error message when total is 100%
			setIsSaveButtonDisabled(false); // Enable save button when total is exactly 100
		}

		setSaveButtonDisabled(total !== 100); // Update external save button state if needed elsewhere
	}, [percentages, setSaveButtonDisabled]);

	const handleInputChange = (e, field) => {
		const inputValue = parseFloat(e.target.value) || 0;

		// Update the specific field in percentages state
		setPercentages({...percentages, [field]: inputValue});
	};

	const handleSaveClick = () => {
		localStorage.setItem("percentages", JSON.stringify(percentages));
		handleSave(); // Call parent's save function
	};

	return (
		<Dialog
			header="Set Percentages"
			visible={dialogVisible}
			style={{width: "30vw"}}
			modal
			onHide={handleDialogClose}
			footer={
				<div>
					<Button
						label="Cancel"
						icon="pi pi-times"
						className="p-button-text"
						onClick={handleDialogClose}
					/>
					<Button
						label="Save"
						icon="pi pi-check"
						onClick={handleSaveClick}
						disabled={isSaveButtonDisabled} // Disable the Save button
					/>
				</div>
			}
		>
			<div className="col-11 mx-auto">
				{errorMessage && <div className="p-error text-center mt-3">{errorMessage}</div>}

				<div className="row fw-bold mt-4">
					<label htmlFor="director">Director Rating (%)</label>
					<InputText
						id="director"
						value={percentages.director}
						onChange={e => handleInputChange(e, "director")}
						keyfilter="pint"
						placeholder="Enter percentage"
						className={`mt-3 ${errorMessage ? "p-invalid" : ""}`} // Add invalid class if error
					/>
				</div>

				<div className="row fw-bold mt-4">
					<label htmlFor="employee">Employee Rating (%)</label>
					<InputText
						id="employee"
						value={percentages.employee}
						onChange={e => handleInputChange(e, "employee")}
						keyfilter="pint"
						placeholder="Enter percentage"
						className={`mt-3 ${errorMessage ? "p-invalid" : ""}`} // Add invalid class if error
					/>
				</div>

				<div className="row fw-bold mt-4">
					<label htmlFor="self">Self Rating (%)</label>
					<InputText
						id="self"
						value={percentages.self}
						onChange={e => handleInputChange(e, "self")}
						keyfilter="pint"
						placeholder="Enter percentage"
						className={`mt-3 ${errorMessage ? "p-invalid" : ""}`} // Add invalid class if error
					/>
				</div>
			</div>
		</Dialog>
	);
};

export default RatingDialog;
