import {useState, useEffect, useRef} from "react";
import {AutoComplete} from "primereact/autocomplete";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import axios from "axios";
import {base, project_events_url, projects_url, locations_url} from "../../../api";
import {Toast} from "primereact/toast";
import {Dropdown} from "primereact/dropdown";
import {Link} from "react-router-dom";

export default function BeneficareForm(props) {
	const toast = useRef(null);
	const [sendData, setSendData] = useState({
		date: "",
		name: "",
	});

	const [inputList, setInputList] = useState([
		{
			full_name: "",
			address: "",
			company: "",
			position: "",
			phone: "",
			email: "",
			gender: "",
			age: "",
		},
	]);

	const [project, setProject] = useState();
	const [selectedProject, setSelectetProject] = useState();
	const [filteredProject, setFilteredProject] = useState();

	const [location, setLocation] = useState();
	const [selectedLocation, setSelectetLocation] = useState();
	const [filteredLocation, setFilteredLocation] = useState();

	const fatchData = () => {
		axios.all([axios.get(base + projects_url), axios.get(base + locations_url), props.formData && axios.get(base + project_events_url)]).then(
			axios.spread((...response) => {
				setProject(response[0].data);
				setLocation(response[1].data);
				setSendData({...sendData, name: response[2].data.name});
			})
		);
	};
	useEffect(() => {
		fatchData();
	}, []);

	function submitData(e) {
		axios
			.post(base + project_events_url, {
				project_id: selectedProject?.id,
				name: sendData.name,
				date: sendData.date,
				location_id: selectedLocation?.id,
				beneficiaries: inputList,
			})
			.then(res => {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: "The item is added successfully",
					life: 3000,
				});
				setTimeout(() => {
					setSendData();

					props?.setFormData(false);
				}, 1500);
			})
			.catch(error => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: "Please add something than press the button ",
					life: 3000,
				});
			});
	}

	const searchProject = e => {
		let query = e.query;
		let _filteredPosition = project?.filter(item => item?.full_name_am?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
		setFilteredProject(_filteredPosition);
	};

	const searchLocation = e => {
		let query = e.query;
		let _filteredPosition = location?.filter(item => item?.title_am?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
		setFilteredLocation(_filteredPosition);
	};

	const handleInputChange = (e, index) => {
		const {name, value} = e.target;
		const list = [...inputList];
		list[index][name] = value;
		setInputList(list);
	};

	const handleRemoveClick = index => {
		const list = [...inputList];
		list.splice(index, 1);
		setInputList(list);
	};

	const handleAddClick = () => {
		setInputList([
			...inputList,
			{
				full_name: "",
				address: "",
				company: "",
				position: "",
				phone: "",
				email: "",
				gender: "",
				age: "",
			},
		]);
	};
	// console.log(inputList);
	return (
		<>
			<div className="col-lg-12 mb-4">
				<div className="card b-radius b-shadow px-4 py-4 mb-3">
					<div className="fs-5 fw-bold text-uppercase border-bottom mb-3 pb-3"> Ավելացնել նոր նախագիծ </div>
					<div className="row">
						<div className="col-3">
							<label className="mb-2">Ծրագիր</label>
							<AutoComplete
								dropdown
								className="col-12 p-0"
								placeholder="Ընտրել ցանկից"
								field={"full_name_am"}
								completeMethod={searchProject}
								suggestions={filteredProject}
								value={selectedProject}
								onChange={e => setSelectetProject(e.value)}
								itemTemplate={item => (
									<div className="d-flex align-items-top">
										<div className="d-grid">
											<span className="text-primary">{item.full_name_am?.slice(0, 30)} </span>
											<span className="text-secondary small"> {item.full_name_en?.slice(0, 30) ?? "not provided"} </span>
											<span className="text-secondary small"> {item.name?.slice(0, 30) ?? "not provided"} </span>
										</div>
									</div>
								)}
							/>
						</div>
						<div className="col-3">
							<label className="mb-2">Միջոցառման անվանում</label>
							<InputText type="text" className="col-12 p-2" value={sendData?.name} onChange={e => setSendData({...sendData, name: e.target.value})} />
						</div>
						<div className="col-3">
							<label className="mb-2">Միջոցառման օրը</label>
							<InputText type="date" className="col-12 p-2" value={sendData?.date} onChange={e => setSendData({...sendData, date: e.target.value})} />
						</div>

						<div className="col-3">
							<label className="mb-2">Վայրը</label>
							<AutoComplete
								dropdown
								className="col-12 p-0"
								placeholder="Ընտրել ցանկից"
								field={"title_am"}
								completeMethod={searchLocation}
								suggestions={filteredLocation}
								value={selectedLocation}
								onChange={e => setSelectetLocation(e.value)}
								itemTemplate={item => (
									<div className="d-flex align-items-top">
										<div className="d-grid">
											<span className="text-primary">{item.title_am?.slice(0, 30)} </span>
											<span className="text-secondary small"> {item.title_en?.slice(0, 30) ?? "not provided"} </span>
										</div>
									</div>
								)}
							/>
						</div>
						<div className="col-12 p-4">
							{inputList.map((item, index) => {
								return (
									<div key={index} className="row align-items-center">
										<div className="col-11 p-0">
											<div className="row">
												<div className="col-3">
													<InputText name="full_name" className="col-12" placeholder="Շահառույի անուն" value={item.full_name} onChange={e => handleInputChange(e, index)} />
												</div>
												<div className="col-3">
													<InputText name="address" className="col-12" placeholder="Հասցե" value={item.address} onChange={e => handleInputChange(e, index)} />
												</div>
												<div className="col-3">
													<InputText name="company" className="col-12" placeholder="Աշխատավայր" value={item.company} onChange={e => handleInputChange(e, index)} />
												</div>
												<div className="col-3">
													<InputText name="position" className="col-12" placeholder="Պաշտոն/հաստիք" value={item.position} onChange={e => handleInputChange(e, index)} />
												</div>
												<div className="col-3">
													<InputText name="phone" className="col-12" placeholder="Հեռախոս" value={item.phone} onChange={e => handleInputChange(e, index)} />
												</div>
												<div className="col-3">
													<InputText name="email" className="col-12" placeholder="Էլ հասցե" value={item.email} onChange={e => handleInputChange(e, index)} />
												</div>
												<div className="col-3 d-flex align-items-center">
													<div className="form-check form-check-inline">
														<input className="form-check-input" type="radio" name="gender" id="gender" value={"Male"} onChange={e => handleInputChange(e, index)} />
														<label className="form-check-label mt-1" for="gender">
															Արական
														</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input" type="radio" name="gender" id="gender" value={"Female"} onChange={e => handleInputChange(e, index)} />
														<label className="form-check-label mt-1" for="gender">
															Իգական
														</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input" type="radio" name="gender" id="gender" value={"Other"} onChange={e => handleInputChange(e, index)} />
														<label className="form-check-label mt-1" for="gender">
															Այլ
														</label>
													</div>
												</div>

												<div className="col-3">
													<select className="form-select" name="age" value={item.age} onChange={e => handleInputChange(e, index)}>
														<option selected disabled>
															Open this select menu
														</option>
														<option value="16-18">16-18</option>
														<option value="18-25">18-25</option>
														<option value="25-30">25-30</option>
														<option value="30+">30+</option>
													</select>
												</div>
											</div>
										</div>

										<div className="col-1 p-0">
											{inputList.length !== 1 && <Button severity="danger" text rounded icon={"pi pi-trash"} className="ms-2" onClick={() => handleRemoveClick(index)} />}
											{inputList.length - 1 === index && <Button severity="success" text rounded icon={"pi pi-plus"} className="ms-2" onClick={() => handleAddClick()} />}
										</div>
										<hr />
									</div>
								);
							})}
						</div>
					</div>

					<div className="col-12 text-center border-top py-5">
						<Link to={"/beneficiaries"} className="mx-2 px-5 btn btn-secondary">
							Cancel
						</Link>
						<Button label="Save" rounded className="mx-2 px-5" severity="primary" onClick={e => submitData(e)} />
					</div>
				</div>
			</div>

			<Toast ref={toast} />
		</>
	);
}
