import React from "react";

export default function Footer() {
	return (
		<footer className="footer p-3 mt-auto text-center mt-5">
			<div className="mt-3 small">web version 1.6.7</div>
			<div className="mt-3 small">
				Copyright &copy; 2018 - {new Date().getFullYear()}
				<a target="blank" href="https://arpinet.am" className="text-muted">
					{" "}
					Arpinet LLC
				</a>
				, All rights reserved.
			</div>
		</footer>
	);
}
