import React from 'react'
import permCard from '../../../assets/images/illustrations/girl-unlock-password-light.png'
export default function PermLessCard() {
    return (
        <div className='col-4 mx-auto my-5 py-5'>
            <img className="w-100" src={permCard} />
            <div className='fw-bold fs-5 text-center mt-5'>
                You don't have permission
            </div>
        </div>
    )
}
