import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import {Button} from "primereact/button";
import {
	base,
	expance,
	unit,
	budget_by_project,
	expance_type,
	// budget_by_project_create,
} from "../../../../api";
import {Dialog} from "primereact/dialog";
import {Toast} from "primereact/toast";
import ExpancesByRow from "./components/ExpancesByRow";
import CurrencyValue_data from "../../widgets/CurrencyValue_data";
import {Calendar} from "primereact/calendar";
import {InputNumber} from "primereact/inputnumber";

export default function ExpancesProjectsMounthly(props) {
	const projectCurrencyValue = parseFloat(props?.project?.currency_value).toFixed(2);
	const toast = useRef(null);
	const [isEditing, setIsEditing] = useState(true);
	const [dialogVisibility, setDialogVisibility] = useState(false);
	const [optionExpances, setOptionExpances] = useState([]);
	const [optionExpanceType, setOptionExpanceType] = useState([]);
	const [optionUnit, setOptionUnit] = useState([]);
	const [budgetByProject, setBudgetByProject] = useState();
	const [filterDialog, setFilterDialog] = useState(false);
	const [projectID, setProjectID] = useState();

	const dateStringToObject = dateStr => {
		return new Date(dateStr);
	};

	const dateObjectToString = dateObj => {
		if (!dateObj) return "";
		const year = dateObj.getFullYear();
		const month = String(dateObj.getMonth() + 1).padStart(2, "0");
		const day = String(dateObj.getDate()).padStart(2, "0");
		return `${year}-${month}-${day}`;
	};

	const minimumDate = props?.project?.start_date
		? dateStringToObject(props.project.start_date)
		: null;
	const maximumDate = props?.project?.end_date ? dateStringToObject(props.project.end_date) : null;

	const [selectedDayRange, setSelectedDayRange] = useState({
		from: null,
		to: null,
	});

	useEffect(() => {
		const startDateStr = props?.project?.start_date;
		const endDateStr = props?.project?.end_date;
		setSelectedDayRange({
			from: startDateStr ? dateStringToObject(startDateStr) : null,
			to: endDateStr ? dateStringToObject(endDateStr) : null,
		});
	}, [props?.project?.start_date, props?.project?.end_date]);

	useEffect(() => {
		if (props.projectID) {
			setProjectID(props.projectID);
		}
	}, [props.projectID]);

	useEffect(() => {
		loadContent();
		console.log("from new");
	}, [props?.contentLoadTrigger]);

	const onFilter = () => {
		loadContent(
			`?from_date=${dateObjectToString(selectedDayRange.from)}&to_date=${dateObjectToString(
				selectedDayRange.to
			)}`
		);
		setFilterDialog(false);
	};

	const loadContent = (params = null) => {
		axios
			.get(`${base}${budget_by_project}${props?.projectID}/${params ? params : ""}`)
			.then(res => {
				setBudgetByProject(res.data.filter(budget => budget.count > 0));
			});
		axios
			.all([axios.get(base + expance), axios.get(base + unit), axios.get(base + expance_type)])
			.then(
				axios.spread((...response) => {
					setOptionExpances(response[0].data);
					setOptionUnit(response[1].data);
					setOptionExpanceType(response[2].data);
				})
			);
	};

	const headerElement = (
		<div className="d-grid align-items-center">
			<span className="text-primary">{props?.project?.number}</span>
			<span className="small fw-normal">{props?.project?.name}</span>
			<span className="small fw-normal text-truncate">{props?.project?.full_name_am}</span>
		</div>
	);

	return (
		<>
			<div className="w-100 card p-3 rounded-4 shadow-lg mt-3 ">
				<div className="text-start my-3 fs-4 text-uppercase fw-bold px-3 border-bottom pb-3 d-flex align-items-center">
					<i className="pi pi-info-circle me-2 fs-4 fw-bold"></i>
					Ծախս
				</div>
				<div className="d-flex justify-content-end px-3 mb-3">
					<CurrencyValue_data data={projectCurrencyValue} />
					<div className="col-lg-4 col-12 text-end">
						<Button
							label="Filter budget"
							rounded
							severity="primary"
							icon="pi pi-filter"
							onClick={() => setFilterDialog(true)}
						/>
						{/* //filter by default this mounth */}
					</div>
				</div>
				{budgetByProject?.length > 0 ? (
					<>
						<div className="col-12 p-0">
							<ExpancesByRow
								data={projectID}
								projectCurrencyValue={projectCurrencyValue}
								currency_value={props?.currency_value}
							/>
						</div>
					</>
				) : (
					<div className="col-12">
						<div className="card w-100 border-0 text-center">Դեռ նշումներ չկան բյուջեի դաշտում</div>
					</div>
				)}
				<Toast ref={toast} />
			</div>
		</>
	);
}
