import React, {useState, useEffect} from "react";
import axios from "axios";
import {InputText} from "primereact/inputtext";
import {AutoComplete} from "primereact/autocomplete";
import {Calendar} from "primereact/calendar";
import {InputNumber} from "primereact/inputnumber";
import {Button} from "primereact/button";
import {base, partners as partnersUrl, projects_url, supply_contracts} from "../../../api";

export default function NewContractAddForm({setDialogVisibility}) {
	const [contractData, setContractData] = useState({
		contractNumber: "",
		partner: null,
		deliveryTime: "", // Normal input for number of days
		warrantyPeriod: "",
		programProject: null,
		project: null,
		startEndDate: {start: null, end: null}, // Start and End date as Date objects
		contractDate: null, // Date object for contract date
		prepaymentPercentage: null,
	});

	const [partners, setPartners] = useState([]);
	const [filteredPartners, setFilteredPartners] = useState([]);
	const [programProjects, setProgramProjects] = useState([]);
	const [filteredProgramProjects, setFilteredProgramProjects] = useState([]);
	const [projects, setProjects] = useState([]);
	const [filteredProjects, setFilteredProjects] = useState([]);

	useEffect(() => {
		axios
			.get(base + partnersUrl)
			.then(response => {
				const partnersData = response.data.map(partner => ({
					label: partner.full_name_en || partner.full_name_am || "",
					value: partner.id,
				}));
				setPartners(partnersData);
			})
			.catch(error => console.error("Error fetching partners:", error));

		axios
			.get(base + projects_url)
			.then(response => {
				const programProjectsData = response.data.map(programProject => ({
					label: programProject.name,
					long_name: programProject.full_name_am,
					value: programProject.id,
				}));
				setProgramProjects(programProjectsData);
			})
			.catch(error => console.error("Error fetching program projects:", error));

		axios
			.get(base + projects_url)
			.then(response => {
				const projectsData = response.data.map(project => ({
					label: project.name,
					long_name: project.full_name_am,
					value: project.id,
				}));
				setProjects(projectsData);
			})
			.catch(error => console.error("Error fetching projects:", error));
	}, []);

	const handleChange = (e, field) => {
		setContractData({...contractData, [field]: e.value || e.target.value});
	};

	const saveData = () => {
		// Validate required fields

		// Prepare the payload with proper formatting
		const requestData = {
			project: contractData.project?.value,
			partner: contractData.partner?.value,
			advance_payment: contractData.prepaymentPercentage,
			contract_date: contractData.contractDate?.toISOString().split("T")[0], // Convert to YYYY-MM-DD
			delivery_time: parseInt(contractData.deliveryTime, 10), // Convert to integer
			start_date: contractData.startEndDate.start?.toISOString().split("T")[0], // Convert to YYYY-MM-DD
			end_date: contractData.startEndDate.end?.toISOString().split("T")[0], // Convert to YYYY-MM-DD
			warranty_period: contractData.warrantyPeriod || null,
			purchase_price: 0,
		};

		console.log("Request payload:", requestData);

		axios
			.post(base + supply_contracts, requestData, {
				headers: {"Content-Type": "application/json"},
			})
			.then(response => {
				console.log("Contract saved successfully:", response.data);
				setDialogVisibility(false);
			})
			.catch(error => {
				console.error("Error saving contract:", error);
			});
	};

	const searchPartner = event => {
		const query = event.query.toLowerCase();
		setFilteredPartners(partners.filter(partner => partner.label.toLowerCase().includes(query)));
	};

	const searchProgramProject = event => {
		const query = event.query.toLowerCase();
		setFilteredProgramProjects(
			programProjects.filter(programProject => programProject.label.toLowerCase().includes(query))
		);
	};

	const searchProject = event => {
		const query = event.query.toLowerCase();
		setFilteredProjects(projects.filter(project => project.label.toLowerCase().includes(query)));
	};

	const showAllPartners = () => setFilteredPartners(partners);
	const showAllProgramProjects = () => setFilteredProgramProjects(programProjects);
	const showAllProjects = () => setFilteredProjects(projects);

	return (
		<>
			<div className="p-fluid row">
				<div className="field col-3">
					<label htmlFor="contractNumber">Contract Number</label>
					<InputText
						id="contractNumber"
						value={contractData.contractNumber}
						onChange={e => handleChange(e, "contractNumber")}
					/>
				</div>

				<div className="field col-4">
					<label htmlFor="partner">Partner</label>
					<AutoComplete
						id="partner"
						value={contractData.partner}
						suggestions={filteredPartners}
						completeMethod={searchPartner}
						field="label"
						onChange={e => setContractData({...contractData, partner: e.value})}
						placeholder="Search Partner"
						dropdown
						onDropdownClick={showAllPartners}
					/>
				</div>

				<div className="field col-5">
					<label htmlFor="programProject">Program Project</label>
					<AutoComplete
						id="programProject"
						value={contractData.programProject}
						suggestions={filteredProgramProjects}
						completeMethod={searchProgramProject}
						field="label"
						onChange={e => setContractData({...contractData, programProject: e.value})}
						placeholder="Search Program Project"
						dropdown
						onDropdownClick={showAllProgramProjects}
					/>
				</div>

				<div className="field col-3">
					<label htmlFor="project">Project</label>
					<AutoComplete
						id="project"
						value={contractData.project}
						suggestions={filteredProjects}
						completeMethod={searchProject}
						field="label"
						onChange={e => setContractData({...contractData, project: e.value})}
						placeholder="Search Project"
						dropdown
						onDropdownClick={showAllProjects}
					/>
				</div>

				<div className="field col-3">
					<label htmlFor="deliveryTime">Delivery Time (days)</label>
					<InputText
						id="deliveryTime"
						value={contractData.deliveryTime}
						onChange={e => handleChange(e, "deliveryTime")}
						placeholder="Enter number of days"
					/>
				</div>

				<div className="field col-3">
					<label htmlFor="warrantyPeriod">Warranty Period</label>
					<InputText
						id="warrantyPeriod"
						value={contractData.warrantyPeriod}
						onChange={e => handleChange(e, "warrantyPeriod")}
					/>
				</div>

				<div className="field col-3">
					<label htmlFor="startEndDateStart">Start Date</label>
					<Calendar
						id="startEndDateStart"
						value={contractData.startEndDate.start}
						onChange={e =>
							setContractData({
								...contractData,
								startEndDate: {...contractData.startEndDate, start: e.value},
							})
						}
						dateFormat="yy-mm-dd"
						placeholder="YYYY-MM-DD"
					/>
				</div>

				<div className="field col-3">
					<label htmlFor="startEndDateEnd">End Date</label>
					<Calendar
						id="startEndDateEnd"
						value={contractData.startEndDate.end}
						onChange={e =>
							setContractData({
								...contractData,
								startEndDate: {...contractData.startEndDate, end: e.value},
							})
						}
						dateFormat="yy-mm-dd"
						placeholder="YYYY-MM-DD"
					/>
				</div>

				<div className="field col-3">
					<label htmlFor="contractDate">Contract Date</label>
					<Calendar
						id="contractDate"
						value={contractData.contractDate}
						onChange={e => handleChange(e, "contractDate")}
						dateFormat="yy-mm-dd"
						placeholder="YYYY-MM-DD"
					/>
				</div>

				<div className="field col-3">
					<label htmlFor="prepaymentPercentage">Prepayment Percentage</label>
					<InputNumber
						id="prepaymentPercentage"
						value={contractData.prepaymentPercentage}
						onValueChange={e => handleChange(e, "prepaymentPercentage")}
						mode="decimal"
						min={0}
						max={100}
						suffix="%"
					/>
				</div>
			</div>

			<div className="col-12 text-center my-4">
				<Button
					label="Cancel"
					icon="pi pi-times"
					rounded
					className="p-button-secondary mx-2"
					onClick={() => setDialogVisibility(false)}
				/>
				<Button label="Save" icon="pi pi-check" rounded className="mx-2" onClick={saveData} />
			</div>
		</>
	);
}
