import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputMask } from "primereact/inputmask";

// import userIcon from '../../../assets/images/illustrations/girl-doing-yoga-light.png'

export default function AddUserForm(props) {
    const inputRef = useRef(null);
    // const [avatar, setAvatar] = useState(null);
    // const [imagePreviewUrl, setImagePreviewURL] = useState('');

    // function handleImageChange(e) {
    //     props.setUserInfo({ ...props.userInfo, avatar: e.target.files[0] })
    //     setAvatar(e.target.files[0])
    //     setImagePreviewURL(URL.createObjectURL(e.target.files[0]))
    // };

    // function removeImage() {
    //     setAvatar(null);
    //     setImagePreviewURL(null)
    // }

    // const [mobile, setMobile] = useState(props?.data.phone)
    // const onChange = (e) => (
    //     console.log(e)
    // )
    useEffect(() => {
        props.setUserInfo({ ...props.userInfo, mobile: props.data.phone, partner: props.data.id, password: '111111' })
    }, [props.data.id])
    console.log();
    return (
        <div className="text-center pt-3">
            {/* <div className="row">
                <div className="d-flex justify-content-between align-items-center pb-4">
                    <div className="col-md-3 col-12 p-0">
                        <div className="image w-100 b-radius b-shadow" style={{ backgroundImage: `url(${imagePreviewUrl ? imagePreviewUrl : userIcon})` }} alt="upload an avatar" />
                    </div>
                    <div className='col-9'>
                        <label htmlFor="upload" rounded severity="help" className="btn btn-primary" tabindex="0">
                            <span className="d-none d-sm-block">Upload avatar</span>
                            <InputText type="file" hidden id="upload" accept="image/png, image/jpeg, image/gif" onChange={handleImageChange} />
                        </label>
                        {imagePreviewUrl && <Button icon={'pi pi-times'} rounded outlined severity='danger' className='mx-3' onClick={removeImage} />}
                        <p className="col-12 text-muted mb-0 mt-2 small ">Allowed JPG, GIF or PNG. Max size of 2MB</p>
                    </div>
                </div>
            </div> */}

            <div className="flex flex-column text-start">
                <label htmlFor="username" className='py-3'>Username</label>
                <InputText id="username" className='text-lowercase'
                    keyfilter="alphanum"
                    value={props?.data.username}
                    onChange={(e) => props.setUserInfo({ ...props.userInfo, username: e.target.value })}
                />
            </div>

            <div className="flex flex-column text-start">
                <label htmlFor="mobile" className='py-3'>Mobile</label>
                <InputText id="mobile" className='text-lowercase'
                    value={props?.userInfo.mobile}
                    onChange={(e) => props.setUserInfo({ ...props.userInfo, mobile: e.target.value })}
                />
            </div>

            <div className="flex flex-column text-start">
                <label htmlFor="email" className='py-3'>Email</label>
                <InputText id="email" disabled value={props?.data.email} />
            </div>

            <div className="flex flex-column text-start">
                <label htmlFor="birth_date" className='py-3'>Birth date</label>
                <InputText id="birth_date" disabled value={props?.data.birth_date} />
            </div>

            <div className="flex flex-column text-start">
                <label htmlFor="address" className='py-3'>Address</label>
                <InputText id="address" disabled value={props?.data.address_en ?? 'no address provided'} />
            </div>

            <div className="flex flex-column text-start">
                <label htmlFor="passport" className='py-3'>Passport / ID</label>
                <InputText id="passport" disabled value={props?.data.passport ?? 'passport or ID not provided'} />
            </div>

            <div className='w-100 d-flex justify-content-center mt-5 mb-3'>
                <Button label="Add a user" rounded severity='primary' className='mx-2'
                    onClick={(e) => { props.add() }}
                />
            </div>
        </div>
    )
}
