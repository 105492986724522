import React, {useState} from "react";
import {Button} from "primereact/button";

const CopyToClipboard = props => {
	const [copied, setCopied] = useState(false);

	const copyToClipboard = () => {
		navigator.clipboard
			.writeText(props.data.property)
			.then(() => {
				setCopied(true);
				setTimeout(() => {
					setCopied(false);
				}, 1500);
			})
			.catch(err => {
				console.error("Failed to copy: ", err);
			});
	};

	return (
		<div className="row">
			<div className="col-8">
				<code className="fw-bold">{props.t(props.data.property)}</code>
				<div className="small">{props.t(props.data.description)}</div>
			</div>
			<div className="col-4 text-end">
				<Button icon={copied ? "pi pi-check" : "pi pi-save"} rounded outlined severity={copied ? "success" : "primary"} onClick={copyToClipboard} className="outline-none me-2" />
			</div>
		</div>
	);
};

export default CopyToClipboard;
